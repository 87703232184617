import { CheckboxField, ChipsField, Form, Formik } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ContentContainer,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  discountConfigurationQuery,
  discountConfigurationQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell25, GridCell50, TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { discountMessages } from '../../messages';
import { DISCOUNT_CONFIGURATION_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export interface FormValues {
  benefitPackages: Record<string, string>;
  orgUnits: Record<string, string>;
  visible: boolean;
}

export const DiscountConfiguration: React.FC = () => {
  const match = useRouteMatch<MatchParams>();
  const { companyId, benefitId: discountId } = match.params;

  const { data, loading, error } = useQuery<
    discountConfigurationQuery,
    discountConfigurationQueryVariables
  >(DISCOUNT_CONFIGURATION_QUERY, {
    errorPolicy: 'all',
    variables: {
      benefitId: discountId,
      companyId,
    },
  });

  const { submit, submissionError } = useSubmit({
    discountId,
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.benefit) {
    return <GraphQlError error={error} />;
  }

  const benefitPackageOptions =
    data?.company?.benefitPackages?.edges.map(({ node: { id, name } }) => ({
      label: name,
      value: id,
    })) ?? [];

  const orgUnitOptions =
    data?.company?.organizationalUnits?.edges.map(({ node: { id, name } }) => ({
      label: name,
      value: id,
    })) ?? [];

  const { entitlementRules, hidden } = data?.benefit ?? {};

  return (
    <ContentContainer>
      <Section>
        {error && <GraphQlError error={error} />}
        <Formik<FormValues>
          initialValues={{
            benefitPackages: entitlementRules?.benefitPackageIds
              ? entitlementRules.benefitPackageIds.reduce(
                  (acc, id) => ({
                    ...acc,
                    [id]: benefitPackageOptions.find(
                      option => option.value === id,
                    )?.label,
                  }),
                  {},
                )
              : {},
            orgUnits: entitlementRules?.ouIds
              ? entitlementRules.ouIds.reduce(
                  (acc, id) => ({
                    ...acc,
                    [id]: orgUnitOptions.find(option => option.value === id)
                      ?.label,
                  }),
                  {},
                )
              : {},
            visible: !hidden,
          }}
          enableReinitialize
          onSubmit={submit}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              <Subsection>
                <SubsectionHeader>
                  <FormattedMessage {...commonMessages.benefitPackages} />
                </SubsectionHeader>
                <Grid>
                  <GridCell50>
                    <ChipsField
                      dense
                      name="benefitPackages"
                      label={
                        <FormattedMessage
                          {...discountMessages.visibilityLabel}
                        />
                      }
                      options={benefitPackageOptions}
                    />
                  </GridCell50>
                </Grid>
              </Subsection>
              <Subsection>
                <SubsectionHeader>
                  <FormattedMessage {...commonMessages.organizationalUnit} />
                </SubsectionHeader>
                <TextGrid>
                  <DescriptionWrapper>
                    <FormattedMessage
                      {...discountMessages.organizationalUnitsDescription}
                    />
                  </DescriptionWrapper>
                </TextGrid>
                <Grid>
                  <GridCell50>
                    <ChipsField
                      dense
                      name="orgUnits"
                      label={
                        <FormattedMessage {...companyMessages.selectOrgUnit} />
                      }
                      options={orgUnitOptions}
                    />
                  </GridCell50>
                </Grid>
              </Subsection>
              <Subsection>
                <SubsectionHeader>
                  <FormattedMessage {...commonMessages.visibility} />
                </SubsectionHeader>
                <TextGrid>
                  <DescriptionWrapper>
                    <FormattedMessage
                      {...discountMessages.visibilityDescription}
                    />
                  </DescriptionWrapper>
                </TextGrid>
                <Grid>
                  <GridCell25>
                    <CheckboxField
                      name="visible"
                      label={
                        <FormattedMessage
                          {...discountMessages.visibilityLabel}
                        />
                      }
                    />
                  </GridCell25>
                </Grid>
              </Subsection>
              {submissionError && <GraphQlError error={submissionError} />}
              <ButtonLayout>
                <Button
                  type="submit"
                  filled
                  disabled={!dirty}
                  loading={isSubmitting}
                >
                  <FormattedMessage {...discountMessages.submit} />
                </Button>
              </ButtonLayout>
            </Form>
          )}
        </Formik>
      </Section>
    </ContentContainer>
  );
};
