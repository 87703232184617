import { Table, Td, Th, Tr } from '@frontend/ui';
import { companyBillingConfigurationDetails } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { billingMessages } from '../../messages';
import { openEditBillingEmailModal } from '../edit';
import { BillingMailRow } from './BillingMailRow';
import { useDelete } from './delete';

interface Props {
  billingEmails: companyBillingConfigurationDetails[];
}

export const BillingEmailTable: React.FC<Props> = ({ billingEmails }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { _delete, deleteError } = useDelete();

  return (
    <>
      <Table>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.email} />
            </Th>
            <Th>
              <FormattedMessage {...billingMessages.attachments} />
            </Th>
            <Th />
          </Tr>
        </thead>
        <tbody>
          {billingEmails.length !== 0 ? (
            billingEmails.map(billingEmail => (
              <BillingMailRow
                key={billingEmail.id}
                billingEmail={billingEmail}
                onDelete={() => _delete(billingEmail.id)}
                onEdit={() =>
                  push({
                    pathname,
                    search: qs.stringify(
                      openEditBillingEmailModal(billingEmail.id),
                    ),
                  })
                }
              />
            ))
          ) : (
            <Tr>
              <Td>
                <FormattedMessage {...billingMessages.noBillingEmails} />
              </Td>
            </Tr>
          )}
        </tbody>
      </Table>
      {deleteError && <GraphQlError error={deleteError} />}
    </>
  );
};
