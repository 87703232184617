import gql from 'graphql-tag';

export const EXECUTE_PROPOSAL_QUERY = gql`
  query executeProposalQuery($id: ID!, $companyId: ID!) {
    proposal(id: $id) {
      id
      startDate
    }
    company(id: $companyId) {
      id
      validKyc
    }
  }
`;
