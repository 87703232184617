import gql from 'graphql-tag';

export const SEND_MEETING_INVITATIONS_MUTATION = gql`
  mutation sendMeetingInvitationsMutation(
    $input: SendMeetingInvitationsInput!
  ) {
    sendMeetingInvitations(input: $input) {
      clientMutationId
    }
  }
`;

export const CONFIRM_MEETING_MUTATION = gql`
  mutation confirmMeetingMutation($input: ConfirmMeetingInput!) {
    confirmMeeting(input: $input) {
      clientMutationId
    }
  }
`;

export const CLAIM_PENSION_TRANSFERS_MUTATION = gql`
  mutation claimPensionTransferTasksMutation(
    $input: ClaimPensionTransferTasksInput!
  ) {
    claimPensionTransferTasks(input: $input) {
      clientMutationId
    }
  }
`;

export const DELETE_MEETING_MUTATION = gql`
  mutation deleteMeetingMutation($input: DeleteMeetingInput!) {
    deleteMeeting(input: $input) {
      clientMutationId
    }
  }
`;

export const CANCEL_MEETING_MUTATION = gql`
  mutation cancelMeetingMutation($input: CancelMeetingInput!) {
    cancelMeeting(input: $input) {
      clientMutationId
    }
  }
`;
