import gql from 'graphql-tag';

export const EDIT_PROPOSAL_CONVERT_RISK_MUTATION = gql`
  mutation editProposalConvertRiskMutation(
    $input: UpdateProposalMembershipInput!
  ) {
    updateProposalMembership(input: $input) {
      id
      convertRiskInsurances
    }
  }
`;
