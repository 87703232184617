import { Loading } from '@frontend/ui';
import { client } from 'app/apollo/client';
import { clearBrowserStore } from 'app/browser-store';
import { getBackstageCsrfTokenFromCookie } from 'app/helper';
import axios from 'axios';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

import { logoutMessages } from './messages';

type Props = RouteComponentProps<object, object, { from?: string }>;

export const Logout: React.FC<Props> = ({
  location: { state },
  history: { push },
}) => {
  const [logoutError, setLogoutError] = useState<boolean>(false);

  const clearState = useCallback(async (): Promise<void> => {
    try {
      await client.resetStore();
      await clearBrowserStore();

      push({
        pathname: '/login',
        search: state?.from ? `?next=${state.from}` : undefined,
      });
    } catch {
      setLogoutError(true);
    }
  }, [push, state]);

  const logout = useCallback(async (): Promise<void> => {
    setLogoutError(false);

    const csrfToken = getBackstageCsrfTokenFromCookie();
    if (!csrfToken) {
      clearState();
      return;
    }

    try {
      await axios.delete(`${window.env.API}/session`, {
        withCredentials: true,
        headers: { 'X-Csrf-Token': csrfToken },
      });
      clearState();
    } catch {
      setLogoutError(true);
    }
  }, [clearState]);

  useEffect(() => {
    logout();
  }, [logout]);

  if (logoutError)
    return (
      <NotificationCard type="warning">
        <FormattedMessage {...logoutMessages.logoutError} />
      </NotificationCard>
    );

  return (
    <Loading message={<FormattedMessage {...logoutMessages.loggingOut} />} />
  );
};
