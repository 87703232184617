import { Table, Td, Th, Tr } from '@frontend/ui';
import { companyImportsQuery_company_Company_imports_ImportConnection_edges_ImportEdge_node_Import as Import } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employeesImportStatusMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';

import { MatchParams } from '../..';

interface Props {
  imports: Import[];
  navigation: React.ReactNode;
}

export const ImportsTable: React.FC<Props> = ({ imports, navigation }) => {
  const history = useHistory();

  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  return (
    <Table navigation={navigation} fixed>
      <>
        <colgroup>
          <col style={{ width: '30%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '30%' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.fileName} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.status} />
            </Th>
            <Th type="number">
              <FormattedMessage {...commonMessages.date} />
            </Th>
            <Th align="left">
              <FormattedMessage {...commonMessages.actor} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {imports.map(i => (
            <Tr
              key={i.id}
              linked
              onClick={() => {
                history.push(
                  `/companies/${companyId}/employees/import/match/${i.id}`,
                );
              }}
            >
              <Td>{i.name}</Td>
              <Td>
                <FormattedMessage
                  select={i.status}
                  messages={employeesImportStatusMessages}
                />
              </Td>
              <Td type="number">
                <FormattedDate
                  value={i.createdAt}
                  timeStyle="short"
                  dateStyle="short"
                />
              </Td>
              <Td align="left">{i.actor?.displayName ?? <NoValue />}</Td>
            </Tr>
          ))}
        </tbody>
      </>
    </Table>
  );
};
