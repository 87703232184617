import { SearchableSelectOption } from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage, IntlShape } from 'components/formats';
import React from 'react';
import * as Yup from 'yup';

import { SelectUserAccountField } from './select-user-account-field';

export const selectUserAccountValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    userAccount: Yup.object().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface SelectUserAccountFormValues {
  userAccount: SearchableSelectOption | null;
}

export const selectUserAccountInitialValues: SelectUserAccountFormValues = {
  userAccount: null,
};

export const SelectUserAccountHeader: React.FC = () => (
  <FormattedMessage {...companyMessages.addAdministrator} />
);

export const SelectUserAccountBody: React.FC = () => (
  <SelectUserAccountField inModal name="userAccount" required />
);
