import gql from 'graphql-tag';

export const CREATE_EURO_ACCIDENT_TJP_AGREEMENT_QUERY = gql`
  query createEuroAccidentTjpAgreementQuery($id: ID!) {
    company(id: $id) {
      id
      managedBy
    }
  }
`;
