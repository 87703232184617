import { BenefitType } from '@frontend/benefit-types';
import { ContentContainer } from '@frontend/ui';
import { flightTakeoff, warning } from '@frontend/ui/icons';
import { startOfNextMonth } from '@frontend/utils';
import {
  employeeDetailsLayoutQuery,
  employeeDetailsLayoutQueryVariables,
  FlexSalaryExchangeRequestStatus,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  employeeMessages,
  employeePensionMessages,
} from 'app/messages/employees';
import { menuMessages } from 'app/messages/menu';
import { noteMessages } from 'app/messages/notes';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage } from 'components/formats';
import { LinkButton } from 'components/LinkButton';
import { NotificationCard } from 'components/NotificationCard';
import { RoutedTab } from 'components/RoutedTabBar';
import { TopLoading } from 'components/TopLoading';
import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { EmployeeFcfwNotification } from 'features/companies/company/employees/assert-work-capability/notification/employee';
import { hasPensionPremiumPermission } from 'features/companies/company/employees/employee/occupational/pension/utils/has-pension-premium-permission';
import { getUpdatePremiumLink } from 'features/companies/company/employees/employee/occupational/update-premium/components/UpdatePensionPremiumModal';
import { getIsMissingFixedPremium } from 'features/companies/company/employees/employee/utils/get-is-missing-fixed-premium';
import { Page } from 'features/page';
import React from 'react';
import {
  matchPath,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import { impersonate } from 'utils/impersonate';

import { EmployeeOverview } from './_index';
import { CompensationRoute } from './compensation';
import { EditEmployeeRoute } from './edit';
import { EmploymentsRoute } from './employments';
import { EmploymentRoute } from './employments/employment';
import { EventsRoute } from './events';
import { EMPLOYEE_DETAILS_LAYOUT_QUERY } from './graphql/queries';
import { InsuranceEventsRoute } from './insurance-events';
import { NotesOverviewRoute } from './notes/_index';
import { AddNoteRoute } from './notes/add';
import { EditNoteRoute } from './notes/edit';
import { ViewNoteRoute } from './notes/view';
import { OccupationalRoute } from './occupational';
import { RiskRoute } from './risk';

export type EmployeeRouteMatchParams = {
  companyId: string;
  userAccountId: string;
};

const benefitTypeNames: BenefitType[] = ['advinans_occupational_pension'];

export const EmployeeRoutes: React.FC<
  RouteComponentProps<EmployeeRouteMatchParams>
> = ({ match, location }) => {
  const { userAccountId, companyId } = match.params;

  const { permissions } = useCurrentUser();

  const { data, loading, error } = useQuery<
    employeeDetailsLayoutQuery,
    employeeDetailsLayoutQueryVariables
  >(EMPLOYEE_DETAILS_LAYOUT_QUERY, {
    variables: {
      companyId,
      userAccountId,
      salaryExchangeRequeststatuses: [
        FlexSalaryExchangeRequestStatus.REQUESTED,
      ],
      benefitTypeNames,
      entitlementDate: startOfNextMonth(),
    },
    skip: !companyId,
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.membership) {
    return (
      <EmptyStatePage
        error={error}
        parentLink={`/companies/${companyId}/employees`}
      />
    );
  }

  const { membership } = data;
  const employeeName = `${membership.givenName} ${membership.lastName}`;

  const isEmploymentPath = !!matchPath(location.pathname, {
    path: '/companies/:companyId/employees/:userAccountId/employments/:id',
  });

  const parentLink = isEmploymentPath
    ? `/companies/${companyId}/employees/${userAccountId}/employments`
    : `/companies/${companyId}/employees`;

  const hasPendingSalaryExchangeRequest =
    !!data.salaryExchangeRequests?.edges.length;

  const isMissingFixedPremium = getIsMissingFixedPremium(data);

  const canAssertFcfw = hasBackstagePermission(
    'flexifits:fcfw',
    'assert',
    permissions,
  );

  const tabs: RoutedTab[] = [
    {
      title: menuMessages.overview,
      url: match.url,
    },
    {
      title: commonMessages.employments,
      url: `${match.url}/employments`,
    },
    {
      title: menuMessages.pension,
      url: `${match.url}/occupational`,
      ...(isMissingFixedPremium
        ? { trailingIcon: warning, color: 'orange30' }
        : {}),
    },
    {
      title: commonMessages.riskInsurance,
      url: `${match.url}/risk`,
    },
    {
      title: menuMessages.employmentEvents,
      url: `${match.url}/employment-events`,
    },
    {
      title: menuMessages.insuranceEvents,
      url: `${match.url}/insurance-events`,
    },
    {
      title: menuMessages.totalCompensation,
      url: `${match.url}/compensation`,
    },
    {
      title: noteMessages.notes,
      url: `${match.url}/notes`,
    },
  ];

  return (
    <NavigationAnchor title={employeeName} url={match.url}>
      <Switch>
        <Route exact path={tabs.map(({ url }) => url)}>
          <Page
            title={employeeName}
            actions={
              hasBackstagePermission('user', 'impersonate', permissions)
                ? [
                    {
                      text: (
                        <FormattedMessage {...employeeMessages.impersonate} />
                      ),
                      onClick: () => impersonate({ userAccountId }),
                      leadingIcon: flightTakeoff,
                    },
                  ]
                : []
            }
            parentLink={parentLink}
            tabs={tabs}
          >
            <ContentContainer>
              {membership.sensitiveInformation && (
                <NotificationCard
                  title={
                    <FormattedMessage
                      {...employeeMessages.sensitiveInformationTitle}
                    />
                  }
                  type="warning"
                >
                  <FormattedMessage
                    {...employeeMessages.sensitiveInformationDescription}
                  />
                </NotificationCard>
              )}
              {canAssertFcfw && (
                <EmployeeFcfwNotification
                  companyId={companyId}
                  userAccountId={userAccountId}
                />
              )}
              {isMissingFixedPremium && (
                <NotificationCard
                  type="warning"
                  actions={
                    hasPensionPremiumPermission(permissions) && (
                      <LinkButton
                        to={{
                          ...getUpdatePremiumLink(location),
                          pathname: `${match.url}/occupational`,
                        }}
                      >
                        <FormattedMessage {...commonMessages.update} />
                      </LinkButton>
                    )
                  }
                >
                  <FormattedMessage
                    {...employeePensionMessages.premiumNeedsUpdate}
                  />
                </NotificationCard>
              )}
              {hasPendingSalaryExchangeRequest && (
                <NotificationCard
                  type="warning"
                  actions={
                    <LinkButton
                      to={`/companies/${companyId}/salary-exchanges/requests`}
                    >
                      <FormattedMessage
                        {...employeePensionMessages.alertButton}
                      />
                    </LinkButton>
                  }
                >
                  <FormattedMessage {...employeePensionMessages.alertMessage} />
                </NotificationCard>
              )}
              <Route exact path={match.path} component={EmployeeOverview} />
              <Route
                exact
                path={`${match.path}/employments`}
                component={EmploymentsRoute}
              />
              <Route
                exact
                path={`${match.path}/occupational`}
                component={OccupationalRoute}
              />
              <Route path={`${match.path}/risk`} component={RiskRoute} />
              <Route
                path={`${match.path}/employment-events`}
                component={EventsRoute}
              />
              <Route
                path={`${match.path}/insurance-events`}
                component={InsuranceEventsRoute}
              />
              <Route
                path={`${match.path}/compensation`}
                component={CompensationRoute}
              />
              <Route
                path={`${match.path}/notes`}
                component={NotesOverviewRoute}
              />
            </ContentContainer>
          </Page>
        </Route>
        <Route
          path={`${match.path}/employments/:employmentId`}
          component={EmploymentRoute}
        />
        <Route path={`${match.path}/edit`} component={EditEmployeeRoute} />
        <Route
          exact
          path={`${match.path}/notes/add`}
          component={AddNoteRoute}
        />
        <Route
          exact
          path={`${match.path}/notes/:noteId/edit`}
          component={EditNoteRoute}
        />
        <Route path={`${match.path}/notes/:noteId`} component={ViewNoteRoute} />
      </Switch>
    </NavigationAnchor>
  );
};
