import gql from 'graphql-tag';

export const createNlpAgreementFragment = gql`
  fragment createNlpAgreementFragment on Company {
    id
    accountClosureMonth
    accountNumber
    nlpSalesRep {
      id
      firstName
      lastName
      externalId
    }
    clearingCode {
      code
      displayName
    }
    paymentMethod
    salaryReviewMonth
  }
`;
