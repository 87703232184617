import { Section, SectionHeader } from '@frontend/ui';
import {
  invoiceLinesQuery,
  invoiceLinesQueryVariables,
} from 'app/apollo/graphql/types';
import { invoiceLinesMessages } from 'app/messages/invoice-lines';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { InvoiceLinesTable } from './components/InvoiceLinesTable';
import { Filters } from './components/InvoiceLinesTable/Filters';
import { INVOICE_LINES_QUERY } from './graphql/queries';
import { updateInvoiceLinesQuery } from './graphql/update-query';

export const InvoiceLines: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = props => {
  const {
    location: { search },
  } = props;

  const {
    match: {
      params: { companyId },
    },
  } = props;

  const { per_page = DEFAULT_RESULT_PER_PAGE, ...filters } = qs.parse(search);
  const resultsPerPage = parseInt(per_page, 10);
  const {
    userAccountIds,
    suppliers,
    periodFrom,
    periodTo,
    dueFrom,
    dueTo,
    invoiceFrom,
    invoiceTo,
    taxClass,
    pensionAct,
    costCenterIdentifiers,
    benefitTypes,
    benefitPackageId,
  } = filters;

  const { data, loading, error, fetchMore, previousData } = useQuery<
    invoiceLinesQuery,
    invoiceLinesQueryVariables
  >(INVOICE_LINES_QUERY, {
    variables: {
      companyId,
      first: resultsPerPage,
      userAccountIds,
      suppliers,
      linePeriod:
        !!periodFrom && !!periodTo
          ? {
              from: periodFrom,
              to: periodTo,
            }
          : undefined,
      dueDate: {
        from: dueFrom || undefined,
        to: dueTo || undefined,
      },
      issueDate: {
        from: invoiceFrom || undefined,
        to: invoiceTo || undefined,
      },
      taxClass,
      pensionLayer: pensionAct,
      costCenterIdentifiers,
      benefitTypes,
      benefitPackageId,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  if (!_data && loading) {
    return <TopLoading />;
  }

  if (!_data?.invoiceLineSearch) {
    return <GraphQlError error={error} />;
  }

  if (
    !Object.keys(filters).length &&
    !_data.invoiceLineSearch.invoiceLines.edges.length
  ) {
    return (
      <EmptyState
        title={<FormattedMessage {...invoiceLinesMessages.noInvoiceLines} />}
        error={error}
      />
    );
  }

  const { invoiceLineSearch } = _data;

  const { pageInfo } = invoiceLineSearch.invoiceLines;

  return (
    <Section>
      {error && <GraphQlError error={error} />}
      {loading && <TopLoading />}
      <SectionHeader>
        <FormattedMessage
          {...invoiceLinesMessages.nResults}
          values={{ count: _data.invoiceLineSearch.invoiceLines.totalCount }}
        />
      </SectionHeader>
      <Filters
        companyId={companyId}
        invoiceLineSearch={invoiceLineSearch}
        {...props}
      />
      <InvoiceLinesTable
        pageInfo={pageInfo}
        onNextPage={() => {
          fetchMore({
            variables: {
              after: pageInfo.endCursor,
            },
            updateQuery: updateInvoiceLinesQuery,
          });
        }}
        onPreviousPage={() => {
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
              first: undefined,
              last: resultsPerPage,
            },
            updateQuery: updateInvoiceLinesQuery,
          });
        }}
        invoiceLines={invoiceLineSearch.invoiceLines}
      />
    </Section>
  );
};
