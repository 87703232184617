import gql from 'graphql-tag';

const internalPostAttachmentDetails = gql`
  fragment internalPostAttachmentDetails on InternalPostAttachment {
    id
    name
    type
    size
    url
  }
`;

export const internalPostDetails = gql`
  fragment internalPostDetails on InternalPost {
    id
    title
    description
    body
    hero: imageUrl(size: 1920)
    publishedAt
    status
    important
    tags
    benefitPackages {
      edges {
        node {
          id
          name
        }
      }
    }
    orgUnitIds
    organizationalUnits {
      edges {
        node {
          id
          name
        }
      }
    }
    attachments {
      ...internalPostAttachmentDetails
    }
  }
  ${internalPostAttachmentDetails}
`;
