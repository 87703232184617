import { Section, SectionHeader, Table, Td, Th, Tr } from '@frontend/ui';
import {
  fixedPremiumHistoryTableQuery,
  fixedPremiumHistoryTableQuery_fixedPremium_FlexFixedPremiumConnection_edges_FlexFixedPremiumEdge_node_FlexFixedPremium as FixedPremium,
  fixedPremiumHistoryTableQueryVariables,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { pensionMessages } from 'app/messages/pension';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import NoValue from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { FIXED_PREMIUM_HISTORY_TABLE_QUERY } from './graphql/queries';
import { updateQuery } from './utils/update-query';

interface FixedPremiumHistory {
  history?: FixedPremium[];
  pageInfo?: pageInfoDetails;
}

const getFixedPremiumHistory = (
  data?: fixedPremiumHistoryTableQuery,
): FixedPremiumHistory => ({
  history: data?.fixedPremium?.edges?.map(edge => edge.node) ?? [],
  pageInfo: data?.fixedPremium?.pageInfo,
});

const isEmpty = (data?: fixedPremiumHistoryTableQuery) =>
  !getFixedPremiumHistory(data).history?.length;

export const FixedPremiumHistoryTable: React.FC = () => {
  const [suspend, setSuspend] = useState(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data, previousData, loading, fetchMore } = useQuery<
    fixedPremiumHistoryTableQuery,
    fixedPremiumHistoryTableQueryVariables
  >(FIXED_PREMIUM_HISTORY_TABLE_QUERY, {
    isEmpty,
    suspend,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setSuspend(false),
    variables: {
      userAccountId,
      companyId,
      first: Number(perPage),
    },
  });

  const fixedPremiumHistory = getFixedPremiumHistory(data ?? previousData);
  const { history, pageInfo } = fixedPremiumHistory;

  if (!history?.length || !pageInfo) {
    return null;
  }

  const { endCursor, startCursor } = pageInfo;

  return (
    <>
      {loading && <TopLoading />}
      <Section>
        <SectionHeader>
          <FormattedMessage {...pensionMessages.fixedPremiumHistory} />
        </SectionHeader>
        <Table
          size="xsmall"
          navigation={
            <TableNavigation
              perPage={perPage}
              pageInfo={pageInfo}
              setPerPage={setPerPage}
              onNextPage={() => {
                fetchMore({
                  variables: {
                    after: endCursor,
                  },
                  updateQuery,
                });
              }}
              onPreviousPage={() => {
                fetchMore({
                  variables: {
                    before: startCursor,
                    first: undefined,
                    last: perPage
                      ? Number(perPage)
                      : DEFAULT_RESULT_PER_PAGE_SMALL,
                  },
                  updateQuery,
                });
              }}
            />
          }
        >
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '35%' }} />
          </colgroup>
          <thead>
            <Tr>
              <Th>
                <FormattedMessage {...commonMessages.amountLabel} />
              </Th>
              <Th type="number">
                <FormattedMessage {...commonMessages.effectiveDate} />
              </Th>
              <Th type="number">
                <FormattedMessage {...commonMessages.reportingDate} />
              </Th>
              <Th align="left">
                <FormattedMessage {...commonMessages.actor} />
              </Th>
            </Tr>
          </thead>
          <tbody>
            {history.map((entry, i) => (
              <Tr key={i}>
                <Td>
                  <FormattedCurrency
                    value={entry.premium}
                    currency="SEK"
                    after="PER_MONTH"
                  />
                </Td>
                <Td type="number">
                  <FormattedDate value={entry.effectiveDate} />
                </Td>
                <Td type="number">
                  <FormattedDate
                    value={entry.createdAt}
                    timeStyle="short"
                    dateStyle="short"
                  />
                </Td>
                <Td align="left">{entry.actor?.displayName ?? <NoValue />}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </>
  );
};
