import { DescriptionList, Section, SectionHeader } from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import {
  membershipRolesQuery,
  membershipRolesQueryVariables,
} from 'app/apollo/graphql/types';
import {
  employeeFormMessages,
  workInformationMessages,
} from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { EMPLOYEE_ROLE } from 'app/utils/constants';
import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { useCurrentUser } from 'contexts/current-permissions';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { MEMBERSHIP_ROLES_QUERY } from './graphql/queries';
import { UpdateMembershipRolesModal } from './update';

export const MembershipRoles: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const intl = useIntl();
  const [isModalOpen, setModalOpen] = useState(false);
  const { permissions } = useCurrentUser();

  const { data } = useQuery<
    membershipRolesQuery,
    membershipRolesQueryVariables
  >(MEMBERSHIP_ROLES_QUERY, {
    variables: {
      userAccountId,
      companyId,
    },
    errorPolicy: 'all',
    suspend: true,
  });

  if (!data?.membership) {
    return null;
  }

  const { membership } = data;

  const canUpdateRole = hasBackstagePermission(
    'membership:role',
    'write',
    permissions,
  );

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...workInformationMessages.membershipRole} />
      </SectionHeader>
      {canUpdateRole && (
        <ChipsWrapper>
          <AssistChip
            onClick={() => setModalOpen(true)}
            text={<FormattedMessage {...employeeFormMessages.updateRole} />}
            leadingIcon={edit}
          />
        </ChipsWrapper>
      )}
      <DescriptionList
        alignLeft
        entries={[
          {
            id: 'membershipRole.role',
            label: intl.formatMessage(workInformationMessages.membershipRole),
            value: membership.roles.length ? (
              membership.roles.map((role, i) => (
                <span key={i}>
                  <FormattedMessage {...EMPLOYEE_ROLE[role]} />
                  {i !== membership.roles.length - 1 && ', '}
                </span>
              ))
            ) : (
              <NoValue />
            ),
          },
        ]}
      />
      <UpdateMembershipRolesModal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        membership={membership}
      />
    </Section>
  );
};
