import { Subsection, Table, Td, Th, Tr } from '@frontend/ui';
import {
  salaryExchangeReportsQuery,
  salaryExchangeReportsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { emptyStateMessages } from 'components/EmptyState/messages';
import { FormattedDate, FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useState } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';

import { SALARY_EXCHANGE_REPORTS_QUERY } from './graphql/queries';
import { updateSalaryExchangeReportQuery } from './utils/update-query';

export const SalaryExchangeReports: React.FC<RouteComponentProps> = ({
  location: { search },
}) => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const [suspend, setSuspend] = useState(true);

  const { per_page } = qs.parse(search);

  const { loading, data, previousData, error, fetchMore } = useQuery<
    salaryExchangeReportsQuery,
    salaryExchangeReportsQueryVariables
  >(SALARY_EXCHANGE_REPORTS_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend,
    onCompleted: () => setSuspend(false),
    variables: {
      companyId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
    },
  });

  const _data = data ?? previousData;

  if (!_data?.flexSalaryExchangeReports) {
    return null;
  }

  const reports =
    _data.flexSalaryExchangeReports.edges.map(({ node }) => node) ?? [];
  const { pageInfo } = _data.flexSalaryExchangeReports;

  return (
    <>
      {!suspend && loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <Subsection>
        <Table
          navigation={
            <TableNavigation
              pageInfo={pageInfo}
              onNextPage={() => {
                fetchMore({
                  variables: {
                    after: pageInfo.endCursor,
                  },
                  updateQuery: updateSalaryExchangeReportQuery,
                });
              }}
              onPreviousPage={() => {
                fetchMore({
                  variables: {
                    before: pageInfo.startCursor,
                    first: undefined,
                    last: per_page
                      ? parseInt(per_page, 10)
                      : DEFAULT_RESULT_PER_PAGE,
                  },
                  updateQuery: updateSalaryExchangeReportQuery,
                });
              }}
            />
          }
        >
          <thead>
            <Tr>
              <Th>
                <FormattedMessage {...commonMessages.report} />
              </Th>
              <Th type="number" align="right">
                <FormattedMessage {...commonMessages.refers} />
              </Th>
            </Tr>
          </thead>
          <tbody>
            {reports.length ? (
              reports?.map(({ file, reportCreatedAt }, i) => (
                <Tr
                  key={i}
                  linked
                  onClick={() => {
                    window.location.href = file.url;
                  }}
                >
                  <Td>{file.name}</Td>
                  <Td type="number" align="right">
                    <FormattedDate value={reportCreatedAt} />
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={2}>
                  <FormattedMessage {...emptyStateMessages.noReports} />
                </Td>
              </Tr>
            )}
          </tbody>
        </Table>
      </Subsection>
    </>
  );
};
