import { CheckboxField, TextField } from '@frontend/ui';
import { alphabeticallyAscending, toSafeArray } from '@frontend/utils';
import { commonMessages } from 'app/messages/common';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { INVOICE_SUPPLIER } from 'features/companies/company/utils/constants';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import { SelectionFilter } from 'features/filter-sheet/components/SelectionFilter';
import { useDebouncedFilters } from 'features/filter-sheet/utils/use-debounced-filters';
import React from 'react';

import { invoiceMessages } from '../../messages';

interface Filters {
  amountFrom?: string;
  amountTo?: string;
  dueFrom?: string;
  dueTo?: string;
  invoiceFrom?: string;
  invoiceNumber?: string;
  invoiceTo?: string;
  supplier?: string | string[];
}

export const Filters: React.FC = () => {
  const { clearFilters, filters, setFilterValue } =
    useDebouncedFilters<Filters>();

  const supplier = toSafeArray(filters.supplier);

  const supplierOptions = Object.entries(INVOICE_SUPPLIER)
    .map(([key, value]) => ({
      id: key,
      label: value,
      selected: supplier.includes(key),
    }))
    .sort((a, b) => alphabeticallyAscending(a.label, b.label));

  const selectedFilters = Object.keys(filters);

  return (
    <ChipsWrapper>
      <SelectionFilter
        nSelected={selectedFilters.length}
        clearFilters={clearFilters}
      >
        <FilterSection
          header={<FormattedMessage {...invoiceMessages.selectSupplier} />}
        >
          {supplierOptions.map(({ selected, id, label }) => (
            <div key={id}>
              <CheckboxField
                checked={selected}
                label={label}
                onChange={() => {
                  const value = supplier.includes(id)
                    ? supplier.filter(_id => id !== _id)
                    : [id, ...supplier];

                  setFilterValue(value, 'supplier');
                }}
              />
            </div>
          ))}
        </FilterSection>
        <FilterSection
          header={<FormattedMessage {...invoiceMessages.selectInvoiceDate} />}
        >
          <TextField
            dense
            label={<FormattedMessage {...commonMessages.from} />}
            persistentLabel
            type="date"
            value={filters.invoiceFrom ?? ''}
            onChange={event =>
              setFilterValue(event.target.value, 'invoiceFrom')
            }
          />
          <TextField
            dense
            label={<FormattedMessage {...commonMessages.to} />}
            persistentLabel
            type="date"
            value={filters.invoiceTo ?? ''}
            onChange={event => setFilterValue(event.target.value, 'invoiceTo')}
          />
        </FilterSection>
        <FilterSection
          header={<FormattedMessage {...invoiceMessages.selectDueDate} />}
        >
          <TextField
            dense
            label={<FormattedMessage {...commonMessages.from} />}
            persistentLabel
            type="date"
            value={filters.dueFrom ?? ''}
            onChange={event => setFilterValue(event.target.value, 'dueFrom')}
          />
          <TextField
            dense
            label={<FormattedMessage {...commonMessages.to} />}
            persistentLabel
            type="date"
            value={filters.dueTo ?? ''}
            onChange={event => setFilterValue(event.target.value, 'dueTo')}
          />
        </FilterSection>
        <FilterSection
          header={<FormattedMessage {...invoiceMessages.searchInvoiceNumber} />}
        >
          <TextField
            dense
            label={<FormattedMessage {...invoiceMessages.invoiceNumber} />}
            value={filters.invoiceNumber ?? ''}
            onChange={event =>
              setFilterValue(event.target.value, 'invoiceNumber')
            }
            withoutMargin
          />
        </FilterSection>
        <FilterSection
          header={<FormattedMessage {...invoiceMessages.selectAmount} />}
        >
          <TextField
            dense
            type="number"
            label={<FormattedMessage {...commonMessages.from} />}
            persistentLabel
            value={filters.amountFrom ?? ''}
            onChange={event => setFilterValue(event.target.value, 'amountFrom')}
          />
          <TextField
            dense
            type="number"
            label={<FormattedMessage {...commonMessages.to} />}
            persistentLabel
            value={filters.amountTo ?? ''}
            onChange={event => setFilterValue(event.target.value, 'amountTo')}
          />
        </FilterSection>
      </SelectionFilter>
    </ChipsWrapper>
  );
};
