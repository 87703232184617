import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const administratorDetails = gql`
  fragment administratorDetails on Membership {
    id
    userAccountId
    naturalPersonIdentifiers
    givenName
    lastName
    roles
  }
`;

export const COMPANY_ADMINISTRATORS_QUERY = gql`
  query companyAdministratorsQuery(
    $companyId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    company(id: $companyId) {
      id
      administrators(
        first: $first
        after: $after
        last: $last
        before: $before
        showInternalAdmins: false
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            ...administratorDetails
          }
        }
      }
    }
  }
  ${pageInfoDetails}
  ${administratorDetails}
`;

export const EDIT_ADMINISTRATOR_QUERY = gql`
  query editAdministratorQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      ...administratorDetails
    }
  }
  ${administratorDetails}
`;
