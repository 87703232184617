import gql from 'graphql-tag';

export const DISCOUNT_PRESENTATION_QUERY = gql`
  query discountPresentationQuery($id: ID!, $locale: String) {
    benefit: flexBenefit(id: $id, locale: $locale) {
      id
      type
      supplier {
        id
        name
        logo {
          url
          path
        }
        websiteUrl
        companyId
      }
      categories {
        id
        locale
        companyId
        name
      }
      content {
        title
        short
        body
        locale
        cardImage {
          url
          path
        }
        heroImage {
          url
          path
        }
        heroImageAlignment
        callToAction {
          title
          link
          type
        }
        attachments {
          name
          path
          url
        }
      }
      configuration {
        data
        ... on EpassiBikeConfiguration {
          files {
            name
            path
            url
          }
        }
      }
    }
  }
`;

export const FILE_UPLOAD_QUERY = gql`
  query fileUploadQuery($files: [FlexFileUploadInput!]) {
    fileUploadUrl(files: $files) {
      url
      path
      name
    }
  }
`;
