import { Formik } from '@frontend/formik';
import { Button } from '@frontend/ui';
import {
  internalPostQuery,
  internalPostQueryVariables,
} from 'app/apollo/graphql/types';
import { EditRouteMatchParams } from 'app/pages/companies/company/internal-news/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import { Details } from '../components/Details';
import { Form, FormValues } from '../components/Form';
import { validationSchema } from '../create';
import { internalNewsFormMessages, internalNewsMessages } from '../messages';
import { getPreviewDetails } from '../utils/get-preview-details';
import { INTERNAL_POST_DETAILS_QUERY } from './graphql/queries';
import { useDelete } from './utils/use-delete';
import { useSubmit } from './utils/use-submit';

export const Edit: React.FC = () => {
  const intl = useIntl();
  const { submit, error: submissionError } = useSubmit();
  const { _delete, loading: deleteLoading, error: deleteError } = useDelete();
  const { formatMessage } = useIntl();
  const {
    params: { id, companyId },
    path,
    url,
  } = useRouteMatch<EditRouteMatchParams>();

  const { data, loading, error } = useQuery<
    internalPostQuery,
    internalPostQueryVariables
  >(INTERNAL_POST_DETAILS_QUERY, {
    variables: { id },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.internalPost) {
    return (
      <EmptyStatePage
        error={error}
        parentLink={`/companies/${companyId}/internal-news`}
      />
    );
  }

  const { internalPost } = data;

  const initialValues = {
    title: internalPost.title,
    preamble: internalPost.description,
    body: internalPost.body,
    status: internalPost.status,
    important: internalPost.important,
    tags: internalPost.tags,
    benefitPackages: Object.fromEntries(
      internalPost?.benefitPackages?.edges.map(
        ({ node: { id: backageId, name } }) => [backageId, name],
      ) ?? [],
    ),
    organizationalUnits: Object.fromEntries(
      internalPost?.orgUnitIds?.map(orgId => {
        const name =
          internalPost?.organizationalUnits?.edges.find(
            e => e.node.id === orgId,
          )?.node.name ??
          formatMessage(internalNewsFormMessages.removedOrgUnitPlaceholder);
        return [id, name];
      }) ?? [],
    ),
    hero: internalPost.hero ? { previewUrl: internalPost.hero } : undefined,
    publicationDate: new Date(internalPost.publishedAt)
      .toISOString()
      .substring(0, 10),
    attachmentFiles: internalPost.attachments?.map((file, i) => ({
      name: file.name ?? (i + 1).toString(),
      id: file.id,
      url: file.url,
    })),
  };

  return (
    <NavigationAnchor title={internalPost.title} url={url}>
      <Formik<FormValues>
        validationSchema={validationSchema(intl)}
        initialValues={initialValues}
        onSubmit={submit}
      >
        {({ values }) => (
          <Switch>
            <Route path={`${path}/preview`}>
              <Details internalNews={getPreviewDetails(values)} />
            </Route>
            <Route path={path}>
              <Form
                submissionError={submissionError ?? deleteError}
                secondaryAction={
                  <Button
                    type="button"
                    loading={deleteLoading}
                    danger
                    onClick={_delete}
                  >
                    <FormattedMessage {...internalNewsMessages.deleteNews} />
                  </Button>
                }
              />
            </Route>
          </Switch>
        )}
      </Formik>
    </NavigationAnchor>
  );
};
