import { Wellness } from '@frontend/benefit-types';
import { DescriptionList, DescriptionListEntry } from '@frontend/ui';
import {
  BenefitPackage,
  FlexEntitlementRules,
  MiscConfiguration,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';

import { InBenefitPackage } from '../../../components/BenefitsTable/InBenefitPackage';
import { benefitFormMessages } from '../../../form/messages';

interface Benefit {
  configuration: MiscConfiguration;
  entitlementRules: Pick<FlexEntitlementRules, 'benefitPackageIds'> | null;
  id: string;
  type: string;
}

interface Props {
  benefit: Wellness<Benefit>;
  benefitPackageOptions: Pick<BenefitPackage, 'id' | 'name'>[];
}

export const WellnessBenefit: React.FC<Props> = ({
  benefit,
  benefitPackageOptions,
}) => {
  const { configuration } = benefit;

  const { allowance } = configuration?.data ?? {};
  const entries: DescriptionListEntry[] = [
    {
      id: 'benefit.yearlyAllowance',
      label: <FormattedMessage {...benefitFormMessages.allowance} />,
      value: allowance ? (
        <FormattedCurrency value={allowance} currency="SEK" />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.inBenefitPackage',
      label: <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />,
      value: (
        <InBenefitPackage
          benefit={benefit}
          benefitPackageOptions={benefitPackageOptions}
        />
      ),
    },
  ];

  return <DescriptionList alignLeft entries={entries} />;
};
