import { SubsectionHeader } from '@frontend/ui';
import {
  FlexSalaryExchangeRequestStatus,
  salaryExchangeRequestsQuery,
  salaryExchangeRequestsQueryVariables,
} from 'app/apollo/graphql/types';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { companySalaryExchangeMessages } from '../../messages';
import { SALARY_EXCHANGE_REQUESTS_QUERY } from '../graphql/queries';
import { RequestSalaryExchangeType } from '../types';
import { updateQuery } from '../utils/update-query';
import { ApproveTable } from './components/Table';

const isEmpty = (data?: salaryExchangeRequestsQuery) =>
  !data?.salaryExchangeRequests?.edges.length;

const hasPendingRequests = (
  data?: salaryExchangeRequestsQuery,
): data is salaryExchangeRequestsQuery => !isEmpty(data);

interface Props {
  requestIds: string[];
}

export const ApproveSalaryExchangeRequests: React.FC<Props> = ({
  requestIds,
}) => {
  const [suspend, setSuspend] = useState(true);

  const [perPage, setPerPage] = useState(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data, previousData, fetchMore, loading } = useQuery<
    salaryExchangeRequestsQuery,
    salaryExchangeRequestsQueryVariables
  >(SALARY_EXCHANGE_REQUESTS_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend,
    isEmpty,
    variables: {
      companyId,
      requestIds,
      statuses: [FlexSalaryExchangeRequestStatus.REQUESTED],
      first: Number(perPage),
    },
    onCompleted: () => setSuspend(false),
  });

  const _data = data ?? previousData;

  if (!_data?.salaryExchangeRequests || !hasPendingRequests(_data)) {
    return null;
  }

  const { edges, pageInfo } = _data.salaryExchangeRequests;

  const requests = edges.map(e => e.node);

  const navigation = (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      pageInfo={pageInfo}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
          updateQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery,
        });
      }}
    />
  );

  return (
    <>
      {!suspend && loading && <TopLoading />}
      <SubsectionHeader>
        <FormattedMessage {...companySalaryExchangeMessages.salaryExchanges} />
      </SubsectionHeader>
      <ApproveTable
        type={RequestSalaryExchangeType.ONGOING}
        navigation={navigation}
        requests={requests}
      />
    </>
  );
};
