import { Section, SectionHeader, Table, Td, Th, Tr } from '@frontend/ui';
import {
  pageInfoDetails,
  singlePremiumDepositHistoryTableQuery,
  singlePremiumDepositHistoryTableQuery_membership_Membership_singlePremiums_FlexSinglePremiumConnection_edges_FlexSinglePremiumEdge_node_FlexSinglePremium as SinglePremium,
  singlePremiumDepositHistoryTableQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { pensionMessages } from 'app/messages/pension';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import NoValue from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { SINGLE_PREMIUM_DEPOSIT_HISTORY_TABLE_QUERY } from './graphql/queries';
import { updateQuery } from './utils/update-query';

interface SinglePremiumHistory {
  history?: SinglePremium[];
  pageInfo?: pageInfoDetails;
}

const getSinglePremiumHistory = (
  data?: singlePremiumDepositHistoryTableQuery,
): SinglePremiumHistory => ({
  history: data?.membership?.singlePremiums?.edges.map(edge => edge.node),
  pageInfo: data?.membership?.singlePremiums?.pageInfo,
});

const isEmpty = (data?: singlePremiumDepositHistoryTableQuery) =>
  !getSinglePremiumHistory(data)?.history?.length;

export const SinglePremiumDepositHistoryTable: React.FC = () => {
  const [suspend, setSuspend] = useState(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE.toString(),
  );

  const {
    params: { companyId, userAccountId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data, previousData, fetchMore, loading } = useQuery<
    singlePremiumDepositHistoryTableQuery,
    singlePremiumDepositHistoryTableQueryVariables
  >(SINGLE_PREMIUM_DEPOSIT_HISTORY_TABLE_QUERY, {
    isEmpty,
    suspend,
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setSuspend(false),
    variables: {
      userAccountId,
      companyId,
      first: Number(perPage),
    },
  });

  const singlePremiumHistory = getSinglePremiumHistory(data ?? previousData);

  const { history } = singlePremiumHistory;
  const { pageInfo } = singlePremiumHistory;

  if (!history?.length || !pageInfo) {
    return null;
  }

  const { endCursor, startCursor } = pageInfo;

  return (
    <>
      {loading && <TopLoading />}
      <Section>
        <SectionHeader>
          <FormattedMessage {...pensionMessages.singlePremiumDeposits} />
        </SectionHeader>
        <Table
          size="xsmall"
          navigation={
            <TableNavigation
              perPage={perPage}
              pageInfo={pageInfo}
              setPerPage={setPerPage}
              onNextPage={() => {
                fetchMore({
                  variables: {
                    after: endCursor,
                  },
                  updateQuery,
                });
              }}
              onPreviousPage={() => {
                fetchMore({
                  variables: {
                    before: startCursor,
                    first: undefined,
                    last: perPage ? Number(perPage) : DEFAULT_RESULT_PER_PAGE,
                  },
                  updateQuery,
                });
              }}
            />
          }
        >
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '30%' }} />
          </colgroup>
          <thead>
            <Tr>
              <Th>
                <FormattedMessage {...commonMessages.insuranceNumber} />
              </Th>
              <Th type="number">
                <FormattedMessage {...commonMessages.amountLabel} />
              </Th>
              <Th type="number">
                <FormattedMessage {...commonMessages.refersToDate} />
              </Th>
              <Th type="number">
                <FormattedMessage {...commonMessages.reportingDate} />
              </Th>
              <Th align="left">
                <FormattedMessage {...commonMessages.actor} />
              </Th>
            </Tr>
          </thead>
          <tbody>
            {history.map((entry, i) => (
              <Tr key={i}>
                <Td type="number" align="left">
                  {entry.insuranceNumber}
                </Td>
                <Td type="number">
                  <FormattedCurrency value={entry.premium} currency="SEK" />
                </Td>
                <Td type="number">
                  <FormattedDate value={entry.effectiveDate} />
                </Td>
                <Td type="number">
                  <FormattedDate
                    value={entry.createdAt}
                    timeStyle="short"
                    dateStyle="short"
                  />
                </Td>
                <Td align="left">{entry.actor?.displayName ?? <NoValue />}</Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </>
  );
};
