import { useMutation } from '@apollo/client';
import { Button, Chip, Icon, Section } from '@frontend/ui';
import { check } from '@frontend/ui/icons';
import {
  companyNotificationsQuery,
  companyNotificationsQueryVariables,
  NotificationStatus,
  updateNotificationMutation,
  updateNotificationMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { ScrollableSet } from 'components/scrollable/Set';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import { styled } from 'styled-components';

import { useNotification } from '../../../notifications';
import { NotificationsList } from './components/NotificationsList';
import { UPDATE_NOTIFICATION_MUTATION } from './graphql/mutations';
import { COMPANY_NOTIFICATIONS_QUERY } from './graphql/queries';
import {
  notificationDescriptions,
  notificationMessages,
  notificationTitles,
} from './messages';
import { getLink } from './utils/get-action-link';
import { updateQuery } from './utils/update-query';

export const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 1rem;
`;

const DEFAULT_RESULT_COUNT = 5;

export enum OpenStatus {
  OPEN = 'OPEN',
  DISMISSED = 'DISMISSED',
  REOPENED = 'REOPENED',
}

export const Notification: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({
  match: {
    params: { companyId },
  },
}) => {
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { send } = useNotification();

  const parsed = qs.parse(location.search);
  const dismissed = parsed.dismissed === 'true';

  const { data, previousData, loading, error, fetchMore } = useQuery<
    companyNotificationsQuery,
    companyNotificationsQueryVariables
  >(COMPANY_NOTIFICATIONS_QUERY, {
    variables: {
      companyId,
      status: dismissed
        ? NotificationStatus.DISMISSED
        : NotificationStatus.OPEN,
      first: DEFAULT_RESULT_COUNT,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const [updateNotification] = useMutation<
    updateNotificationMutation,
    updateNotificationMutationVariables
  >(UPDATE_NOTIFICATION_MUTATION, {
    awaitRefetchQueries: true,
    update: cache => {
      cache.evict({ fieldName: 'notifications' });
      cache.gc();
    },
  });

  const setDismissed = (value?: string) => {
    push({
      ...location,
      search: qs.stringify({
        ...parsed,
        dismissed: value,
      }),
    });
  };

  const _data = data ?? previousData;

  const { totalCount, pageInfo, edges } = _data?.notifications || {};

  const updateStatus =
    (id: string, status: NotificationStatus) => async (): Promise<void> => {
      try {
        const input = {
          id,
          status,
          clientMutationId: id,
        };

        const res = await updateNotification({
          variables: { input },
        });

        if (
          res?.data?.updateNotification?.clientMutationId !== id ||
          res?.errors?.length
        ) {
          throw new Error();
        }
      } catch {
        send({
          message: formatMessage(notificationMessages.statusUpdateError),
          type: 'error',
        });
      }
    };

  const notifications =
    edges?.map(node => {
      const n = node.node;
      const title = formatMessage({
        select: n.type,
        messages: notificationTitles,
      });
      const description = formatMessage({
        select: n.type,
        messages: notificationDescriptions,
        values: n.metadata ?? undefined,
      });
      return {
        key: n.id,
        title,
        description,
        link: getLink(n),
        createdAt: new Date(n.createdAt),
        author: n.statusChangedBy?.name || undefined,
        statusUpdatedAt: n.statusUpdatedAt
          ? new Date(n.statusUpdatedAt)
          : undefined,
        onClick: updateStatus(
          n.id,
          n.status === NotificationStatus.DISMISSED
            ? NotificationStatus.OPEN
            : NotificationStatus.DISMISSED,
        ),
        status:
          n.status === NotificationStatus.DISMISSED
            ? OpenStatus.DISMISSED
            : n.statusChangedBy
              ? OpenStatus.REOPENED
              : OpenStatus.OPEN,
      };
    }) ?? [];

  return (
    <Section>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <ChipsWrapper>
        <ScrollableSet>
          <Chip
            text={<FormattedMessage {...notificationMessages.open} />}
            leadingIcon={!dismissed ? <Icon decorative icon={check} /> : null}
            onClick={() => setDismissed('false')}
            selected={!dismissed}
          />
          <Chip
            text={<FormattedMessage {...notificationMessages.dismissed} />}
            leadingIcon={dismissed ? <Icon decorative icon={check} /> : null}
            onClick={() => setDismissed('true')}
            selected={dismissed}
          />
        </ScrollableSet>
      </ChipsWrapper>
      {!notifications.length && !loading ? (
        <EmptyState
          title={<FormattedMessage {...notificationMessages.noNotifications} />}
          error={error}
        />
      ) : (
        <TextGrid>
          {!!notifications.length && (
            <NotificationsList notifications={notifications} />
          )}
          <LoadMoreContainer>
            {!!totalCount && !!edges && edges.length < totalCount && (
              <Button
                onClick={() =>
                  fetchMore({
                    variables: {
                      after: pageInfo?.endCursor,
                      first: DEFAULT_RESULT_COUNT,
                    },
                    updateQuery,
                  })
                }
              >
                <FormattedMessage {...notificationMessages.loadMore} />
              </Button>
            )}
          </LoadMoreContainer>
        </TextGrid>
      )}
    </Section>
  );
};
