import { AdvinansSalaryExchange } from '@frontend/benefit-types';
import { benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit } from 'app/apollo/graphql/types';
import {
  commonBenefitMessages,
  salaryExchangeBenefitMessages,
} from 'app/messages/benefits';
import { DetailCell } from 'components/DetailCell';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { useRemunerationTypes } from 'features/companies/company/utils/use-remuneration-types';
import React from 'react';

interface SalaryExchangeDetailCellProps {
  companyId: string;
  salaryExchange: AdvinansSalaryExchange<Benefit>;
}

export const SalaryExchangeDetailCell: React.FC<
  SalaryExchangeDetailCellProps
> = ({ companyId, salaryExchange }) => {
  const { remunerationTypes } = useRemunerationTypes({ companyId });

  if (!salaryExchange.configuration?.data) {
    return <NoValue />;
  }
  const {
    onetimeMax,
    onetimeDeductionShareMax,
    ongoingMax,
    ongoingDeductionShareMax,
    basePensionableIncomePayrollElementCode,
    basePensionableIncomePayrollElementMultiplier,
  } = salaryExchange.configuration.data;

  const basePensionableIncomePayrollElement = remunerationTypes.find(
    type => type.id === basePensionableIncomePayrollElementCode,
  );

  return (
    <DetailCell>
      {(ongoingMax || ongoingDeductionShareMax) && (
        <FormattedMessage
          {...salaryExchangeBenefitMessages.salaryExchangeDeductionValue}
          values={{ amount: ongoingMax, share: ongoingDeductionShareMax }}
        />
      )}
      {(onetimeMax || onetimeDeductionShareMax) && (
        <FormattedMessage
          {...salaryExchangeBenefitMessages.oneTimeSalaryExchangeDeductionValue}
          values={{ amount: onetimeMax, share: onetimeDeductionShareMax }}
        />
      )}
      {!!basePensionableIncomePayrollElement &&
        !!basePensionableIncomePayrollElementMultiplier && (
          <p>
            <FormattedMessage
              {...commonBenefitMessages.benefitQualifyingIncomeTableDescription}
              values={{
                annualFactor: basePensionableIncomePayrollElementMultiplier,
                remunerationType: `${basePensionableIncomePayrollElement.id} ${
                  basePensionableIncomePayrollElement.name ?? ''
                }`,
              }}
            />
          </p>
        )}
    </DetailCell>
  );
};
