import { useMutation } from '@apollo/client';
import { chunkArray, getSubmitErrors } from '@frontend/utils';
import { BATCH_MAX, client } from 'app/apollo/client';
import {
  AssertFullyCapableForWorkInput,
  assertFullyCapableForWorkMutation,
  assertFullyCapableForWorkMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import mapSeries from 'promise-map-series';
import { Dispatch, SetStateAction, useId } from 'react';

import { ASSERT_FULLY_CAPABLE_FOR_WORK_MUTATION } from '../../graphql/mutations';
import { workCapabilityMessages } from '../../messages';
import { refetchFcfwRequests } from '../../utils/refetch-fcfw-requests';
import { FormValues } from '../index';
import { RequestEmployee } from './constants';
import { getFcfwInputCollection } from './get-fcfw-input-collection';

interface Args {
  companyId: string;
  employees: RequestEmployee[];
  infoText: string;
  setSubmissionErrors: Dispatch<SetStateAction<string[]>>;
  onSuccess?: () => void;
}

export const useSubmit = ({
  employees,
  infoText,
  companyId,
  setSubmissionErrors,
  onSuccess,
}: Args) => {
  const { send } = useNotification();
  const intl = useIntl();
  const batchId = useId();

  const [assertFullyCapableForWork] = useMutation<
    assertFullyCapableForWorkMutation,
    assertFullyCapableForWorkMutationVariables
  >(ASSERT_FULLY_CAPABLE_FOR_WORK_MUTATION, {
    context: { batch: true, batchId },
  });

  const submit = async (values: FormValues) => {
    const { includedEmployees, employeeEffectiveDates } = values;

    const inputCollection = getFcfwInputCollection(
      employees,
      includedEmployees,
      employeeEffectiveDates,
      infoText,
    );

    const inputChunks = chunkArray(inputCollection, BATCH_MAX);

    const results = await mapSeries(
      inputChunks,
      async (inputChunk: AssertFullyCapableForWorkInput[]) => {
        const result = await Promise.allSettled(
          inputChunk.map(input =>
            assertFullyCapableForWork({
              variables: {
                input,
              },
            }),
          ),
        );

        return result;
      },
    );

    await refetchFcfwRequests(companyId);
    client.cache.evict({ fieldName: 'fcfwAssertions' });
    client.cache.gc();

    const hasFullfilled = results
      .flat()
      .some(item => item.status === 'fulfilled');
    const hasRejected = results.flat().some(item => item.status === 'rejected');

    if (hasRejected) {
      const rejected = results
        .flat()
        // eslint-disable-next-line
        .filter(
          item => item.status === 'rejected' && item.reason,
        ) as Array<PromiseRejectedResult>;

      setSubmissionErrors(
        rejected.map(item => getSubmitErrors({ intl, error: item.reason })),
      );
    }
    if (hasFullfilled) {
      send({
        type: 'success',
        message: intl.formatMessage(
          workCapabilityMessages.assertWorkCapabilitySuccess,
        ),
      });

      if (!hasRejected && onSuccess) {
        onSuccess();
      }
    }
  };

  return { submit };
};
