import gql from 'graphql-tag';

export const VERSION_HISTORY_QUERY = gql`
  query versionHistoryQuery($benefitId: ID!, $companyId: ID!, $asOf: String) {
    company(id: $companyId) {
      id
      managedBy
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
      remunerationTypes {
        edges {
          node {
            id
            name
            currency
            unitCode
          }
        }
      }
    }
    flexBenefit(id: $benefitId) {
      id
      type
      content {
        locale
        title
      }
      entitlementRules(asOf: $asOf) {
        benefitPackageIds
      }
      configuration(asOf: $asOf) {
        data
        ... on EpassiBikeConfiguration {
          customerId
          useDefaultPolicy
          files {
            path
            url
            name
          }
        }
      }
    }
  }
`;
