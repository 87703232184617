import gql from 'graphql-tag';

export const SME_COMPANY_BENEFIT_PACKAGE_DETAILS_QUERY = gql`
  query smeCompanyBenefitPackageDetailsQuery($id: ID!) {
    proposal(id: $id) {
      id
      benefitPackages {
        id
        benefits {
          id
          configuration {
            ... on ProposalAccidentBenefitConfig {
              accidentLevel
            }
            ... on ProposalEmployersPensionBenefitConfig {
              defaultManagementType
              premiumDeductions
              premiumWaiver
              premiumCalculationMethod
              premiumMatrix {
                age
                baseAmount
                baseAmountType
                intervals
                salaryMultiplier
              }
            }
            ... on ProposalHealthcareBenefitConfig {
              healthcareLevel
              waitingPeriod
            }
            ... on ProposalLifeBenefitConfig {
              lifeLevel
            }
            ... on ProposalOccupationalPensionBenefitConfig {
              defaultManagementType
              premiumDeductions
              premiumWaiver
              premiumCalculationMethod
              premiumMatrix {
                age
                baseAmount
                baseAmountType
                intervals
                salaryMultiplier
              }
            }
            ... on ProposalSalaryExchangeBenefitConfig {
              premiumWaiver
            }
            ... on ProposalSicknessBenefitConfig {
              sicknessLevel
            }
          }
          type
        }
        cost {
          employersPension
          occupationalPension
          riskSickness
          riskAccident
          riskLife
          riskHealthcare
          riskTgl
          riskPensionPremiumWaiver
          total
        }
        memberships {
          id
          cost {
            employersPension
            occupationalPension
            riskSickness
            riskAccident
            riskLife
            riskHealthcare
            riskTgl
            riskPensionPremiumWaiver
            total
          }
          firstName
          lastName
          monthlySalary
          personalIdentityNumber
        }
        name
      }
    }
  }
`;
