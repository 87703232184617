import { useMutation } from '@apollo/client';
import {
  updateOrganizationalUnitMutation,
  updateOrganizationalUnitMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { UPDATE_ORGANIZATIONAL_UNIT_MUTATION } from '../../graphql/mutations';
import { orgUnitMessages } from '../../messages';
import { FormValues } from '../';

interface Args {
  onRequestClose: () => void;
}

export const useSubmit = (args: Args) => {
  const { onRequestClose } = args;
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [update, { error }] = useMutation<
    updateOrganizationalUnitMutation,
    updateOrganizationalUnitMutationVariables
  >(UPDATE_ORGANIZATIONAL_UNIT_MUTATION);

  const submit = async ({ unitOf, ...formValues }: FormValues) => {
    try {
      const res = await update({
        variables: {
          input: {
            unitOf: unitOf === 'NO_ORG_UNIT' ? null : unitOf,
            ...formValues,
          },
        },
      });
      if (!res?.data?.updateOrganizationalUnit) {
        throw new Error();
      }
      send({
        type: 'success',
        message: formatMessage(orgUnitMessages.updateOrgUnitSuccess),
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    error,
  };
};
