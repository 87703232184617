import { ApolloError, useMutation } from '@apollo/client';
import {
  editProposalAccidentBenefitMutation,
  editProposalAccidentBenefitMutationVariables,
  UpdateProposalAccidentBenefitInput,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { clearProposalCache } from '../../../../../utils/clear-proposal-cache';
import { FormValues } from '../../../form/accident';
import { EDIT_PROPOSAL_ACCIDENT_BENEFIT_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

interface Params {
  params: BenefitMatchParams;
}

export const useSubmit = ({ params }: Params): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [editBenefit, { error: submissionError }] = useMutation<
    editProposalAccidentBenefitMutation,
    editProposalAccidentBenefitMutationVariables
  >(EDIT_PROPOSAL_ACCIDENT_BENEFIT_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals'],
    }),
  });

  const submit = async (formValues: FormValues) => {
    // This value is guaranteed through the form validation
    if (!formValues.level) {
      return;
    }
    try {
      const input: UpdateProposalAccidentBenefitInput = {
        id: params.benefitId,
        benefitPackageIds: formValues.benefitPackageIds,
        level: formValues.level,
      };

      const res = await editBenefit({
        variables: { benefitId: params.benefitId, input },
      });

      if (!res.data?.updateProposalAccidentBenefit) {
        return;
      }

      send({
        message: formatMessage(smeBenefitFormMessages.benefitSaved),
        type: 'success',
      });
      push(`/sme/${params.companyId}/proposals/${params.proposalId}/benefits`);
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
