import { pageInfoDetails } from 'app/fragments';
import { gql } from 'graphql-tag';

export const COMPANIES_QUERY = gql`
  query companiesQuery(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $search: String
    $filters: CompanyFilters
  ) {
    companies(
      first: $first
      last: $last
      before: $before
      after: $after
      search: $search
      filters: $filters
    ) {
      edges {
        node {
          id
          name
          displayName
          registrationNumber
          notifications
          companyAnalytics {
            numberOfActiveEmployments
          }
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;

export const COMPANY_DETAILS_QUERY = gql`
  query companyDetailsQuery($id: ID!) {
    nlpSalesReps {
      id
      firstName
      lastName
      externalId
    }
    company(id: $id) {
      id
      nlpSalesRep {
        id
        firstName
        lastName
        externalId
      }
      clearingCode {
        code
        displayName
      }
      hasCompanyGroup
      legalEntityType
      salaryReviewMonth
      accountClosureMonth
      registrationNumber
      name
      displayName
      logoUrl
      email
      phone
      hasJobOffers
      newsletterOptOut
      validKyc
      address {
        line1
        line2
        co
        postalCode
        city
      }
      emailLoginAllowed
      employeeEmailLanguage
    }
  }
`;

export const ALL_BENEFIT_PACKAGES_QUERY = gql`
  query allBenefitPackagesQuery(
    $companyId: ID!
    $includeTerminated: Boolean
    $locale: String
  ) {
    companyBenefitPackages: company(id: $companyId) {
      id
      benefitPackages {
        edges {
          node {
            id
            name
            hasBenefitPortal
            showTotalCompensation
            memberships(includeTerminated: $includeTerminated) {
              edges {
                node {
                  id
                }
              }
            }
            benefits(onlyGetBenefits: true, locale: $locale) {
              edges {
                node {
                  id
                  type
                  content {
                    locale
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BENEFITS_QUERY = gql`
  query benefitsQuery(
    $companyId: ID!
    $benefitPackageIds: [ID!]
    $benefitTypeNames: [String!]
    $onlyGetBenefits: Boolean
    $locale: String
    $categoryIds: [ID!]
  ) {
    company(id: $companyId) {
      id
      benefitCategories: flexBenefits(onlyGetBenefits: $onlyGetBenefits) {
        edges {
          node {
            id
            type
            categories {
              id
              name
            }
            content {
              locale
            }
          }
        }
      }
      benefits: flexBenefits(
        benefitPackageIds: $benefitPackageIds
        benefitTypeNames: $benefitTypeNames
        onlyGetBenefits: $onlyGetBenefits
        locale: $locale
        categoryIds: $categoryIds
      ) {
        edges {
          node {
            id
            type
            content {
              title
              locale
            }
            supplier {
              id
              name
            }
            entitlementRules {
              benefitPackageIds
            }
            categories {
              id
              name
            }
            configuration {
              data
            }
          }
        }
      }
      managedBy
    }
  }
`;

export const BENEFIT_PACKAGE_NAME_QUERY = gql`
  query benefitPackageNameQuery($benefitPackageId: ID!) {
    benefitPackage(id: $benefitPackageId) {
      id
      name
    }
  }
`;

export const SALARY_REPORTS_QUERY = gql`
  query salaryReportsQuery(
    $companyId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $locale: String
  ) {
    company(id: $companyId) {
      id
      salaryReports(
        first: $first
        last: $last
        before: $before
        after: $after
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          cursor
          node {
            id
            companyId
            date
            content(locale: $locale) {
              name
              excelUrl: url(format: EXCEL)
            }
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;

export const COMPANY_REMUNERATION_TYPES_QUERY = gql`
  query companyRemunerationTypesQuery(
    $companyId: ID!
    $search: String
    $first: Int
    $before: String
    $after: String
    $last: Int
  ) {
    company(id: $companyId) {
      id
      remunerationTypes(
        search: $search
        first: $first
        before: $before
        after: $after
        last: $last
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            id
            name
            currency
            unitCode
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;

export const BENEFIT_PACKAGES_OVERVIEW_QUERY = gql`
  query benefitPackagesOverviewQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const ACCOUNTING_OBJECTS_QUERY = gql`
  query accountingObjectsQuery(
    $companyId: ID!
    $dimensionId: String
    $first: Int
    $before: String
    $after: String
    $last: Int
  ) {
    accountingObjects(
      companyId: $companyId
      dimensionId: $dimensionId
      first: $first
      before: $before
      after: $after
      last: $last
    ) {
      pageInfo {
        ...pageInfoDetails
      }
      edges {
        node {
          id
          name
          identifier
          memberships {
            totalCount
          }
        }
      }
      totalCount
    }
  }
  ${pageInfoDetails}
`;

export const BENEFIT_PACKAGES_OPTIONS_QUERY = gql`
  query benefitPackagesOptionsQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      name
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
