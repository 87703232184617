import { ApolloError, useMutation } from '@apollo/client';
import {
  CreateCompany2Input,
  createSmeCompanyMutation,
  createSmeCompanyMutationVariables,
  PaymentMethod,
  SetProposalCompanyContactPersonInput,
  SetProposalCompanyHeadAdminInput,
  updateSmeCompanyContactPersonMutation,
  updateSmeCompanyContactPersonMutationVariables,
  updateSmeCompanyHeadAdminMutation,
  updateSmeCompanyHeadAdminMutationVariables,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import {
  UPDATE_SME_COMPANY_CONTACT_PERSON_MUTATION,
  UPDATE_SME_COMPANY_HEAD_ADMIN_MUTATION,
} from 'features/sme/companies/graphql/mutations';
import { smeCreateCompanyMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { FormValues, HasCompanyGroupType } from '..';
import { CREATE_SME_COMPANY_MUTATION } from '../graphql/mutations';

const isPaymentMethod = (value: PaymentMethod | '') => value !== '';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createSmeCompany, { error: createCompanyError }] = useMutation<
    createSmeCompanyMutation,
    createSmeCompanyMutationVariables
  >(CREATE_SME_COMPANY_MUTATION, {
    update: cache => {
      cache.evict({ fieldName: 'companies' });
      cache.gc();
    },
  });

  const [updateContactPerson, { error: updateContactPersonError }] =
    useMutation<
      updateSmeCompanyContactPersonMutation,
      updateSmeCompanyContactPersonMutationVariables
    >(UPDATE_SME_COMPANY_CONTACT_PERSON_MUTATION);

  const [updateHeadAdmin, { error: updateHeadAdminError }] = useMutation<
    updateSmeCompanyHeadAdminMutation,
    updateSmeCompanyHeadAdminMutationVariables
  >(UPDATE_SME_COMPANY_HEAD_ADMIN_MUTATION);

  const submit = async (formValues: FormValues) => {
    try {
      const { separateContactAndAdmin } = formValues;
      const companyInput: CreateCompany2Input = {
        accountNumber: formValues.accountNumber,
        clearingCode: formValues.clearingSalesOffice?.value,
        paymentMethod: isPaymentMethod(formValues.paymentMethod)
          ? formValues.paymentMethod
          : undefined,
        registrationNumber: formValues.registrationNumber,
        smeOnboarding: true,
        accountClosureMonth: Number(formValues.accountClosureMonth),
        salaryReviewMonth: Number(formValues.salaryReviewMonth),
        salesRep: formValues.salesRep,
        companyStatusEffective: new Date().toISOString(),
        hasCompanyGroup: formValues.hasCompanyGroup === HasCompanyGroupType.YES,
        administrator: [],
      };

      const companyRes = await createSmeCompany({
        variables: { input: companyInput },
      });

      if (!companyRes.data?.createCompany2) {
        return;
      }

      const { company } = companyRes.data.createCompany2;

      const contactPersonInput: SetProposalCompanyContactPersonInput = {
        companyId: company.id,
        firstName: formValues.contactFirstName,
        lastName: formValues.contactLastName,
        personalIdentityNumber:
          formValues.contactNaturalPersonIdentifier !== ''
            ? formValues.contactNaturalPersonIdentifier
            : undefined,
        phoneNumber: formValues.contactPhone,
        email: formValues.contactEmail,
      };

      const headAdminInput: SetProposalCompanyHeadAdminInput = {
        companyId: company.id,
        firstName: formValues.adminFirstName,
        lastName: formValues.adminLastName,
        personalIdentityNumber: formValues.adminNaturalPersonIdentifier,
        phoneNumber: formValues.adminPhone,
        email: formValues.adminEmail,
      };

      const [contactRes, headAdminRes] = await Promise.all([
        updateContactPerson({
          variables: {
            input: separateContactAndAdmin
              ? contactPersonInput
              : headAdminInput,
          },
        }),
        updateHeadAdmin({
          variables: { input: headAdminInput },
        }),
      ]);

      if (
        !contactRes.data?.setProposalContactPerson ||
        !headAdminRes.data?.setProposalHeadAdmin
      ) {
        return;
      }

      send({
        message: formatMessage(smeCreateCompanyMessages.created),
        type: 'success',
      });
      push(`/sme/${company?.id}`);
    } catch {
      // do nothing
    }
  };

  return {
    submit,
    submissionError:
      createCompanyError || updateContactPersonError || updateHeadAdminError,
  };
};
