import gql from 'graphql-tag';

export const INTERNAL_NEWS_FORM_CONFIG_QUERY = gql`
  query internalNewsFormConfigQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
      organizationalUnits {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;
