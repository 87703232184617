import { DescriptionList, DescriptionListEntry, Dot } from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import { capitalize, formatRegistrationNumber } from '@frontend/utils';
import {
  smeCompanyQuery_company_Company as Company,
  smeCompanyQuery_proposalContactPerson_ProposalCompanyContactPerson as ProposalContactPerson,
  smeCompanyQuery_proposalHeadAdmin_ProposalCompanyHeadAdmin as ProposalHeadAdmin,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import { companyLegalEntityTypeMessages } from 'features/companies/messages';
import {
  smeCompanyMessages,
  smePaymentMethodMessages,
} from 'features/sme/messages/sme';
import React from 'react';

interface Props {
  company: Company;
  proposalContactPerson: ProposalContactPerson | null;
  proposalHeadAdmin: ProposalHeadAdmin | null;
}

export const InformationTable: React.FC<Props> = ({
  company,
  proposalContactPerson,
  proposalHeadAdmin,
}) => {
  const { nlpSalesRep } = company;
  const { formatDate } = useIntl();

  const companyData: DescriptionListEntry[] = [
    {
      id: 'company.registrationNumber',
      label: <FormattedMessage {...smeCompanyMessages.registrationNumber} />,
      value: formatRegistrationNumber(company.registrationNumber),
    },
    {
      id: 'company.salesRep',
      label: <FormattedMessage {...smeCompanyMessages.salesRep} />,
      value: nlpSalesRep ? (
        `${nlpSalesRep.firstName} ${nlpSalesRep.lastName} (${nlpSalesRep.externalId})`
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'company.displayName',
      label: <FormattedMessage {...smeCompanyMessages.companyName} />,
      value: company.displayName,
    },
    {
      id: 'company.legalEntityType',
      label: <FormattedMessage {...smeCompanyMessages.legalEntityType} />,
      value: (
        <FormattedMessage
          select={company.legalEntityType}
          messages={companyLegalEntityTypeMessages}
        />
      ),
    },
    {
      id: 'company.partOfGroup',
      label: <FormattedMessage {...smeCompanyMessages.hasCompanyGroup} />,
      value: company.hasCompanyGroup ? (
        <FormattedMessage {...commonMessages.yes} />
      ) : (
        <FormattedMessage {...commonMessages.no} />
      ),
    },
    {
      id: 'company.address',
      label: <FormattedMessage {...smeCompanyMessages.companyAddress} />,
      value: company.address.line1 ?? <NoValue />,
    },
    {
      id: 'company.adminName',
      label: <FormattedMessage {...smeCompanyMessages.adminName} />,
      value: proposalHeadAdmin ? (
        `${proposalHeadAdmin.firstName} ${proposalHeadAdmin.lastName}`
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'company.adminPid',
      label: <FormattedMessage {...smeCompanyMessages.adminPid} />,
      value: proposalHeadAdmin ? (
        <FormattedNaturalPersonIdentifier
          nid={proposalHeadAdmin.personalIdentityNumber}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'company.adminEmail',
      label: <FormattedMessage {...smeCompanyMessages.adminEmail} />,
      value: proposalHeadAdmin?.email ?? <NoValue />,
    },
    {
      id: 'company.adminPhone',
      label: <FormattedMessage {...smeCompanyMessages.adminPhone} />,
      value: proposalHeadAdmin?.phoneNumber ?? <NoValue />,
    },
    {
      id: 'company.contactName',
      label: <FormattedMessage {...smeCompanyMessages.contactName} />,
      value: proposalContactPerson ? (
        `${proposalContactPerson.firstName} ${proposalContactPerson.lastName}`
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'company.contactPid',
      label: <FormattedMessage {...smeCompanyMessages.contactPid} />,
      value: proposalContactPerson?.personalIdentityNumber ? (
        <FormattedNaturalPersonIdentifier
          nid={proposalContactPerson.personalIdentityNumber}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'company.contactEmail',
      label: <FormattedMessage {...smeCompanyMessages.contactEmail} />,
      value: proposalContactPerson?.email ?? <NoValue />,
    },
    {
      id: 'company.contactPhone',
      label: <FormattedMessage {...smeCompanyMessages.contactPhone} />,
      value: proposalContactPerson?.phoneNumber ?? <NoValue />,
    },
    {
      id: 'company.accountClosureMonth',
      label: <FormattedMessage {...smeCompanyMessages.accountClosureMonth} />,
      value: company.accountClosureMonth ? (
        capitalize(
          formatDate(new Date().setMonth(company.accountClosureMonth - 1), {
            month: 'long',
          }),
        )
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'company.salaryReviewMonth',
      label: <FormattedMessage {...smeCompanyMessages.salaryReviewMonth} />,
      value: company.salaryReviewMonth ? (
        capitalize(
          formatDate(new Date().setMonth(company.salaryReviewMonth - 1), {
            month: 'long',
          }),
        )
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'company.paymentMethod',
      label: <FormattedMessage {...smeCompanyMessages.paymentMethod} />,
      value: company.paymentMethod ? (
        <FormattedMessage
          select={company.paymentMethod}
          messages={smePaymentMethodMessages}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'company.clearingSalesOffice',
      label: <FormattedMessage {...smeCompanyMessages.clearingSalesOffice} />,
      value: company.clearingCode?.displayName ?? <NoValue />,
    },
    {
      id: 'company.validKyc',
      label: <FormattedMessage {...smeCompanyMessages.kdk} />,
      value:
        company.validKyc != null ? (
          company.validKyc ? (
            <Dot
              colorValue="green"
              size={0.75}
              label={<FormattedMessage {...smeCompanyMessages.kdkValid} />}
            />
          ) : (
            <Dot
              colorValue="error"
              size={0.75}
              label={<FormattedMessage {...smeCompanyMessages.kdkInvalid} />}
            />
          )
        ) : (
          <NoValue />
        ),
    },
  ];

  return (
    <>
      <ChipsWrapper>
        <AssistChip
          text={
            <FormattedMessage
              {...smeCompanyMessages.editOrganisationInformation}
            />
          }
          leadingIcon={edit}
          to={`${company.id}/edit`}
        />
      </ChipsWrapper>
      <DescriptionList alignLeft entries={companyData} />
    </>
  );
};
