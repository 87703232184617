import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { AddNlpAgreementForm } from 'features/companies/company/agreements/add/nlp';
import { EditCompanyDetailsModal } from 'features/companies/company/agreements/add/nlp/edit-company-details';
import { agreementMessages } from 'features/companies/company/agreements/messages';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../../..';

export const AddNlpAgreementRoute: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({ match: { params, url } }) => (
  <NavigationAnchor title={agreementMessages.addNlpAgreement} url={url}>
    <Page
      title={<FormattedMessage {...agreementMessages.addNlpAgreement} />}
      parentLink={`/companies/${params.companyId}/settings`}
    >
      <ContentContainer>
        <AddNlpAgreementForm />
      </ContentContainer>
      <EditCompanyDetailsModal />
    </Page>
  </NavigationAnchor>
);
