import { ApolloError, useMutation } from '@apollo/client';
import { stripSearchParams, toNumber } from '@frontend/utils';
import {
  createNlpAgreementEditCompanyMutation,
  createNlpAgreementEditCompanyMutationVariables,
  PaymentMethod,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { agreementMessages } from '../../../messages';
import { CREATE_NLP_AGREEMENT_EDIT_COMPANY_MUTATION } from '../graphql/mutations';
import { EDIT_COMPANY_SEARCH_PARAM, FormValues } from './';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const history = useHistory();
  const location = useLocation();
  const { send } = useNotification();

  const [updateCompany, { error }] = useMutation<
    createNlpAgreementEditCompanyMutation,
    createNlpAgreementEditCompanyMutationVariables
  >(CREATE_NLP_AGREEMENT_EDIT_COMPANY_MUTATION);

  const onRequestClose = () => {
    stripSearchParams(history, location, [EDIT_COMPANY_SEARCH_PARAM]);
  };

  const submit = async (values: FormValues) => {
    try {
      await updateCompany({
        variables: {
          input: {
            id: companyId,
            clearingCode: values?.clearingSalesOffice?.value,
            accountClosureMonth: toNumber(values.accountClosureMonth),
            accountNumber:
              values.paymentMethod &&
              values.paymentMethod !== PaymentMethod.INVOICE
                ? values.accountNumber
                : undefined,
            salaryReviewMonth: toNumber(values.salaryReviewMonth),
            salesRep: values.salesRep,
            paymentMethod: values.paymentMethod || undefined,
          },
        },
      });

      send({
        type: 'success',
        message: formatMessage(agreementMessages.updateCompanyDetailsSuccess),
      });

      onRequestClose();
    } catch {
      // Do nothing. Error handled by apollo mutation hook
    }
  };

  return {
    submit,
    error,
  };
};
