import { fcfwEventHistoryTableQuery } from 'app/apollo/graphql/types';

interface FetchMoreResult {
  fetchMoreResult?: fcfwEventHistoryTableQuery;
}

export const updateQuery = (
  previousResult: fcfwEventHistoryTableQuery,
  { fetchMoreResult }: FetchMoreResult,
) => {
  if (!previousResult.fcfwAssertions || !fetchMoreResult?.fcfwAssertions) {
    return previousResult;
  }

  return fetchMoreResult;
};
