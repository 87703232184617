import { client } from 'app/apollo/client';
import {
  fileUploadQuery,
  fileUploadQueryVariables,
} from 'app/apollo/graphql/types';

import { FormFile } from '../form';
import { FILE_UPLOAD_QUERY } from '../graphql/queries';
import { uploadFile } from './upload-file';

export const getFilePath = async <T extends FormFile>(
  companyId: string,
  item: T,
) => {
  if (!item?.file) {
    if (!item?.path) {
      throw new Error();
    }
    return item.path;
  }
  const uploadResponse = await client.query<
    fileUploadQuery,
    fileUploadQueryVariables
  >({
    query: FILE_UPLOAD_QUERY,
    variables: {
      files: [
        {
          companyId,
          fileName: item.name ?? '',
        },
      ],
    },
  });
  const { path, url } = uploadResponse.data.fileUploadUrl[0];
  if (!url || !path) {
    throw new Error();
  }
  const { status } = await uploadFile(url, item.file);
  if (status !== 200) {
    throw new Error();
  }
  return path;
};

export const getFilePaths = async <T extends FormFile>(
  companyId: string,
  files?: T[],
) => {
  const attachmentPaths = await Promise.all(
    (files ?? []).map(async item => getFilePath(companyId, item)),
  );
  return attachmentPaths;
};
