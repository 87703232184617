import { ApolloError, useMutation } from '@apollo/client';
import { toNumber } from '@frontend/utils';
import {
  editProposalEmployersPensionBenefitMutation,
  editProposalEmployersPensionBenefitMutationVariables,
  UpdateProposalEmployersPensionBenefitInput,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { clearProposalCache } from '../../../../../utils/clear-proposal-cache';
import { FormValues } from '../../../form/employers-pension';
import { formatPremiumMatrixInput } from '../../../form/utils';
import { EDIT_PROPOSAL_EMPLOYERS_PENSION_BENEFIT_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

interface Params {
  params: BenefitMatchParams;
}

export const useSubmit = ({ params }: Params): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [editBenefit, { error: submissionError }] = useMutation<
    editProposalEmployersPensionBenefitMutation,
    editProposalEmployersPensionBenefitMutationVariables
  >(EDIT_PROPOSAL_EMPLOYERS_PENSION_BENEFIT_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals'],
    }),
  });

  const submit = async (formValues: FormValues) => {
    // Thes values are guaranteed through the form validation
    if (
      !formValues.defaultManagementType ||
      !formValues.premiumCalculationMethod
    ) {
      return;
    }
    try {
      const input: UpdateProposalEmployersPensionBenefitInput = {
        id: params.benefitId,
        benefitPackageIds: formValues.benefitPackageIds,
        convertingPremiumWaiverFrom:
          formValues.premiumWaiver === 'yes'
            ? formValues.convertingPremiumWaiver
              ? formValues.convertingPremiumWaiverFrom
              : null
            : null,
        defaultManagementType: formValues.defaultManagementType,
        extraFixedPremium: ['CUSTOM_MATRIX', 'ITP_MATRIX'].includes(
          formValues.premiumCalculationMethod,
        )
          ? formValues.extraFixedPremium
          : false,
        premiumCalculationMethod: formValues.premiumCalculationMethod,
        premiumDeductions: formValues.premiumDeductions,
        premiumMatrix:
          formValues.premiumCalculationMethod === 'CUSTOM_MATRIX'
            ? formatPremiumMatrixInput(formValues.customPremiumMatrix)
            : formValues.premiumCalculationMethod === 'ITP_MATRIX'
              ? formatPremiumMatrixInput(formValues.itpPremiumMatrix)
              : undefined,
        premiumWaiver: formValues.premiumWaiver === 'yes',
        retirementAge: toNumber(formValues.retirementAge) ?? 0,
      };

      const res = await editBenefit({
        variables: { benefitId: params.benefitId, input },
      });

      if (!res.data?.updateProposalEmployersPensionBenefit) {
        return;
      }

      send({
        message: formatMessage(smeBenefitFormMessages.benefitSaved),
        type: 'success',
      });
      push(`/sme/${params.companyId}/proposals/${params.proposalId}/benefits`);
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
