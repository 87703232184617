import { useCurrentUser } from 'contexts/current-permissions';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { internalNewsMessages } from 'features/companies/company/internal-news/messages';
import { hasInternalNewsPermissions } from 'features/companies/company/internal-news/utils/has-internal-news-permissions';
import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import { MatchParams } from '..';
import { OverviewRoute } from './_index';
import { CreateRoute } from './create';
import { EditRoute } from './edit';

export const InternalNewsRoute: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    path,
    url,
    params: { companyId },
  },
}) => {
  const { permissions } = useCurrentUser();
  const { hasPermissions } = hasInternalNewsPermissions(permissions);

  if (!hasPermissions) {
    return <Redirect to={`/companies/${companyId}`} />;
  }

  return (
    <NavigationAnchor title={internalNewsMessages.news} url={url}>
      <Switch>
        <Route path={`${path}/create`} component={CreateRoute} />
        <Route path={`${path}/:id`} component={EditRoute} />
        <Route path={path} component={OverviewRoute} />
      </Switch>
    </NavigationAnchor>
  );
};
