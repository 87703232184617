import { FormattedMessage } from 'components/formats';
import { Edit } from 'features/companies/company/internal-news/edit';
import { internalNewsMessages } from 'features/companies/company/internal-news/messages';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { MatchParams } from '../..';

export interface EditRouteMatchParams extends MatchParams {
  id: string;
}

type Props = RouteComponentProps<EditRouteMatchParams>;

export const EditRoute: React.FC<Props> = ({
  match: {
    params: { companyId },
    url,
  },
}) => (
  <Page
    parentLink={`/companies/${companyId}/internal-news`}
    title={<FormattedMessage {...internalNewsMessages.editNews} />}
    tabs={[
      {
        title: internalNewsMessages.editNews,
        url,
      },
      {
        title: internalNewsMessages.preview,
        url: `${url}/preview`,
      },
    ]}
  >
    <Edit />
  </Page>
);
