import { DatePickerField, Form, Formik } from '@frontend/formik';
import { Button, Section, Subsection } from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import React from 'react';
import * as Yup from 'yup';

import { agreementMessages } from '../../messages';
import { useSubmit } from './utils/use-submit';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    startDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  startDate: string;
}

export const AddNordnetAgreementForm: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { submit, error: submissionError } = useSubmit();

  return (
    <Section>
      <Formik<FormValues>
        validationSchema={validationSchema(intl)}
        initialValues={{ startDate: '' }}
        onSubmit={submit}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Subsection>
              <TextGrid>
                <DatePickerField
                  dense
                  required
                  name="startDate"
                  label={formatMessage(agreementMessages.startDate)}
                />
              </TextGrid>
            </Subsection>
            {submissionError && <GraphQlError error={submissionError} />}
            <Button type="submit" filled loading={isSubmitting}>
              <FormattedMessage {...agreementMessages.add} />
            </Button>
          </Form>
        )}
      </Formik>
    </Section>
  );
};
