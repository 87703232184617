import gql from 'graphql-tag';

export const UPDATE_PROPOSAL_QUERY = gql`
  query updateProposalQuery($id: ID!, $skipAdviceData: Boolean!) {
    proposal(id: $id) {
      id
      companySize
      fees {
        annualFeeFund
        annualFeePortfolio
        annualFeeTraditional
        capitalFeeFund
        capitalFeePortfolio
        capitalFeeTraditional
        fundDiscountPermitted
        fundDiscountValue
        commissionWaiverAllowed
        commissionWaiverHealthcare
        commissionWaiverPremiumWaiver
        commissionWaiverSickness
        riskBucket
      }
      need @skip(if: $skipAdviceData) {
        collectiveAgreementBlueCollar
        collectiveAgreementWhiteCollar
        documentationProvided
        text
      }
      productKnowledge @skip(if: $skipAdviceData) {
        adviceSavingsInsurance
        recentExperiencePension
        professionalExperiencePension
        pensionInstrumentKnowledge
        notes
      }
      summary @skip(if: $skipAdviceData) {
        checkNeed
        checkExistingCoverage
        checkPlannedDevelopment
        checkDeductionSpace
        checkFinancialSituation
        format
        text
      }
      validity {
        validProposal {
          isValid
        }
        validNeed {
          isValid
        }
        validProductKnowledge {
          isValid
        }
        validBenefits {
          isValid
        }
        validEmployees {
          isValid
        }
        validFees {
          isValid
        }
        validCompilation {
          isValid
          info
          warnings
          errors
        }
        validSummary {
          isValid
        }
      }
    }
  }
`;

export const PROPOSAL_SAVINGS_ADVICE_QUERY = gql`
  query proposalSavingsAdviceQuery($id: ID!) {
    proposal(id: $id) {
      id
      adviceWaived
      companySize
      productKnowledge {
        adviceSavingsInsurance
      }
    }
  }
`;
