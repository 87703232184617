import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { AddEuroAccidentTjpAgreementForm } from 'features/companies/company/agreements/add/euro-accident-tjp';
import { agreementMessages } from 'features/companies/company/agreements/messages';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../../..';

export const AddEuroAccidentTjpAgreementRoute: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({ match: { params, url } }) => (
  <NavigationAnchor title={agreementMessages.addEuroAccidentTjp} url={url}>
    <Page
      title={<FormattedMessage {...agreementMessages.addEuroAccidentTjp} />}
      parentLink={`/companies/${params.companyId}/settings`}
    >
      <ContentContainer>
        <AddEuroAccidentTjpAgreementForm />
      </ContentContainer>
    </Page>
  </NavigationAnchor>
);
