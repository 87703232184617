import {
  Button,
  ButtonLayout,
  CheckboxField,
  ChipsField,
  NumberField,
  SelectField,
} from '@frontend/ui';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages, suffixMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { SideSheet } from 'components/SideSheet';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import React from 'react';
import useIntl from 'react-intl/src/components/useIntl';

import { FilterParams } from '../../../utils/use-filter-params';

type FilterSideSheetProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  setFilterParam: (params: Partial<FilterParams>) => void;
  clearFilter: () => void;
  filterParams: FilterParams;
  companies: Array<{ label: string; value: string }>;
  benefitPackages: Array<{ label: string; value: string }>;
};

export const PensionTransferEmployeeFilterSideSheet: React.FC<
  FilterSideSheetProps
> = ({
  isOpen,
  setOpen,
  setFilterParam,
  clearFilter,
  filterParams,
  companies,
  benefitPackages,
}) => {
  const { formatMessage } = useIntl();

  return (
    <SideSheet
      footer={
        <ButtonLayout align="right">
          <Button onClick={clearFilter}>
            <FormattedMessage {...commonMessages.clear} />
          </Button>
          <Button filled onClick={() => setOpen(false)}>
            <FormattedMessage {...commonMessages.done} />
          </Button>
        </ButtonLayout>
      }
      open={isOpen}
      setOpen={setOpen}
      title={<FormattedMessage {...advisorMessages.filters} />}
    >
      <FilterSection>
        <CheckboxField
          label={<FormattedMessage {...advisorMessages.excludeTerminated} />}
          checked={filterParams.excludeTerminated === 'true'}
          onChange={checked => {
            setFilterParam({
              excludeTerminated: checked ? 'true' : '',
            });
          }}
        />
        <CheckboxField
          label={
            <FormattedMessage {...advisorMessages.excludeBrokeredByNode} />
          }
          checked={filterParams.brokeredByNode === 'false'}
          onChange={checked => {
            setFilterParam({
              brokeredByNode: checked ? 'false' : '',
            });
          }}
        />
      </FilterSection>
      <FilterSection
        header={<FormattedMessage {...advisorMessages.minCapital} />}
      >
        <NumberField
          dense
          label={<FormattedMessage {...advisorMessages.capital} />}
          value={filterParams.minCapital}
          affix={formatMessage(suffixMessages.kr)}
          decimalScale={0}
          onValueChange={values => {
            setFilterParam({
              minCapital: values.value.split(' ').join(''),
            });
          }}
        />
      </FilterSection>
      <FilterSection
        header={<FormattedMessage {...advisorMessages.maxCapital} />}
      >
        <NumberField
          dense
          label={<FormattedMessage {...advisorMessages.capital} />}
          value={filterParams.maxCapital}
          affix={formatMessage(suffixMessages.kr)}
          decimalScale={0}
          onValueChange={values => {
            setFilterParam({
              maxCapital: values.value.split(' ').join(''),
            });
          }}
        />
      </FilterSection>
      <FilterSection
        header={<FormattedMessage {...advisorMessages.chooseCompany} />}
      >
        <SelectField
          dense
          label={<FormattedMessage {...commonMessages.company} />}
          options={companies}
          value={filterParams.companyId}
          onChange={event => {
            setFilterParam({ companyId: event.detail.value });
            // Reset benefitPackageIds to not filter on other companies' benefit packages
            setFilterParam({ benefitPackageIds: [] });
          }}
        />
      </FilterSection>
      <FilterSection
        header={<FormattedMessage {...advisorMessages.chooseBenefitPackage} />}
      >
        <ChipsField
          dense
          label={<FormattedMessage {...commonMessages.benefitPackage} />}
          options={benefitPackages}
          value={benefitPackages
            .filter(({ value }) =>
              filterParams.benefitPackageIds.includes(value),
            )
            .reduce(
              (acc, { label, value }) => ({ ...acc, [value]: label }),
              {},
            )}
          onChange={selections => {
            setFilterParam({ benefitPackageIds: Object.keys(selections) });
          }}
          disabled={!filterParams.companyId}
        />
      </FilterSection>
    </SideSheet>
  );
};
