import { useMutation } from '@apollo/client';
import {
  updateMembershipRolesMutation,
  updateMembershipRolesMutationVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { UPDATE_MEMBERSHIP_ROLES_MUTATION } from '../../graphql/mutations';
import { FormValues } from '..';

interface Params {
  companyId: string;
}

type Submit = (values: FormValues) => Promise<void>;

export const useSubmit = ({ companyId }: Params) => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [updateMembershipRoles, { error: submissionError }] = useMutation<
    updateMembershipRolesMutation,
    updateMembershipRolesMutationVariables
  >(UPDATE_MEMBERSHIP_ROLES_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${companyId}`,
        fieldName: 'administrators',
      });
      cache.evict({
        id: `Company: ${companyId}`,
        fieldName: 'memberships',
      });
      cache.gc();
    },
  });

  const submit: Submit = async (values: FormValues) => {
    // This value is guaranteed through the form validation
    if (!values.userAccount) {
      return;
    }

    try {
      await updateMembershipRoles({
        variables: {
          companyId,
          userAccountId: values.userAccount.value,
          roles: values.roles,
        },
      });

      send({
        message: formatMessage(commonMessages.changesSaved),
        type: 'success',
      });
    } catch {
      // Do nothing
    }
  };
  return { submit, submissionError };
};
