import { validationMessages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import { isEmail, isValidPhoneNumber } from 'validations';
import * as Yup from 'yup';

const requiredIfNoNid = (intl: IntlShape) =>
  Yup.string().when('nids', {
    is: (nids: string[]) => !nids.length,
    then: schema =>
      schema.required(intl.formatMessage(validationMessages.mandatoryField)),
  });

/**
 * An array of possible combinations of `otherAddressFields`.
 * Required by Yup validation schema to avoid circular dependencies.
 */
const ADDRESS_DEPENDENCIES: [string, string][] = [
  ['addressCity', 'addressLine1'],
  ['addressCity', 'addressPostalCode'],
  ['addressPostalCode', 'addressLine1'],
];
/**
 * For address fields, validate that all or no address fields are set.
 */
/**
 * For address fields, validate that all or no address fields are set.
 */
const validateAddress = (
  schema: Yup.StringSchema,
  currentAddressField: string,
  intl: IntlShape,
) => {
  const otherAddressFields = [
    'addressCity',
    'addressLine1',
    'addressPostalCode',
  ].filter(field => field !== currentAddressField);

  return schema.when(otherAddressFields, {
    is: (...values: string[]) => values.some(Boolean),
    then: _schema =>
      _schema.required(intl.formatMessage(validationMessages.isValidAddress)),
  });
};

export const personalDataValidationSchema = (intl: IntlShape) =>
  Yup.object().shape(
    {
      addressCity: validateAddress(requiredIfNoNid(intl), 'addressCity', intl),
      addressLine1: validateAddress(
        requiredIfNoNid(intl),
        'addressLine1',
        intl,
      ),
      addressPostalCode: validateAddress(
        requiredIfNoNid(intl),
        'addressPostalCode',
        intl,
      ),
      email: Yup.string()
        .required(intl.formatMessage(validationMessages.mandatoryField))
        .test(
          'valid email',
          intl.formatMessage(validationMessages.isValidEmail),
          value => isEmail(value),
        ),
      phone: Yup.string()
        .nullable()
        .test(
          'valid or no phone number',
          intl.formatMessage(validationMessages.isValidPhone),
          value => !value || isValidPhoneNumber(value),
        ),
    },
    ADDRESS_DEPENDENCIES,
  );
