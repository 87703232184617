import { bpsToDecimalFraction } from '@frontend/utils';
import { IntlShape } from 'components/formats';

export const getTradCapitalFeeOptions = (intl: IntlShape) =>
  [45, 50, 55, 60, 65, 70, 75].map(value => ({
    label: intl.formatNumber(Number(bpsToDecimalFraction(value)), {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    value: value.toString(),
  }));
