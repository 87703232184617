import { Th, Tr } from '@frontend/ui';
import {
  companyBillingConfigurationQuery_company_Company_agreements as Agreements,
  companyBillingConfigurationQuery_company_Company_supplierReferenceMapping_SupplierReferenceMapping as SupplierReferences,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { ListTable } from 'components/ListTable';
import { TopLoading } from 'components/TopLoading';
import React from 'react';

import { billingMessages } from '../../messages';
import { ContractReferenceRow } from './ContractReferenceRow';
import { useUpdate } from './update';

interface Props {
  agreements?: ReadonlyArray<Agreements>;
  references?: SupplierReferences | null;
}

export enum SupplierReference {
  NLP = 'NLP',
  ADVINANS = 'ADVINANS',
  NORDNET = 'NORDNET',
  EURO_ACCIDENT = 'EURO_ACCIDENT',
}

export const ContractReferenceTable: React.FC<Props> = ({
  agreements,
  references,
}) => {
  const { _update, updateLoading, updateError } = useUpdate();

  const activeAgreements = (agreements || []).reduce(
    (acc, curr) => {
      if (curr.status !== 'ACTIVE') {
        return acc;
      }
      acc[curr.__typename] = true;
      return acc;
    },
    {} as Record<
      'AgreementEuroAccident' | 'AgreementNordea' | 'AgreementNordnet',
      boolean
    >,
  );

  return (
    <>
      {updateLoading && <TopLoading />}
      <ListTable>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.supplier} />
            </Th>
            <Th>
              <FormattedMessage {...billingMessages.reference} />
            </Th>
            <Th />
          </Tr>
        </thead>
        <tbody>
          <ContractReferenceRow
            supplier={SupplierReference.ADVINANS}
            reference={references?.[SupplierReference.ADVINANS]}
            onDelete={() =>
              _update({ key: SupplierReference.ADVINANS, value: null })
            }
          />
          {activeAgreements.AgreementEuroAccident && (
            <ContractReferenceRow
              supplier={SupplierReference.EURO_ACCIDENT}
              reference={references?.[SupplierReference.EURO_ACCIDENT]}
              onDelete={() =>
                _update({ key: SupplierReference.EURO_ACCIDENT, value: null })
              }
            />
          )}
          {activeAgreements.AgreementNordea && (
            <ContractReferenceRow
              supplier={SupplierReference.NLP}
              reference={references?.[SupplierReference.NLP]}
              onDelete={() =>
                _update({ key: SupplierReference.NLP, value: null })
              }
            />
          )}
          {activeAgreements.AgreementNordnet && (
            <ContractReferenceRow
              supplier={SupplierReference.NORDNET}
              reference={references?.[SupplierReference.NORDNET]}
              onDelete={() =>
                _update({ key: SupplierReference.NORDNET, value: null })
              }
            />
          )}
        </tbody>
      </ListTable>
      {updateError && <GraphQlError error={updateError} />}
    </>
  );
};
