import { defineMessages } from 'react-intl';

export const invoiceMessages = defineMessages({
  shredded: {
    id: 'menuMessages.shredded',
    defaultMessage: 'Makulerad',
  },
  invoices: {
    id: 'menuMessages.invoices',
    defaultMessage: 'Fakturor',
  },
  invoiceLines: {
    id: 'menuMessages.invoiceLines',
    defaultMessage: 'Fakturarader',
  },
  supplier: {
    id: 'invoiceMessages.supplier',
    description: 'Supplier',
    defaultMessage: 'Leverantör',
  },
  invoiceNumber: {
    id: 'invoiceMessages.invoiceNumber',
    description: 'Invoice number',
    defaultMessage: 'Fakturanummer',
  },
  invoiceDate: {
    id: 'invoiceMessages.invoiceDate',
    description: 'Invoice date',
    defaultMessage: 'Fakturadatum',
  },
  dueDate: {
    id: 'invoiceMessages.dueDate',
    description: 'Due date',
    defaultMessage: 'Förfallodag',
  },
  downloadSie4: {
    id: 'invoiceMessages.downloadSie4',
    description: 'Download as SIE4',
    defaultMessage: 'Ladda ned som SIE4',
  },
  downloadPeppol: {
    id: 'invoiceMessages.downloadPeppol',
    description: 'Download as PEPPOL',
    defaultMessage: 'Ladda ned som PEPPOL',
  },
  downloadPdf: {
    id: 'invoiceMessages.downloadPdf',
    description: 'Download as PDF',
    defaultMessage: 'Ladda ned som PDF',
  },
  downloadExcel: {
    id: 'invoiceMessages.downloadExcel',
    description: 'Download as Excel',
    defaultMessage: 'Ladda ned som Excel',
  },
  downloadCsv: {
    id: 'invoiceMessages.downloadCsv',
    description: 'Download as CSV',
    defaultMessage: 'Ladda ned som CSV',
  },
  noInvoices: {
    id: 'invoiceMessages.enptyStateText',
    description: 'There are no invoices',
    defaultMessage: 'Det finns inga fakturor att visa',
  },
  noInvoiceMatch: {
    id: 'invoiceMessages.noInvoiceMatch',
    defaultMessage: 'Inga fakturor matchar sökningen',
  },
  selectSupplier: {
    id: 'invoiceMessages.selectSupplier',
    defaultMessage: 'Välj leverantör',
  },
  selectInvoiceDate: {
    id: 'invoiceMessages.selectInvoiceDate',
    defaultMessage: 'Välj fakturadatum',
  },
  searchInvoiceNumber: {
    id: 'invoiceMessages.searchInvoiceNumber',
    defaultMessage: 'Sök fakturanummer',
  },
  selectAmount: {
    id: 'invoiceMessages.selectAmount',
    defaultMessage: 'Välj belopp',
  },
  selectDueDate: {
    id: 'invoiceMessages.selectDueDate',
    defaultMessage: 'Välj förfallodag',
  },
  uploadPdfInvoice: {
    id: 'invoiceMessages.uploadPdfInvoice',
    defaultMessage: 'Ladda upp PDF-faktura',
  },
  uploadPdfInvoiceSuccess: {
    id: 'invoiceMessages.uploadPdfInvoiceSuccess',
    defaultMessage: 'Fakturan har laddats upp',
  },
  payableAmount: {
    id: 'invoiceMessages.payableAmount',
    defaultMessage: 'Belopp',
  },
  issueDate: {
    id: 'invoiceMessages.issueDate',
    defaultMessage: 'Utfärdandedatum',
  },
  invoiceRequired: {
    id: 'invoiceMessages.invoiceRequired',
    defaultMessage: 'Faktura krävs',
  },
});
