import { BankingInstituteId } from 'app/utils/constants';
import { Permissions } from 'contexts/current-permissions';

import { hasPensionPremiumPermission } from './has-pension-premium-permission';

interface Insurance {
  institute: {
    id: string;
  };
}

interface Args {
  pensionInsurance?: Insurance;
  permissions?: Permissions;
}

/**
 * @returns true iff pension insurance exists,
 * the pension provider is not Nordnet, and
 * the user has permission to update pension premiums.
 */
export const canUpdateSinglePremium = ({
  pensionInsurance,
  permissions,
}: Args): boolean =>
  !!pensionInsurance &&
  pensionInsurance.institute.id !== BankingInstituteId.NORDNET &&
  hasPensionPremiumPermission(permissions);
