import gql from 'graphql-tag';

export const VERSION_HISTORY_TABLE_QUERY = gql`
  query versionHistoryTableQuery($benefitId: ID!, $companyId: ID!) {
    company(id: $companyId) {
      id
      startDate
    }
    flexBenefit(id: $benefitId) {
      id
      content {
        locale
      }
      changes {
        id
        from
        to
        actor {
          displayName
        }
        createdAt
      }
    }
  }
`;
