import { defaultTheme } from '@frontend/ui';
import {
  InsuranceComponentStatus,
  InsurancePremiumStatus,
  MembershipRole,
} from 'app/apollo/graphql/types';
import { suffixMessages } from 'app/messages/common';
import { workInformationMessages } from 'app/messages/employees';
import { MessageDescriptor } from 'react-intl';

export const POLL_INTERVAL = 500;
export const CRAWL_POLL_INTERVAL = 1500;

export const translatePBB = Pbb => `${Pbb.replace('PBB_', '')} PBB`;
export const translatePBBLevel = Pbb => `${Pbb.replace('PBB', '')} PBB`;

export const DEFAULT_RESULT_PER_PAGE = 50;
export const DEFAULT_RESULT_PER_PAGE_SMALL = 10;

export const SMALL_SCREEN_RESOLUTION = defaultTheme.breakpoints.desktopHD * 16;

export const MOBILE_SCREEN_RESOLUTION =
  defaultTheme.breakpoints.tablet * 16 - 1;

export enum BankingInstituteId {
  ADVINANS = 'ADVINANS', // Advinans
  AFA = 'AFA', // AFA Försäkring
  ALAND = 'ALAND', // Ålandsbanken
  ALECTA = 'ALECTA', // Alecta
  AMF = 'AMF', // AMF
  ANKE = 'ANKE', // Allmäna Änke- och pupillkassan i sverige
  AVANZA = 'AVANZA', // Avanza
  BLIWA = 'BLIWA', // Bliwa
  BRUMMER = 'BRUMMER', // Brummer
  DANSKE = 'DANSKE', // Danske Bank
  EACCIDENT = 'EACCIDENT',
  FOL = 'FOL', // Folksam
  FORA = 'FORA', // Fora
  FORENADE = 'FORENADE', // Förenade Liv
  FUTUR = 'FUTUR', // Futur
  HJERTA = 'HJERTA', // Hjerta
  ICA = 'ICA', // ICA Banken
  IDUN = 'IDUN', // Idun Liv
  IF = 'IF', // If Försäkring
  IKANO = 'IKANO', // Ikano
  KAPAN = 'KAPAN', // Kåpan
  KPA = 'KPA', // KPA Pension
  LFS = 'LFS', // Länsförsäkringar
  LRF = 'LRF', // Svenska Lärarförsäkringar
  MOVESTICK = 'MOVESTICK', // Movestick
  NORDEA = 'NORDEA', // Nordea
  NORDNET = 'NORDNET', // Nordnet
  PPM = 'PPM', // Pensionsmyndigheten
  PPP = 'PPP', // PP Pension
  PRI = 'PRI', // PRI
  SBAB = 'SBAB', // SBAB
  SEB = 'SEB', // SEB
  SHB = 'SHB', // Handelsbanken
  SHP = 'SHP', // SH Pension
  SKANDIA = 'SKANDIA', // Skandia
  SPK = 'SPK', // SPK
  SPP = 'SPP', // SPP
  SPV = 'SPV', // SPV
  SVEA = 'SVEA', // Svea Bank
  SWEDBANK = 'SWEDBANK', // Swedbank
  TRYGG = 'TRYGG', // Trygg-Hansa
  VFF = 'VFF', // VFF Pension
}

export type OCCUPATIONAL_PENSION_PROVIDER_ID = Extract<
  BankingInstituteId,
  | BankingInstituteId.FUTUR
  | BankingInstituteId.NORDNET
  | BankingInstituteId.LFS
  | BankingInstituteId.NORDEA
>;

export enum CollectiveAgreement {
  AIP = 'AIP',
  AKAPKL = 'AKAPKL',
  AKAPKR = 'AKAPKR',
  BTP = 'BTP',
  BTP1 = 'BTP1',
  BTP1X = 'BTP1X',
  FTP = 'FTP',
  FTP1 = 'FTP1',
  ITP = 'ITP',
  ITP1 = 'ITP1',
  KAP = 'KAP',
  KAPKL = 'KAPKL',
  KTP = 'KTP',
  KTP1 = 'KTP1',
  PA161 = 'PA161',
  PA162 = 'PA162',
  PAKFS09 = 'PAKFS09',
  PPA13 = 'PPA13',
  SAFLO = 'SAFLO',
}

export const MEMBERSHIP_INSURANCE_COMPONENT_STATUS_COLOR: Record<
  InsuranceComponentStatus,
  string
> = {
  NEW: 'orange',
  ACTIVE: 'green',
  NOT_SPECIFIED: 'error',
  PAID_UP: 'outline',
  PAID_OUT: 'outline',
  PAYING_OUT: 'green',
};

export const EMPLOYEE_ROLE: Record<MembershipRole, MessageDescriptor> = {
  SUPER: workInformationMessages.superAdmin,
  HR: workInformationMessages.HRAdmin,
  CONTENT: workInformationMessages.contentAdmin,
  FINANCE: workInformationMessages.financeAdmin,
};

export const MEMBERSHIP_BENEFIT_STATUS_COLOR: Record<
  InsurancePremiumStatus,
  string
> = {
  ACTIVE: 'green',
  ACTIVE_WAIVER: 'green',
  COMPLETE: 'outline',
  INACTIVE: 'outline',
  OPEN: 'green',
  PENDING_ACTIVATION: 'orange',
};

export const COLLECTIVE_AGREEMENT_LABEL: Record<CollectiveAgreement, string> = {
  ITP1: 'ITP 1',
  ITP: 'ITP 2',
  SAFLO: 'SAF-LO',
  KAPKL: 'KAP-KL',
  AKAPKL: 'AKAP-KL',
  AKAPKR: 'AKAP-KR',
  PA161: 'PA 16 avdelning 1',
  PA162: 'PA 16 avdelning 2',
  PAKFS09: 'PA-KFS 09',
  BTP1: 'BTP 1',
  BTP1X: 'BTP 1, hängavtal',
  BTP: 'BTP 2',
  FTP1: 'FTP 1',
  FTP: 'FTP 2',
  KTP1: 'KTP 1',
  KTP: 'KTP 2',
  KAP: 'KAP (KFO-LO)',
  AIP: 'AIP',
  PPA13: 'PPA 13',
};

export const collectiveAgreementOptions = [
  ...Object.keys(CollectiveAgreement).map(agreement => ({
    value: agreement,
    label: COLLECTIVE_AGREEMENT_LABEL[agreement],
  })),
];

export const MEMBERSHIP_INSURANCE_PREMIUM_STATUS: Record<
  InsuranceComponentStatus,
  InsurancePremiumStatus | undefined
> = {
  NEW: InsurancePremiumStatus.PENDING_ACTIVATION,
  ACTIVE: InsurancePremiumStatus.ACTIVE,
  NOT_SPECIFIED: undefined,
  PAID_UP: InsurancePremiumStatus.INACTIVE,
  PAID_OUT: InsurancePremiumStatus.INACTIVE,
  PAYING_OUT: InsurancePremiumStatus.ACTIVE,
};

export const PENSION_PROVIDER: Record<string, string> = {
  advinans: 'Advinans Autopilot',
  nordnet: 'Nordnet',
  lf: 'Länsförsäkringar',
  nordea_fund: 'Nordea',
  nordea_trad: 'Nordea',
};

export const APP_ID = 'app';

export const SALARY_EXCHANGE_TYPE_CODE = 'LÖNEVÄXLING';

export const FUTUR_PENSION = 'Futur Pension';

export const DEFAULT_EMPLOYER_CONTRIBUTIONS_SHARE = 0.3142;

export enum UnitCodeOptions {
  ANN = 'ANN',
  LS = 'LS',
  MON = 'MON',
}

export const unitCodeSuffixMessages: Omit<
  Record<UnitCodeOptions, MessageDescriptor>,
  'LS'
> = {
  [UnitCodeOptions.MON]: suffixMessages.monthly,
  [UnitCodeOptions.ANN]: suffixMessages.annually,
};

export enum ValidAgreementTypeNames {
  AKAPKL = 'AKAPKL',
  AKAPKR = 'AKAPKR',
  ITP = 'ITP',
  ITP1 = 'ITP1',
  KAPKL = 'KAPKL',
  PA161 = 'PA161',
  PA162 = 'PA162',
  PAKFS09 = 'PAKFS09',
  SAFLO = 'SAFLO',
}
