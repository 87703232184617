import gql from 'graphql-tag';

export const CREATE_EURO_ACCIDENT_GROUP_AGREEMENT_QUERY = gql`
  query createEuroAccidentGroupAgreementQuery($id: ID!) {
    company(id: $id) {
      id
      managedBy
    }
  }
`;
