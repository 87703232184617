import {
  DescriptionList,
  Section,
  SectionHeader,
  Subsection,
} from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import { getAccountingDimensionAccountingCosts } from '@frontend/utils';
import {
  membershipOrganizationQuery,
  membershipOrganizationQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { costReportingMessages } from 'app/messages/cost-reporting';
import { formMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { MEMBERSHIP_ORGANIZATION_QUERY } from './graphql/queries';
import { AccountingDimensionTable } from './table';
import { UpdateMembershipAccountingDimensionModal } from './update-accounting-dimension';
import { UpdateMembershipOrgUnitModal } from './update-org-unit';

export const MembershipOrganization: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const [selectedAccountingDimension, setSelectedAccountingDimension] =
    useState<{ id: string; name: string } | undefined>(undefined);
  const [orgUnitModalOpen, setOrgUnitModalOpen] = useState<boolean>(false);

  const { data } = useQuery<
    membershipOrganizationQuery,
    membershipOrganizationQueryVariables
  >(MEMBERSHIP_ORGANIZATION_QUERY, {
    variables: { companyId, userAccountId },
    errorPolicy: 'all',
    suspend: true,
  });

  if (
    !data?.company ||
    !data?.accountingDimensions ||
    !data?.membership?.accountingCost
  ) {
    return null;
  }

  const { accountingDimensions, membership, company } = data;
  const { accountingCost, organizationalUnit } = membership;

  const hasOrgUnits = !!company.organizationalUnits?.edges.length;

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonMessages.organization} />
      </SectionHeader>
      <Subsection>
        {hasOrgUnits && (
          <ChipsWrapper>
            <AssistChip
              onClick={() => setOrgUnitModalOpen(true)}
              text={<FormattedMessage {...formMessages.editOrgUnit} />}
              leadingIcon={edit}
            />
          </ChipsWrapper>
        )}
        <DescriptionList
          alignLeft
          entries={[
            {
              id: 'membershipCostCenter.organizationalUnit',
              label: (
                <FormattedMessage {...commonMessages.organizationalUnit} />
              ),
              value: organizationalUnit ? organizationalUnit.name : <NoValue />,
            },
          ]}
        />
        <UpdateMembershipOrgUnitModal
          isOpen={orgUnitModalOpen}
          organizationalUnit={organizationalUnit}
          onRequestClose={() => setOrgUnitModalOpen(false)}
          membershipId={membership.id}
        />
      </Subsection>
      {accountingDimensions.map(dimension => (
        <Subsection key={dimension.id}>
          <ChipsWrapper>
            <AssistChip
              text={
                <FormattedMessage
                  {...costReportingMessages.editObject}
                  values={{ dimensionName: dimension.name }}
                />
              }
              leadingIcon={edit}
              onClick={() =>
                setSelectedAccountingDimension({
                  id: dimension.id,
                  name: dimension.name,
                })
              }
            />
          </ChipsWrapper>
          <AccountingDimensionTable
            dimensionName={dimension.name}
            entries={getAccountingDimensionAccountingCosts(
              accountingCost,
              dimension.id,
            )}
          />
        </Subsection>
      ))}
      <UpdateMembershipAccountingDimensionModal
        isOpen={!!selectedAccountingDimension}
        membership={membership}
        dimensionId={selectedAccountingDimension?.id}
        dimensionName={selectedAccountingDimension?.name}
        assignedObjects={getAccountingDimensionAccountingCosts(
          accountingCost,
          selectedAccountingDimension?.id,
        )}
        onRequestClose={() => setSelectedAccountingDimension(undefined)}
      />
    </Section>
  );
};
