import { PersonalAdvice } from '@frontend/benefit-types';
import { benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit } from 'app/apollo/graphql/types';
import { DetailCell } from 'components/DetailCell';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import {
  benefitPersonalAdvicePaymentMethodMessages,
  personalAdviceFeeDetailsMessages,
} from 'features/companies/company/benefits/form/messages';
import React from 'react';

interface PersonalAdviceDetailCellProps {
  benefit: PersonalAdvice<Benefit>;
}

export const PersonalAdviceDetailCell: React.FC<
  PersonalAdviceDetailCellProps
> = ({ benefit }) => {
  const { configuration } = benefit;

  if (!configuration?.data) {
    return <NoValue />;
  }

  const { paymentMethod, fee } = configuration.data;

  return (
    <DetailCell>
      <p>
        <FormattedMessage
          messages={benefitPersonalAdvicePaymentMethodMessages}
          select={paymentMethod}
        />
      </p>

      {!!fee && paymentMethod !== 'CAPITAL_FEE' && (
        <p>
          <FormattedMessage
            select={paymentMethod}
            messages={personalAdviceFeeDetailsMessages}
            values={{
              fee,
            }}
          />
        </p>
      )}
    </DetailCell>
  );
};
