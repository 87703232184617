import { CheckboxGroupField, useFormikContext } from '@frontend/formik';
import { ReadOnlyField } from '@frontend/ui';
import {
  MembershipRole,
  membershipRolesQuery,
  membershipRolesQueryVariables,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { EMPLOYEE_ROLE } from 'app/utils/constants';
import { usePersistedValue } from 'app/utils/use-persisted-value';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { MatchParams } from 'features/companies/company';
import { MEMBERSHIP_ROLES_QUERY } from 'features/companies/company/employees/employee/roles/graphql/queries';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import { FormValues } from '../..';

export const selectRoleValidationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    roles: Yup.array().min(
      1,
      intl.formatMessage(validationMessages.minOneOptionRequired),
    ),
  });

export interface SelectRoleFormValues {
  roles: MembershipRole[];
}

export const selectRoleInitialValues: SelectRoleFormValues = {
  roles: [],
};

export const SelectRoleHeader: React.FC = () => {
  const { values } = useFormikContext<FormValues>();
  return (
    <FormattedMessage
      {...companyMessages.selectRoles}
      values={{
        employee: values.userAccount?.label,
      }}
    />
  );
};

export const SelectRoleBody: React.FC = () => {
  const { formatMessage } = useIntl();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const { companyId } = useParams<MatchParams>();
  const userAccountId = values.userAccount?.value;

  const { data, loading, error } = useQuery<
    membershipRolesQuery,
    membershipRolesQueryVariables
  >(MEMBERSHIP_ROLES_QUERY, {
    errorPolicy: 'all',
    skip: !values.userAccount?.value,
    variables: {
      companyId: companyId ?? '',
      userAccountId: userAccountId ?? '',
    },
  });

  const currentRoles = data?.membership?.roles;

  /**
   * Present potential initial roles as read-only field.
   * If the roles data is updated but the user account remains the same,
   * this is due to a successful role update submission which should not
   * be presented while modal is fading out.
   */
  const initialRoles = usePersistedValue(currentRoles, userAccountId);

  useEffect(() => {
    if (currentRoles) {
      setFieldValue('roles', currentRoles);
    }
  }, [currentRoles, setFieldValue]);

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} inModal />}
      {!!initialRoles?.length && (
        <ReadOnlyField
          label={<FormattedMessage {...companyMessages.currentRoles} />}
          value={initialRoles
            .map(role =>
              formatMessage({
                select: role,
                messages: EMPLOYEE_ROLE,
              }),
            )
            .join(', ')}
        />
      )}
      <CheckboxGroupField
        disabled={loading}
        name="roles"
        options={Object.values(MembershipRole).map(role => ({
          label: formatMessage({
            select: role,
            messages: EMPLOYEE_ROLE,
          }),
          value: role,
        }))}
        value={values.roles}
        label={<FormattedMessage {...companyMessages.membershipRole} />}
      />
    </>
  );
};
