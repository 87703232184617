import { Section, SectionHeader, Td, Tr } from '@frontend/ui';
import {
  companyBookkeepingAccountsQuery,
  companyBookkeepingAccountsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { AccountPlanTable, AccountPlanTableRow } from '../components/Table';
import { COMPANY_BOOKKEEPING_ACCOUNTS_QUERY } from '../graphql/queries';
import { accountPlanMessages } from '../messages';
import {
  BOOKKEEPING_ACCOUNTS,
  LocationState,
} from '../update-bookkeeping-account';
import { updateQuery } from './utils/update-query';

export const BookkeepingAccounts: React.FC = () => {
  const { formatMessage } = useIntl();
  const { state, pathname } = useLocation<LocationState>();

  const [suspend, setSuspend] = useState(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { data, loading, previousData, refetch, fetchMore } = useQuery<
    companyBookkeepingAccountsQuery,
    companyBookkeepingAccountsQueryVariables
  >(COMPANY_BOOKKEEPING_ACCOUNTS_QUERY, {
    variables: {
      companyId,
      first: Number(perPage),
      includeDefault: true,
    },
    notifyOnNetworkStatusChange: true,
    suspend,
    onCompleted: () => setSuspend(false),
  });

  useEffect(() => {
    if (state?.refetch === BOOKKEEPING_ACCOUNTS) {
      refetch();
    }
  }, [state]);

  const bookkeepingAccountsConnection =
    data?.company?.bookkeepingAccounts ??
    previousData?.company?.bookkeepingAccounts;

  const bookkeepingAccounts = bookkeepingAccountsConnection?.edges.map(
    edge => edge.node,
  );

  return (
    <>
      {loading && <TopLoading />}
      {!!bookkeepingAccountsConnection && !!bookkeepingAccounts?.length && (
        <Section>
          <SectionHeader>
            <FormattedMessage {...accountPlanMessages.bookkeepingTypes} />
          </SectionHeader>
          <AccountPlanTable
            columns={[
              formatMessage(accountPlanMessages.transactionLabel),
              formatMessage(accountPlanMessages.accountLabel),
            ]}
            editLinks
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: bookkeepingAccountsConnection.pageInfo.endCursor,
                },
                updateQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: bookkeepingAccountsConnection.pageInfo.startCursor,
                  first: undefined,
                  last: Number(perPage),
                },
                updateQuery,
              });
            }}
            perPage={perPage}
            setPerPage={setPerPage}
            pageInfo={bookkeepingAccountsConnection.pageInfo}
          >
            {!bookkeepingAccounts.length ? (
              <Tr>
                <Td colSpan={2}>
                  <FormattedMessage
                    {...accountPlanMessages.noBookkeepingAccounts}
                  />
                </Td>
              </Tr>
            ) : (
              bookkeepingAccounts.map(
                ({ accountKey, customAccountName, customAccountNumber }) => (
                  <AccountPlanTableRow
                    key={accountKey}
                    editLink={{
                      pathname,
                      search: qs.stringify({
                        edit: accountKey,
                      }),
                    }}
                  >
                    <Td>{customAccountName ?? <NoValue />}</Td>
                    <Td>{customAccountNumber ?? <NoValue />}</Td>
                  </AccountPlanTableRow>
                ),
              )
            )}
          </AccountPlanTable>
        </Section>
      )}
    </>
  );
};
