import { Button, ButtonLayout, ModalFooter, TextField } from '@frontend/ui';
import { download } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { Props as AssistChipProps } from 'components/AssistChip';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import qs from 'query-string';
import React, { useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';

import { useSubmit } from './utils/use-submit';

export const DownloadPersonalAdviceInvoiceModal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [date, setDate] = useState<string>('');

  const { 'download-invoice': downloadInvoice } = qs.parse(location.search);
  const isOpen = !!downloadInvoice;

  const onRequestClose = () => {
    stripSearchParams(history, location, ['download-invoice']);
  };
  const { submit, loading, error } = useSubmit({ onRequestClose });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  };

  return (
    <Modal size="small" isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...advisorMessages.downloadInvoice} />
      </ModalHeader>
      <ModalBody>
        <TextField
          gridMargin
          type="month"
          persistentLabel
          dense
          required
          onChange={handleChange}
        />
        {error && <GraphQlError error={error} inModal />}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button
            text
            type="submit"
            loading={loading}
            disabled={!date}
            onClick={() => submit(`${date}-01`)}
          >
            <FormattedMessage {...commonMessages.confirm} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};

export const getDownloadInvoiceAction = (
  location: RouteComponentProps['location'],
): AssistChipProps => ({
  to: {
    ...location,
    search: qs.stringify({ 'download-invoice': true }),
  },
  text: <FormattedMessage {...advisorMessages.downloadInvoice} />,
  leadingIcon: download,
});
