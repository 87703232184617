import { Chip, ChipSet, Icon, TextField } from '@frontend/ui';
import { clear } from '@frontend/ui/icons';
import { useIntl } from 'components/formats';
import { internalNewsFormMessages } from 'features/companies/company/internal-news/messages';
import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 1.5rem;
`;

const StyledChipSet = styled(ChipSet)`
  margin-top: 0.75rem;
`;

interface Props {
  label: React.ReactNode;
  setValue: (value: string[]) => void;
  value: string[];
}

export const FieldTags: React.FC<Props> = ({ label, value, setValue }) => {
  const { formatMessage } = useIntl();
  const [tagInput, setTagInput] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [tags, setTags] = useState(value?.length ? value : []);

  const removeError = () => setError(undefined);
  const setErrorWithIntl = () =>
    setError(formatMessage(internalNewsFormMessages.categoryErrorMsg));

  const onPressEnter = event => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const _value = event.target.value.trim();
      if (_value.length) {
        if (tags.indexOf(_value) !== -1) {
          setErrorWithIntl();
        } else {
          const newTags = [...tags, _value];

          setTags(newTags);
          setTagInput('');
          setValue(newTags);
          removeError();
        }
      }
    }
  };

  const onInputChange = event => {
    setTagInput(event.target.value);
  };

  const onPressRemove = tagValue => () => {
    const newTags = tags.filter(e => e !== tagValue);

    setTags(newTags);
    setValue(newTags);
    removeError();
  };

  const chips = tags
    ? tags.map((tag, i) => (
        <Chip
          key={i}
          text={tag}
          trailingIcon={<Icon icon={clear} decorative />}
          onClick={onPressRemove(tag)}
        />
      ))
    : null;

  return (
    <Container>
      <TextField
        dense
        label={label}
        onKeyDown={onPressEnter}
        onChange={onInputChange}
        value={tagInput}
        validationMsg={Boolean(error)}
        persistent={Boolean(error)}
        errorText={error || undefined}
      />
      {!!chips?.length && <StyledChipSet>{chips}</StyledChipSet>}
    </Container>
  );
};
