import { ContentContainer, PageLede } from '@frontend/ui';
import {
  editEmployeeLayoutQuery,
  editEmployeeLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { employeeFormMessages } from 'app/messages/employees';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { EditEmployeeDetailsForm } from 'features/companies/company/employees/employee/details/edit';
import { Page } from 'features/page';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { EmployeeRouteMatchParams } from '..';
import { EDIT_EMPLOYEE_LAYOUT_QUERY } from './graphql/queries';

export const EditEmployeeRoute: React.FC = () => {
  const {
    url,
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { formatMessage } = useIntl();

  const { data, loading, error } = useQuery<
    editEmployeeLayoutQuery,
    editEmployeeLayoutQueryVariables
  >(EDIT_EMPLOYEE_LAYOUT_QUERY, {
    errorPolicy: 'all',
    variables: { userAccountId, companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.membership) {
    return (
      <EmptyStatePage
        error={error}
        parentLink={`/companies/${companyId}/employees`}
      />
    );
  }

  const name = `${data.membership.givenName} ${data.membership.lastName}`;

  return (
    <NavigationAnchor
      title={formatMessage(employeeFormMessages.editContactInformationTitle, {
        name,
      })}
      url={url}
    >
      <Page
        title={
          <FormattedMessage
            {...employeeFormMessages.editContactInformationTitle}
            values={{ name }}
          />
        }
        parentLink={`/companies/${companyId}/employees/${userAccountId}`}
      >
        <Suspense batch fallback={<TopLoading />}>
          {({ errors }) => (
            <ContentContainer>
              {errors && <GraphQlErrors errors={errors} />}
              <TextGrid>
                <PageLede>
                  <FormattedMessage
                    {...employeeFormMessages.editContactInformationDescription}
                  />
                </PageLede>
              </TextGrid>
              <EditEmployeeDetailsForm />
            </ContentContainer>
          )}
        </Suspense>
      </Page>
    </NavigationAnchor>
  );
};
