import gql from 'graphql-tag';

import { createNlpAgreementFragment } from './fragments';

export const CREATE_NLP_AGREEMENT_MUTATION = gql`
  mutation createNlpAgreementMutation(
    $input: CreateNlpAgreementMutationInput!
  ) {
    createNlpAgreement(input: $input) {
      clientMutationId
    }
  }
`;

export const CREATE_NLP_AGREEMENT_EDIT_COMPANY_MUTATION = gql`
  mutation createNlpAgreementEditCompanyMutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        ...createNlpAgreementFragment
      }
    }
  }
  ${createNlpAgreementFragment}
`;
