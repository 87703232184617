import { Section, SectionHeader } from '@frontend/ui';
import {
  membershipEventHistoryTableQuery,
  membershipEventHistoryTableQueryVariables,
} from 'app/apollo/graphql/types';
import { membershipEventHistoryMessages } from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { Table } from './components/Table';
import { MEMBERSHIP_EVENT_HISTORY_TABLE_QUERY } from './graphql/queries';
import { updatemembershipEventHistoryTableQuery } from './graphql/update-query';

const isEmpty = (data?: membershipEventHistoryTableQuery) =>
  !data?.membership?.eventHistory?.edges.length;

export const Events: React.FC = () => {
  const [suspend, setSuspend] = useState(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data, loading, fetchMore } = useQuery<
    membershipEventHistoryTableQuery,
    membershipEventHistoryTableQueryVariables
  >(MEMBERSHIP_EVENT_HISTORY_TABLE_QUERY, {
    errorPolicy: 'all',
    suspend,
    variables: {
      userAccountId,
      companyId,
      first: Number(perPage),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setSuspend(false),
    isEmpty,
  });

  if (!data?.membership?.eventHistory?.edges.length) {
    return null;
  }

  const eventHistory = data?.membership?.eventHistory?.edges.map(
    ({ node }) => node,
  );
  const pageInfo = data?.membership?.eventHistory?.pageInfo;

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...membershipEventHistoryMessages.eventHistory} />
      </SectionHeader>
      {!suspend && loading && <TopLoading />}
      {!!eventHistory?.length && pageInfo && (
        <Table
          perPage={perPage}
          setPerPage={setPerPage}
          pageInfo={pageInfo}
          onNextPage={() =>
            fetchMore({
              variables: {
                after: pageInfo.endCursor,
              },
              updateQuery: updatemembershipEventHistoryTableQuery,
            })
          }
          onPreviousPage={() =>
            fetchMore({
              variables: {
                before: pageInfo.startCursor,
                first: undefined,
                last: Number(perPage),
              },
              updateQuery: updatemembershipEventHistoryTableQuery,
            })
          }
          eventHistory={eventHistory}
        />
      )}
    </Section>
  );
};
