import {
  defaultTheme,
  DESKTOP_HORIZONTAL_PADDING,
  ElevationLevel,
  IconButton,
  MAIN_BORDER_WIDTH,
  media,
  MOBILE_HORIZONTAL_PADDING,
} from '@frontend/ui';
import { keyboardArrowLeft, keyboardArrowRight } from '@frontend/ui/icons';
import { a11yMessages } from 'app/messages/a11y';
import { useIsSmallScreen } from 'app/utils/use-is-small-screen';
import { useIntl } from 'components/formats';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce/lib';

const FOCUS_MARGIN = 0.375;

export const BACKWARDS_NAV_ID = 'backwards-nav';
export const FORWARDS_NAV_ID = 'forwards-nav';

interface Props {
  children?: React.ReactNode;
}

const ScrollableWrapper = styled.div`
  // To prevent the focus indicator from being cut off
  padding: ${FOCUS_MARGIN}rem;
  margin: calc(-1 * ${FOCUS_MARGIN}rem);
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  position: relative;
  align-items: center;
  margin-bottom: calc(-1 * ${FOCUS_MARGIN}rem);

  // Hide scrollbars
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const DESKTOP_MARGIN = `calc(${DESKTOP_HORIZONTAL_PADDING} + ${MAIN_BORDER_WIDTH})`;
const MOBILE_MARGIN = `calc(${MOBILE_HORIZONTAL_PADDING} + ${MAIN_BORDER_WIDTH})`;

const ScrollableContainer = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  transition: width 0.3s ease;

  ${media.lessThan('desktop')`
    margin: 0 calc(-1 * ${DESKTOP_MARGIN});
    ${ScrollableWrapper} {
      >:first-child {
        margin-left: ${DESKTOP_MARGIN};
      }
      // This pseudo-element is used instead of a right margin on the last child
      // since such margin is ignored in safari 
      &::after {
        content: '';
        display: block;
        flex: 0 0 ${DESKTOP_MARGIN};
      }
    }
  `}

  ${media.lessThan('tablet')`
    margin: 0 calc(-1 * ${MOBILE_MARGIN});
    ${ScrollableWrapper} {
      >:first-child {
        margin-left: ${MOBILE_MARGIN};
      }
      // This pseudo-element is used instead of a right margin on the last child
      // since such margin is ignored in safari   
      &::after {
        content: '';
        display: block;
        flex: 0 0 ${MOBILE_MARGIN};
      }
    }
  `}
`;

const CHIP_SCROLL_WIDTH = 300;

interface ScrollButtonContainerProps extends Props {
  elevation?: ElevationLevel;
  isLeft?: boolean;
  isSmallScreen?: boolean;
}

const scrollButtonContainerCommonCss = css`
  position: absolute;
  display: flex;
  flex-direction: row;
`;

const ScrollChipButtonContainer = styled.div<ScrollButtonContainerProps>`
  ${scrollButtonContainerCommonCss}
  justify-content: ${p => (p.isLeft ? 'flex-start' : ' flex-end')};
  width: ${p => (p.isSmallScreen ? '1rem' : '5.5rem')};
  ${p =>
    p.isLeft
      ? `left: calc(-1 * ${FOCUS_MARGIN}rem);`
      : `right: calc(-1 * ${FOCUS_MARGIN}rem);`}
  ${p => p.isSmallScreen && 'height: 3rem'};
  background: linear-gradient(
    to ${p => (p.isLeft ? 'left' : 'right')},
    rgba(255, 255, 255, 0) 0%,
    ${p => p.theme.surface} ${p => (p.isSmallScreen ? '100%' : '35%')},
    ${p => p.theme.surface} 100%
  );
`;

const ScrollButtonContainer: React.FC<ScrollButtonContainerProps> = ({
  children,
  ...props
}) => (
  <ScrollChipButtonContainer {...props} aria-hidden={props.isSmallScreen}>
    {children}
  </ScrollChipButtonContainer>
);

interface Overflow {
  active: boolean;
  end: boolean;
  start: boolean;
}

export const ScrollableSet: React.FC<Props> = ({ children }) => {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState<Overflow>({
    active: false,
    end: false,
    start: false,
  });
  const isSmallScreen = useIsSmallScreen(defaultTheme.breakpoints.desktop * 16);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleAction = () => {
    if (!ref.current) {
      return;
    }
    const { scrollLeft, scrollWidth, offsetWidth } = ref.current;
    const _scrollStart = scrollLeft <= 0;
    /* XXX: When the screen width (px) is odd, scrollLeft is off
    by a pixel for offsetWidth + scrollLeft = scrollWidth to add up.
    Therefore, we compensate with an extra pixel in the calcuation.
    */
    const _scrollEnd = scrollWidth - scrollLeft - 1 <= offsetWidth;
    const _overflowActive = offsetWidth < scrollWidth;

    if (
      _scrollStart !== overflow.start ||
      _scrollEnd !== overflow.end ||
      _overflowActive !== overflow.active
    ) {
      setOverflow({
        active: _overflowActive,
        end: _scrollEnd,
        start: _scrollStart,
      });
    }
  };

  const [_handleAction] = useDebouncedCallback(handleAction, 50);

  useEffect(() => {
    if (!ref?.current) {
      return undefined;
    }
    _handleAction();
    window.addEventListener('resize', _handleAction);
    return () => window.removeEventListener('resize', _handleAction);
  }, [ref]);

  useLayoutEffect(() => {
    if (children) {
      handleAction();
    }
  }, [children]);

  const handleScrollClick = (scrollDirection: number) =>
    ref.current?.scrollTo({
      left: ref.current.scrollLeft + CHIP_SCROLL_WIDTH * scrollDirection,
    });

  return (
    <ScrollableContainer ref={containerRef}>
      <ScrollableWrapper ref={ref} onScroll={handleAction}>
        {children}
      </ScrollableWrapper>
      {overflow.active && (
        <>
          {!overflow.start && (
            <ScrollButtonContainer isLeft isSmallScreen={isSmallScreen}>
              {!isSmallScreen && (
                <IconButton
                  icon={keyboardArrowLeft}
                  color="primary"
                  label={formatMessage(a11yMessages.backward)}
                  onClick={() => handleScrollClick(-1)}
                  id={BACKWARDS_NAV_ID}
                />
              )}
            </ScrollButtonContainer>
          )}
          {!overflow.end && (
            <ScrollButtonContainer isSmallScreen={isSmallScreen}>
              {!isSmallScreen && (
                <IconButton
                  icon={keyboardArrowRight}
                  color="primary"
                  label={formatMessage(a11yMessages.forward)}
                  onClick={() => handleScrollClick(1)}
                  id={FORWARDS_NAV_ID}
                />
              )}
            </ScrollButtonContainer>
          )}
        </>
      )}
    </ScrollableContainer>
  );
};
