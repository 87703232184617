import { ContentContainer } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { Overview } from 'features/companies/company/internal-news';
import { internalNewsMessages } from 'features/companies/company/internal-news/messages';
import { Page } from 'features/page';
import React from 'react';

export const OverviewRoute: React.FC = () => (
  <Page title={<FormattedMessage {...internalNewsMessages.news} />}>
    <ContentContainer>
      <Overview />
    </ContentContainer>
  </Page>
);
