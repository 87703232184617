import {
  CompanySize,
  ManagingCompany,
  TjpAgreementType,
} from 'app/apollo/graphql/types';

const NLP_AGREEMENTS_NO_EXTENDED_SALARY_LIMIT: Record<
  CompanySize,
  TjpAgreementType
> = {
  [CompanySize.LT_10]: TjpAgreementType._590005,
  [CompanySize.LT_50]: TjpAgreementType._590001,
  [CompanySize.GTE_50]: TjpAgreementType._590004,
  [CompanySize.GTE_250]: TjpAgreementType._590003,
  [CompanySize.GTE_1000]: TjpAgreementType._590000,
};

const NODE_AGREEMENTS_NO_EXTENDED_SALARY_LIMIT: Record<
  CompanySize,
  TjpAgreementType
> = {
  [CompanySize.LT_10]: TjpAgreementType._570005,
  [CompanySize.LT_50]: TjpAgreementType._570001,
  [CompanySize.GTE_50]: TjpAgreementType._570004,
  [CompanySize.GTE_250]: TjpAgreementType._570003,
  [CompanySize.GTE_1000]: TjpAgreementType._570000,
};

interface Args {
  companySize: CompanySize;
  extendedSalaryLimit: boolean;
  managedBy: ManagingCompany;
}

/**
 * Retrieves the relevant euro accident TJP agreement given the
 * company size and whether the company has an extended salary limit, and who manages the company.
 *
 * The extended salary limit is only relevant for companies with less than 10 employees.
 */
export const getAgreementForCompanySize = (args: Args) => {
  const { companySize, extendedSalaryLimit, managedBy } = args;

  if (managedBy === ManagingCompany.NLP) {
    return extendedSalaryLimit && companySize === CompanySize.LT_10
      ? TjpAgreementType._590002
      : NLP_AGREEMENTS_NO_EXTENDED_SALARY_LIMIT[companySize];
  }

  return extendedSalaryLimit && companySize === CompanySize.LT_10
    ? TjpAgreementType._570002
    : NODE_AGREEMENTS_NO_EXTENDED_SALARY_LIMIT[companySize];
};
