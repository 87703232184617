import { employeeFormMessages } from 'app/messages/employees';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Fcfw } from 'features/companies/company/employees/employee/risk/fcfw';
import { Insurances } from 'features/companies/company/employees/employee/risk/insurances';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const RiskRoute: React.FC = () => (
  <Suspense
    batch
    fallback={<TopLoading />}
    emptyState={
      <EmptyState
        title={
          <FormattedMessage {...employeeFormMessages.noInsuranceBenefits} />
        }
      >
        <FormattedMessage {...employeeFormMessages.noInsuranceBenefitsText} />
      </EmptyState>
    }
  >
    {({ errors }) => (
      <>
        {errors && <GraphQlErrors errors={errors} />}
        <Insurances />
        <Fcfw />
      </>
    )}
  </Suspense>
);
