import { EuroAccidentPrivatAccess } from '@frontend/benefit-types';
import { Formik } from '@frontend/formik';
import {
  companyEditBenefitQuery,
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  companyEditBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  FormValues,
  HealthcareForm,
  validationSchema,
} from '../../form/healthcare';
import { getBenefitPackageOptions } from '../../form/utils';
import { COMPANY_EDIT_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const EditHealthcare: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    companyEditBenefitQuery,
    companyEditBenefitQueryVariables
  >(COMPANY_EDIT_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, companyId: params.companyId },
  });

  const { submit, submissionError } = useSubmit();

  if (loading) {
    return <TopLoading />;
  }

  if (
    !data?.company?.agreements ||
    !data.company.benefitPackages ||
    !data.flexBenefit?.configuration?.data ||
    !data.flexBenefit?.entitlementRules
  ) {
    return <EmptyState error={error} />;
  }

  const benefit: EuroAccidentPrivatAccess<Benefit> = data.flexBenefit;

  const { agreements, benefitPackages, managedBy } = data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    'euro_accident_privataccess',
    benefitPackages,
    benefit.id,
  );

  const { configEffectiveDate, configuration, entitlementRules } = benefit;

  if (!configuration?.data || !entitlementRules) {
    return null;
  }

  const initialValues: FormValues = {
    benefitPackageIds: [...entitlementRules.benefitPackageIds],
    converting: !!configuration.data.convertingFrom,
    convertingFrom: configuration.data.convertingFrom ?? '',
    effectiveFrom: '',
    isActiveOnParentalLeave: configuration.data.isActiveOnParentalLeave,
    level: configuration.data.level,
    // waitingPeriod: configuration.data.waitingPeriod ? 'yes' : 'no',
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={values => submit(values, benefit)}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      <HealthcareForm
        agreements={agreements}
        benefitPackageOptions={benefitPackageOptions}
        isEdit
        latestChangesEffectiveDate={configEffectiveDate}
        managedBy={managedBy}
        submissionError={submissionError}
      />
    </Formik>
  );
};
