import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

const internalPostSummary = gql`
  fragment internalPostSummary on InternalPost {
    id
    title
    important
    publishedAt
  }
`;

export const INTERNAL_NEWS_OVERVIEW_QUERY = gql`
  query internalNewsOverviewQuery(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $companyId: ID!
    $search: String
  ) {
    company(id: $companyId) {
      id
      internalPosts(
        first: $first
        after: $after
        last: $last
        before: $before
        publishedUntil: null
        status: null
        orgUnitIds: null
        benefitPackageIds: null
        search: $search
      ) {
        edges {
          node {
            ...internalPostSummary
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${internalPostSummary}
  ${pageInfoDetails}
`;
