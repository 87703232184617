import { Id } from '@advinans/belt-id';
import { NaturalPersonIdentifier } from '@advinans/belt-natural-person-identifier';
import {
  parsePersonalIdentityNumber,
  parseRegistrationNumber,
} from '@advinans/belt-se-identity-number';
import { validationMessages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import startOfMonth from 'date-fns/startOfMonth';
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js/min';
import { MessageDescriptor } from 'react-intl';
import _isEmail from 'validator/lib/isEmail';

export enum ValidationErrorCode {
  'MANDATORY' = 'MANDATORY',
  'IS_VALID_NID' = 'IS_VALID_NID',
  'IS_VALID_FIRST_NID' = 'IS_VALID_FIRST_NID',
  'IS_BEFORE_START_DATE' = 'IS_BEFORE_START_DATE',
  'INVALID_EMPLOYMENT_RATE' = 'INVALID_EMPLOYMENT_RATE',
  'INVALID_DATE' = 'INVALID_DATE',
  'INVALID_MONTH' = 'INVALID_MONTH',
  'IS_VALID_ADDRESS' = 'IS_VALID_ADDRESS',
  'ACCOUNTING_ITEM_EFFECTIVE_DATE_ERROR' = 'ACCOUNTING_ITEM_EFFECTIVE_DATE_ERROR',
  'IS_VALID_EMAIL' = 'IS_VALID_EMAIL',
}

type Func = () => void;

const throws = (func: Func) => {
  try {
    func();
  } catch {
    return true;
  }
  return false;
};

/**
 * List of valid email domains.
 */
const VALID_EMAIL_DOMAINS = [
  '.uk',
  '.tech',
  '.se',
  '.ro',
  '.pl',
  '.pe',
  '.org',
  '.nu',
  '.net',
  '.me',
  '.life',
  '.law',
  '.io',
  '.ie',
  '.fr',
  '.football',
  '.fi',
  '.eu',
  '.es',
  '.energy',
  '.dev',
  '.de',
  '.cz',
  '.com',
  '.br',
  '.ac',
  '.ai',
  '.dk',
  '.no',
];

/**
 * Checks is belongs to a, by us, valid email domain.
 */
const isValidEmailDomain = (value?: string): boolean =>
  !!value && VALID_EMAIL_DOMAINS.some(domain => value.endsWith(domain));

/**
 * Checks if provided email is valid and ensures local part of
 * email only consists of English characters.
 *
 * @param value - email value
 * @returns {boolean} - Returns true if email is valid.
 */
export const isEmail = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  return (
    _isEmail(value, { allow_utf8_local_part: false }) &&
    isValidEmailDomain(value)
  );
};

const isValidationErrorCode = (
  errorCode?: string,
): errorCode is ValidationErrorCode =>
  errorCode != null && Object.keys(ValidationErrorCode).includes(errorCode);

const VALIDATION_MSG: Record<ValidationErrorCode, MessageDescriptor> = {
  MANDATORY: validationMessages.mandatoryField,
  IS_VALID_NID: validationMessages.isValidNid,
  IS_VALID_FIRST_NID: validationMessages.isValidFirstNid,
  IS_BEFORE_START_DATE: validationMessages.isBeforeStartDate,
  INVALID_EMPLOYMENT_RATE: validationMessages.invalidEmploymentRate,
  INVALID_DATE: validationMessages.invalidDate,
  INVALID_MONTH: validationMessages.invalidMonth,
  IS_VALID_ADDRESS: validationMessages.isValidAddress,
  ACCOUNTING_ITEM_EFFECTIVE_DATE_ERROR:
    validationMessages.accountingItemEffectiveDateError,
  IS_VALID_EMAIL: validationMessages.isValidEmail,
};

// Write validators so that you run the validation if you have a value, and
// then if invalid return a ValidationErrorCode. Otherwise you're introducing a
// "required" validation to your field, as well.

export const getValidationMessage = (
  intl: IntlShape,
  error?: string,
): string | undefined =>
  isValidationErrorCode(error)
    ? intl.formatMessage(VALIDATION_MSG[error])
    : undefined;

export const validateNaturalPersonIdentifier = (value: string): boolean => {
  try {
    NaturalPersonIdentifier.FromString(value);
  } catch {
    return false;
  }

  return true;
};

const DEFAULT_REGION = 'SE';

// Parse the raw value as a phone number object, or fail with null
function parsePhoneRaw(value: string): PhoneNumber | null {
  if (!value) {
    return null;
  }
  const parsedNumber = parsePhoneNumberFromString(value, DEFAULT_REGION);
  return parsedNumber || null;
}

export const isValidPhoneNumber = (value: string): boolean => {
  const noBlankSpaces = value.split(' ').join('');

  // Phone numbers should not have area codes with more than four digits
  if (noBlankSpaces.includes('-') && noBlankSpaces.split('-')[0].length > 4) {
    return false;
  }

  const parsed = parsePhoneRaw(noBlankSpaces);

  // This length checks seems extremely arbitrary, but I
  // dare not remove it. It's probably there for a reason..
  if (parsed === null || noBlankSpaces.length > 13) {
    return false;
  }

  return parsed.isValid();
};

export const isValidIdentityNumber = (value: string): boolean =>
  !throws(() => parseRegistrationNumber(value)) ||
  !throws(() => parsePersonalIdentityNumber(value));

export const isValidRegistrationNumber = (value: string): boolean => {
  try {
    parseRegistrationNumber(value);
  } catch {
    return false;
  }
  return true;
};

export const isValidUuid = (value: string): boolean => Id.isValid(value);

export const isValidDate = (value: unknown): boolean => {
  if (value === undefined || value === null || value === '') {
    return false;
  }

  if (typeof value === 'string' && isValid(new Date(value))) {
    return true;
  }

  return false;
};

export const isValidMonth = (value: unknown): boolean => {
  if (value === undefined || value === null || value === '') {
    return true;
  }

  if (typeof value === 'string') {
    // Matches format YYYY-MM
    const dateFormatRegEx = /^\d{4}-\d{2}$/;
    if (isValid(new Date(value)) && value.match(dateFormatRegEx) != null) {
      return true;
    }
  }

  return false;
};

// eslint-disable-next-line
export const monthToDate = (value: unknown): any => {
  if (typeof value === 'string') {
    // Matches format YYYY-MM
    const dateFormatRegEx = /^\d{4}-\d{2}$/;
    if (isValid(new Date(value)) && value.match(dateFormatRegEx) != null) {
      return format(startOfMonth(new Date(value)), 'yyyy-MM-dd');
    }
  }

  return value;
};

export const normalizeWhiteSpace = (
  val?: string | null,
): string | null | undefined => {
  if (val) {
    const trimed = val.trim();
    return trimed.length === 0 ? null : trimed;
  }
  return val;
};

export const isValidPersonalIdentityNumber = (
  value?: string | null,
): boolean => {
  if (!value) {
    return false;
  }
  try {
    parsePersonalIdentityNumber(value);
    return true;
  } catch {
    return false;
  }
};
