import { invoiceLinesQuery } from 'app/apollo/graphql/types';

export const updateInvoiceLinesQuery = (
  previousResult: invoiceLinesQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: invoiceLinesQuery;
  },
): invoiceLinesQuery => {
  if (
    !previousResult?.invoiceLineSearch?.invoiceLines ||
    !fetchMoreResult?.invoiceLineSearch?.invoiceLines
  ) {
    return previousResult;
  }

  const { edges, pageInfo, totalCount } =
    fetchMoreResult.invoiceLineSearch.invoiceLines;

  return {
    invoiceLineSearch: {
      ...previousResult.invoiceLineSearch,
      invoiceLines: {
        edges: [...edges],
        pageInfo,
        totalCount,
      },
    },
  };
};
