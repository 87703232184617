import { PaymentMethod } from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { IntlShape } from 'components/formats';
import {
  isEmail,
  isValidPersonalIdentityNumber,
  isValidPhoneNumber,
  isValidRegistrationNumber,
  validateNaturalPersonIdentifier,
} from 'validations';
import * as Yup from 'yup';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    clearingCode: Yup.object()
      .nullable()
      .when('createNordea', {
        is: true,
        then: schema =>
          schema.required(
            intl.formatMessage(validationMessages.mandatoryField),
          ),
      }),
    salesRep: Yup.string().when('createNordea', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    isOwner: Yup.boolean().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    startDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    registrationNumber: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid registration number',
        intl.formatMessage(validationMessages.invalidRegistrationNumber),
        value =>
          !value ||
          isValidRegistrationNumber(value) ||
          isValidPersonalIdentityNumber(value),
      ),
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    language: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    email: Yup.string().test(
      'valid email',
      intl.formatMessage(validationMessages.isValidEmail),
      value => (value ? isEmail(value) : true),
    ),
    phone: Yup.string().test(
      'valid phone',
      intl.formatMessage(validationMessages.isValidPhone),
      value => (value ? isValidPhoneNumber(value) : true),
    ),
    pid: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid first nid',
        intl.formatMessage(validationMessages.isValidFirstNid),
        value => !value || validateNaturalPersonIdentifier(value),
      ),
    nodeAdminPid: Yup.string().test(
      'valid first nid',
      intl.formatMessage(validationMessages.isValidFirstNid),
      value => !value || validateNaturalPersonIdentifier(value),
    ),
    accountNumber: Yup.string().when('paymentMethod', {
      is: (paymentMethod: PaymentMethod | '') =>
        !!paymentMethod && paymentMethod !== PaymentMethod.INVOICE,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
  });
