import { Icon, Td, Tr } from '@frontend/ui';
import { _delete, edit } from '@frontend/ui/icons';
import { select } from '@frontend/utils';
import { InvoiceSupplier } from 'app/apollo/graphql/types';
import { Reporter } from 'components/ErrorBoundary';
import { FormattedMessage } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import { NoValue } from 'components/NoValue';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { billingMessages } from '../../../messages';
import { openEditReferenceModal } from '../../modal';
import { SupplierReference } from '..';

interface Props {
  onDelete: () => void;
  supplier: SupplierReference;
  reference?: string | null;
}

const INVOICE_SUPPLIERS: Record<InvoiceSupplier, string> = {
  ACTIWAY: 'Actiway AB',
  FUTUR: 'Danica Pension Försäkringsaktiebolag (publ)',
  EDENRED: 'Edenred',
  EURO_ACCIDENT: 'Euro Accident',
  LF: 'Länsförsäkringar Fondliv Försäkrings AB',
  NLP: 'Nordea Livförsäkring Sverige AB (publ)',
  ADVINANS: 'Nordea Node AB',
  NORDNET: 'Nordnet Pensionsförsäkring AB',
  SL: 'SL',
};

export const ContractReferenceRow: React.FC<Props> = ({
  reference,
  onDelete,
  supplier,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <Tr
      inlineMenu={
        <KebabMenu fixed>
          <MenuItem
            title={<FormattedMessage {...billingMessages.editReference} />}
            icon={<Icon icon={edit} decorative />}
            onClick={() =>
              push({
                pathname,
                search: qs.stringify(openEditReferenceModal(supplier)),
              })
            }
          />
          <MenuItem
            title={<FormattedMessage {...billingMessages.deleteReference} />}
            icon={<Icon icon={_delete} decorative />}
            onClick={onDelete}
          />
        </KebabMenu>
      }
    >
      <Td>
        {select({
          params: {
            key: supplier,
            record: INVOICE_SUPPLIERS,
            user: 'backstage',
          },
          report: Reporter.report,
          shouldReportError: window.env.ERROR_REPORTING_ACTIVE === 'true',
        }) ?? supplier}
      </Td>
      <Td>{reference || <NoValue />}</Td>
    </Tr>
  );
};
