import { LegalEntityType, PaymentMethod } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const companyMessages = defineMessages({
  companyLanguage: {
    id: 'companies.companyLanguage',
    defaultMessage: 'Koncernspråk',
  },
  registrationNumber: {
    id: 'companies.registrationNumber',
    defaultMessage: 'Person-/Organisationsnummer',
  },
  startDate: {
    id: 'companies.startDate',
    defaultMessage: 'Startdatum/Första inloggningsdatum',
  },
  firstName: { id: 'companies.firstName', defaultMessage: 'Förnamn' },
  lastName: { id: 'companies.lastName', defaultMessage: 'Efternamn' },
  pid: { id: 'companies.pid', defaultMessage: 'Personnummer' },
  email: { id: 'companies.email', defaultMessage: 'E-post' },
  phone: { id: 'companies.phone', defaultMessage: 'Telefonnummer' },
  isOwner: {
    id: 'companies.isOwner',
    defaultMessage: 'Äger minst en tredjedel av företagets aktier',
  },
  created: {
    id: 'companies.created',
    defaultMessage: 'Företag har skapats',
  },
  customerInformation: {
    id: 'companies.customerInformation',
    defaultMessage: 'Kunduppgifter',
  },
  addCompany: {
    id: 'companies.addCompany',
    defaultMessage: 'Lägg till företag',
  },
  nodeAdmin: {
    id: 'companies.nodeAdmin',
    defaultMessage: 'Administratör Nordea Node',
  },
  nordeaSalesRep: {
    id: 'companies.nordeaSalesRep',
    defaultMessage: 'Ansvarig säljare (NID)',
  },
  discountValue: {
    id: 'companies.discountValue',
    defaultMessage: 'Rabatt värde',
  },
  discountPercent: {
    id: 'companies.discountPercent',
    defaultMessage: 'Rabatt procent',
  },
  discountType: {
    id: 'companies.discountType',
    defaultMessage: 'Administrativ rabattyp',
  },
  administrator: {
    id: 'companies.administrator',
    defaultMessage: 'Huvudadministratör',
  },
  addAdministrativeDiscount: {
    id: 'companies.addAdministrativeDiscount',
    defaultMessage: 'Lägg till administrativ rabatt',
  },
  clearingSalesOffice: {
    id: 'companies.clearingSalesOffice',
    defaultMessage: 'Clearing ansvarigt kontor',
  },
  accountClosureMonth: {
    id: 'companies.accountClosureMonth',
    defaultMessage: 'Bokslutsmånad',
  },
  displayName: {
    id: 'companies.displayName',
    defaultMessage: 'Synligt namn',
  },
  companyAddress: {
    id: 'companies.companyAddress',
    defaultMessage: 'Företagsadress',
  },
  companyName: {
    id: 'companies.companyName',
    defaultMessage: 'Företagets namn',
  },
  salaryReviewMonth: {
    id: 'companies.salaryReviewMonth',
    defaultMessage: 'Lönerevisionsmånad',
  },
  legalEntityType: {
    id: 'companies.legalEntityType',
    defaultMessage: 'Bolagsform',
  },
  hasCompanyGroup: {
    id: 'companies.hasCompanyGroup',
    defaultMessage: 'Del i koncern',
  },
  kyc: {
    id: 'companies.kyc',
    defaultMessage: 'Känn din kund (KDK)',
  },
  salesRep: {
    id: 'companies.salesRep',
    defaultMessage: 'Ansvarig säljare',
  },
  about: {
    id: 'companies.about',
    defaultMessage: 'Uppgifter om organisationen',
  },
  contact: {
    id: 'companies.contact',
    defaultMessage: 'Kontaktuppgifter',
  },
  other: {
    id: 'companies.other',
    defaultMessage: 'Övriga uppgifter',
  },
  editLogo: {
    id: 'companies.editLogo',
    defaultMessage: 'Ändra logotyp',
  },
  paymentMethod: {
    id: 'companies.paymentMethod',
    defaultMessage: 'Betalningsmetod',
  },
});

export const paymentMethodMessages = defineMessages<PaymentMethod>({
  AUTOGIRO: {
    id: 'paymentMethodMessages.AUTOGIRO',
    defaultMessage: 'Autogiro',
  },
  HAMT_ACCOUNT: {
    id: 'paymentMethodMessages.HAMT_ACCOUNT',
    defaultMessage: 'Hämt, konto',
  },
  HAMT_PLUSGIRO: {
    id: 'paymentMethodMessages.HAMT_PLUSGIRO',
    defaultMessage: 'Hämt, plusgiro',
  },
  INVOICE: {
    id: 'paymentMethodMessages.INVOICE',
    defaultMessage: 'Faktura',
  },
});

export const accountNumberMessages = defineMessages<
  Exclude<PaymentMethod, 'INVOICE'>
>({
  AUTOGIRO: {
    id: 'accountNumberMessages.AUTOGIRO',
    defaultMessage: 'Kontonummer',
  },
  HAMT_ACCOUNT: {
    id: 'accountNumberMessages.HAMT_ACCOUNT',
    defaultMessage: 'Kontonummer',
  },
  HAMT_PLUSGIRO: {
    id: 'accountNumberMessages.HAMT_PLUSGIRO',
    defaultMessage: 'Plusgiro',
  },
});

export const companyLegalEntityTypeMessages = defineMessages<LegalEntityType>({
  BRANCH: {
    id: 'smeCompanyLegalEntityTypeMessages.BRANCH',
    defaultMessage: 'Filial',
  },
  ECONOMIC_ASSOCIATION: {
    id: 'smeCompanyLegalEntityTypeMessages.ECONOMIC_ASSOCIATION',
    defaultMessage: 'Ekonomisk förening',
  },
  FOUNDATION: {
    id: 'smeCompanyLegalEntityTypeMessages.FOUNDATION',
    defaultMessage: 'Stiftelse',
  },
  LIMITED_COMPANY: {
    id: 'smeCompanyLegalEntityTypeMessages.LIMITED_COMPANY',
    defaultMessage: 'Aktiebolag',
  },
  LIMITED_PARTNERSHIP: {
    id: 'smeCompanyLegalEntityTypeMessages.LIMITED_PARTNERSHIP',
    defaultMessage: 'Kommanditbolag',
  },
  NONPROFIT_ASSOCIATION: {
    id: 'smeCompanyLegalEntityTypeMessages.NONPROFIT_ASSOCIATION',
    defaultMessage: 'Ideell förening',
  },
  SOLE_TRADER: {
    id: 'smeCompanyLegalEntityTypeMessages.SOLE_TRADER',
    defaultMessage: 'Enskild näringsidkare',
  },
  TRADING_PARTNERSHIP: {
    id: 'smeCompanyLegalEntityTypeMessages.TRADING_PARTNERSHIP',
    defaultMessage: 'Handelsbolag',
  },
});
