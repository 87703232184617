import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  welcome1: {
    id: 'index.welcome1',
    defaultMessage:
      'Om du administrerar sanningen behöver du aldrig komma ihåg någonting',
  },
  welcome2: {
    id: 'index.welcome2',
    defaultMessage:
      'De vackraste sakerna på jorden kan inte ses eller höras, de måste administreras',
  },
  welcome3: {
    id: 'index.welcome3',
    defaultMessage: 'En dag utan administration är en dag förslösad',
  },
  welcome4: {
    id: 'index.welcome4',
    defaultMessage: 'Jag administrerar, alltså är jag',
  },
  welcome5: {
    id: 'index.welcome5',
    defaultMessage:
      'Den bästa administrationen är den administration du gör av dig själv',
  },
  welcome6: {
    id: 'index.welcome6',
    defaultMessage:
      'Lev som om du skulle dö i morgon. Administrera som om du skulle leva för evigt',
  },
  welcome7: {
    id: 'index.welcome7',
    defaultMessage:
      'En administratör administrerar aldrig i samma system två gånger, ty systemet är inte detsamma och inte heller administratören',
  },
  welcome8: {
    id: 'index.welcome8',
    defaultMessage:
      'Jag kan inte administrera allt, men jag kan administrera något',
  },
  welcome9: {
    id: 'index.welcome9',
    defaultMessage: 'Var de administrativa operationer du vill se i systemet',
  },
  welcome10: {
    id: 'index.welcome10',
    defaultMessage:
      'Fråga inte vad ditt administrativa gränssnitt kan göra för dig, fråga vad du kan göra för ditt administrativa gränssnitt',
  },
  welcome11: {
    id: 'index.welcome11',
    defaultMessage: 'Den administrerar fortast, som administrerar ensam',
  },
  welcome12: {
    id: 'index.welcome12',
    defaultMessage: 'Vänlighet och administration får timmarna att verka korta',
  },
  welcome13: {
    id: 'index.welcome13',
    defaultMessage:
      'Den som aldrig gör misstag administrerar inte tillräckligt',
  },
  welcome14: {
    id: 'index.welcome14',
    defaultMessage: 'Att administrera är inte allt, det är det enda',
  },
  welcome15: {
    id: 'index.welcome15',
    defaultMessage:
      'Administrera först det som är nödvändigt; administrera sedan det som är möjligt; och plötsligt administrerar du det omöjliga',
  },
  welcome16: {
    id: 'index.welcome16',
    defaultMessage:
      'Den bästa förberedelsen för morgondagen är att administrera ditt bästa idag',
  },
  welcome17: {
    id: 'index.welcome17',
    defaultMessage:
      'Två vägar skiljde sig åt i en skog, jag valde att administrera, och det har gjort hela skillnaden',
  },
  welcome18: {
    id: 'index.welcome18',
    defaultMessage:
      'En hjälte är någon som administrerar något större än sig själv',
  },
  welcome19: {
    id: 'index.welcome19',
    defaultMessage:
      'Administration är det enda sinnet aldrig tröttar ut, aldrig fruktar och aldrig ångrar',
  },
  welcome20: {
    id: 'index.welcome20',
    defaultMessage:
      'Fyll inte livet med dagar, fyll dagarna med administration',
  },
  welcome21: {
    id: 'index.welcome21',
    defaultMessage:
      'Dansa som om ingen tittar, älska som om du aldrig kan bli sårad, sjung som om ingen lyssnar, och administrera som det vore himmelriket på jorden',
  },
  welcome22: {
    id: 'index.welcome22',
    defaultMessage:
      'Den som inte tar sig tid till administration kommer förr eller senare få ta sig tid till ett sjukt system',
  },
  welcome23: {
    id: 'index.welcome23',
    defaultMessage:
      'Att administrera fritt är stort, men att administrera rätt är större',
  },
  welcome24: {
    id: 'index.welcome24',
    defaultMessage: 'Jag kom, jag såg, jag administrerade',
  },
  welcome25: {
    id: 'index.welcome25',
    defaultMessage:
      'Den mätta dagen är aldrig störst, den bästa dagen är en dag av administration',
  },
  welcome26: {
    id: 'index.welcome26',
    defaultMessage:
      'Det finns ingenting som inte har hänt, men mycket som inte har administrerats',
  },
  welcome27: {
    id: 'index.welcome27',
    defaultMessage:
      'Dåren må tro att han är vis, men administratören vet att han är en dåre',
  },
  welcome28: {
    id: 'index.welcome28',
    defaultMessage: 'Väl administrerat är bättre än väl påtalat',
  },
  welcome29: {
    id: 'index.welcome29',
    defaultMessage:
      'Den enda omöjliga administrationen är den du aldrig ger dig på',
  },
  welcome30: {
    id: 'index.welcome30',
    defaultMessage:
      'Du må endast administrera en gång, men om väl administrerat är en gång tillräckligt',
  },
  welcome31: {
    id: 'index.welcome31',
    defaultMessage: 'Livet är antingen djärv administration, eller ingenting',
  },
  welcome32: {
    id: 'index.welcome32',
    defaultMessage:
      'Framgång är inte avgörande, misslyckande är inte ödesdigert, det är modet att våga administrera som gör skillnaden',
  },
  welcome33: {
    id: 'index.welcome33',
    defaultMessage:
      'Du vet att du är administratör när du inte längre sover, verkligheten har blivit bättre än dina drömmar',
  },
  welcome34: {
    id: 'index.welcome34',
    defaultMessage:
      'Framtiden tillhör dem som tror på skönheten i sin administration',
  },
  welcome35: {
    id: 'index.welcome35',
    defaultMessage:
      'Administratören administrerar inte systemet för att han måste. Han administrerar systemet för att han njuter av det, och han njuter av det för att det är vackert',
  },
  welcome36: {
    id: 'index.welcome36',
    defaultMessage:
      'Det är under våra mörkaste ögonblick vi måste administrera för att se ljuset',
  },
  welcome37: {
    id: 'index.welcome37',
    defaultMessage:
      'Du kan inte alltid administrera stora saker, men du kan alltid administrera små saker med stor kärlek',
  },
  welcome38: {
    id: 'index.welcome38',
    defaultMessage:
      'Endast administration gjord för andra är en administration som varar',
  },
  welcome39: {
    id: 'index.welcome39',
    defaultMessage:
      'Administratören är den som inte bara administrerar det som är, utan det som kan vara',
  },
  welcome40: {
    id: 'index.welcome40',
    defaultMessage:
      'Administration är inte en destination, det är en resa. Det är inte en plikt, det är en passion',
  },
  welcome41: {
    id: 'index.welcome41',
    defaultMessage:
      'Den som administrerar för mycket, har ingen tid att administrera något',
  },
  welcome42: {
    id: 'index.welcome42',
    defaultMessage:
      'Det är inte vad du administrerar, utan hur du administrerar det',
  },
  welcome43: {
    id: 'index.welcome43',
    defaultMessage:
      'Administration är inte att göra en sak stor, utan att göra en stor sak',
  },
  welcome44: {
    id: 'index.welcome44',
    defaultMessage:
      'Den som administrerar av kärlek, administrerar utan att administrera',
  },
  welcome45: {
    id: 'index.welcome45',
    defaultMessage:
      'Det är inte vad du administrerar, utan vad du administrerar för',
  },
  welcome46: {
    id: 'index.welcome46',
    defaultMessage:
      'Administration är en livslång läxa i ödmjukhet, tålamod och kärlek',
  },
  welcome47: {
    id: 'index.welcome47',
    defaultMessage:
      'När du inser att du vill tillbringa resten av ditt liv med administration, vill du att resten av ditt liv ska börja så snart som möjligt',
  },
  welcome48: {
    id: 'index.welcome48',
    defaultMessage:
      'Det spelar ingen roll hur långsamt du administrerar så länge du inte slutar',
  },
  welcome49: {
    id: 'index.welcome49',
    defaultMessage:
      'Administration upprepar sig självt, först som en tragedi, sedan som en fars',
  },
  welcome50: {
    id: 'index.welcome50',
    defaultMessage:
      'Administrera som om din administration gör skillnad, för det gör den',
  },
  welcome51: {
    id: 'index.welcome51',
    defaultMessage:
      'Lycka finns inte i ägodelar, inte i guld, lycka finns i administration',
  },
  welcome52: {
    id: 'index.welcome52',
    defaultMessage:
      'Den som kan administrera har hopp, och den som har hopp har allt',
  },
  welcome53: {
    id: 'index.welcome53',
    defaultMessage:
      'All administration har hemliga destinationer som administratören inte känner till',
  },
  welcome54: {
    id: 'index.welcome54',
    defaultMessage:
      'Den största ära i administration ligger inte i att aldrig misslyckas, utan i att resa sig varje gång man faller',
  },
  welcome55: {
    id: 'index.welcome55',
    defaultMessage:
      'Framgång i administration är att gå från misslyckande till misslyckande utan att förlora hoppet',
  },
  welcome56: {
    id: 'index.welcome56',
    defaultMessage: 'Döden är inte slutet, administration är slutet',
  },
  welcome57: {
    id: 'index.welcome57',
    defaultMessage: 'Sofistikerad administration är enkel administration',
  },
  welcome58: {
    id: 'index.welcome58',
    defaultMessage: 'En administratör bygger broar, inte väggar',
  },
  welcome59: {
    id: 'index.welcome59',
    defaultMessage:
      'En god administratör lyssnar till råd, en stor administratör lyssnar till sitt hjärta',
  },
  welcome60: {
    id: 'index.welcome60',
    defaultMessage:
      'Administrera inte för ett perfekt system imorgon, administrera för ett bättre system idag',
  },
  welcome61: {
    id: 'index.welcome61',
    defaultMessage: 'Administrera systemet du har, inte systemet du önskar',
  },
  welcome62: {
    id: 'index.welcome62',
    defaultMessage:
      'Det är inte den starkaste administratören som överlever, inte heller den mest intelligenta, utan den som är mest anpassningsbar till förändring',
  },
  welcome63: {
    id: 'index.welcome63',
    defaultMessage:
      'Administratörens största åstadkommande är inte vad administratören själv gör, utan vad andra åstadkommer i ett väl administrerat system',
  },
  welcome64: {
    id: 'index.welcome64',
    defaultMessage:
      'Den som administrerar för att vinna, vinner ibland. Den som administrerar för att lära, vinner alltid',
  },
  welcome65: {
    id: 'index.welcome65',
    defaultMessage:
      'En administratörs hand är en osynlig fjärilsvinge vars slag kan förändra världen',
  },
  welcome66: {
    id: 'index.welcome66',
    defaultMessage:
      'Administration är inte genomförandet av en uppgift, utan faciliterandet av ett system där kunskap och kreativitet flödar fritt',
  },
  welcome67: {
    id: 'index.welcome67',
    defaultMessage:
      'En bra administratör skapar en systemmiljö där framgång är ofrånkomlig',
  },
  welcome68: {
    id: 'index.welcome68',
    defaultMessage:
      'Den som administrerar för att bevara status quo, administrerar för att förlora',
  },
  welcome69: {
    id: 'index.welcome69',
    defaultMessage:
      'En administratör likt en trädgårdsmästare vårdar möjligheternas frön, med vetskapen att det är i jorden av administration som framtidens frukter växer',
  },
  welcome70: {
    id: 'index.welcome70',
    defaultMessage:
      'En administratör är inte en person som administrerar, utan en person som inte kan låta bli att administrera',
  },
  welcome71: {
    id: 'index.welcome71',
    defaultMessage:
      'Oavsiktlig administration är en olycka, avsiktlig administration är en konst',
  },
  welcome72: {
    id: 'index.welcome72',
    defaultMessage:
      'Att förlora sig själv i administration är att finna sig själv',
  },
  welcome73: {
    id: 'index.welcome73',
    defaultMessage:
      'Idag är första dagen i resten av din administrativa karriär',
  },
  welcome74: {
    id: 'index.welcome74',
    defaultMessage:
      'En administrativ operation är ett litet steg för administratören, men ett stort steg på vägen för systemet',
  },
  welcome75: {
    id: 'index.welcome75',
    defaultMessage:
      'Det finns ingen omöjlig administration, för administration i sig själv är möjlig',
  },
  welcome76: {
    id: 'index.welcome76',
    defaultMessage:
      'Man kan ta administratören ur systemet, men man kan aldrig ta administratören ur administratören',
  },
  welcome77: {
    id: 'index.welcome77',
    defaultMessage:
      'Somliga söker ett vackert system, administratören administrerar för det',
  },
  welcome78: {
    id: 'index.welcome78',
    defaultMessage:
      'Det är aldrig för sent att bli den administratör du kunde varit',
  },
  welcome79: {
    id: 'index.welcome79',
    defaultMessage:
      'Det administratören får ut av att administrera är mindre viktigt än vad administratören blir av att administrera',
  },
  welcome80: {
    id: 'index.welcome80',
    defaultMessage:
      'Administratören måste vara villig att ge upp det system som är för att nå det system som väntar',
  },
  welcome81: {
    id: 'index.welcome81',
    defaultMessage:
      'Det finns något gott i den här världen, och det är värt att administrera för.',
  },
  welcome82: {
    id: 'index.welcome82',
    defaultMessage:
      'Mod är inte frånvaron av rädsla, utan viljan att administrera trots rädslan.',
  },
  welcome83: {
    id: 'index.welcome83',
    defaultMessage:
      'Det finns en viss levande kraft i världen, och den är hos oss nu - om vi bara fortsätter administrera.',
  },
  welcome84: {
    id: 'index.welcome84',
    defaultMessage: 'Vi kan inte ändra det som varit. Men vi kan administrera.',
  },
  welcome85: {
    id: 'index.welcome85',
    defaultMessage:
      'En administratör är för ett system vad ett bröd är för den hungrige.',
  },
  welcome86: {
    id: 'index.welcome86',
    defaultMessage:
      'En administratör är för ett system vad en varm soppa är för en kall höstdag.',
  },
  welcome87: {
    id: 'index.welcome87',
    defaultMessage:
      'En administratör är för ett system vad ett kallt glas vatten är för den varmaste sommardagen.',
  },
  goodbye: {
    id: 'index.goodbye',
    defaultMessage:
      'Det ser inte ut som att du är administratör. Kontakta din administratörsadministratör om du vill bli administratör.',
  },
  backstage: {
    id: 'index.backstage',
    defaultMessage: 'Välkommen till backstage',
  },
  gold: {
    id: 'index.gold',
    defaultMessage: 'Morgonstund har guld i mund, {firstName}',
  },
  morning: {
    id: 'index.morning',
    defaultMessage: 'God morgon {firstName}',
  },
  earlyDay: {
    id: 'index.earlyDay',
    defaultMessage: 'God förmiddag {firstName}',
  },
  day: { id: 'index.day', defaultMessage: 'God dag {firstName}' },
  lateDay: {
    id: 'index.lateDay',
    defaultMessage: 'God eftermiddag {firstName}',
  },
  evening: { id: 'index.evening', defaultMessage: 'God kväll {firstName}' },
  night: { id: 'index.night', defaultMessage: 'God afton {firstName}' },
  midnight: {
    id: 'index.midnight',
    defaultMessage:
      'Alla sova i enslig gård djupt under midnattstimma, {firstName}',
  },
});
