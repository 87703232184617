import { ApolloError, useMutation } from '@apollo/client';
import { toNumber } from '@frontend/utils';
import {
  AdministratorInput2,
  CreateCompany2Input,
  createCompany2Mutation,
  createCompany2MutationVariables,
  MembershipRole,
  PaymentMethod,
} from 'app/apollo/graphql/types';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory } from 'react-router';

import { companyMessages } from '../../messages';
import { FormValues } from '..';
import { CREATE_COMPANY_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createCompany, { error: companyError }] = useMutation<
    createCompany2Mutation,
    createCompany2MutationVariables
  >(CREATE_COMPANY_MUTATION);

  const submit = async (formValues: FormValues) => {
    try {
      const {
        accountClosureMonth,
        accountNumber,
        clearingCode,
        email,
        firstName,
        isOwner,
        language,
        lastName,
        nodeAdminPid,
        paymentMethod,
        phone,
        pid,
        registrationNumber,
        salaryReviewMonth,
        salesRep,
        startDate,
      } = formValues;

      const admins: AdministratorInput2[] = [
        {
          email,
          isOwner,
          personalIdentityNumber: pid,
          phone,
          firstName,
          lastName,
          role: MembershipRole.SUPER,
        },
      ];
      if (nodeAdminPid) {
        admins.push({
          personalIdentityNumber: nodeAdminPid,
          isOwner: false,
          role: MembershipRole.SUPER,
          firstName: '',
          lastName: '',
        });
      }

      const input: CreateCompany2Input = {
        administrator: admins,
        accountClosureMonth: toNumber(accountClosureMonth),
        accountNumber:
          paymentMethod && paymentMethod !== PaymentMethod.INVOICE
            ? accountNumber
            : undefined,
        clearingCode: clearingCode ? clearingCode.value : undefined,
        companyStatusEffective: new Date(startDate).toISOString(),
        email,
        employeeEmailLanguage: language,
        paymentMethod: paymentMethod || undefined,
        phone,
        registrationNumber,
        salesRep: salesRep || undefined,
        salaryReviewMonth: toNumber(salaryReviewMonth),
        smeOnboarding: false,
      };

      const res = await createCompany({
        variables: { input },
      });

      if (!res.data?.createCompany2) {
        return;
      }

      send({
        message: formatMessage(companyMessages.created),
        type: 'success',
      });
      push(`/companies/${res.data.createCompany2?.company.id}`);
    } catch {
      // do nothing
    }
  };

  return {
    submit,
    submissionError: companyError,
  };
};
