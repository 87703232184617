import { DescriptionListEntry } from '@frontend/ui';
import {
  ProposalPremiumDeductions,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalAccidentBenefitConfig as AccidentConfig,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalEmployersPensionBenefitConfig as EmployersPensionConfig,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalHealthcareBenefitConfig as HealthcareConfig,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalLifeBenefitConfig as LifeEntry,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalOccupationalPensionBenefitConfig as OccupationalPensionConfig,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSalaryExchangeBenefitConfig as SalaryExchangeConfig,
  smeCompanyBenefitPackageDetailsQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit_configuration_ProposalSicknessBenefitConfig as SicknessConfig,
} from 'app/apollo/graphql/types';
import { FormattedMessage } from 'components/formats';
import {
  smeBenefitPensionPremiumCalculationMethodMessages,
  smeCompilationAccidentInsuranceMessages,
  smeCompilationDisabilityInsuranceMessages,
  smeCompilationHealthcareInsuranceMessages,
  smeCompilationLifeInsuranceMessages,
  smeCompilationManagementTypeMessages,
  smeCompilationMessages,
} from 'features/sme/messages/sme';
import React from 'react';

import { isOccupationalPensionConfig } from '../BenefitsTable/utils';

export const getPensionEntry = (
  config: EmployersPensionConfig | OccupationalPensionConfig,
): DescriptionListEntry => ({
  id: 'pension',
  label: <FormattedMessage {...smeCompilationMessages.managementType} />,
  value: (
    <FormattedMessage
      messages={smeCompilationManagementTypeMessages}
      select={config.defaultManagementType}
    />
  ),
});

export const getPensionPremiumCalculationMethodEntry = (
  config: EmployersPensionConfig | OccupationalPensionConfig,
): DescriptionListEntry => ({
  id: 'pension-premium-calculation',
  label: (
    <FormattedMessage
      {...(isOccupationalPensionConfig(config)
        ? smeCompilationMessages.occupationalPension
        : smeCompilationMessages.employersPension)}
    />
  ),
  value: (
    <FormattedMessage
      messages={smeBenefitPensionPremiumCalculationMethodMessages}
      select={config.premiumCalculationMethod}
    />
  ),
});

export const getPremiumWaiverEntry = (
  config: EmployersPensionConfig | OccupationalPensionConfig,
): DescriptionListEntry => {
  const premiumDeduction = config.premiumDeductions.includes(
    ProposalPremiumDeductions.PREMIUM_WAIVER,
  );
  return {
    id: `premiumWaiver`,
    label: <FormattedMessage {...smeCompilationMessages.premiumWaiver} />,
    value: (
      <FormattedMessage
        {...(premiumDeduction
          ? smeCompilationMessages.premiumWaiverPremiumDeduction
          : smeCompilationMessages.premiumWaiverNoPremiumDeduction)}
      />
    ),
  };
};

export const getDisabilityEntry = (
  config: SicknessConfig,
  pensionConfig?: EmployersPensionConfig | OccupationalPensionConfig,
): DescriptionListEntry => {
  const premiumDeduction = pensionConfig?.premiumDeductions.includes(
    ProposalPremiumDeductions.SICKNESS_INSURANCE,
  );
  return {
    id: 'disability',
    label: <FormattedMessage {...smeCompilationMessages.disabilityInsurance} />,
    value: (
      <FormattedMessage
        messages={smeCompilationDisabilityInsuranceMessages}
        select={config.sicknessLevel}
        values={{
          premiumDeduction: (
            <FormattedMessage
              {...(premiumDeduction
                ? smeCompilationMessages.premiumDeduction
                : smeCompilationMessages.noPremiumDeduction)}
            />
          ),
        }}
      />
    ),
  };
};

export const getHealthcareEntry = (
  config: HealthcareConfig,
): DescriptionListEntry => ({
  id: 'healthcare',
  label: <FormattedMessage {...smeCompilationMessages.healthcareInsurance} />,
  value: (
    <FormattedMessage
      messages={smeCompilationHealthcareInsuranceMessages}
      select={config.healthcareLevel}
      values={{
        waitingPeriod: (
          <FormattedMessage
            {...(config.waitingPeriod
              ? smeCompilationMessages.waitingPeriod
              : smeCompilationMessages.noWaitingPeriod)}
          />
        ),
      }}
    />
  ),
});

export const getAccidentEntry = (
  config: AccidentConfig,
): DescriptionListEntry => ({
  id: 'accident',
  label: <FormattedMessage {...smeCompilationMessages.accidentInsurance} />,
  value: (
    <FormattedMessage
      messages={smeCompilationAccidentInsuranceMessages}
      select={config.accidentLevel}
    />
  ),
});

export const getLifeEntry = (config: LifeEntry): DescriptionListEntry => ({
  id: 'life',
  label: <FormattedMessage {...smeCompilationMessages.lifeInsurance} />,
  value: (
    <FormattedMessage
      messages={smeCompilationLifeInsuranceMessages}
      select={config.lifeLevel}
    />
  ),
});

export const getTglEntry = (): DescriptionListEntry => ({
  id: 'tgl',
  label: <FormattedMessage {...smeCompilationMessages.tglInsurance} />,
  value: <FormattedMessage {...smeCompilationMessages.tglNoPremiumDeduction} />,
});

export const getSalaryExchangeEntry = (
  config: SalaryExchangeConfig,
): DescriptionListEntry => ({
  id: `salary-exchange`,
  label: (
    <FormattedMessage
      {...(config.premiumWaiver
        ? smeCompilationMessages.salaryExchangeWithPremiumWaiver
        : smeCompilationMessages.salaryExchangeWithoutPremiumWaiver)}
    />
  ),
  value: (
    <FormattedMessage
      {...(config.premiumWaiver
        ? smeCompilationMessages.salaryExchangeWithPremiumWaiverDesc
        : smeCompilationMessages.salaryExchangeWithoutPremiumWaiverDesc)}
    />
  ),
});
