import { Dot } from '@frontend/ui';
import { capitalize } from '@frontend/utils';
import { companyDetailsQuery_company_Company as Company } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  FormattedAddress,
  FormattedIdentityNumber,
  FormattedMessage,
  IntlShape,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import {
  companyLegalEntityTypeMessages,
  companyMessages,
} from 'features/companies/messages';
import React from 'react';

import { CompanyDetailRow } from '../';

export const getCompanyDetails = (company: Company, intl: IntlShape) => {
  const companyDetails: CompanyDetailRow[] = [
    {
      id: 'registrationNumber',
      label: <FormattedMessage {...commonMessages.registrationNumber} />,
      value: <FormattedIdentityNumber value={company.registrationNumber} />,
    },
    {
      id: 'nlpSalesRep',
      label: <FormattedMessage {...companyMessages.nordeaSalesRep} />,
      value: company.nlpSalesRep ? (
        `${company.nlpSalesRep.firstName} ${company.nlpSalesRep.lastName} (${company.nlpSalesRep.externalId})`
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'companyId',
      label: <FormattedMessage {...commonMessages.companyId} />,
      value: company.id,
    },
    {
      id: 'companyName',
      label: <FormattedMessage {...companyMessages.companyName} />,
      value: company.name,
    },
    {
      id: 'displayName',
      label: <FormattedMessage {...companyMessages.displayName} />,
      value: company.displayName,
    },
    {
      id: 'legalEntityType',
      label: <FormattedMessage {...companyMessages.legalEntityType} />,
      value: (
        <FormattedMessage
          select={company.legalEntityType}
          messages={companyLegalEntityTypeMessages}
        />
      ),
    },
    {
      id: 'hasCompanyGroup',
      label: <FormattedMessage {...companyMessages.hasCompanyGroup} />,
      value:
        company.hasCompanyGroup != null ? (
          company.hasCompanyGroup ? (
            <FormattedMessage {...commonMessages.yes} />
          ) : (
            <FormattedMessage {...commonMessages.no} />
          )
        ) : (
          <NoValue />
        ),
    },
    {
      id: 'address',
      label: <FormattedMessage {...companyMessages.companyAddress} />,
      value: (
        <FormattedAddress
          address={company.address.line1 ?? ''}
          careOf={company.address.co}
          postalCode={company.address.postalCode}
          city={company.address.city ?? ''}
        />
      ),
    },
    {
      id: 'phone',
      label: <FormattedMessage {...commonMessages.phone} />,
      value: company.phone ? company.phone : <NoValue />,
      type: 'number',
    },
    {
      id: 'email',
      label: <FormattedMessage {...commonMessages.email} />,
      value: company.email ? company.email : <NoValue />,
    },
    {
      id: 'accountClosureMonth',
      label: <FormattedMessage {...companyMessages.accountClosureMonth} />,
      value: company.accountClosureMonth ? (
        capitalize(
          intl.formatDate(
            new Date().setMonth(company.accountClosureMonth - 1),
            {
              month: 'long',
            },
          ),
        )
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'salaryReviewMonth',
      label: <FormattedMessage {...companyMessages.salaryReviewMonth} />,
      value: company.salaryReviewMonth ? (
        capitalize(
          intl.formatDate(new Date().setMonth(company.salaryReviewMonth - 1), {
            month: 'long',
          }),
        )
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'clearingCode',
      label: <FormattedMessage {...companyMessages.clearingSalesOffice} />,
      value: company.clearingCode?.displayName ?? <NoValue />,
    },
    {
      id: 'kyc',
      label: <FormattedMessage {...companyMessages.kyc} />,
      value:
        company.validKyc != null ? (
          company.validKyc ? (
            <Dot
              size={0.75}
              colorValue="green"
              label={<FormattedMessage {...commonMessages.approved} />}
            />
          ) : (
            <Dot
              size={0.75}
              colorValue="error"
              label={<FormattedMessage {...commonMessages.missing} />}
            />
          )
        ) : (
          <NoValue />
        ),
    },
  ];

  return companyDetails;
};
