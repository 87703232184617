import { Section } from '@frontend/ui';
import { toEffectiveUntil } from '@frontend/utils';
import {
  employeeMeetingsQuery,
  employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge_node_EmployeeMeeting as EmployeeMeeting,
  employeeMeetingsQuery_employeeMeetings_EmployeeMeetingConnection_edges_EmployeeMeetingEdge_node_EmployeeMeeting_membership_Membership as Membership,
  employeeMeetingsQueryVariables,
  EventTypeCategory,
  MembershipKey,
} from 'app/apollo/graphql/types';
import { OpenAddHeldMeetingModal } from 'app/pages/advisor';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';

import { EMPLOYEE_MEETINGS_QUERY } from '../graphql/queries';
import { useCancelMeeting } from '../utils/use-cancel-meeting';
import { useDeleteMeeting } from '../utils/use-delete-meeting';
import { FilterParams } from '../utils/use-filter-params';
import { updateMeetingsQuery } from './lib/update-meetings-query';
import { PaginatedAdviceMeetingTable } from './table';

export type EmployeeWithMeeting = EmployeeMeeting & {
  membership: Membership;
};
interface Props {
  clearFilter: () => void;
  filterParams: FilterParams;
  openAddHeldMeetingModal: OpenAddHeldMeetingModal;
  openSendInvitationsModal: (membershipKeys: Array<MembershipKey>) => void;
  toggleFilterSideSheet: () => void;
}

export const AdviceMeetings: React.FC<Props> = ({
  openAddHeldMeetingModal,
  toggleFilterSideSheet,
  clearFilter,
  filterParams,
  openSendInvitationsModal,
}) => {
  const location = useLocation();

  const { search, per_page } = qs.parse(location.search);

  const { data, loading, previousData, error, fetchMore } = useQuery<
    employeeMeetingsQuery,
    employeeMeetingsQueryVariables
  >(EMPLOYEE_MEETINGS_QUERY, {
    variables: {
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search: search || undefined,
      from: filterParams.from || undefined,
      to: filterParams.to ? toEffectiveUntil(filterParams.to) : undefined,
      advisorId: filterParams.advisor || undefined,
      eventTypeCategories:
        Object.values(EventTypeCategory).filter(
          category =>
            !filterParams.excludedEventTypeCategories.includes(category),
        ) || undefined,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;
  const meetings =
    _data?.employeeMeetings.edges
      .map(e => e.node)
      .filter((node): node is EmployeeWithMeeting => node.membership != null) ??
    [];
  const pageInfo = _data?.employeeMeetings.pageInfo ?? {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  };

  const {
    deleteMeeting,
    error: deleteMeetingError,
    loading: deleteMeetingLoading,
  } = useDeleteMeeting();

  const {
    cancelMeeting,
    error: cancelMeetingError,
    loading: cancelMeetingLoading,
  } = useCancelMeeting();

  const _loading = loading || deleteMeetingLoading || cancelMeetingLoading;
  const _error = error || deleteMeetingError || cancelMeetingError;

  return (
    <>
      {_loading && <TopLoading />}
      {_error && <GraphQlError error={_error} />}
      <Section>
        <PaginatedAdviceMeetingTable
          pageInfo={pageInfo}
          onNextPage={() => {
            fetchMore({
              variables: {
                after: pageInfo?.endCursor,
              },
              updateQuery: updateMeetingsQuery,
            });
          }}
          onPreviousPage={() => {
            fetchMore({
              variables: {
                before: pageInfo?.startCursor,
                first: undefined,
                last: per_page
                  ? parseInt(per_page, 10)
                  : DEFAULT_RESULT_PER_PAGE,
              },
              updateQuery: updateMeetingsQuery,
            });
          }}
          meetings={meetings}
          openAddHeldMeetingModal={openAddHeldMeetingModal}
          toggleFilterSideSheet={toggleFilterSideSheet}
          clearFilter={clearFilter}
          openSendInvitationsModal={openSendInvitationsModal}
          deleteMeeting={deleteMeeting}
          cancelMeeting={cancelMeeting}
        />
      </Section>
    </>
  );
};
