import { PaymentMethod } from 'app/apollo/graphql/types';

interface ClearingCode {
  code: string;
}

interface NlpSalesRep {
  id: string;
}

interface Args {
  accountClosureMonth: number | null;
  accountNumber: string | null;
  clearingCode: ClearingCode | null;
  nlpSalesRep: NlpSalesRep | null;
  paymentMethod: PaymentMethod | null;
  salaryReviewMonth: number | null;
}

/**
 * Returns true iff the user is allowed to create a Nordea agreement, otherwise false.
 */
export const isAllowedToCreateNlpAgreement = (args: Args): boolean => {
  const {
    accountClosureMonth,
    accountNumber,
    paymentMethod,
    salaryReviewMonth,
    clearingCode,
    nlpSalesRep,
  } = args;

  return (
    accountClosureMonth != null &&
    salaryReviewMonth != null &&
    clearingCode != null &&
    nlpSalesRep != null &&
    (paymentMethod === PaymentMethod.INVOICE ||
      (!!paymentMethod && accountNumber != null))
  );
};
