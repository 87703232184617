import { defineMessages } from 'react-intl';

import { Status } from '../components/FcfwStatus';

export const workCapabilityMessages = defineMessages({
  fullyCapableForWorkInfoTitle: {
    id: 'workCapabilityMessages.fullyCapableForWorkInfoTitle',
    defaultMessage: 'Vad är fullt arbetsför?',
  },
  assertWorkCapabilitySuccess: {
    id: 'workCapabilityMessages.assertWorkCapabilitySuccess',
    defaultMessage: 'Intygande av fullt arbetsför lyckades',
  },
  confirmFullWorkCapabilityLede: {
    id: 'workCapabilityMessages.confirmFullWorkCapabilityLede',
    defaultMessage:
      'För att teckna eller ändra försäkringar hos Euro Accident, måste du intyga att den anställde är fullt arbetsför. Oriktiga uppgifter kan påverka försäkringens giltighet.',
  },
  confirmFullWorkCapability: {
    id: 'workCapabilityMessages.confirmFullWorkCapability',
    defaultMessage: 'Intyga fullt arbetsför',
  },
  employeesToConfirmFullWorkCapability: {
    id: 'workCapabilityMessages.employeesToConfirmFullWorkCapability',
    defaultMessage: 'Anställda att intyga',
  },
  noEmployeesToAssertWorkCapability: {
    id: 'workCapabilityMessages.noEmployeesToAssertWorkCapability',
    defaultMessage: 'Inga anställda behöver intygas',
  },
  confirmFullWorkCapabilityEmployeesLede: {
    id: 'workCapabilityMessages.confirmFullWorkCapabilityEmployeesLede',
    defaultMessage:
      'För att teckna eller ändra försäkringar hos Euro Accident måste du intyga fullt arbetsför. Oriktiga uppgifter kan påverka försäkringars giltighet.',
  },
  confirmFullWorkCapabilityFor: {
    id: 'workCapabilityMessages.confirmFullWorkCapabilityFor',
    defaultMessage: 'Intyga att {name} är fullt arbetsför',
  },
  certificationOfFullWorkCapability: {
    id: 'workCapabilityMessages.certificationOfFullWorkCapability',
    defaultMessage: 'Intygande om fullt arbetsför',
  },
  certificationDate: {
    id: 'workCapabilityMessages.certificationDate',
    defaultMessage: 'Intygat för datum',
  },
});

export const effectiveDateStatusMessages = defineMessages<Status>({
  INSURANCE_WARNING: {
    id: 'workCapabilityMessages.effectiveDateStatusMessage.INSURANCE_WARNING',
    defaultMessage: 'Skjut upp ändringar',
  },
  NOT_COMPLETE: {
    id: 'workCapabilityMessages.effectiveDateStatusMessage.NOT_COMPLETE',
    defaultMessage: 'Några ändringar kommer kräva ett nytt intygande',
  },
  COMPLETE: {
    id: 'workCapabilityMessages.effectiveDateStatusMessage.COMPLETE',
    defaultMessage: 'Alla ändringar kommer genomföras',
  },
});
