import gql from 'graphql-tag';

export const MISSING_KYC_NOTIFICATION_QUERY = gql`
  query missingKycNotificationQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      validKyc
    }
  }
`;
