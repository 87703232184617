import {
  AccidentLevel,
  DeductionType,
  DisabilityLevel,
  HealthLevel,
  LifeLevel,
  PensionDefaultProvider,
  PensionProvider,
  PersonalAdvicePaymentMethod,
  SalaryExchangeProvider,
  SicknessAccidentLevel,
} from '@frontend/benefit-types';
import { CheckboxGroupOption } from '@frontend/ui';
import { suffixMessages } from 'app/messages/common';
import { MessageDescriptor } from 'react-intl';

import {
  PensionProviderOptionValue,
  SalaryExchangeProviderOptionValue,
} from '../types';

/**
 * This object maps the pension provider option values to the corresponding
 * agreement type.
 */
export const AGREEMENT: Record<PensionProviderOptionValue, string> = {
  NORDEA: 'AgreementNordea',
  NORDNET: 'AgreementNordnet',
};

export const PENSION_PROVIDER_OPTIONS: CheckboxGroupOption<PensionProviderOptionValue>[] =
  [
    { label: 'Nordea', value: 'NORDEA' },
    { label: 'Nordnet', value: 'NORDNET' },
  ];

/**
 * This object maps the pension provider option values to the corresponding
 * pension providers in the API.
 */
export const PENSION_PROVIDER_INPUT_VALUES: Record<
  PensionProviderOptionValue,
  PensionProvider[] | PensionProvider
> = {
  NORDEA: ['nordea_fund', 'nordea_trad'],
  NORDNET: 'nordnet',
};

/**
 * This object maps the pension provider values in the API
 * to the corresponding pension provider option values.
 */
export const PENSION_PROVIDER_FORM_VALUES: Record<
  PensionProvider,
  PensionProviderOptionValue
> = {
  nordea_fund: 'NORDEA',
  nordea_trad: 'NORDEA',
  nordnet: 'NORDNET',
};

/**
 * This array lists the default management type options available
 * sorted for display purposes rather than in alphabetical order.
 *
 * TODO: Replace this with the type of defaultProvider on from the pension
 * schema when available (https://nordeanode.atlassian.net/browse/COM-1889)
 */
export const PENSION_DEFAULT_MANAGEMENT_TYPE_OPTION_VALUES = [
  'FUND',
  'TRAD',
] as const;

/**
 * This array lists the default pension provider options available
 * sorted for display purposes rather than in alphabetical order.
 */
export const PENSION_DEFAULT_PROVIDER_OPTION_VALUES: PensionDefaultProvider[] =
  ['nordea_fund', 'nordea_trad'];

/**
 * This array lists the pension premium calculation methods available for NLP,
 * sorted for display purposes rather than in alphabetical order.
 */
export const PENSION_PREMIUM_CALCULATION_METHODS_NLP = [
  'ITP_LIKE_MATRIX',
  'CUSTOM_MATRIX',
  'FIXED_PREMIUM_ONLY',
] as const;

/**
 * This array lists the pension premium calculation methods available for Node,
 * sorted for display purposes rather than in alphabetical order.
 * `PENSION_PREMIUM_CALCULATION_METHODS_NLP` is assumed to be a subset of
 * `PENSION_PREMIUM_CALCULATION_METHODS_NODE` in the corresponding type.
 */
export const PENSION_PREMIUM_CALCULATION_METHODS_NODE = [
  'ITP_MATRIX',
  ...PENSION_PREMIUM_CALCULATION_METHODS_NLP,
] as const;

/**
 * This array lists the pension premium deductions available for NLP,
 * sorted for display purposes rather than in the alphabetical order.
 */
export const PREMIUM_DEDUCTIONS_NLP: DeductionType[] = [
  'PREMIUM_WAIVER',
  'SICKNESS_INSURANCE',
  // TODO: Add this type when it exists in the API.
  // 'SURVIVORS_PENSION',
];

/**
 * This array lists the pension premium deductions available for Node,
 * sorted for display purposes rather than in the alphabetical order.
 */
export const PREMIUM_DEDUCTIONS_NODE: DeductionType[] = [
  ...PREMIUM_DEDUCTIONS_NLP,
  'ADVINANS_ADVICE_FEE',
  'ADVINANS_MEMBERSHIP_FEE',
];
/**
 * Salary exchange ITP provider
 */
export const COLLECTUM_PROVIDER = 'COLLECTUM';

/**
 * This object maps the salary exchange provider option values to the corresponding
 * salary exchange providers in the API.
 */
export const SALARY_EXCHANGE_PROVIDER_INPUT_VALUES: Record<
  SalaryExchangeProviderOptionValue,
  SalaryExchangeProvider[] | SalaryExchangeProvider
> = {
  COLLECTUM: 'collectum',
  NORDEA: ['nordea_fund', 'nordea_trad'],
  NORDNET: 'nordnet',
};

/**
 * This object maps the salary exchange provider values in the API
 * to the corresponding salary exchange provider option values.
 */
export const SALARY_EXCHANGE_PROVIDER_FORM_VALUES: Record<
  SalaryExchangeProvider,
  SalaryExchangeProviderOptionValue
> = {
  collectum: 'COLLECTUM',
  nordea_fund: 'NORDEA',
  nordea_trad: 'NORDEA',
  nordnet: 'NORDNET',
};

/**
 * Minimum required income after salary exchange salary deduction
 * expressed in income base amounts.
 */
export const INCOME_MIN_IBB = 8.07;

/**
 * Default value for the minimum required income after salary exchange salary deduction
 */
export const SALARY_EXCHANGE_INCOME_MIN_IBB_DEFAULT_VALUE = '8.07';
/**
 * Default value for the maximum share of deduction
 */
export const SALARY_EXCHANGE_MAX_DEDUCTION_SHARE_DEFAULT_VALUE = '35';
/**
 * Default value for the maximum deduction expressed in SEK
 */
export const SALARY_EXCHANGE_MAX_DEDUCTION_DEFAULT_VALUE = '8000';
/**
 * Default value for the payroll element multipler
 */
export const SALARY_EXCHANGE_PAYROLL_ELEMENT_MULTIPLIER_DEFAULT_VALUE = '12';
/**
 * Default value for the tax benefit share that is awarded to the employee
 */
export const SALARY_EXCHANGE_PENSION_TAX_BENEFIT_SHARE_DEFAULT_VALUE = '100';
/**
 * The default providers for salary exchange
 */
export const SALARY_EXCHANGE_PROVIDERS_DEFAULT_VALUE: SalaryExchangeProviderOptionValue[] =
  ['NORDEA'];
/**
 * Default retirement age
 */
export const SALARY_EXCHANGE_RETIREMENT_AGE_DEFAULT_VALUE = '67';

/**
 * This array lists the accident levels available
 * sorted for display purposes rather than in the alphabetical order.
 */
export const ACCIDENT_LEVELS: AccidentLevel[] = [
  'PBB20',
  'PBB30',
  'PBB40',
  'PBB50',
];

/**
 * This array lists the disability levels available
 * sorted for display purposes rather than in the alphabetical order.
 */

export const DISABILITY_LEVELS: DisabilityLevel[] = [
  'ITP',
  'KOMP',
  'MAX',
  'CONSOLIDATION',
  'HYBRID',
];

/**
 * This array lists the healthcare levels available
 * sorted for display purposes rather than in the alphabetical order.
 */
export const HEALTHCARE_LEVELS: HealthLevel[] = [
  'BRONS_500',
  'BRONS_0',
  'L750',
  'L1000',
  'L1500',
  'L0',
  'GOLD',
];

/**
 * This array lists the life levels available
 * sorted for display purposes rather than in the alphabetical order.
 */
export const LIFE_LEVELS: LifeLevel[] = [
  'PBB5',
  'PBB10',
  'PBB15',
  'PBB20',
  'PBB25',
  'PBB30',
  'PBB35',
  'PBB40',
  'PBB45',
  'PBB50',
];

/**
 * This array lists the sickness and accident levels available
 * sorted for display purposes rather than in the alphabetical order.
 */
export const SICKNESS_AND_ACCIDENT_LEVELS: SicknessAccidentLevel[] = [
  'PBB20',
  'PBB30',
  'PBB40',
  'PBB50',
];

/**
 * This array lists the personal advice payment methods available
 * sorted for display purposes rather than in the alphabetical order.
 */
export const PERSONAL_ADVICE_PAYMENT_METHODS: PersonalAdvicePaymentMethod[] = [
  'EMPLOYER_PAYS_PER_MEETING',
  'EMPLOYER_PAYS_SUBSCRIPTION',
  'EMPLOYEE_PAYS_PREMIUM_DEDUCTION',
  'EMPLOYEE_PAYS_PER_MEETING',
  'CAPITAL_FEE',
];

/**
 * The suffixes for the personal advice payment methods that should be
 * displayed in the fee input field
 */
export const PERSONAL_ADVICE_PAYMENT_SUFFIX: Record<
  Exclude<PersonalAdvicePaymentMethod, 'CAPITAL_FEE'>,
  MessageDescriptor
> = {
  EMPLOYER_PAYS_PER_MEETING: suffixMessages.kr,
  EMPLOYER_PAYS_SUBSCRIPTION: suffixMessages.krPerMonth,
  EMPLOYEE_PAYS_PREMIUM_DEDUCTION: suffixMessages.krPerMonth,
  EMPLOYEE_PAYS_PER_MEETING: suffixMessages.kr,
};

/**
 * This array lists the personal advice meeting frequency options available
 * sorted for display purposes rather than in the alphabetical order.
 */
export const PERSONAL_ADVICE_MEETING_FREQUENCY_OPTION_VALUES = [
  'ONE_ADVICE_MEETING_LIMIT',
  '0',
  '12',
  '24',
  '36',
  '48',
  '60',
] as const;

/**
 * The number of months in the free period for personal advice
 */
export const PERSONAL_ADVICE_SIX_MONTHS_FREE_PERIOD = 6;
/**
 * The number of months in no free period for personal advice
 */
export const PERSONAL_ADVICE_NO_FREE_PERIOD = 0;
