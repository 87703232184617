import { ApolloError, useMutation } from '@apollo/client';
import {
  EmployersPension,
  EmployersPensionInput,
} from '@frontend/benefit-types';
import { toNumber } from '@frontend/utils';
import {
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  FlexUpdateBenefitInput,
  updateBenefitMutation,
  updateBenefitMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useIntl } from 'components/formats';
import format from 'date-fns/format';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { FormValues } from '../../../form/employers-pension';
import { benefitFormMessages } from '../../../form/messages';
import { formatPremiumMatrixInput } from '../../../form/utils';
import { clearBenefitsCache } from '../../../utils/clear-benefit-cache';
import { UPDATE_BENEFIT_MUTATION } from '../../graphql/mutations';

interface Submit {
  submit: (
    values: FormValues,
    benefit: EmployersPension<Benefit>,
  ) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [updateBenefit, { error: submissionError }] = useMutation<
    updateBenefitMutation,
    updateBenefitMutationVariables
  >(UPDATE_BENEFIT_MUTATION, {
    update: clearBenefitsCache({
      companyId: params.companyId,
    }),
  });

  const submit = async (
    values: FormValues,
    benefit: EmployersPension<Benefit>,
  ) => {
    try {
      const configEffectiveDate = format(
        new Date(values.effectiveFrom),
        'yyyy-MM-dd',
      );
      const input: EmployersPensionInput<FlexUpdateBenefitInput> = {
        id: params.benefitId,
        configEffectiveDate,
        configuration: {
          defaultProvider: values.defaultProvider || undefined,
          fixedPremium:
            values.premiumCalculationMethod === 'FIXED_PREMIUM_ONLY' ||
            values.extraFixedPremium,
          retirementAge: toNumber(values.retirementAge) ?? 0,
          premiumWaiver: values.premiumWaiver === 'yes',
          premiumDeductions: values.premiumDeductions,
          payrollElementCode:
            values.premiumCalculationMethod !== 'FIXED_PREMIUM_ONLY'
              ? values.payrollElementCode
              : undefined,
          payrollElementMultiplier:
            values.premiumCalculationMethod !== 'FIXED_PREMIUM_ONLY'
              ? values.payrollElementMultiplier
              : undefined,
          isActiveOnParentalLeave: values.isActiveOnParentalLeave,
          providers: ['nordea_fund', 'nordea_trad'],
          premiumMatrix:
            values.premiumCalculationMethod === 'ITP_MATRIX'
              ? formatPremiumMatrixInput(values.itpPremiumMatrix)
              : values.premiumCalculationMethod === 'ITP_LIKE_MATRIX'
                ? formatPremiumMatrixInput(values.itpLikePremiumMatrix)
                : values.premiumCalculationMethod === 'CUSTOM_MATRIX'
                  ? formatPremiumMatrixInput(values.customPremiumMatrix)
                  : null,
          premiumWaiverConvertingFrom:
            values.premiumWaiver === 'yes'
              ? values.premiumWaiverConverting
                ? values.premiumWaiverConvertingFrom
                : null
              : null,
        },
        entitlementRules: {
          accountingCostIds: benefit.entitlementRules?.accountingCostIds ?? [],
          benefitPackageIds: values.benefitPackageIds,
          ouIds: benefit.entitlementRules?.ouIds ?? [],
        },
      };

      await updateBenefit({
        variables: {
          input,
        },
      });

      send({
        message: formatMessage(benefitFormMessages.benefitSaved),
        type: 'success',
      });
      push(
        `/companies/${params.companyId}/benefit-packages/benefits/${params.benefitId}/version-history/${configEffectiveDate}`,
      );
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
