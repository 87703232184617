import { getEmployeeRiskInsurances } from '@frontend/utils';
import {
  riskInsurancesAdminQuery,
  riskInsurancesAdminQueryVariables,
} from 'app/apollo/graphql/types';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { AccidentInsurance } from './components/AccidentInsurance';
import { HealthInsurance } from './components/HealthInsurance';
import { LifeInsurance } from './components/LifeInsurance';
import { SicknessInsurance } from './components/SicknessInsurance';
import { RISK_INSURANCES_ADMIN_QUERY } from './graphql/queries';

const isEmpty = (data?: riskInsurancesAdminQuery) =>
  !getEmployeeRiskInsurances(
    data?.membership?.insurances?.edges.map(edge => edge.node) ?? [],
  ).length;

export const Insurances: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const { data } = useQuery<
    riskInsurancesAdminQuery,
    riskInsurancesAdminQueryVariables
  >(RISK_INSURANCES_ADMIN_QUERY, {
    suspend: true,
    isEmpty,
    errorPolicy: 'all',
    variables: {
      userAccountId,
      companyId,
      includeDeleted: true,
    },
  });

  if (!data?.membership) {
    return null;
  }

  const advinansBrokeredEAInsuranceComponents = getEmployeeRiskInsurances(
    data?.membership?.insurances?.edges.map(({ node }) => node) ?? [],
  );

  return advinansBrokeredEAInsuranceComponents.map(component => {
    switch (component.__typename) {
      case 'InsuranceComponentSickness':
        return <SicknessInsurance key={component.id} data={component} />;
      case 'InsuranceComponentAccident':
        return <AccidentInsurance key={component.id} data={component} />;
      case 'InsuranceComponentDeath':
        return <LifeInsurance key={component.id} data={component} />;
      case 'InsuranceComponentHealthCare':
        return <HealthInsurance key={component.id} data={component} />;
      default:
        return null;
    }
  });
};
