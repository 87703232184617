/* eslint-disable */
import {
  Button,
  ButtonLayout,
  Icon,
  MenuSurface,
  MenuSurfaceAnchor,
  ModalBody,
  ModalFooter,
  NumberField,
  TextField,
} from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Option } from '../Option';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { OnValueChange } from 'react-number-format';

interface Props {
  doCollapse: () => void;
  onChange: (...input: any[]) => void;
  onExpandEvent: () => void;
  config?: { [key: string]: any };
  expanded?: boolean;
  translations?: { [key: string]: any };
}

export const Embedded: React.FC<Props> = ({
  expanded,
  doCollapse,
  onChange,
  onExpandEvent,
  config,
  translations,
}) => {
  const { locale } = useIntlContext();

  const initState = {
    showModal: false,
    embeddedLink: '',
    height: config?.defaultSize?.height,
    width: config?.defaultSize?.width,
  };

  const [state, setState] = useState(initState);
  const [cancelButtonFocused, setCancelButtonFocused] = useState(false);

  useEffect(() => {
    if (!expanded) {
      setState(initState);
    }
  }, [expanded]);

  const addEmbeddedLink = () =>
    !!onChange && onChange(state.embeddedLink, state.height, state.width);

  const updateValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const { value } = e.target;
    setState(prev => ({ ...prev, [`${name}`]: value }));
  };

  const updateNumericValue: OnValueChange = (values, sourceInfo) => {
    const target = sourceInfo?.event?.target as HTMLInputElement;
    const { value } = values;
    setState(prev => ({ ...prev, [`${target.name}`]: value }));
  };

  const expandModal = () => {
    if (onExpandEvent) {
      onExpandEvent();
    }

    setState({
      showModal: true,
      embeddedLink: '',
      height: state.height,
      width: state.width,
    });
  };

  return (
    <div className="rdw-embedded-wrapper" aria-label="rdw-embedded-control">
      <Option
        className={config?.className}
        onClick={expandModal}
        title={
          !!translations &&
          translations['components.controls.embedded.embedded']
        }
      >
        <Icon icon={config?.icon} size="small" decorative />
      </Option>
      {expanded && state.showModal && (
        <MenuSurfaceAnchor>
          <MenuSurface
            style={{ transform: 'none' }}
            open={state.showModal}
            onClose={doCollapse}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.stopPropagation();
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement?.blur();
              }
            }}
          >
            {/* eslint-disable-next-line */}
            <div
              role="form"
              onKeyDown={e => {
                if (e.key === 'Enter' && !cancelButtonFocused) {
                  e.preventDefault();
                  if (state.embeddedLink && state.height && state.width) {
                    addEmbeddedLink();
                  }
                }
              }}
            >
              <ModalBody style={{ minWidth: '15rem' }}>
                <TextField
                  name="embeddedLink"
                  label={
                    !!translations &&
                    translations['components.controls.embedded.link']
                  }
                  value={state.embeddedLink}
                  onChange={updateValue}
                  onClick={e => e.stopPropagation()}
                  dense
                  required
                />
                <NumberField
                  name="height"
                  label={
                    !!translations &&
                    translations['components.controls.embedded.height']
                  }
                  value={state.height}
                  onValueChange={updateNumericValue}
                  onClick={e => e.stopPropagation()}
                  dense
                  required
                  locale={locale}
                />
                <NumberField
                  name="width"
                  label={
                    !!translations &&
                    translations['components.controls.embedded.width']
                  }
                  value={state.width}
                  onValueChange={updateNumericValue}
                  onClick={e => e.stopPropagation()}
                  dense
                  required
                  locale={locale}
                />
              </ModalBody>
              <ModalFooter>
                <ButtonLayout align="right">
                  <Button
                    text
                    onClick={doCollapse}
                    onFocus={() => setCancelButtonFocused(true)}
                    onBlur={() => setCancelButtonFocused(false)}
                  >
                    <FormattedMessage {...formMessages.cancel} />
                  </Button>
                  <Button
                    filled
                    disabled={
                      !state.embeddedLink || !state.width || !state.height
                    }
                    onClick={addEmbeddedLink}
                  >
                    <FormattedMessage {...commonMessages.embed} />
                  </Button>
                </ButtonLayout>
              </ModalFooter>
            </div>
          </MenuSurface>
        </MenuSurfaceAnchor>
      )}
    </div>
  );
};
