import { AdvinansOccupationalPension } from '@frontend/benefit-types';
import { Formik } from '@frontend/formik';
import {
  companyEditBenefitQuery,
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  companyEditBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { MONTHLY_SALARY_REMUNERATION_TYPE_ID } from 'features/companies/company/utils/constants';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { customPremiumMatrixInitialValues } from '../../form/custom-premium-matrix/utils/constants';
import {
  FormValues,
  OccupationalPensionForm,
  validationSchema,
} from '../../form/occupational-pension';
import {
  itpLikePremiumMatrixInitialValues,
  itpPremiumMatrixInitialValues,
} from '../../form/static-premium-matrix/utils/constants';
import {
  formatPremiumMatrixFormValues,
  getBenefitPackageOptions,
  getHasEaTjpAgreement,
  getPremiumCalculationMethod,
} from '../../form/utils';
import {
  PENSION_PREMIUM_CALCULATION_METHODS_NLP,
  PENSION_PREMIUM_CALCULATION_METHODS_NODE,
  PENSION_PROVIDER_FORM_VALUES,
} from '../../form/utils/constants';
import { COMPANY_EDIT_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const EditOccupationalPension: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    companyEditBenefitQuery,
    companyEditBenefitQueryVariables
  >(COMPANY_EDIT_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { benefitId: params.benefitId, companyId: params.companyId },
  });

  const { submit, submissionError } = useSubmit();

  if (loading) {
    return <TopLoading />;
  }

  if (
    !data?.company?.agreements ||
    !data.company.benefitPackages ||
    !data.company.remunerationTypes ||
    !data.flexBenefit?.configuration?.data ||
    !data.flexBenefit?.entitlementRules
  ) {
    return <EmptyState error={error} />;
  }

  const benefit: AdvinansOccupationalPension<Benefit> = data.flexBenefit;

  const { agreements, benefitPackages, managedBy, remunerationTypes } =
    data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    ['advinans_occupational_pension', 'employers_pension'],
    benefitPackages,
    benefit.id,
  );

  const { configEffectiveDate, configuration, entitlementRules } = benefit;

  if (!configuration?.data || !entitlementRules) {
    return null;
  }

  const { premiumMatrix } = configuration.data;

  const isManagedByNode = managedBy === 'NODE';

  const PREMIUM_CALCULATION_METHOD_OPTIONS = isManagedByNode
    ? PENSION_PREMIUM_CALCULATION_METHODS_NODE
    : PENSION_PREMIUM_CALCULATION_METHODS_NLP;

  const premiumCalculationMethod = getPremiumCalculationMethod({
    availableOptions: PREMIUM_CALCULATION_METHOD_OPTIONS,
    premiumMatrix,
  });

  const initialValues: FormValues = {
    benefitPackageIds: [...entitlementRules.benefitPackageIds],
    premiumWaiverConverting: !!configuration.data?.premiumWaiverConvertingFrom,
    premiumWaiverConvertingFrom:
      configuration?.data.premiumWaiverConvertingFrom ?? '',
    customPremiumMatrix:
      premiumMatrix && premiumCalculationMethod === 'CUSTOM_MATRIX'
        ? formatPremiumMatrixFormValues(premiumMatrix)
        : customPremiumMatrixInitialValues,
    defaultProvider: configuration.data.defaultProvider ?? '',
    effectiveFrom: '',
    extraFixedPremium:
      premiumCalculationMethod !== 'FIXED_PREMIUM_ONLY'
        ? !!configuration.data.fixedPremium
        : false,
    isActiveOnParentalLeave: configuration.data.isActiveOnParentalLeave,
    itpLikePremiumMatrix: itpLikePremiumMatrixInitialValues,
    itpPremiumMatrix: itpPremiumMatrixInitialValues,
    payrollElementCode:
      configuration.data.payrollElementCode ??
      MONTHLY_SALARY_REMUNERATION_TYPE_ID,
    payrollElementMultiplier:
      configuration.data.payrollElementMultiplier ?? '12',
    premiumCalculationMethod,
    premiumDeductions: configuration.data.premiumDeductions,
    premiumWaiver:
      getHasEaTjpAgreement(agreements) && configuration.data.premiumWaiver
        ? 'yes'
        : 'no',
    providers: [
      ...new Set(
        configuration.data.providers.map(
          provider => PENSION_PROVIDER_FORM_VALUES[provider],
        ),
      ),
    ],
    retirementAge: configuration.data.retirementAge.toString(),
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={values => submit(values, benefit)}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      <OccupationalPensionForm
        agreements={agreements}
        benefitPackageOptions={benefitPackageOptions}
        isEdit
        latestChangesEffectiveDate={configEffectiveDate}
        managedBy={managedBy}
        premiumCalculationMethodOptions={PREMIUM_CALCULATION_METHOD_OPTIONS}
        remunerationTypes={remunerationTypes}
        submissionError={submissionError}
      />
    </Formik>
  );
};
