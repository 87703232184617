import { Section, SectionHeader } from '@frontend/ui';
import {
  insuranceEventsTableQuery,
  insuranceEventsTableQueryVariables,
} from 'app/apollo/graphql/types';
import { membershipEventHistoryMessages } from 'app/messages/employees';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { Filters } from './Filters';
import { getFilterQueryArgs } from './Filters/utils/get-filter-query-args';
import { INSURANCE_EVENTS_TABLE_QUERY } from './graphql/queries';
import { updateInsuranceEventsTableQuery } from './graphql/update-query';
import { insuranceEventHistoryMessages } from './messages';
import { InsuranceEventsTable } from './Table';

const isEmpty = (data?: insuranceEventsTableQuery) =>
  !data?.membership?.changesHistory?.edges?.length;

export const InsuranceEvents: React.FC = () => {
  const { search } = useLocation();

  const [suspend, setSuspend] = useState(true);

  const { per_page } = qs.parse(search);

  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const filterArgs = getFilterQueryArgs();

  const { data, previousData, loading, error, fetchMore } = useQuery<
    insuranceEventsTableQuery,
    insuranceEventsTableQueryVariables
  >(INSURANCE_EVENTS_TABLE_QUERY, {
    skip: !companyId,
    suspend,
    errorPolicy: 'all',
    variables: {
      userAccountId,
      companyId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      filter: {
        ...filterArgs,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setSuspend(false),
    isEmpty,
  });

  const _data = data ?? previousData;

  if (!_data?.membership?.changesHistory) {
    if (suspend) {
      return null;
    }
    if (loading) {
      return <TopLoading />;
    }
    return <GraphQlError error={error} />;
  }

  const changesHistory = _data?.membership?.changesHistory.edges.map(
    ({ node }) => node,
  );

  if (changesHistory.length === 0 && !search) {
    return (
      <EmptyState
        title={
          <FormattedMessage {...insuranceEventHistoryMessages.noChanges} />
        }
      />
    );
  }

  const pageInfo = _data?.membership?.changesHistory?.pageInfo;

  return (
    <Section>
      {!suspend && loading && <TopLoading />}

      <SectionHeader>
        <FormattedMessage {...membershipEventHistoryMessages.eventHistory} />
      </SectionHeader>
      <Filters />
      <InsuranceEventsTable
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={() =>
              fetchMore({
                variables: {
                  after: pageInfo.endCursor,
                },
                updateQuery: updateInsuranceEventsTableQuery,
              })
            }
            onPreviousPage={() =>
              fetchMore({
                variables: {
                  before: pageInfo.startCursor,
                  first: undefined,
                  last: per_page
                    ? parseInt(per_page, 10)
                    : DEFAULT_RESULT_PER_PAGE,
                },
                updateQuery: updateInsuranceEventsTableQuery,
              })
            }
          />
        }
        changesHistory={changesHistory}
      />
    </Section>
  );
};
