import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  // eslint-disable-next-line
  useQuery as useApolloQuery,
} from '@apollo/client';
import { Reporter } from 'components/ErrorBoundary';
import { detect as detectBrowser } from 'detect-browser';
import { useSuspenseContext } from 'features/Suspense';
import { DefinitionNode, OperationDefinitionNode } from 'graphql';
import { useEffect, useId, useMemo } from 'react';

interface SuspendedQueryHookOptions<
  TData,
  TVariables extends OperationVariables,
> extends QueryHookOptions<TData, TVariables> {
  /**
   * Toggles whether the query should be suspended in its current suspense context.
   *
   * If set to `true`, the query will be suspended.
   *
   * The 'no-fallback' option is used for suspended queries which renders outside the Suspense boundary due
   * to fixed positioning, e.g. banners. These have no effect on the empty state of the Suspense context.
   */
  suspend: boolean | 'no-fallback';
  isEmpty?: (data: TData | undefined) => boolean;
}

const isSuspendedQuery = <TData, TVariables extends OperationVariables>(
  options?: QueryHookOptions<TData, TVariables>,
): options is SuspendedQueryHookOptions<TData, TVariables> =>
  (options as SuspendedQueryHookOptions<TData, TVariables>)?.suspend != null;

const isOperationDefinitionNode = (
  node?: DefinitionNode,
): node is OperationDefinitionNode => node?.kind === 'OperationDefinition';

export const useQuery = <
  TData,
  // eslint-disable-next-line
  TVariables extends OperationVariables | {} = {},
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?:
    | QueryHookOptions<TData, TVariables>
    | SuspendedQueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => {
  const queryId = useId();
  const {
    updateQuery,
    addQuery,
    removeQuery,
    batch,
    batchId,
    loading,
    hasEmptyState,
  } = useSuspenseContext();
  const browser = useMemo(() => detectBrowser(), []);

  const suspend = isSuspendedQuery(options) ? options.suspend : undefined;
  const isEmpty = isSuspendedQuery(options)
    ? options.suspend === 'no-fallback'
      ? () => true
      : options.isEmpty
    : undefined;

  const result = useApolloQuery(query, {
    context: suspend ? { batch, batchId } : undefined,
    ...options,
  });

  useEffect(() => {
    if (suspend) {
      addQuery(queryId);

      return () => {
        removeQuery(queryId);
      };
    }

    return undefined;
  }, []);

  useEffect(() => {
    if (suspend) {
      updateQuery(queryId, {
        error: result.error,
        loading: result.loading,
        isEmpty: isEmpty?.(result.data) ?? false,
      });
    }
  }, [result]);

  const queryName = query.definitions.find(isOperationDefinitionNode)?.name
    ?.value;

  useEffect(() => {
    if (suspend && hasEmptyState && !isEmpty) {
      const error = new Error(
        `"isEmpty" is not defined for query: ${queryName}. When using "isEmpty" or "emptyState" in a Suspense boundary, 
        all suspended queries within the boundary must define an "isEmpty" function.`,
      );
      // eslint-disable-next-line
      console.error(error);
      if (window.env.ERROR_REPORTING_ACTIVE === 'true') {
        const context = {
          httpRequest: {
            url: window.location.pathname,
            userAgent: `${browser?.os}, ${browser?.name}@${browser?.version}`,
          },
          user: 'backstage',
        };
        Reporter.report({ error, context });
      }
    }
  }, [hasEmptyState]);

  return {
    ...result,
    data: suspend === 'no-fallback' && loading ? undefined : result.data,
  };
};
