import { Dot, Section, Table, Td, Th, Tr } from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import { select } from '@frontend/utils';
import {
  agreementsListQuery,
  agreementsListQueryVariables,
  AgreementStatus,
} from 'app/apollo/graphql/types';
import { companyMessages } from 'app/messages/company';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { EmptyState } from 'components/EmptyState';
import { Reporter } from 'components/ErrorBoundary';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { NoValue } from 'components/NoValue';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { getAddAgreementLink } from './components/AddAgreementModal';
import { AgreementAttachmentList } from './components/AgreementAttachmentsList';
import { AgreementName } from './components/AgreementName';
import { AGREEMENTS_LIST_QUERY } from './graphql/queries';
import { agreementMessages, agreementStatusMessages } from './messages';

const SIGNABLE_AGREEMENT_TYPES = [
  'AgreementRequestAdminPoa',
  'AgreementRequestDanica',
  'AgreementAdminPoa',
  'AgreementDanica',
  'AgreementCustom',
  'AgreementEuroAccident',
  'AgreementRequestEuroAccident',
  'AgreementNordea',
  'AgreementRequestNordea',
  'AgreementNordnet',
];

/**
 * The valid agreement statuses that we can display in the UI.
 *
 * Other agreement types are mostly a remnant of old implementations and
 * are not being set by backend anymore
 */
export type ValidAgreementStatus =
  | AgreementStatus.ACTIVE
  | AgreementStatus.PREPARATION
  | AgreementStatus.INTERNAL_ERROR;

const isValidAgreementStatus = (
  status: AgreementStatus,
): status is ValidAgreementStatus =>
  status === AgreementStatus.ACTIVE ||
  status === AgreementStatus.PREPARATION ||
  status === AgreementStatus.INTERNAL_ERROR;

const AGREEMENT_STATUS_COLORS: Record<ValidAgreementStatus, string> = {
  [AgreementStatus.ACTIVE]: 'green',
  [AgreementStatus.PREPARATION]: 'orange',
  [AgreementStatus.INTERNAL_ERROR]: 'error',
};

export const Agreements: React.FC = () => {
  const {
    params: { companyId: id },
  } = useRouteMatch<CompanyMatchParams>();
  const location = useLocation();

  const { loading, data, error } = useQuery<
    agreementsListQuery,
    agreementsListQueryVariables
  >(AGREEMENTS_LIST_QUERY, {
    errorPolicy: 'all',
    variables: { id },
  });

  if (loading) {
    return <TopLoading />;
  }

  const agreements = data?.company?.agreements.filter(
    agreement =>
      isValidAgreementStatus(agreement.status) &&
      SIGNABLE_AGREEMENT_TYPES.includes(agreement.__typename ?? ''),
  );

  if (!agreements?.length) {
    return (
      <EmptyState
        error={error}
        title={<FormattedMessage {...agreementMessages.noAgreements} />}
        actions={
          <LinkButton to={getAddAgreementLink({ location })}>
            <FormattedMessage {...companyMessages.addAgreements} />
          </LinkButton>
        }
      >
        <FormattedMessage {...agreementMessages.noAgreementsText} />
      </EmptyState>
    );
  }

  return (
    <Section>
      {error && <GraphQlError error={error} />}
      <ChipsWrapper>
        <AssistChip
          text={<FormattedMessage {...companyMessages.addAgreements} />}
          leadingIcon={addCircle}
          to={getAddAgreementLink({ location })}
        />
      </ChipsWrapper>
      <Table>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...agreementMessages.agreement} />
            </Th>
            <Th>
              <FormattedMessage {...agreementMessages.status} />
            </Th>
            <Th multiline>
              <FormattedMessage {...agreementMessages.document} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {agreements.map(agreement => (
            <Tr key={agreement.id}>
              <Th multiline>
                <AgreementName agreement={agreement} />
              </Th>
              <Td multiline>
                <Dot
                  // We default the color to 'error' (red) in case the status is not
                  // found in the AGREEMENT_STATUS_COLORS object to indicate
                  // to the user that something is wrong.
                  colorValue={
                    select({
                      params: {
                        key: agreement.status,
                        record: AGREEMENT_STATUS_COLORS,
                        user: 'backstage',
                      },
                      report: Reporter.report,
                      shouldReportError:
                        window.env.ERROR_REPORTING_ACTIVE === 'true',
                    }) ?? 'error'
                  }
                  size={0.75}
                  label={
                    <FormattedMessage
                      messages={agreementStatusMessages}
                      select={agreement.status}
                    />
                  }
                />
              </Td>
              <Td multiline>
                {agreement.attachments.length ? (
                  <AgreementAttachmentList
                    attachments={agreement.attachments}
                  />
                ) : (
                  <NoValue />
                )}
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Section>
  );
};
