import { FieldAttributes, useField } from '@frontend/formik';
import { FieldTags as FieldTagsComponent } from 'components/FieldTags';
import React from 'react';

interface Props {
  label: React.ReactNode;
  name: string;
}

export const FieldTags: React.FC<FieldAttributes<Props>> = ({
  name,
  label,
}) => {
  const [{ value }, , { setValue }] = useField({ name });

  return <FieldTagsComponent label={label} value={value} setValue={setValue} />;
};
