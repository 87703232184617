import { Formik } from '@frontend/formik';
import { InternalPostStatus } from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { IntlShape, useIntl } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { isValidDate } from 'validations';
import * as Yup from 'yup';

import { MatchParams } from '../..';
import { Details } from '../components/Details';
import { Form, FormValues } from '../components/Form';
import { internalNewsMessages } from '../messages';
import { getPreviewDetails } from '../utils/get-preview-details';
import { useSubmit } from './utils/use-submit';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    preamble: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    body: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    status: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    publicationDate: Yup.date().test(
      'valid date',
      intl.formatMessage(validationMessages.invalidDate),
      value => isValidDate(value?.toISOString()),
    ),
  });

export const Create: React.FC = () => {
  const intl = useIntl();
  const { path, url } = useRouteMatch<MatchParams>();
  const { submit, error } = useSubmit();

  const initialValues: FormValues = {
    title: '',
    preamble: '',
    body: '',
    status: InternalPostStatus.PUBLIC,
    important: false,
    benefitPackages: {},
    organizationalUnits: {},
    publicationDate: new Date().toISOString().substring(0, 10),
  };

  return (
    <NavigationAnchor
      title={intl.formatMessage(internalNewsMessages.createNews)}
      url={url}
    >
      <Formik
        validationSchema={validationSchema(intl)}
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
      >
        {({ values }) => (
          <Switch>
            <Route path={`${path}/preview`}>
              <Details internalNews={getPreviewDetails(values)} />
            </Route>
            <Route path={path}>
              <Form submissionError={error} />
            </Route>
          </Switch>
        )}
      </Formik>
    </NavigationAnchor>
  );
};
