import { A, Chip, Section, SectionHeader } from '@frontend/ui';
import { description } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { EmptyState } from 'components/EmptyState';
import { FeaturePageLayout } from 'components/FeaturePageLayout';
import { FileInfo } from 'components/FileUploadTable';
import { FormattedDate, FormattedMessage } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { MatchParams } from 'features/companies/company';
import React from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

const StyledA = styled(A)`
  padding-right: 0.75rem;
`;

export interface Details {
  attachments: FileInfo[];
  body: string;
  description: string;
  publishedAt: string;
  tags: readonly string[];
  title: string;
  hero?: string;
}

interface Props {
  internalNews: Details;
}

export const Details: React.FC<Props> = ({ internalNews }) => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  if (!internalNews) {
    return <EmptyState />;
  }

  const {
    title,
    publishedAt,
    description: newsDescription,
    hero,
    body,
    attachments,
  } = internalNews;

  const chips = internalNews.tags?.length
    ? internalNews.tags.map((tag, i) => <Chip key={i} text={tag} />)
    : null;

  return (
    <FeaturePageLayout
      title={title}
      overline={<FormattedDate value={publishedAt} />}
      description={newsDescription}
      heroImage={hero}
      parentLink={`/companies/${companyId}/internal-news`}
      chips={chips}
      withinPage
    >
      <Section>
        <TextGrid>
          <div
            className="with-presentation-text-styles"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </TextGrid>
      </Section>
      {!!attachments.length && (
        <Section>
          <SectionHeader>
            <FormattedMessage {...commonMessages.attachedFiles} />
          </SectionHeader>
          {attachments &&
            attachments.map(attachment => (
              <StyledA
                icon={description}
                key={attachment.id}
                href={attachment.url}
              >
                {attachment.name}
              </StyledA>
            ))}
        </Section>
      )}
    </FeaturePageLayout>
  );
};
