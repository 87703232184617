import gql from 'graphql-tag';

import { internalPostDetails } from '../../graphql/fragments';

export const CREATE_INTERNAL_POST_MUTATION = gql`
  mutation createInternalPostMutation($input: CreateInternalPostInput!) {
    createInternalPost(input: $input) {
      internalPost {
        ...internalPostDetails
      }
    }
  }
  ${internalPostDetails}
`;
