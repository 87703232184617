import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  MembershipKey,
  sendMeetingInvitationsMutation,
  sendMeetingInvitationsMutationVariables,
} from 'app/apollo/graphql/types';
import { advisorMessages } from 'app/messages/advisor';
import { useIntl } from 'components/formats';
import {
  EMPLOYEES_WITH_PERSONAL_ADVICE_QUERY,
  MEETING_STATISTICS_QUERY,
} from 'features/advisor/graphql/queries';
import { useNotification } from 'features/notifications';

import { SEND_MEETING_INVITATIONS_MUTATION } from '../../graphql/mutations';
import { SendFormValues } from '..';

export interface SendInvitationEmployee {
  membershipId: string;
}

interface Props {
  membershipKeys: Array<MembershipKey>;
  onRequestClose: () => void;
}

interface Submit {
  loading: boolean;
  submit: (
    values?: SendFormValues,
    helpers?: FormikHelpers<SendFormValues>,
  ) => void;
  error?: ApolloError;
}

export const useSubmit = ({
  membershipKeys,
  onRequestClose,
}: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [
    sendInvitations,
    { error: sendInvitationsError, loading: sendInvitationsLoading },
  ] = useMutation<
    sendMeetingInvitationsMutation,
    sendMeetingInvitationsMutationVariables
  >(SEND_MEETING_INVITATIONS_MUTATION, {
    refetchQueries: [
      EMPLOYEES_WITH_PERSONAL_ADVICE_QUERY,
      MEETING_STATISTICS_QUERY,
    ],
  });

  const callback = async (values, helpers) => {
    try {
      await sendInvitations({
        variables: {
          input: {
            membershipKeys:
              membershipKeys.length > 0
                ? membershipKeys.map(({ userAccountId, companyId }) => ({
                    userAccountId,
                    companyId,
                  }))
                : [
                    {
                      userAccountId: values.userAccountId,
                      companyId: values.companyId,
                    },
                  ],
            eventTypeUri: values.eventType,
          },
        },
      });

      send({
        message: formatMessage(advisorMessages.invitationsSent),
        type: 'success',
      });

      onRequestClose();
    } catch (error) {
      helpers.setErrors({
        submissionError: error?.message,
      });
    }
  };

  return {
    submit: callback,
    loading: sendInvitationsLoading,
    error: sendInvitationsError,
  };
};
