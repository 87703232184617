import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const INVOICE_LINES_QUERY = gql`
  query invoiceLinesQuery(
    $companyId: String!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $userAccountIds: [String]
    $costCenterIdentifiers: [String!]
    $suppliers: [InvoiceSupplier!]
    $taxClass: TaxClass
    $pensionLayer: PensionLayer
    $linePeriod: LineDateFilter
    $issueDate: DateFilter
    $dueDate: DateFilter
    $benefitTypes: [BenefitType!]
    $benefitPackageId: String
  ) {
    invoiceLineSearch(
      companyId: $companyId
      first: $first
      last: $last
      after: $after
      before: $before
      userAccountIds: $userAccountIds
      costCenterIdentifiers: $costCenterIdentifiers
      suppliers: $suppliers
      taxClass: $taxClass
      pensionLayer: $pensionLayer
      linePeriod: $linePeriod
      issueDate: $issueDate
      dueDate: $dueDate
      benefitTypes: $benefitTypes
      benefitPackageId: $benefitPackageId
    ) {
      invoiceLines {
        edges {
          node {
            invoiceId
            lineIndex
            name
            firstName
            lastName
            quantity
            naturalPersonIdentifier
            startDate
            endDate
            supplier
            priceAmount
            vat
            totalAmountCurrency
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
        totalCount
      }
      excelUrl: url(format: XLSX)
      csvUrl: url(format: CSV)
    }
  }
  ${pageInfoDetails}
`;
