import { Section } from '@frontend/ui';
import {
  internalNewsOverviewQuery,
  internalNewsOverviewQueryVariables,
} from 'app/apollo/graphql/types';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LinkButton } from 'components/LinkButton';
import { TopLoading } from 'components/TopLoading';
import { useCurrentUser } from 'contexts/current-permissions';
import { internalNewsMessages } from 'features/companies/company/internal-news/messages';
import qs from 'query-string';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '..';
import { Table } from './components/Table';
import { updateQuery } from './components/Table/utils/update-query';
import { INTERNAL_NEWS_OVERVIEW_QUERY } from './graphql/queries';
import { hasInternalNewsPermissions } from './utils/has-internal-news-permissions';

export const Overview: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();
  const { search, per_page } = qs.parse(location.search);

  const { permissions } = useCurrentUser();
  const { canUpdate } = hasInternalNewsPermissions(permissions);

  const { data, previousData, loading, error, fetchMore } = useQuery<
    internalNewsOverviewQuery,
    internalNewsOverviewQueryVariables
  >(INTERNAL_NEWS_OVERVIEW_QUERY, {
    variables: {
      companyId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
      search,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  if (!_data && loading) {
    return <TopLoading />;
  }

  const internalNewsData = _data?.company?.internalPosts;
  const internalNews = internalNewsData?.edges.map(({ node }) => node) ?? [];
  const pageInfo = internalNewsData?.pageInfo ?? {
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  };

  if (!search && !loading && !internalNews?.length) {
    return (
      <EmptyState
        actions={
          canUpdate ? (
            <LinkButton to={`/companies/${companyId}/internal-news/create`}>
              <FormattedMessage {...internalNewsMessages.createNews} />
            </LinkButton>
          ) : null
        }
        title={<FormattedMessage {...internalNewsMessages.noInternalNews} />}
        error={error}
      >
        <FormattedMessage {...internalNewsMessages.noInternalNewsText} />
      </EmptyState>
    );
  }

  return (
    <Section>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <Table
        internalNews={internalNews}
        pageInfo={pageInfo}
        onNextPage={() => {
          fetchMore({
            variables: {
              after: pageInfo.endCursor,
            },
            updateQuery,
          });
        }}
        onPreviousPage={() => {
          fetchMore({
            variables: {
              before: pageInfo.startCursor,
              first: undefined,
              last: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
            },
            updateQuery,
          });
        }}
      />
    </Section>
  );
};
