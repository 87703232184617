import { useMutation } from '@apollo/client';
import {
  addCompanyBillingConfigurationMutation,
  addCompanyBillingConfigurationMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { ADD_COMPANY_BILLING_CONFIGURATION_MUTATION } from '../../graphql/mutations';
import { COMPANY_BILLING_CONFIGURATION_QUERY } from '../../graphql/queries';
import { billingMessages } from '../../messages';
import { BillingEmailModal, FormValues } from '../modal';

export const openAddBillingEmailModal = { add: true };

export const AddBillingEmailModal: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();
  const { add: isOpen } = qs.parse(search);

  const [addCompanyBillingConfiguration, { error }] = useMutation<
    addCompanyBillingConfigurationMutation,
    addCompanyBillingConfigurationMutationVariables
  >(ADD_COMPANY_BILLING_CONFIGURATION_MUTATION, {
    refetchQueries: [
      {
        query: COMPANY_BILLING_CONFIGURATION_QUERY,
        variables: { companyId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const onRequestClose = () => push(pathname);

  if (!isOpen) {
    return null;
  }

  return (
    <BillingEmailModal
      isOpen
      error={error}
      onRequestClose={onRequestClose}
      label={formatMessage(billingMessages.addBillingEmail)}
      onSubmit={async (formValues: FormValues) => {
        const res = await addCompanyBillingConfiguration({
          variables: {
            input: {
              companyId,
              ...formValues,
            },
          },
        });

        if (!res?.data?.addCompanyBillingConfiguration) {
          throw new Error();
        }

        send({
          type: 'success',
          message: formatMessage(billingMessages.addBillingEmailSuccess),
        });
        onRequestClose();
      }}
    />
  );
};
