import {
  companyLayoutQuery,
  companyLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { COMPANY_LAYOUT_QUERY } from 'app/queries';
import { companyName } from 'app/utils/company-name';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

import { CompanyRoute } from './_index';
import { BenefitPackages } from './benefit-packages';
import { Employees } from './employees';
import { InternalNewsRoute } from './internal-news';
import { Invoices } from './invoices';
import { JobOffers } from './job-offers';
import { Notes } from './notes';
import { Notifications } from './notifications';
import { Reports } from './reports';
import { SalaryExchange } from './salary-exchange';
import { Settings } from './settings';

export interface MatchParams {
  companyId: string;
}

export const CompanyRoutes: React.FC<RouteComponentProps<MatchParams>> = ({
  match,
}) => {
  const { companyId } = match.params;

  const { data, loading, error } = useQuery<
    companyLayoutQuery,
    companyLayoutQueryVariables
  >(COMPANY_LAYOUT_QUERY, {
    variables: {
      companyId,
    },
    skip: !companyId,
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <EmptyStatePage error={error} parentLink="/companies" />;
  }

  const { company } = data;
  const title = companyName(company);

  return (
    <NavigationAnchor title={title} url={match.url}>
      <Switch>
        <Route exact path={match.path}>
          <CompanyRoute
            title={title}
            registrationNumber={company.registrationNumber}
          />
        </Route>
        <Route path={`${match.path}/reports`} component={Reports} />
        <Route path={`${match.path}/settings`} component={Settings} />
        <Route path={`${match.path}/invoices`} component={Invoices} />
        <Route path={`${match.path}/notifications`} component={Notifications} />
        <Route
          path={`${match.path}/benefit-packages`}
          component={BenefitPackages}
        />
        <Route path={`${match.path}/employees`} component={Employees} />
        <Route
          path={`${match.path}/salary-exchanges`}
          component={SalaryExchange}
        />
        <Route path={`${match.path}/job-offers`} component={JobOffers} />
        <Route path={`${match.path}/notes`} component={Notes} />
        <Route
          path={`${match.path}/internal-news`}
          component={InternalNewsRoute}
        />
      </Switch>
    </NavigationAnchor>
  );
};
