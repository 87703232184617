import { Table, Td, Th, Tr } from '@frontend/ui';
import { addCircle, download } from '@frontend/ui/icons';
import {
  companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership as Employee,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employeeFormMessages, employeeMessages } from 'app/messages/employees';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { ScrollableSet } from 'components/scrollable/Set';
import { SearchField } from 'components/SearchField';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { EmployeeTableRow } from '../EmployeeTableRow';
import { IncludeTerminatedFilter } from '../IncludeTerminatedFilter';

interface Props {
  employees: Employee[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
  hideAddEmployee?: boolean;
  membershipExportUrl?: string | null;
}

const EmployeeTableColgroup = () => (
  <colgroup>
    <col style={{ width: '30%' }} />
    <col style={{ width: '12%' }} />
    <col style={{ width: '18%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '10%' }} />
  </colgroup>
);

export const EmployeesTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  employees,
  hideAddEmployee,
  membershipExportUrl,
}) => {
  const { push } = useHistory();
  const { url } = useRouteMatch();

  return (
    <>
      <SearchField label={<FormattedMessage {...employeeMessages.search} />} />
      <ChipsWrapper belowSearch>
        <ScrollableSet>
          <IncludeTerminatedFilter />
          {!hideAddEmployee && (
            <AssistChip
              text={<FormattedMessage {...employeeFormMessages.addEmployee} />}
              leadingIcon={addCircle}
              onClick={() => push(`${url}/add`)}
            />
          )}
          {employees.length > 0 && membershipExportUrl && (
            <AssistChip
              text={
                <FormattedMessage {...employeeFormMessages.exportEmployees} />
              }
              leadingIcon={download}
              onClick={() => {
                window.location.href = membershipExportUrl;
              }}
            />
          )}
        </ScrollableSet>
      </ChipsWrapper>
      <Table
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
        }
        fixed
      >
        {employees.length === 0 ? (
          <tbody>
            <Tr>
              <Td>
                <FormattedMessage {...employeeMessages.noEmployeeMatch} />
              </Td>
            </Tr>
          </tbody>
        ) : (
          <>
            <EmployeeTableColgroup />
            <thead>
              <Tr>
                <Th>
                  <FormattedMessage {...commonMessages.userAccountId} />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.id} />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.name} />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.status} />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.benefitPackage} />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.monthlySalary} />
                </Th>
              </Tr>
            </thead>
            <tbody>
              {employees.map(employee => (
                <EmployeeTableRow
                  key={employee.userAccountId}
                  employee={employee}
                />
              ))}
            </tbody>
          </>
        )}
      </Table>
    </>
  );
};
