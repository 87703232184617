import { ApolloError, useMutation } from '@apollo/client';
import {
  updateMembershipRolesMutation,
  updateMembershipRolesMutationVariables,
} from 'app/apollo/graphql/types';
import { companyMessages } from 'app/messages/company';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';

import { UPDATE_MEMBERSHIP_ROLES_MUTATION } from '../../graphql/mutations';

interface Delete {
  _delete: (userAccountId: string) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

interface Params {
  companyId: string;
}

export const useDelete = ({ companyId }: Params): Delete => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const { confirm } = useConfirm();

  const [updateMembershipRoles, { error, loading }] = useMutation<
    updateMembershipRolesMutation,
    updateMembershipRolesMutationVariables
  >(UPDATE_MEMBERSHIP_ROLES_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${companyId}`,
        fieldName: 'administrators',
      });
      cache.evict({
        id: `Company: ${companyId}`,
        fieldName: 'memberships',
      });
      cache.gc();
    },
  });

  const _delete = async (userAccountId: string) => {
    const confirmed = await confirm({
      description: formatMessage(companyMessages.confirmRemoveAdministrator),
    });
    if (!confirmed) {
      return;
    }
    await updateMembershipRoles({
      variables: {
        userAccountId,
        companyId,
        roles: [],
      },
    });

    send({
      type: 'success',
      message: formatMessage(companyMessages.administratorRemoveSuccess),
    });
  };

  return {
    _delete,
    loading,
    error,
  };
};
