import { Formik } from '@frontend/formik';
import { contactPage } from '@frontend/ui/icons';
import {
  smeCompanyConvertRiskQuery,
  smeCompanyConvertRiskQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { ConvertRiskForm, FormValues } from './form';
import { SME_COMPANY_CONVERT_RISK_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

const ErrorWrapper = styled.div`
  margin-top: 1rem;
`;

export const ConvertRisk: React.FC = () => {
  const { params } = useRouteMatch<MatchParams>();
  const { submit, submissionErrors } = useSubmit({ params });

  const { data } = useQuery<
    smeCompanyConvertRiskQuery,
    smeCompanyConvertRiskQueryVariables
  >(SME_COMPANY_CONVERT_RISK_QUERY, {
    errorPolicy: 'all',
    suspend: true,
    variables: { proposalId: params.proposalId },
  });

  if (!data?.proposal?.memberships) {
    return null;
  }

  const { memberships } = data.proposal;

  if (!memberships.length) {
    return (
      <EmptyState
        title={<FormattedMessage {...smeEmployeesMessages.noEmployeesTitle} />}
        icon={contactPage}
      >
        <FormattedMessage {...smeEmployeesMessages.noEmployeesDescription} />
      </EmptyState>
    );
  }

  const initialValues = memberships.reduce<FormValues>(
    (acc, { id, convertRiskInsurances }) => ({
      ...acc,
      [id]: convertRiskInsurances,
    }),
    {},
  );

  return (
    <>
      <Formik<FormValues> initialValues={initialValues} onSubmit={submit}>
        <ConvertRiskForm employees={memberships} />
      </Formik>
      {submissionErrors && (
        <ErrorWrapper>
          <GraphQlErrors errors={submissionErrors} />
        </ErrorWrapper>
      )}
    </>
  );
};

type Props = Pick<LinkProps, 'to'>;

export const EditConvertRiskAssistChip: React.FC<Props> = ({ to }) => {
  const isReadonly = useProposalReadonly();

  return (
    <AssistChip
      text={<FormattedMessage {...smeEmployeesMessages.editConvertRisk} />}
      leadingIcon={contactPage}
      to={to}
      disabled={isReadonly}
    />
  );
};
