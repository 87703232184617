import {
  DescriptionList,
  DescriptionListEntry,
  Dot,
  Section,
  SectionHeader,
} from '@frontend/ui';
import { riskInsurancesAdminQuery_membership_Membership_insurances_InsuranceConnection_edges_InsuranceEdge_node_Insurance_components_InsuranceComponentHealthCare as Component } from 'app/apollo/graphql/types';
import {
  commonBenefitMessages,
  healthBenefitMessages,
} from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { insuranceMessages } from 'app/messages/insurance';
import { insuranceComponentStatusMessages } from 'app/messages/status';
import { MEMBERSHIP_INSURANCE_COMPONENT_STATUS_COLOR } from 'app/utils/constants';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { getInsurancePremium } from '../../get-insurance-premium';

interface Props {
  data: Component;
}

export const HealthInsurance: React.FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  const healthData: DescriptionListEntry[] = [
    {
      id: 'healthInsurance.status',
      label: <FormattedMessage {...commonMessages.status} />,
      value:
        data.insurance.deletedAt != null ? (
          <Dot
            colorValue="error"
            size={0.75}
            label={<FormattedMessage {...insuranceMessages.terminated} />}
          />
        ) : data.status ? (
          <Dot
            colorValue={
              MEMBERSHIP_INSURANCE_COMPONENT_STATUS_COLOR[data.status]
            }
            size={0.75}
            label={
              <FormattedMessage
                select={data.status}
                messages={insuranceComponentStatusMessages}
              />
            }
          />
        ) : (
          <NoValue />
        ),
    },
    {
      id: 'healthInsurance.healthLevel',
      label: <FormattedMessage {...healthBenefitMessages.healthLevel} />,
      value: data.copayment ? (
        <FormattedCurrency
          currency={data.copayment.currency}
          value={data.copayment.value}
          noParse
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'healthInsurance.premium',
      label: <FormattedMessage {...commonMessages.premium} />,
      value: getInsurancePremium(data.insurance),
    },
    {
      id: 'healthInsurance.insuranceProvider',
      label: <FormattedMessage {...commonMessages.insuranceProvider} />,
      value: data.insurance.institute.name ?? <NoValue />,
    },
    {
      id: 'healthInsurance.insuranceNumber',
      label: <FormattedMessage {...commonMessages.insuranceNumber} />,
      value: data.insuranceNumber ?? <NoValue />,
    },
    {
      id: 'healthInsurance.effectiveDate',
      label: <FormattedMessage {...commonMessages.effectiveFrom} />,
      value: data.insurance.effectiveDate ? (
        <FormattedDate value={data.insurance.effectiveDate} />
      ) : (
        <NoValue />
      ),
    },
  ];

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonBenefitMessages.health} />
      </SectionHeader>
      <DescriptionList alignLeft entries={healthData} />
    </Section>
  );
};
