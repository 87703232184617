import { useEffect, useRef, useState } from 'react';

/**
 * @returns `value` as of the last render where `dependency` changed or `value` received
 * its initial value.
 */
export const usePersistedValue = <T>(value: T, dependency: unknown): T => {
  const [persistedValue, setPersistedValue] = useState(value);
  const lastDependency = useRef(dependency);

  useEffect(() => {
    if (lastDependency.current !== dependency || persistedValue == null) {
      setPersistedValue(value);
      lastDependency.current = dependency;
    }
  }, [persistedValue, value, dependency]);

  return persistedValue;
};
