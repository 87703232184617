import {
  DatePickerField,
  Form,
  Formik,
  RadioGroupField,
  SelectField,
} from '@frontend/formik';
import { Button, Section, Subsection } from '@frontend/ui';
import {
  createNlpAgreementQuery,
  createNlpAgreementQueryVariables,
  NLPFundFeeDiscountType,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { LinkButton } from 'components/LinkButton';
import { NotificationCard } from 'components/NotificationCard';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router';
import * as Yup from 'yup';

import {
  agreementMessages,
  nlpFundFeeDiscountTypeMessages,
} from '../../messages';
import { EDIT_COMPANY_SEARCH_PARAM } from './edit-company-details';
import { CREATE_NLP_AGREEMENT_QUERY } from './graphql/queries';
import { getFundCapitalFeeOptions } from './utils/get-fund-capital-fee-options';
import { getTradCapitalFeeOptions } from './utils/get-trad-capital-fee-options';
import { isAllowedToCreateNlpAgreement } from './utils/is-allowed-to-create-nordea-agreement';
import { useSubmit } from './utils/use-submit';

export enum AnnualFeeType {
  Yes = 'Yes',
  No = 'No',
}

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    capitalFeeTrad: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    capitalFeeFund: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    capitalFeePortfolio: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    annualFeeFund: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    annualFeePortfolio: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    annualFeeTrad: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    startDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  annualFeeFund: AnnualFeeType | '';
  annualFeePortfolio: AnnualFeeType | '';
  annualFeeTrad: AnnualFeeType | '';
  capitalFeeFund: string;
  capitalFeePortfolio: string;
  capitalFeeTrad: string;
  fundFeeDiscountType: NLPFundFeeDiscountType | '';
  startDate: string;
}

export const AddNlpAgreementForm: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { submit, error: submissionError } = useSubmit();

  const { data, loading, error } = useQuery<
    createNlpAgreementQuery,
    createNlpAgreementQueryVariables
  >(CREATE_NLP_AGREEMENT_QUERY, {
    errorPolicy: 'all',
    variables: { companyId },
  });

  const fundCapitalFeeOptions = getFundCapitalFeeOptions(intl);
  const tradCapitalFeeOptions = getTradCapitalFeeOptions(intl);

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <EmptyState error={error} />;
  }

  const isAllowed = isAllowedToCreateNlpAgreement(data.company);
  const {
    salaryReviewMonth,
    accountClosureMonth,
    paymentMethod,
    clearingCode,
    nlpSalesRep,
  } = data.company;

  return (
    <Section>
      {!isAllowed && (
        <NotificationCard
          type="warning"
          title={
            <FormattedMessage {...agreementMessages.missingCompanyDetails} />
          }
          actions={
            <LinkButton
              replace
              to={{
                pathname: location.pathname,
                search: `?${EDIT_COMPANY_SEARCH_PARAM}`,
              }}
            >
              <FormattedMessage {...agreementMessages.editCompanyDetails} />
            </LinkButton>
          }
        >
          <p>
            <FormattedMessage
              {...agreementMessages.missingCompanyDetailsDescription}
            />
          </p>
          <ul>
            {!salaryReviewMonth && (
              <li>
                <FormattedMessage {...agreementMessages.salaryReviewMonth} />
              </li>
            )}
            {!accountClosureMonth && (
              <li>
                <FormattedMessage {...agreementMessages.accountClosureMonth} />
              </li>
            )}
            {!paymentMethod && (
              <li>
                <FormattedMessage {...agreementMessages.paymentMethod} />
              </li>
            )}
            {!clearingCode && (
              <li>
                <FormattedMessage {...agreementMessages.clearingCode} />
              </li>
            )}
            {!nlpSalesRep && (
              <li>
                <FormattedMessage {...agreementMessages.nlpSalesRep} />
              </li>
            )}
          </ul>
        </NotificationCard>
      )}
      {error && <GraphQlError error={error} />}

      <Formik<FormValues>
        validationSchema={validationSchema(intl)}
        initialValues={{
          capitalFeeFund: '',
          capitalFeePortfolio: '',
          capitalFeeTrad: '',
          annualFeeFund: '',
          annualFeePortfolio: '',
          annualFeeTrad: '',
          fundFeeDiscountType: '',
          startDate: '',
        }}
        onSubmit={submit}
      >
        {({
          isSubmitting,
          values: { capitalFeeFund, capitalFeePortfolio },
        }) => {
          const feeWarning = capitalFeeFund !== capitalFeePortfolio;

          return (
            <Form noValidate>
              <Subsection>
                <TextGrid>
                  <DescriptionWrapper>
                    <FormattedMessage {...agreementMessages.feesDescription} />
                  </DescriptionWrapper>

                  <SelectField
                    dense
                    label={formatMessage(
                      agreementMessages.nlpFundFeeDiscountType,
                    )}
                    name="fundFeeDiscountType"
                    options={Object.keys(NLPFundFeeDiscountType).map(k => ({
                      value: k,
                      label: formatMessage({
                        messages: nlpFundFeeDiscountTypeMessages,
                        select: k,
                      }),
                    }))}
                  />
                  <DatePickerField
                    dense
                    required
                    type="month"
                    name="startDate"
                    label={formatMessage(agreementMessages.firstLoginDate)}
                    helperText={formatMessage(
                      agreementMessages.firstLoginDateHelper,
                    )}
                  />
                  <SelectField
                    dense
                    required
                    options={fundCapitalFeeOptions}
                    name="capitalFeeFund"
                    label={formatMessage(agreementMessages.capitalFeeFund)}
                    helperText={
                      feeWarning && (
                        <FormattedMessage
                          {...agreementMessages.portfolioAndFundFeeMismatch}
                        />
                      )
                    }
                    warning={feeWarning}
                  />
                  <RadioGroupField
                    required
                    name="annualFeeFund"
                    label={formatMessage(agreementMessages.annualFeeFund)}
                    options={[
                      {
                        value: AnnualFeeType.Yes,
                        label: formatMessage(commonMessages.yes),
                      },
                      {
                        value: AnnualFeeType.No,
                        label: formatMessage(commonMessages.no),
                      },
                    ]}
                  />
                  <SelectField
                    dense
                    required
                    options={fundCapitalFeeOptions}
                    name="capitalFeePortfolio"
                    label={formatMessage(agreementMessages.capitalFeePortfolio)}
                    helperText={
                      feeWarning && (
                        <FormattedMessage
                          {...agreementMessages.portfolioAndFundFeeMismatch}
                        />
                      )
                    }
                    warning={feeWarning}
                  />
                  <RadioGroupField
                    required
                    name="annualFeePortfolio"
                    label={formatMessage(agreementMessages.annualFeePortfolio)}
                    options={[
                      {
                        value: AnnualFeeType.Yes,
                        label: formatMessage(commonMessages.yes),
                      },
                      {
                        value: AnnualFeeType.No,
                        label: formatMessage(commonMessages.no),
                      },
                    ]}
                  />
                  <SelectField
                    dense
                    required
                    options={tradCapitalFeeOptions}
                    name="capitalFeeTrad"
                    label={formatMessage(agreementMessages.capitalFeeTrad)}
                  />
                  <RadioGroupField
                    required
                    name="annualFeeTrad"
                    label={formatMessage(agreementMessages.annualFeeTrad)}
                    options={[
                      {
                        value: AnnualFeeType.Yes,
                        label: formatMessage(commonMessages.yes),
                      },
                      {
                        value: AnnualFeeType.No,
                        label: formatMessage(commonMessages.no),
                      },
                    ]}
                  />
                </TextGrid>
              </Subsection>
              {submissionError && <GraphQlError error={submissionError} />}
              <Button type="submit" loading={isSubmitting} filled>
                <FormattedMessage {...agreementMessages.add} />
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Section>
  );
};
