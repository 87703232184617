import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const BENEFIT_PACKAGE_HISTORY_QUERY = gql`
  query benefitPackageHistoryQuery(
    $benefitPackageId: ID!
    $companyId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    company(id: $companyId) {
      id
      startDate
    }
    benefitPackage(id: $benefitPackageId) {
      id
      createdAt
      actor {
        displayName
      }
      benefitsHistory(
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            benefitId
            action
            benefitTypeName
            createdAt
            effectiveDate
            actor {
              displayName
            }
          }
        }
      }
    }
  }
  ${pageInfoDetails}
`;
