import { PersonalAdvice } from '@frontend/benefit-types';
import { DescriptionList, DescriptionListEntry } from '@frontend/ui';
import {
  BenefitPackage,
  FlexEntitlementRules,
  MiscConfiguration,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { DetailCell } from 'components/DetailCell';
import { FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';

import { InBenefitPackage } from '../../../components/BenefitsTable/InBenefitPackage';
import {
  benefitFormMessages,
  benefitPersonalAdviceMeetingFrequencyMessages,
  benefitPersonalAdvicePaymentMethodMessages,
  personalAdviceFeeDetailsMessages,
} from '../../../form/messages';
import { formatPersonalAdviceMeetingFrequencyMonthsFormValue } from '../../../form/utils';

interface Benefit {
  configuration: MiscConfiguration;
  entitlementRules: Pick<FlexEntitlementRules, 'benefitPackageIds'> | null;
  id: string;
  type: string;
}

interface Props {
  benefit: PersonalAdvice<Benefit>;
  benefitPackageOptions: Pick<BenefitPackage, 'id' | 'name'>[];
}

export const PersonalAdviceBenefit: React.FC<Props> = ({
  benefit,
  benefitPackageOptions,
}) => {
  const { configuration } = benefit;

  const {
    fee,
    paymentMethod,
    onboardingFreePeriodMonths,
    employmentStartFreePeriodMonths,
    meetingFrequencyMonths,
  } = configuration?.data ?? {};

  const entries: DescriptionListEntry[] = [
    {
      id: 'benefit.paymentMethod',
      label: <FormattedMessage {...benefitFormMessages.paymentMethod} />,
      value: paymentMethod ? (
        <DetailCell>
          <p>
            <FormattedMessage
              messages={benefitPersonalAdvicePaymentMethodMessages}
              select={paymentMethod}
            />
          </p>

          {!!fee && paymentMethod !== 'CAPITAL_FEE' && (
            <p>
              <FormattedMessage
                select={paymentMethod}
                messages={personalAdviceFeeDetailsMessages}
                values={{
                  fee,
                }}
              />
            </p>
          )}
        </DetailCell>
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.freeMeeting',
      label: <FormattedMessage {...benefitFormMessages.freeAdviceAtLaunch} />,
      value:
        onboardingFreePeriodMonths || employmentStartFreePeriodMonths ? (
          <DetailCell>
            {!!onboardingFreePeriodMonths && (
              <p>
                <FormattedMessage
                  {...benefitFormMessages.onboadingFreePeriod}
                />
              </p>
            )}
            {!!employmentStartFreePeriodMonths && (
              <p>
                <FormattedMessage
                  {...benefitFormMessages.employmentStartFreePeriod}
                />
              </p>
            )}
          </DetailCell>
        ) : (
          <NoValue />
        ),
    },
    {
      id: 'benefit.meetingFrequency',
      label: (
        <FormattedMessage {...benefitFormMessages.meetingFrequencyMonths} />
      ),
      value: meetingFrequencyMonths ? (
        <FormattedMessage
          messages={benefitPersonalAdviceMeetingFrequencyMessages}
          select={formatPersonalAdviceMeetingFrequencyMonthsFormValue(
            meetingFrequencyMonths,
          )}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.inBenefitPackage',
      label: <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />,
      value: (
        <InBenefitPackage
          benefit={benefit}
          benefitPackageOptions={benefitPackageOptions}
        />
      ),
    },
  ];

  return <DescriptionList alignLeft entries={entries} />;
};
