import { hasBackstagePermission } from 'app/utils/has-backstage-access';
import { Permissions } from 'contexts/current-permissions';

interface hasInternalNewsPermissions {
  canUpdate: boolean;
  hasPermissions: boolean;
}

export const hasInternalNewsPermissions = (
  permissions?: Permissions,
): hasInternalNewsPermissions => {
  const canRead =
    !!permissions &&
    !!hasBackstagePermission('internalPosts', 'read', permissions);
  const canUpdate =
    !!permissions &&
    !!hasBackstagePermission('internalPosts', 'write', permissions);

  return {
    canUpdate,
    hasPermissions: canRead || canUpdate,
  };
};
