import {
  missingKycNotificationQuery,
  missingKycNotificationQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/sme/company';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { MISSING_KYC_NOTIFICATION_QUERY } from './graphql/queries';

export const MissingKycNotification: React.FC = () => {
  const [suspend, setSuspend] = useState(true);
  const { params } = useRouteMatch<CompanyMatchParams>();

  const { data } = useQuery<
    missingKycNotificationQuery,
    missingKycNotificationQueryVariables
  >(MISSING_KYC_NOTIFICATION_QUERY, {
    suspend,
    errorPolicy: 'all',
    // This component alone is not meaningful for the page. Therefore we set the isEmpty to
    // return 'true' always, so that the empty state is only controlled by the sibling component(s)
    isEmpty: () => true,
    variables: { companyId: params.companyId },
    onCompleted: () => setSuspend(false),
  });

  if (!data?.company) {
    return null;
  }

  const isMissingKyc = !data.company.validKyc;

  if (!isMissingKyc) {
    return null;
  }

  return (
    <NotificationCard
      type="warning"
      title={
        <FormattedMessage
          {...smeCompanyProposalsMessages.kdkMissingNotificationTitle}
        />
      }
    >
      <FormattedMessage
        {...smeCompanyProposalsMessages.kdkMissingNotificationDescription}
      />
    </NotificationCard>
  );
};
