import { Button } from '@frontend/ui';
import { FormattedMessage } from 'components/formats';
import { jobOfferMessages } from 'features/companies/company/job-offers/messages';
import React, { useEffect, useRef, useState } from 'react';

const CLIPBOARD_SUCCESS_TIMEOUT = 2500;

export const CopyTextButton: React.FC<{
  copyText: string;
  buttonText?: React.ReactNode;
  successText?: React.ReactNode;
}> = ({ copyText, buttonText, successText }) => {
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const copySuccessTimeout = useRef<NodeJS.Timeout>();

  useEffect(
    () => () => {
      if (copySuccessTimeout.current) {
        clearTimeout(copySuccessTimeout.current);
      }
    },
    [copySuccessTimeout],
  );

  const copyToClipBoard = (link: string) => {
    const el = document.createElement('textarea');
    el.value = link;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();

    try {
      document.execCommand('copy');
      setCopySuccess(true);
    } catch {
      setCopySuccess(false);
    }

    document.body.removeChild(el);

    if (copySuccessTimeout) {
      if (copySuccessTimeout.current) {
        clearTimeout(copySuccessTimeout.current);
      }

      copySuccessTimeout.current = setTimeout(() => {
        setCopySuccess(false);
      }, CLIPBOARD_SUCCESS_TIMEOUT);
    }
  };

  if (document.queryCommandSupported('copy')) {
    return (
      <Button text onClick={() => copyToClipBoard(copyText)}>
        {copySuccess
          ? successText ?? <FormattedMessage {...jobOfferMessages.copied} />
          : buttonText ?? <FormattedMessage {...jobOfferMessages.copyLink} />}
      </Button>
    );
  }

  return null;
};
