import { onError } from '@apollo/client/link/error';
import { detect } from 'detect-browser';
import { DefinitionNode, OperationDefinitionNode } from 'graphql';

import { Reporter } from '..';

class InfoError extends Error {
  constructor(
    message: string,
    public info: Record<string, unknown>,
  ) {
    super(message);
  }
}

const isMutationOperationDefinitionNode = (
  node?: DefinitionNode,
): node is OperationDefinitionNode =>
  node?.kind === 'OperationDefinition' && node.operation === 'mutation';

export const getErrorLink = () =>
  onError(({ graphQLErrors, operation }) => {
    const operationDefinition = operation.query.definitions.find(
      isMutationOperationDefinitionNode,
    );

    if (!operationDefinition) {
      return;
    }

    if (graphQLErrors) {
      const browser = detect();

      const operationName = operationDefinition.name?.value ?? 'Mutation';

      graphQLErrors.forEach(({ message }) => {
        const errorMessage = `[${operationName}_error]: Message: ${message}`;

        Reporter.report({
          error: new InfoError(errorMessage, {
            operation: operationName,
            variables: operation.variables,
          }),
          context: {
            httpRequest: {
              url: location.pathname,
              userAgent: `${browser?.os}, ${browser?.name}@${browser?.version}`,
            },
            user: 'backstage',
          },
        });
      });
    }
  });
