import { ibb } from '@advinans/belt-se-constants';
import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  CheckboxGroupField,
  Form,
  NumberField,
  SelectField,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  CalculationLabel,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { roundUpToNearestHundred, toNumber } from '@frontend/utils';
import { ProposalBaseAmountType } from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { suffixMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import {
  FormattedCurrency,
  FormattedMessage,
  FormattedPercent,
  IntlShape,
  useIntl,
} from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50, TextGrid } from 'components/GridCell';
import { NotificationCard } from 'components/NotificationCard';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import React from 'react';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../../utils/use-proposal-readonly';
import { SALARY_MULTIPLIER_OPTIONS } from '../utils/constants';

const INCOME_LIMIT_IBB = 8.07;
const getExtraPremiumFromTaxBenefitShare = (val: number) =>
  (1.3142 / 1.2426 - 1) * (val / 100);

export interface FormValues {
  automaticApproval: boolean;
  benefitPackageIds: string[];
  incomeMinIbb: string;
  ongoingMax: string;
  pensionTaxBenefitShare: string;
  pensionToSalaryMax: string;
  premiumWaiver: boolean;
  retirementAge: string;
  salaryMultiplier: string;
}

interface BenefitPackage {
  id: string;
  name: string;
}

interface Props {
  isSubmitting: boolean;
  isValid: boolean;
  values: FormValues;
  benefitPackages?: readonly BenefitPackage[] | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    incomeMinIbb: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    pensionToSalaryMax: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'max 35%',
        message: intl.formatMessage(validationMessages.max, {
          max: intl.formatNumber(0.35, { style: 'percent' }),
        }),
        test: (value: string) => (toNumber(value) ?? 0) <= 35,
      }),
    ongoingMax: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'max 30 000',
        message: intl.formatMessage(validationMessages.max, {
          max: intl.formatNumber(30000, {
            style: 'currency',
            currency: 'SEK',
            maximumFractionDigits: 0,
          }),
        }),
        test: (value: string) => (toNumber(value) ?? 0) <= 30000,
      }),
    pensionTaxBenefitShare: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    retirementAge: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'at least 55',
        message: intl.formatMessage(smeBenefitFormMessages.minRetirementAge),
        test: (value: string) => (toNumber(value) ?? 0) >= 55,
      }),
    salaryMultiplier: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const SalaryExchangeForm: React.FC<Props> = ({
  benefitPackages,
  isSubmitting,
  isValid,
  submissionError,
  values,
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const { locale } = useIntlContext();
  const disabled = useProposalReadonly();

  const year = new Date().getFullYear();
  const _ibb = ibb(year);

  if (!_ibb) {
    return (
      <NotificationCard type="error">
        <FormattedMessage {...formMessages.queryErrorMessage} />
      </NotificationCard>
    );
  }

  const yearlyThreshold = _ibb * INCOME_LIMIT_IBB;

  return (
    <Form>
      <Section>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.retirementAge} />
          </SubsectionHeader>
          <Grid>
            <GridCell50>
              <NumberField
                affix={formatMessage(suffixMessages.age)}
                allowNegative={false}
                decimalScale={0}
                dense
                gridMargin
                label={
                  <FormattedMessage {...smeBenefitFormMessages.retirementAge} />
                }
                locale={locale}
                name="retirementAge"
                required
                disabled={disabled}
              />
            </GridCell50>
          </Grid>
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.premiumDeduction} />
          </SubsectionHeader>
          <CheckboxField
            label={
              <FormattedMessage
                {...smeBenefitFormMessages.premiumWaiverSalaryExchange}
              />
            }
            name="premiumWaiver"
            disabled={disabled}
          />
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.salaryMultiplier} />
          </SubsectionHeader>
          <Grid>
            <GridCell50>
              <SelectField
                name="salaryMultiplier"
                dense
                label={
                  <FormattedMessage
                    {...smeBenefitFormMessages.salaryMultiplier}
                  />
                }
                options={SALARY_MULTIPLIER_OPTIONS.map(value => ({
                  label: formatNumber(value, {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  }),
                  value,
                }))}
                required
                gridMargin
                disabled={disabled}
              />
            </GridCell50>
          </Grid>
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.ongoingMax} />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...smeBenefitFormMessages.ongoingMaxDescription}
              />
            </DescriptionWrapper>
          </TextGrid>
          <Grid>
            <GridCell50>
              <NumberField
                affix={formatMessage(suffixMessages.krPerMonth)}
                decimalScale={0}
                dense
                helperText={
                  <FormattedMessage
                    {...validationMessages.max}
                    values={{
                      max: <FormattedCurrency value={30000} currency="SEK" />,
                    }}
                  />
                }
                label={formatMessage(smeBenefitFormMessages.ongoingMaxSek)}
                locale={locale}
                name="ongoingMax"
                required
                disabled={disabled}
              />
              <NumberField
                affix={formatMessage(suffixMessages.percentage)}
                decimalScale={0}
                dense
                gridMargin
                helperText={
                  <FormattedMessage
                    {...validationMessages.max}
                    values={{
                      max: <FormattedPercent integer value={0.35} />,
                    }}
                  />
                }
                label={formatMessage(smeBenefitFormMessages.pensionToSalaryMax)}
                locale={locale}
                name="pensionToSalaryMax"
                required
                disabled={disabled}
              />
            </GridCell50>
          </Grid>
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.incomeMinIbbTitle} />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...smeBenefitFormMessages.incomeMinIbbDescription}
                values={{
                  incomeLimitIbb: INCOME_LIMIT_IBB,
                  monthlyThreshold: yearlyThreshold / 12,
                  year,
                  yearlyThreshold,
                }}
              />
            </DescriptionWrapper>
          </TextGrid>
          <NumberField
            affix={ProposalBaseAmountType.IBB}
            decimalScale={2}
            dense
            label={formatMessage(smeBenefitFormMessages.incomeMinIbb)}
            locale={locale}
            name="incomeMinIbb"
            postfix={
              <CalculationLabel
                prefix="="
                label={
                  <FormattedCurrency
                    currency="SEK"
                    value={
                      values.incomeMinIbb
                        ? roundUpToNearestHundred(
                            (toNumber(values.incomeMinIbb) ?? 0) * _ibb,
                          )
                        : 0
                    }
                  />
                }
              />
            }
            required
            disabled={disabled}
          />
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...smeBenefitFormMessages.pensionTaxBenefitShareTitle}
            />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...smeBenefitFormMessages.pensionTaxBenefitShareDescription}
              />
            </DescriptionWrapper>
          </TextGrid>
          <NumberField
            affix={formatMessage(suffixMessages.percentage)}
            decimalScale={0}
            dense
            label={formatMessage(smeBenefitFormMessages.pensionTaxBenefitShare)}
            locale={locale}
            name="pensionTaxBenefitShare"
            postfix={
              <CalculationLabel
                prefix="="
                label={
                  <FormattedMessage
                    {...smeBenefitFormMessages.pensionTaxBenefitShareHelperText}
                    values={{
                      share: getExtraPremiumFromTaxBenefitShare(
                        toNumber(values.pensionTaxBenefitShare) ?? 0,
                      ),
                    }}
                  />
                }
              />
            }
            required
            disabled={disabled}
          />
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...smeBenefitFormMessages.automaticApprovalTitle}
            />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...smeBenefitFormMessages.automaticApprovalDescription}
              />
            </DescriptionWrapper>
          </TextGrid>
          <CheckboxField
            label={
              <FormattedMessage {...smeBenefitFormMessages.automaticApproval} />
            }
            name="automaticApproval"
            disabled={disabled}
          />
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...smeBenefitFormMessages.benefitPackages} />
          </SubsectionHeader>
          {benefitPackages?.length ? (
            <>
              <DescriptionWrapper>
                <FormattedMessage
                  {...smeBenefitFormMessages.benefitPackagesDescription}
                />
              </DescriptionWrapper>

              <CheckboxGroupField
                name="benefitPackageIds"
                options={benefitPackages.map(benefitPackage => ({
                  label: benefitPackage.name,
                  value: benefitPackage.id,
                }))}
                disabled={disabled}
              />
            </>
          ) : (
            <NotificationCard type="warning">
              <FormattedMessage {...commonBenefitMessages.noBenefitPackages} />
            </NotificationCard>
          )}
        </Subsection>
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={disabled || !isValid}
          >
            <FormattedMessage {...smeBenefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
