import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

import { companyBillingConfigurationDetails } from './fragments';

export const COMPANY_BILLING_CONFIGURATION_QUERY = gql`
  query companyBillingConfigurationQuery(
    $companyId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    company(id: $companyId) {
      id
      agreements {
        __typename
        status
      }
      supplierReferenceMapping {
        ADVINANS
        FUTUR
        EURO_ACCIDENT
        NORDNET
        ACTIWAY
        LF
        # NLP
      }
      billingConfigurations(
        first: $first
        after: $after
        last: $last
        before: $before
      ) {
        pageInfo {
          ...pageInfoDetails
        }
        edges {
          node {
            ...companyBillingConfigurationDetails
          }
        }
      }
    }
  }
  ${companyBillingConfigurationDetails}
  ${pageInfoDetails}
`;

export const UPDATE_COMPANY_CONTRACT_REFERENCE_MUTATION = gql`
  mutation updateCompanyContractReferenceMutation(
    $id: ID!
    $contractReference: SupplierReferenceMappingInput!
  ) {
    updateCompany(input: { id: $id, contractReference: $contractReference }) {
      company {
        id
        supplierReferenceMapping {
          ADVINANS
          FUTUR
          EURO_ACCIDENT
          NORDNET
          ACTIWAY
          LF
          # NLP
        }
      }
    }
  }
`;
