import { benefitPackageHistoryQuery } from 'app/apollo/graphql/types';

export const updateBenefitPackageHistoryQuery = (
  previousResult: benefitPackageHistoryQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: benefitPackageHistoryQuery;
  },
) => {
  if (
    !previousResult?.benefitPackage?.benefitsHistory ||
    !fetchMoreResult?.benefitPackage?.benefitsHistory
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.benefitPackage.benefitsHistory;
  return {
    company: previousResult.company,
    benefitPackage: {
      ...previousResult.benefitPackage,
      benefitsHistory: {
        __typename: previousResult.benefitPackage.benefitsHistory.__typename,
        pageInfo,
        edges: [...edges],
      },
    },
  };
};
