import { fixedPremiumHistoryTableQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: fixedPremiumHistoryTableQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: fixedPremiumHistoryTableQuery;
  },
) => {
  if (!previousResult?.fixedPremium || !fetchMoreResult?.fixedPremium) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.fixedPremium;

  return {
    fixedPremium: {
      __typename: previousResult.fixedPremium.__typename,
      edges,
      pageInfo,
    },
  };
};
