import { defineMessages } from 'react-intl';

export const internalNewsMessages = defineMessages({
  createNews: {
    id: 'internalNewsMessages.createNews',
    defaultMessage: 'Skapa artikel',
  },
  noInternalNews: {
    id: 'internalNewsMessages.noInternalNews',
    defaultMessage: 'Det finns inga artiklar att visa',
  },
  noInternalNewsText: {
    id: 'internalNewsMessages.noInternalNewsText',
    defaultMessage: 'När du har skapat artiklar kommer de att visas här.',
  },
  news: {
    id: 'internalNewsMessages.news',
    defaultMessage: 'Artiklar',
  },
  noArticlesMatch: {
    id: 'internalNewsMessages.noArticlesMatch',
    defaultMessage: 'Inga artiklar matchar sökningen',
  },
  important: {
    id: 'internalNewsMessages.important',
    defaultMessage: 'Viktigt',
  },
  searchArticles: {
    id: 'internalNewsMessages.searchArticles',
    defaultMessage: 'Sök artiklar',
  },
  editNews: {
    id: 'internalNewsMessages.editNews',
    defaultMessage: 'Redigera artikel',
  },
  edit: {
    id: 'internalNewsMessages.edit',
    defaultMessage: 'Redigera',
  },
  preview: {
    id: 'internalNewsMessages.preview',
    defaultMessage: 'Granska',
  },
  deleteNews: {
    id: 'internalNewsMessages.deleteNews',
    defaultMessage: 'Ta bort artikel',
  },
});

export const internalNewsFormMessages = defineMessages({
  title: {
    id: 'internalNewsFormMessages.title',
    defaultMessage: 'Rubrik',
  },
  description: {
    id: 'internalNewsFormMessages.description',
    defaultMessage: 'Ingress',
  },
  body: {
    id: 'internalNewsFormMessages.body',
    defaultMessage: 'Brödtext',
  },
  detail: {
    id: 'internalNewsFormMessages.detail',
    defaultMessage: 'Detaljer',
  },
  selectFiles: {
    id: 'internalNewsFormMessages.selectFiles',
    defaultMessage: 'Välj filer',
  },
  attachDocuments: {
    id: 'internalNewsFormMessages.attachDocuments',
    defaultMessage: 'Bifoga filer',
  },
  markAsImportant: {
    id: 'internalNewsFormMessages.markAsImportant',
    defaultMessage: 'Markera som “Viktigt”',
  },
  tags: {
    id: 'internalNewsFormMessages.tags',
    defaultMessage: 'Kategorier',
  },
  publicationStatus: {
    id: 'internalNewsFormMessages.publicationStatus',
    defaultMessage: 'Synlighet',
  },
  published: {
    id: 'internalNewsFormMessages.published',
    defaultMessage: 'Publicerad',
  },
  private: {
    id: 'internalNewsFormMessages.private',
    defaultMessage: 'Ej publicerad',
  },
  benefitPackagesDescription: {
    id: 'internalNewsFormMessages.benefitPackagesDescription',
    defaultMessage:
      'Ska artikeln endast vara tillgängligt för vissa förmånsgrupper väljer du dessa här, annars är de tillgängliga för alla.',
  },
  organizationalUnitsDescription: {
    id: 'internalNewsFormMessages.organizationalUnitsDescription',
    defaultMessage:
      'Ska artikeln endast vara tillgängligt för vissa avdelningar väljer du dessa här, annars är de tillgängliga för alla.',
  },
  publicationDate: {
    id: 'internalNewsFormMessages.publicationDate',
    defaultMessage: 'Publiceringsdatum',
  },
  publicationDateHelperText: {
    id: 'internalNewsFormMessages.publicationDateHelperText',
    defaultMessage: 'Artiklar sorteras fallande efter publiceringsdatum',
  },
  addImage: {
    id: 'internalNewsFormMessages.addImage',
    defaultMessage: 'Välj bild',
  },
  editImage: {
    id: 'internalNewsFormMessages.editImage',
    defaultMessage: 'Ändra bild',
  },
  image: {
    id: 'internalNewsFormMessages.image',
    defaultMessage: 'Bild',
  },
  categorization: {
    id: 'internalNewsFormMessages.categorization',
    defaultMessage: 'Kategorisering',
  },
  categoryErrorMsg: {
    id: 'internalNewsFormMessages.categoryErrorMsg',
    defaultMessage: 'Kategorin finns redan',
  },
  removedOrgUnitPlaceholder: {
    id: 'internalNewsFormMessages.removedOrgUnitPlaceholder',
    defaultMessage: 'Borttagen avdelning',
  },
});
