import { Table, Td, Th, Tr } from '@frontend/ui';
import { getEventHistoryDate, toEffectiveThrough } from '@frontend/utils';
import { versionHistoryTableQuery_flexBenefit_FlexBenefit_changes_FlexBenefitChangesHistoryItemRecord as Change } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { FormattedDate, FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

interface Props {
  changes: readonly Change[];
  companyStartDate?: string | null;
}

export const VersionHistoryTable: React.FC<Props> = ({
  changes,
  companyStartDate,
}) => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();
  return (
    <Table size="xsmall">
      <colgroup>
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '30%' }} />
      </colgroup>
      <thead>
        <Tr>
          <Th type="number" align="left">
            <FormattedMessage {...commonMessages.effectiveDate} />
          </Th>
          <Th type="number">
            <FormattedMessage {...commonMessages.effectiveUntil} />
          </Th>
          <Th type="number">
            <FormattedMessage {...commonMessages.reportingDate} />
          </Th>
          <Th>
            <FormattedMessage {...commonMessages.actor} />
          </Th>
        </Tr>
      </thead>
      <tbody>
        {changes.map((change, index) => (
          <Tr
            key={index}
            linked
            onClick={() =>
              push(
                `/companies/${params.companyId}/benefit-packages/benefits/${params.benefitId}/version-history/${change.from}`,
              )
            }
          >
            <Td type="number" align="left">
              {change.from ? (
                <FormattedDate
                  value={getEventHistoryDate({
                    date: change.from,
                    companyStartDate,
                  })}
                />
              ) : (
                <NoValue />
              )}
            </Td>
            <Td type="number">
              {change.to ? (
                <FormattedDate value={toEffectiveThrough(change.to)} />
              ) : (
                <NoValue />
              )}
            </Td>
            <Td type="number">
              {change.createdAt ? (
                <FormattedDate
                  value={getEventHistoryDate({
                    date: change.createdAt,
                    companyStartDate,
                  })}
                  dateStyle="short"
                  timeStyle="short"
                />
              ) : (
                <NoValue />
              )}
            </Td>
            <Td>{change.actor?.displayName ?? <NoValue />}</Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};
