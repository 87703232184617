import gql from 'graphql-tag';

export const CREATE_COMPANY_MUTATION = gql`
  mutation createCompany2Mutation($input: CreateCompany2Input!) {
    createCompany2(input: $input) {
      company {
        id
      }
    }
  }
`;
