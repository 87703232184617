import { Section, SectionHeader } from '@frontend/ui';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { Employees } from 'features/sme/companies/company/proposals/edit/employees';
import { EditConvertRiskAssistChip } from 'features/sme/companies/company/proposals/edit/employees/convert-risk';
import { CreateEmployeeAssistChip } from 'features/sme/companies/company/proposals/edit/employees/create';
import { EditFcfwAssistChip } from 'features/sme/companies/company/proposals/edit/employees/fcfw';
import { EmployeesSettings } from 'features/sme/companies/company/proposals/edit/employees/settings';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { RouteComponentProps } from 'react-router';

export const SmeCompanyProposalEmployeesRoute: React.FC<
  RouteComponentProps
> = ({ match }) => (
  <Suspense fallback={<TopLoading />} batch>
    {({ errors }) => (
      <>
        <Section>
          {errors && <GraphQlErrors errors={errors} />}
          <SectionHeader>
            <FormattedMessage {...smeEmployeesMessages.createEmployees} />
          </SectionHeader>
          <ChipsWrapper>
            <CreateEmployeeAssistChip to={`${match.url}/create`} />
            <EditFcfwAssistChip to={`${match.url}/fcfw`} />
            <EditConvertRiskAssistChip to={`${match.url}/convert-risk`} />
          </ChipsWrapper>
          <Employees />
        </Section>
        <Section>
          <SectionHeader>
            <FormattedMessage {...smeEmployeesMessages.settings} />
          </SectionHeader>
          <EmployeesSettings />
        </Section>
      </>
    )}
  </Suspense>
);
