import {
  smeCompanyQuery,
  smeCompanyQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useQuery } from 'app/utils/use-query';
import { SME_COMPANY_QUERY } from 'features/sme/companies/company/graphql/queries';
import React, { useState } from 'react';
import { useParams } from 'react-router';

import { InformationTable } from '../components/InformationTable';

export const Company: React.FC = () => {
  const [suspend, setSuspend] = useState(true);

  const { companyId } = useParams<MatchParams>();
  const { data } = useQuery<smeCompanyQuery, smeCompanyQueryVariables>(
    SME_COMPANY_QUERY,
    {
      skip: !companyId,
      variables: {
        id: companyId,
      },
      errorPolicy: 'all',
      suspend,
      onCompleted: () => setSuspend(false),
    },
  );

  if (!data?.company) {
    return null;
  }

  const { company, proposalContactPerson, proposalHeadAdmin } = data;

  return (
    <InformationTable
      company={company}
      proposalContactPerson={proposalContactPerson}
      proposalHeadAdmin={proposalHeadAdmin}
    />
  );
};
