import { ApolloError, useMutation } from '@apollo/client';
import {
  TotalCompensationConfigurationInput,
  UpdateBenefitPackagesVisibilityInput,
  updateTotalCompensationConfigurationMutation,
  updateTotalCompensationConfigurationMutationVariables,
} from 'app/apollo/graphql/types';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '../..';
import { FormValues } from '../form';
import { UPDATE_TOTAL_COMPENSATION_CONFIGURATION_MUTATION } from '../graphql/mutations';

interface Params {
  benefitPackageIds: string[];
}

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = ({ benefitPackageIds }: Params): Submit => {
  const intl = useIntl();

  const { send } = useNotification();

  const { params } = useRouteMatch<MatchParams>();

  const [updateTotalCompConfig, { error: submissionError }] = useMutation<
    updateTotalCompensationConfigurationMutation,
    updateTotalCompensationConfigurationMutationVariables
  >(UPDATE_TOTAL_COMPENSATION_CONFIGURATION_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${params.companyId}`,
        fieldName: 'benefitPackages',
      });
      cache.gc();
    },
  });

  const submit = async (values: FormValues) => {
    try {
      const { benefitPackageIds: checkedBenefitPackageIds, ...configFields } =
        values;

      const benefitPackagesVisibility: UpdateBenefitPackagesVisibilityInput[] =
        benefitPackageIds.map(id => {
          if (checkedBenefitPackageIds?.includes(id)) {
            return { id, showTotalCompensation: true };
          }
          return { id, showTotalCompensation: false };
        });

      const totalCompensationConfig: TotalCompensationConfigurationInput = {
        companyId: params.companyId,
        benefitPackagesVisibility,
        ...configFields,
      };

      await updateTotalCompConfig({
        variables: { input: totalCompensationConfig },
      });

      send({
        type: 'success',
        message: intl.formatMessage(
          totalCompensationMessages.configurationUpdated,
        ),
      });
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
