import { ApolloError, useMutation } from '@apollo/client';
import {
  createEuroAccidentTjpAgreementMutation,
  createEuroAccidentTjpAgreementMutationVariables,
  ManagingCompany,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { AGREEMENTS_LIST_QUERY } from '../../../graphql/queries';
import { agreementMessages } from '../../../messages';
import { FormValues } from '..';
import { CREATE_EURO_ACCIDENT_TJP_AGREEMENT_MUTATION } from '../graphql/mutations';
import { getAgreementForCompanySize } from './get-agreement-for-company-size';

interface Submit {
  submit: (values: FormValues, managedBy: ManagingCompany) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { send } = useNotification();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const [createEuroAccidentTjpAgreement, { error }] = useMutation<
    createEuroAccidentTjpAgreementMutation,
    createEuroAccidentTjpAgreementMutationVariables
  >(CREATE_EURO_ACCIDENT_TJP_AGREEMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AGREEMENTS_LIST_QUERY,
        variables: {
          id: companyId,
        },
      },
    ],
  });

  const submit = async (values: FormValues, managedBy: ManagingCompany) => {
    try {
      const { companySize, extendedSalaryLimit } = values;

      // This field is required by Yup, so it should always be present
      if (!companySize) {
        throw new Error();
      }

      const tjpAgreementType = getAgreementForCompanySize({
        companySize,
        managedBy,
        extendedSalaryLimit,
      });

      await createEuroAccidentTjpAgreement({
        variables: {
          input: {
            companyId,
            tjpAgreementType,
            companySize,
            startDate: values.startDate,
          },
        },
      });

      send({
        message: formatMessage(agreementMessages.createEuroAccidentTjpSuccess),
        type: 'success',
      });

      push(`/companies/${companyId}/settings/agreements`);
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    error,
  };
};
