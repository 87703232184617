import { archive } from '@frontend/ui/icons';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { ProposalsSection } from 'features/sme/companies/company/proposals';
import { DuplicateProposalModal } from 'features/sme/companies/company/proposals/duplicate';
import { EditProposalNameModal } from 'features/sme/companies/company/proposals/edit/name';
import { ExecuteProposalModal } from 'features/sme/companies/company/proposals/execute';
import { MissingKycNotification } from 'features/sme/companies/company/proposals/missing-kyc-notification';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';

export const SmeCompanyProposalsRoute: React.FC = () => (
  <>
    <Suspense
      batch
      fallback={<TopLoading />}
      emptyState={
        <EmptyState
          title={
            <FormattedMessage
              {...smeCompanyProposalsMessages.noProposalsTitle}
            />
          }
          icon={archive}
        >
          <FormattedMessage
            {...smeCompanyProposalsMessages.noProposalsDescription}
          />
        </EmptyState>
      }
    >
      {({ errors }) => (
        <>
          {errors && <GraphQlErrors errors={errors} />}
          <MissingKycNotification />
          <ProposalsSection />
        </>
      )}
    </Suspense>
    <EditProposalNameModal />
    <ExecuteProposalModal />
    <DuplicateProposalModal />
  </>
);
