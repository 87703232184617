import { Section, SectionHeader } from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import {
  companyBillingConfigurationQuery,
  companyBillingConfigurationQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { openAddBillingEmailModal } from './billing-emails/add';
import { BillingEmailTable } from './billing-emails/table';
import { ContractReferenceTable } from './contract-references/table';
import { COMPANY_BILLING_CONFIGURATION_QUERY } from './graphql/queries';
import { billingMessages } from './messages';

export const Billing: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const { data, loading, error } = useQuery<
    companyBillingConfigurationQuery,
    companyBillingConfigurationQueryVariables
  >(COMPANY_BILLING_CONFIGURATION_QUERY, {
    variables: { companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const billingEmails =
    data?.company?.billingConfigurations?.edges?.map(e => e.node) ?? [];

  return (
    <>
      {error && <GraphQlError error={error} />}
      <Section>
        <SectionHeader>
          <FormattedMessage {...billingMessages.recipients} />
        </SectionHeader>
        <ChipsWrapper>
          <AssistChip
            onClick={() =>
              push({
                pathname,
                search: qs.stringify(openAddBillingEmailModal),
              })
            }
            text={<FormattedMessage {...billingMessages.addBillingEmail} />}
            leadingIcon={addCircle}
          />
        </ChipsWrapper>
        <BillingEmailTable billingEmails={billingEmails} />
      </Section>
      <Section>
        <SectionHeader>
          <FormattedMessage {...billingMessages.invoiceReference} />
        </SectionHeader>
        <ContractReferenceTable
          references={data?.company?.supplierReferenceMapping}
          agreements={data?.company?.agreements}
        />
      </Section>
    </>
  );
};
