import { Form, Formik, FormikHelpers } from '@frontend/formik';
import {
  Button,
  IconButton,
  ModalBodyStep,
  ModalFooter,
  ModalFooterStepperIndicator,
  ModalFooterStepperLayout,
  ModalHeaderStep,
} from '@frontend/ui';
import { clear } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import { a11yMessages } from 'app/messages/a11y';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import {
  afterFadeout,
  Modal,
  ModalBody,
  ModalHeader,
  Step,
  useModalStepper,
} from 'components/Modal';
import qs from 'query-string';
import React from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';

import {
  SelectRoleBody,
  SelectRoleFormValues,
  SelectRoleHeader,
  selectRoleInitialValues,
  selectRoleValidationSchema,
} from './steps/select-role';
import {
  SelectUserAccountBody,
  SelectUserAccountFormValues,
  SelectUserAccountHeader,
  selectUserAccountInitialValues,
  selectUserAccountValidationSchema,
} from './steps/select-user-account';
import { useSubmit } from './utils/use-submit';

export type FormValues = SelectUserAccountFormValues & SelectRoleFormValues;

const steps: Step[] = [
  {
    body: <SelectUserAccountBody />,
    header: <SelectUserAccountHeader />,
    validationSchema: selectUserAccountValidationSchema,
  },
  {
    body: <SelectRoleBody />,
    header: <SelectRoleHeader />,
    validationSchema: selectRoleValidationSchema,
  },
];

export const AddAdministratorModal = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const history = useHistory();
  const location = useLocation();
  const { companyId } = useParams<MatchParams>();

  const search = qs.parse(location.search);

  const isOpen = 'add-administrator' in search;

  const handleClose = () => {
    stripSearchParams(history, location, ['add-administrator']);
  };

  const {
    activeStep,
    isFirstStep,
    isLastStep,
    stepsWithIndex,
    goToNext,
    goToPrevious,
    resetStepper,
  } = useModalStepper({ steps });

  const { submit, submissionError } = useSubmit({
    companyId,
  });

  const handleNext = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    if (isLastStep) {
      await submit(values);
      handleClose();
      afterFadeout(() => {
        helpers.resetForm();
        resetStepper();
      });
      return;
    }
    helpers.setSubmitting(false);
    goToNext();
  };

  const initialValues: FormValues = {
    ...selectUserAccountInitialValues,
    ...selectRoleInitialValues,
  };

  const { validationSchema } = activeStep;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleNext}
      validationSchema={validationSchema?.(intl)}
    >
      {({ isSubmitting, resetForm }) => (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => {
            handleClose();
            afterFadeout(() => {
              resetForm();
              resetStepper();
            });
          }}
          size="medium"
        >
          <Form noValidate>
            <ModalHeader
              stepperCloseAction={
                <IconButton
                  icon={clear}
                  label={formatMessage(a11yMessages.close)}
                  onClick={() => {
                    handleClose();
                    afterFadeout(() => {
                      resetForm();
                      resetStepper();
                    });
                  }}
                  size="small"
                />
              }
            >
              {stepsWithIndex.map(step => (
                <ModalHeaderStep
                  activeStep={activeStep.index}
                  key={step.index}
                  stepIndex={step.index}
                >
                  {step.header}
                </ModalHeaderStep>
              ))}
            </ModalHeader>
            <ModalBody>
              {stepsWithIndex.map(step => (
                <ModalBodyStep
                  activeStep={activeStep.index}
                  key={step.index}
                  stepIndex={step.index}
                >
                  {step.body}
                  {isLastStep && submissionError && (
                    <GraphQlError error={submissionError} inModal />
                  )}
                </ModalBodyStep>
              ))}
            </ModalBody>
            <ModalFooter>
              <ModalFooterStepperLayout
                leftAction={
                  <Button disabled={isFirstStep} onClick={goToPrevious} text>
                    <FormattedMessage {...commonMessages.back} />
                  </Button>
                }
                rightAction={
                  <Button loading={isSubmitting} text type="submit">
                    <FormattedMessage
                      {...(isLastStep
                        ? commonMessages.confirm
                        : commonMessages.next)}
                    />
                  </Button>
                }
                stepIndicator={
                  <ModalFooterStepperIndicator
                    activeStep={activeStep.index}
                    label={formatMessage(a11yMessages.progress)}
                    nSteps={stepsWithIndex.length}
                    stepLabel={i =>
                      formatMessage(a11yMessages.progressStep, {
                        step: i + 1,
                        nSteps: stepsWithIndex.length,
                      })
                    }
                  />
                }
              />
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export const getAddAdministratorLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: 'add-administrator',
});
