import { Form, Formik, TextField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import {
  companyBillingConfigurationQuery,
  companyBillingConfigurationQueryVariables,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import * as Yup from 'yup';

import { COMPANY_BILLING_CONFIGURATION_QUERY } from '../../graphql/queries';
import { billingMessages } from '../../messages';
import { useUpdate } from '../table/update';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    reference: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  reference: string;
}

export const openEditReferenceModal = (editReference: string) => ({
  editReference,
});

export const ContractReferenceModal: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const { editReference: supplier } = qs.parse(search);
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { data, error } = useQuery<
    companyBillingConfigurationQuery,
    companyBillingConfigurationQueryVariables
  >(COMPANY_BILLING_CONFIGURATION_QUERY, {
    skip: !supplier,
    variables: { companyId },
  });

  const { _update, updateError } = useUpdate();

  const initialValue =
    data?.company?.supplierReferenceMapping?.[supplier] || '';

  const onRequestClose = () =>
    stripSearchParams(history, location, ['editReference']);

  return (
    <Modal isOpen={!!supplier} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          reference: initialValue,
        }}
        enableReinitialize
        validationSchema={validationSchema(intl)}
        onSubmit={async ({ reference: updateReference }) => {
          await _update({ key: supplier, value: updateReference });
          history.push({
            pathname,
          });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalHeader>
              <FormattedMessage {...billingMessages.editReference} />
            </ModalHeader>
            <ModalBody>
              {error && <GraphQlError inModal error={error} />}
              {updateError && <GraphQlError inModal error={updateError} />}
              <TextField
                required
                name="reference"
                label={<FormattedMessage {...billingMessages.reference} />}
              />
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  text
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
