import { Table, Td, Text, Th, Tr } from '@frontend/ui';
import { getEventHistoryDate } from '@frontend/utils';
import {
  benefitPackageHistoryQuery,
  benefitPackageHistoryQueryVariables,
} from 'app/apollo/graphql/types';
import {
  benefitPackageActionMessages,
  benefitTypesMessages,
  commonBenefitMessages,
} from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { menuMessages } from 'app/messages/menu';
import { MatchParams } from 'app/pages/companies/company/benefit-packages/benefit-package';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedDate, FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import NoValue from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import { BENEFIT_PACKAGE_HISTORY_QUERY } from './graphql/queries';
import { updateBenefitPackageHistoryQuery } from './graphql/update-query';

const AuthorInfo = styled(Text)`
  display: block;
  margin-top: 3rem;
`;

export const BenefitPackageHistory: React.FC = () => {
  const { search } = useLocation();
  const { benefitPackageId, companyId } = useParams<MatchParams>();
  const { per_page } = qs.parse(search);

  const { data, previousData, loading, error, fetchMore } = useQuery<
    benefitPackageHistoryQuery,
    benefitPackageHistoryQueryVariables
  >(BENEFIT_PACKAGE_HISTORY_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    variables: {
      benefitPackageId,
      companyId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
    },
  });

  const _data = data ?? previousData;

  if (!_data && loading) {
    return <TopLoading />;
  }

  if (!_data?.benefitPackage || !_data.benefitPackage) {
    return <GraphQlError error={error} />;
  }

  if (!_data.benefitPackage.benefitsHistory?.edges.length) {
    return (
      <EmptyState
        title={<FormattedMessage {...commonBenefitMessages.noHistory} />}
        error={error}
      />
    );
  }

  const { pageInfo } = _data.benefitPackage.benefitsHistory;
  const { startCursor, endCursor } = pageInfo;

  const { actor, createdAt } = _data.benefitPackage;
  const changes =
    _data?.benefitPackage?.benefitsHistory?.edges.map(({ node }) => node) ?? [];

  const companyStartDate = _data.company?.startDate;

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <Table
        size="small"
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: endCursor,
                },
                updateQuery: updateBenefitPackageHistoryQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: startCursor,
                  first: undefined,
                  last: per_page
                    ? parseInt(per_page, 10)
                    : DEFAULT_RESULT_PER_PAGE,
                },
                updateQuery: updateBenefitPackageHistoryQuery,
              });
            }}
          />
        }
      >
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: 'auto' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...menuMessages.events} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.detail} />
            </Th>
            <Th type="number" align="right">
              <FormattedMessage {...commonMessages.effectiveDate} />
            </Th>
            <Th type="number" align="right">
              <FormattedMessage {...commonMessages.reportingDate} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.actor} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {changes.map((change, index) => (
            <Tr key={index}>
              <Td>
                <FormattedMessage
                  messages={benefitPackageActionMessages}
                  select={change.action}
                />
              </Td>
              <Td>
                <FormattedMessage
                  messages={benefitTypesMessages}
                  select={change.benefitTypeName}
                />
              </Td>
              <Td type="number" align="right">
                <FormattedDate
                  value={getEventHistoryDate({
                    date: change.effectiveDate,
                    companyStartDate,
                  })}
                />
              </Td>
              <Td type="number" align="right">
                <FormattedDate
                  value={getEventHistoryDate({
                    date: change.createdAt,
                    companyStartDate,
                  })}
                  dateStyle="short"
                  timeStyle="short"
                />
              </Td>
              <Td>{change.actor?.displayName ?? <NoValue />}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
      <AuthorInfo use="labelLarge">
        <FormattedMessage
          {...commonBenefitMessages.benefitPackageAuthorInfo}
          values={{
            author: actor?.displayName,
            createdAt: (
              <FormattedDate
                value={createdAt}
                dateStyle="short"
                timeStyle="short"
              />
            ),
          }}
        />
      </AuthorInfo>
    </>
  );
};
