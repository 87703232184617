import { SelectField as SelectFieldUI } from '@frontend/ui';
import { SelectFieldProps } from '@frontend/ui/components/Fields/Inputs/SelectField';
import React from 'react';
import styled from 'styled-components';

interface InlineSelectFieldProps extends SelectFieldProps {
  nbrOfCharacters: number;
}

interface TransientInlineSelectFieldProps
  extends Omit<InlineSelectFieldProps, 'nbrOfCharacters'> {
  $nbrOfCharacters: number;
}

const SelectField = styled(SelectFieldUI)<TransientInlineSelectFieldProps>`
  width: calc(4.125rem + ${p => p.$nbrOfCharacters} * 0.5rem);
  margin-right: 0.5rem;
  .mdc-menu {
    margin: 1.25rem 0;
  }
`;

export const InlineSelectField: React.FC<InlineSelectFieldProps> = ({
  nbrOfCharacters,
  ...props
}) => <SelectField plain $nbrOfCharacters={nbrOfCharacters} {...props} />;
