import { Table, Td, Th, Tr } from '@frontend/ui';
import { salaryReportsQuery_company_Company_salaryReports_SalaryReportConnection_edges_SalaryReportEdge_node_SalaryReport as SalaryReport } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedDate, FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';

interface SalaryReportRowProps {
  report: SalaryReport;
}

const SalaryReportRow: React.FC<SalaryReportRowProps> = ({ report }) => (
  <Tr
    linked={Boolean(report.content?.excelUrl)}
    // eslint-disable-next-line
    onClick={() => report.content?.excelUrl && (window.location.href = report.content.excelUrl)}
  >
    <Td>{report.content?.name ?? <NoValue />}</Td>
    <Td>
      <FormattedDate value={report.date} />
    </Td>
  </Tr>
);

interface Props {
  navigation: React.ReactNode;
  reports: SalaryReport[];
}

export const SalaryReportsTable: React.FC<Props> = ({
  reports,
  navigation,
}) => (
  <Table size="xsmall" navigation={navigation}>
    <thead>
      <Tr>
        <Th>
          <FormattedMessage {...commonMessages.name} />
        </Th>
        <Th>
          <FormattedMessage {...commonMessages.date} />
        </Th>
      </Tr>
    </thead>
    <tbody>
      {reports.map(report => (
        <SalaryReportRow key={report.id} report={report} />
      ))}
    </tbody>
  </Table>
);
