import { ApolloError, useLazyQuery } from '@apollo/client';
import {
  personalAdviceInvoiceUrlQuery,
  personalAdviceInvoiceUrlQueryVariables,
} from 'app/apollo/graphql/types';

import { PERSONAL_ADVICE_INVOICE_URL } from '../graphql/queries';

interface Props {
  onRequestClose: () => void;
}

interface Submit {
  loading: boolean;
  submit: (date: string) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = ({ onRequestClose }: Props): Submit => {
  const [personalAdviceInvoiceUrl, { loading, error }] = useLazyQuery<
    personalAdviceInvoiceUrlQuery,
    personalAdviceInvoiceUrlQueryVariables
  >(PERSONAL_ADVICE_INVOICE_URL, {
    errorPolicy: 'all',
  });

  const submit = async (date: string) => {
    try {
      const { data } = await personalAdviceInvoiceUrl({
        variables: { date },
      });
      const url = data?.personalAdviceInvoiceUrl;
      if (!url) {
        throw new Error();
      }
      window.location.href = url;
      onRequestClose();
    } catch {
      // do nothing
    }
  };

  return {
    submit,
    error,
    loading,
  };
};
