import gql from 'graphql-tag';

export const membershipsSearchQueryDetails = gql`
  fragment membershipsSearchQueryDetails on Membership {
    id
    userAccountId
    givenName
    lastName
  }
`;

export const MEMBERSHIPS_SEARCH_QUERY = gql`
  query membershipsSearchQuery($id: ID!, $search: String) {
    company(id: $id) {
      id
      memberships(search: $search, includeTerminated: true, first: 10) {
        edges {
          node {
            ...membershipsSearchQueryDetails
          }
        }
      }
    }
  }
  ${membershipsSearchQueryDetails}
`;

export const EMPLOYEE_NAME_SEARCH_QUERY = gql`
  query employeeNameSearchQuery($companyId: ID!, $userAccountId: ID!) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      ...membershipsSearchQueryDetails
    }
  }
  ${membershipsSearchQueryDetails}
`;

export const COMPANY_COST_CENTERS_QUERY = gql`
  query companyCostCentersQuery($companyId: ID!) {
    company(id: $companyId) {
      id
      costCenters {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const COMPANY_BENEFIT_PACKAGES_QUERY = gql`
  query companyBenefitPackagesQuery($companyId: ID!) {
    companyBenefitPackages: company(id: $companyId) {
      benefitPackages {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const COMPANY_INVOICE_SUPPLIERS_QUERY = gql`
  query companyInvoiceSuppliersQuery($companyId: ID!) {
    companyInvoiceSuppliers: company(id: $companyId) {
      invoiceSuppliers
    }
  }
`;

export const COMPANY_INVOICE_BENEFIT_TYPES_QUERY = gql`
  query companyInvoiceBenefitTypesQuery($companyId: ID!) {
    companyInvoiceBenefitTypes: company(id: $companyId) {
      invoiceBenefitTypes
    }
  }
`;
