import { ApolloError, useMutation } from '@apollo/client';
import {
  UpdateInternalPostInput,
  updateInternalPostMutation,
  updateInternalPostMutationVariables,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'app/messages/form';
import { EditRouteMatchParams } from 'app/pages/companies/company/internal-news/edit';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { FormValues } from '../../components/Form';
import { UPDATE_INTERNAL_POST_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const {
    params: { id: postId, companyId },
  } = useRouteMatch<EditRouteMatchParams>();

  const [updateInternalPost, { error }] = useMutation<
    updateInternalPostMutation,
    updateInternalPostMutationVariables
  >(UPDATE_INTERNAL_POST_MUTATION, {
    update: cache => {
      cache.evict({ id: `InternalPost:${postId}` });
      cache.gc();
    },
  });

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const submit = async (values: FormValues) => {
    try {
      const {
        publicationDate,
        attachmentFiles,
        benefitPackages,
        organizationalUnits,
        hero,
        preamble,
        ...rest
      } = values;

      const input: UpdateInternalPostInput = {
        ...rest,
        id: postId,
        description: preamble,
        publicationDate:
          publicationDate && new Date(publicationDate).toISOString(),
        attachmentIds: attachmentFiles?.map(file => file.id),
        benefitPackageIds: benefitPackages
          ? Object.keys(benefitPackages)
          : null,
        orgUnitIds: organizationalUnits
          ? Object.keys(organizationalUnits)
          : null,
        heroImageId: hero?.uploadId,
      };

      const res = await updateInternalPost({
        variables: {
          input,
        },
      });

      if (!res?.data?.updateInternalPost?.internalPost) {
        throw new Error();
      }

      const {
        internalPost: { title },
      } = res.data.updateInternalPost;

      send({
        message: `${formatMessage(formSubmitMessages.updateNewsSuccessText, {
          title,
        })}`,
        type: 'success',
      });

      push(`/companies/${companyId}/internal-news/${postId}/preview`);
    } catch {
      // Do nothing
    }
  };

  return { submit, error };
};
