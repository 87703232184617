import {
  CompanySize,
  GroupAgreementType,
  NLPFundFeeDiscountType,
} from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

import { ValidAgreementStatus } from '..';

export const agreementMessages = defineMessages({
  poa: {
    id: 'agreementMessages.poa',
    defaultMessage: 'Fullmakt',
  },
  status: {
    id: 'agreementMessages.status',
    defaultMessage: 'Status',
  },
  other: {
    id: 'agreementMessages.other',
    defaultMessage: 'Övrigt',
  },
  document: {
    id: 'agreementMessages.document',
    defaultMessage: 'Dokument',
  },
  agreement: {
    id: 'agreementMessages.agreement',
    defaultMessage: 'Avtal',
  },
  add: {
    id: 'agreementMessages.add',
    defaultMessage: 'Lägg till avtal',
  },
  noAgreements: {
    id: 'agreementMessages.noAgreements',
    defaultMessage: 'Det finns inga avtal att visa',
  },
  noAgreementsText: {
    id: 'agreementMessages.noAgreementsText',
    defaultMessage: 'När avtal lagts till kommer de att visas här.',
  },
  addEuroAccidentGroup: {
    id: 'agreementMessages.addEuroAccidentGroup',
    defaultMessage: 'Lägg till Euro Accident gruppavtal',
  },
  euroAccidentGroup: {
    id: 'agreementMessages.euroAccidentGroup',
    defaultMessage: 'Euro Accident gruppavtal',
  },
  createEuroAccidentGroupSuccess: {
    id: 'agreementMessages.createEuroAccidentGroupSuccess',
    defaultMessage: 'Euro Accident gruppavtal skapat',
  },
  eaGroupAgreementType: {
    id: 'agreementMessages.eaGroupAgreementType',
    defaultMessage: 'Gruppavtal typ',
  },
  addEuroAccidentTjp: {
    id: 'agreementMessages.addEuroAccidentTjp',
    defaultMessage: 'Lägg till Euro Accident tjp-avtal',
  },
  euroAccidentTjp: {
    id: 'agreementMessages.euroAccidentTjp',
    defaultMessage: 'Euro Accident tjp-avtal',
  },
  createEuroAccidentTjpSuccess: {
    id: 'agreementMessages.createEuroAccidentTjpSuccess',
    defaultMessage: 'Euro Accident tjp-avtal skapat',
  },
  addNlpAgreement: {
    id: 'agreementMessages.addNlpAgreement',
    defaultMessage: 'Lägg till Nordeaavtal',
  },
  createNlpAgreementSuccess: {
    id: 'agreementMessages.createNlpAgreementSuccess',
    defaultMessage: 'Nordeaavtal skapat',
  },
  missingCompanyDetails: {
    id: 'agreementMessages.missingCompanyDetails',
    defaultMessage: 'Företagsuppgifter saknas',
  },
  missingCompanyDetailsDescription: {
    id: 'agreementMessages.missingCompanyDetailsDescription',
    defaultMessage: 'Nordeaavtal kan inte skapas. Följande uppgifter saknas:',
  },
  editCompanyDetails: {
    id: 'agreementMessages.editCompanyDetails',
    defaultMessage: 'Redigera företagsuppgifter',
  },
  updateCompanyDetailsSuccess: {
    id: 'agreementMessages.updateCompanyInformationSuccess',
    defaultMessage: 'Företagsuppgifter har uppdaterats',
  },
  nlpFundFeeDiscountType: {
    id: 'agreementMessages.nlpFundFeeDiscountType',
    defaultMessage: 'Fond rabattnivå',
  },
  firstLoginDate: {
    id: 'agreementMessages.firstLoginDate',
    defaultMessage: 'Första inloggningsdatum',
  },
  firstLoginDateHelper: {
    id: 'agreementMessages.firstLoginDateHelper',
    defaultMessage: 'Första inloggningsdatum avser alltid den första i månaden',
  },
  annualFeeFund: {
    id: 'agreementMessages.annualFeeFund',
    defaultMessage: 'Årsavgift fond',
  },
  capitalFeeFund: {
    id: 'agreementMessages.capitalFeeFund',
    defaultMessage: 'Kapitalavgift fond',
  },
  annualFeePortfolio: {
    id: 'agreementMessages.annualFeePortfolio',
    defaultMessage: 'Årsavgift depå',
  },
  capitalFeePortfolio: {
    id: 'agreementMessages.capitalFeePortfolio',
    defaultMessage: 'Kapitalavgift depå',
  },
  annualFeeTrad: {
    id: 'agreementMessages.annualFeeTrad',
    defaultMessage: 'Årsavgift garanti',
  },
  capitalFeeTrad: {
    id: 'agreementMessages.capitalFeeTrad',
    defaultMessage: 'Kapitalavgift garanti',
  },
  feesDescription: {
    id: 'agreementMessages.feesDescription',
    defaultMessage:
      'Du kan ange kapitalavgift i hela steg om fem punkter. Tänk på att avgiften alltid ska vara samma för fond- och depåförsäkring.',
  },
  portfolioAndFundFeeMismatch: {
    id: 'agreementMessages.portfolioAndFundFeeMismatch',
    defaultMessage:
      'Kapitalavgift för fond- och depåförsäkring överensstämmer inte',
  },
  addNordnetAgreement: {
    id: 'agreementMessages.addNordnetAgreement',
    defaultMessage: 'Lägg till Nordnetavtal',
  },
  createNordnetAgreementSuccess: {
    id: 'agreementMessages.createNordnetAgreementSuccess',
    defaultMessage: 'Nordnetavtal skapat',
  },
  startDate: {
    id: 'agreementMessages.startDate',
    defaultMessage: 'Startdatum avtal',
  },
  companySize: {
    id: 'agreementMessages.nInsured',
    defaultMessage: 'Storlek bolag',
  },
  companySizeHelperText: {
    id: 'agreementMessages.companySizeHelperText',
    defaultMessage: 'Om koncern, ange alla som ska försäkras',
  },
  accountClosureMonth: {
    id: 'agreementMessages.accountClosureMonth',
    defaultMessage: 'Bokslutsmånad',
  },
  salaryReviewMonth: {
    id: 'agreementMessages.salaryReviewMonth',
    defaultMessage: 'Lönerevisionsmånad',
  },
  paymentMethod: {
    id: 'agreementMessages.paymentMethod',
    defaultMessage: 'Betalningsmetod',
  },
  clearingCode: {
    id: 'agreementMessages.clearingCode',
    defaultMessage: 'Clearing för ansvarigt kontor',
  },
  nlpSalesRep: {
    id: 'agreementMessages.nlpSalesRep',
    defaultMessage: 'Ansvarig säljare',
  },
  extendedSalaryLimit: {
    id: 'agreementMessages.extendedSalaryLimit',
    defaultMessage: 'Utökat maxbelopp',
  },
  selectAgreementType: {
    id: 'agreementMessages.selectAgreementType',
    defaultMessage: 'Välj avtalstyp',
  },
});

export const companySizeMessages = defineMessages<CompanySize>({
  LT_10: {
    id: 'companySizeMessages.LT_10',
    defaultMessage: '0-9',
  },
  LT_50: {
    id: 'companySizeMessages.LT_50',
    defaultMessage: '10-49',
  },
  GTE_50: {
    id: 'companySizeMessages.GTE_50',
    defaultMessage: '50-249',
  },
  GTE_250: {
    id: 'companySizeMessages.GTE_250',
    defaultMessage: '250-999',
  },
  GTE_1000: {
    id: 'companySizeMessages.GTE_1000',
    defaultMessage: '1000+',
  },
});

export const agreementStatusMessages = defineMessages<ValidAgreementStatus>({
  ACTIVE: {
    id: 'agreementStatusMessages.ACTIVE',
    defaultMessage: 'Aktivt',
  },
  INTERNAL_ERROR: {
    id: 'agreementStatusMessages.INTERNAL_ERROR',
    defaultMessage: 'Fel',
  },
  PREPARATION: {
    id: 'agreementStatusMessages.PREPARATION',
    defaultMessage: 'Inväntar svar',
  },
});

export const nlpFundFeeDiscountTypeMessages =
  defineMessages<NLPFundFeeDiscountType>({
    FUND_AGREEMENT_20: {
      id: 'nlpFundFeeDiscountTypeMessages.FUND_AGREEMENT_20',
      defaultMessage: '20 % rabatt',
    },
    FUND_AGREEMENT_30: {
      id: 'nlpFundFeeDiscountTypeMessages.FUND_AGREEMENT_30',
      defaultMessage: '30 % rabatt',
    },
    FUND_AGREEMENT_50: {
      id: 'nlpFundFeeDiscountTypeMessages.FUND_AGREEMENT_50',
      defaultMessage: '50 % rabatt',
    },
  });

export const groupAgreementTypeMessages = defineMessages<GroupAgreementType>({
  _9797LIV10_99: {
    id: 'groupAgreementTypeMessages._9797LIV10_99',
    defaultMessage: 'Liv 10-99',
  },
  _9797LIV100: {
    id: 'groupAgreementTypeMessages._9797LIV100',
    defaultMessage: 'Liv 100+',
  },
  _9797mk1: {
    id: 'groupAgreementTypeMessages._9797mk1',
    defaultMessage: 'Med karens - Riskhink 1',
  },
  _9797mk2: {
    id: 'groupAgreementTypeMessages._9797mk2',
    defaultMessage: 'Med karens - Riskhink 2',
  },
  _9797mk3: {
    id: 'groupAgreementTypeMessages._9797mk3',
    defaultMessage: 'Med karens - Riskhink 3',
  },
  _9797mk4: {
    id: 'groupAgreementTypeMessages._9797mk4',
    defaultMessage: 'Med karens - Riskhink 4',
  },
  _9797mk5: {
    id: 'groupAgreementTypeMessages._9797mk5',
    defaultMessage: 'Med karens - Riskhink 5',
  },
  _9797mk6: {
    id: 'groupAgreementTypeMessages._9797mk6',
    defaultMessage: 'Med karens - Riskhink 6',
  },
  _9797mk7: {
    id: 'groupAgreementTypeMessages._9797mk7',
    defaultMessage: 'Med karens - Riskhink 7',
  },
  _9797mkNY: {
    id: 'groupAgreementTypeMessages._9797mkNY',
    defaultMessage: 'Med karens - Ny',
  },
  _9797mkSMA: {
    id: 'groupAgreementTypeMessages._9797mkSMA',
    defaultMessage: 'Med karens - Små',
  },
  _9797uk1: {
    id: 'groupAgreementTypeMessages._9797uk1',
    defaultMessage: 'Utan karens - Riskhink 1',
  },
  _9797uk2: {
    id: 'groupAgreementTypeMessages._9797uk2',
    defaultMessage: 'Utan karens - Riskhink 2',
  },
  _9797uk3: {
    id: 'groupAgreementTypeMessages._9797uk3',
    defaultMessage: 'Utan karens - Riskhink 3',
  },
  _9797uk4: {
    id: 'groupAgreementTypeMessages._9797uk4',
    defaultMessage: 'Utan karens - Riskhink 4',
  },
  _9797uk5: {
    id: 'groupAgreementTypeMessages._9797uk5',
    defaultMessage: 'Utan karens - Riskhink 5',
  },
  _9797uk6: {
    id: 'groupAgreementTypeMessages._9797uk6',
    defaultMessage: 'Utan karens - Riskhink 6',
  },
  _9797uk7: {
    id: 'groupAgreementTypeMessages._9797uk7',
    defaultMessage: 'Utan karens - Riskhink 7',
  },
  _9797ukNY: {
    id: 'groupAgreementTypeMessages._9797ukNY',
    defaultMessage: 'Utan karens - Ny',
  },
  _9797ukSMA: {
    id: 'groupAgreementTypeMessages._9797ukSMA',
    defaultMessage: 'Utan karens - Små',
  },
  _9918LIV10_99: {
    id: 'groupAgreementTypeMessages._9918LIV10_99',
    defaultMessage: 'Liv 10-99',
  },
  _9918LIV100: {
    id: 'groupAgreementTypeMessages._9918LIV100',
    defaultMessage: 'Liv 100+',
  },
  _9918mk1: {
    id: 'groupAgreementTypeMessages._9918mk1',
    defaultMessage: 'Med karens - Riskhink 1',
  },
  _9918mk2: {
    id: 'groupAgreementTypeMessages._9918mk2',
    defaultMessage: 'Med karens - Riskhink 2',
  },
  _9918mk3: {
    id: 'groupAgreementTypeMessages._9918mk3',
    defaultMessage: 'Med karens - Riskhink 3',
  },
  _9918mk4: {
    id: 'groupAgreementTypeMessages._9918mk4',
    defaultMessage: 'Med karens - Riskhink 4',
  },
  _9918mk5: {
    id: 'groupAgreementTypeMessages._9918mk5',
    defaultMessage: 'Med karens - Riskhink 5',
  },
  _9918mk6: {
    id: 'groupAgreementTypeMessages._9918mk6',
    defaultMessage: 'Med karens - Riskhink 6',
  },
  _9918mk7: {
    id: 'groupAgreementTypeMessages._9918mk7',
    defaultMessage: 'Med karens - Riskhink 7',
  },
  _9918mkNY: {
    id: 'groupAgreementTypeMessages._9918mkNY',
    defaultMessage: 'Med karens - Ny',
  },
  _9918mkSMA: {
    id: 'groupAgreementTypeMessages._9918mkSMA',
    defaultMessage: 'Med karens - Små',
  },
  _9918uk1: {
    id: 'groupAgreementTypeMessages._9918uk1',
    defaultMessage: 'Utan karens - Riskhink 1',
  },
  _9918uk2: {
    id: 'groupAgreementTypeMessages._9918uk2',
    defaultMessage: 'Utan karens - Riskhink 2',
  },
  _9918uk3: {
    id: 'groupAgreementTypeMessages._9918uk3',
    defaultMessage: 'Utan karens - Riskhink 3',
  },
  _9918uk4: {
    id: 'groupAgreementTypeMessages._9918uk4',
    defaultMessage: 'Utan karens - Riskhink 4',
  },
  _9918uk5: {
    id: 'groupAgreementTypeMessages._9918uk5',
    defaultMessage: 'Utan karens - Riskhink 5',
  },
  _9918uk6: {
    id: 'groupAgreementTypeMessages._9918uk6',
    defaultMessage: 'Utan karens - Riskhink 6',
  },
  _9918uk7: {
    id: 'groupAgreementTypeMessages._9918uk7',
    defaultMessage: 'Utan karens - Riskhink 7',
  },
  _9918ukNY: {
    id: 'groupAgreementTypeMessages._9918ukNY',
    defaultMessage: 'Utan karens - Ny',
  },
  _9918ukSMA: {
    id: 'groupAgreementTypeMessages._9918ukSMA',
    defaultMessage: 'Utan karens - Små',
  },
});
