import { ApolloError, useMutation } from '@apollo/client';
import {
  createEuroAccidentGroupAgreementMutation,
  createEuroAccidentGroupAgreementMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { AGREEMENTS_LIST_QUERY } from '../../../graphql/queries';
import { agreementMessages } from '../../../messages';
import { FormValues } from '..';
import { CREATE_EURO_ACCIDENT_GROUP_AGREEMENT_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { send } = useNotification();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const [createEuroAccidentGroupAgreement, { error }] = useMutation<
    createEuroAccidentGroupAgreementMutation,
    createEuroAccidentGroupAgreementMutationVariables
  >(CREATE_EURO_ACCIDENT_GROUP_AGREEMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AGREEMENTS_LIST_QUERY,
        variables: {
          id: companyId,
        },
      },
    ],
  });

  const submit = async (values: FormValues) => {
    try {
      const { companySize, groupAgreementType, startDate } = values;

      // This field is required by Yup, so it should always be present
      if (!companySize || !groupAgreementType) {
        throw new Error();
      }

      await createEuroAccidentGroupAgreement({
        variables: {
          input: {
            companyId,
            groupAgreementType,
            companySize,
            startDate,
          },
        },
      });

      send({
        message: formatMessage(
          agreementMessages.createEuroAccidentGroupSuccess,
        ),
        type: 'success',
      });

      push(`/companies/${companyId}/settings/agreements`);
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    error,
  };
};
