import { ApolloError, FetchResult, useMutation } from '@apollo/client';
import { chunkArray } from '@frontend/utils';
import { BATCH_MAX } from 'app/apollo/client';
import {
  editProposalConvertRiskMutation,
  editProposalConvertRiskMutationVariables,
  UpdateProposalMembershipInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import mapSeries from 'promise-map-series';
import { useId, useState } from 'react';
import { useHistory } from 'react-router';

import { clearProposalCache } from '../../../../utils/clear-proposal-cache';
import { FormValues } from '../form';
import { EDIT_PROPOSAL_CONVERT_RISK_MUTATION } from '../graphql/mutations';

const isRejectedResult = (
  result: PromiseSettledResult<FetchResult>,
): result is PromiseRejectedResult =>
  result.status === 'rejected' && result.reason;

interface Submit {
  submit: (values: FormValues) => void;
  submissionErrors?: ApolloError[];
}

interface Params {
  params: MatchParams;
}

export const useSubmit = ({ params }: Params): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const intl = useIntl();
  const { formatMessage } = intl;
  const batchId = useId();

  const [errors, setErrors] = useState<ApolloError[]>([]);

  const [editProposalEmployee] = useMutation<
    editProposalConvertRiskMutation,
    editProposalConvertRiskMutationVariables
  >(EDIT_PROPOSAL_CONVERT_RISK_MUTATION, {
    context: { batch: true, batchId },
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['proposals'],
    }),
  });

  const submit = async (formValues: FormValues) => {
    const input: UpdateProposalMembershipInput[] = Object.entries(
      formValues,
    ).map(([id, convertRiskInsurances]) => ({
      id,
      convertRiskInsurances,
      proposalId: params.proposalId,
    }));

    const inputChunks = chunkArray(input, BATCH_MAX);

    const results: PromiseSettledResult<
      FetchResult<editProposalConvertRiskMutation>
    >[][] = await mapSeries(
      inputChunks,
      async (inputChunk: UpdateProposalMembershipInput[]) => {
        const result = await Promise.allSettled(
          inputChunk.map(chunk =>
            editProposalEmployee({
              variables: {
                input: chunk,
              },
            }),
          ),
        );
        return result;
      },
    );

    const hasFullfilled = results
      .flat()
      .some(item => item.status === 'fulfilled');

    const rejected: PromiseRejectedResult[] = results
      .flat()
      .filter(isRejectedResult);

    if (rejected.length) {
      setErrors(rejected.map(item => item.reason));
    }

    if (hasFullfilled) {
      send({
        type: 'success',
        message: formatMessage(smeEmployeesMessages.changesSaved),
      });
      if (!rejected.length) {
        push(
          `/sme/${params.companyId}/proposals/${params.proposalId}/employees`,
        );
      }
    }
  };

  return { submit, submissionErrors: errors.length ? errors : undefined };
};
