import { QueryResult } from '@apollo/client';
import {
  CheckboxField,
  ChipsField,
  RadioGroup,
  SelectField,
  TextField,
} from '@frontend/ui';
import { download } from '@frontend/ui/icons';
import { alphabeticallyAscending, toSafeArray } from '@frontend/utils';
import { client } from 'app/apollo/client';
import {
  companyBenefitPackagesQuery,
  companyBenefitPackagesQueryVariables,
  companyCostCentersQuery,
  companyCostCentersQueryVariables,
  companyInvoiceBenefitTypesQuery,
  companyInvoiceBenefitTypesQueryVariables,
  companyInvoiceSuppliersQuery,
  companyInvoiceSuppliersQueryVariables,
  employeeNameSearchQuery,
  employeeNameSearchQueryVariables,
  invoiceLinesQuery_invoiceLineSearch_InvoiceLineSearchResult as InvoiceLineSearch,
  membershipsSearchQuery,
  membershipsSearchQueryVariables,
  PensionLayer,
  TaxClass,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  benefitTypeMessages,
  invoiceLinesMessages,
  pensionActMessages,
  taxClassMessages,
} from 'app/messages/invoice-lines';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage, useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { INVOICE_SUPPLIER } from 'features/companies/company/utils/constants';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import { SelectionFilter } from 'features/filter-sheet/components/SelectionFilter';
import {
  DEBOUNCE_TIMER,
  useDebouncedFilters,
} from 'features/filter-sheet/utils/use-debounced-filters';
import React, { useEffect, useId, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import {
  COMPANY_BENEFIT_PACKAGES_QUERY,
  COMPANY_COST_CENTERS_QUERY,
  COMPANY_INVOICE_BENEFIT_TYPES_QUERY,
  COMPANY_INVOICE_SUPPLIERS_QUERY,
  EMPLOYEE_NAME_SEARCH_QUERY,
  MEMBERSHIPS_SEARCH_QUERY,
} from './graphql/queries';

const ChipsFieldWrapper = styled.div`
  margin-top: 1rem;
`;

const isFulfilled = (
  res: PromiseSettledResult<
    QueryResult<employeeNameSearchQuery, employeeNameSearchQueryVariables>
  >,
): res is PromiseFulfilledResult<
  QueryResult<employeeNameSearchQuery, employeeNameSearchQueryVariables>
> => res.status === 'fulfilled';

interface FilterProps {
  companyId: string;
  invoiceLineSearch: InvoiceLineSearch;
}

interface Filters {
  benefitPackageId?: string | string[];
  benefitTypes?: string | string[];
  costCenterIdentifiers?: string | string[];
  dueFrom?: string;
  dueTo?: string;
  invoiceFrom?: string;
  invoiceTo?: string;
  pensionAct?: string;
  periodFrom?: string;
  periodTo?: string;
  suppliers?: string | string[];
  taxClass?: string;
  userAccountIds?: string | string[];
}

export const Filters: React.FC<FilterProps> = ({
  companyId,
  invoiceLineSearch,
}) => {
  const pensionActId = useId();
  const taxClassId = useId();
  const { formatMessage } = useIntl();
  const { clearFilters, filters, setFilterValue } =
    useDebouncedFilters<Filters>();
  const [employeeSearch, setEmployeeSearch] = useState<string>('');
  const [selectedEmployees, setSelectedEmployees] = useState<
    Record<string, string>
  >({});
  const [selectedBenefitPackages, setSelectedBenefitPackages] =
    useState<string>();
  const [debouncedEmployeeSearch] = useDebouncedCallback((search: string) => {
    setEmployeeSearch(search);
  }, DEBOUNCE_TIMER);

  const { data: membershipSearchData, loading: membershipSearchLoading } =
    useQuery<membershipsSearchQuery, membershipsSearchQueryVariables>(
      MEMBERSHIPS_SEARCH_QUERY,
      {
        variables: {
          id: companyId,
          search: employeeSearch,
        },
        skip: !employeeSearch,
        errorPolicy: 'all',
      },
    );

  const { data: costCenterData, loading: costCenterLoading } = useQuery<
    companyCostCentersQuery,
    companyCostCentersQueryVariables
  >(COMPANY_COST_CENTERS_QUERY, {
    variables: {
      companyId,
    },
    errorPolicy: 'all',
  });

  const { data: benefitPackageData, loading: benefitPackageLoading } = useQuery<
    companyBenefitPackagesQuery,
    companyBenefitPackagesQueryVariables
  >(COMPANY_BENEFIT_PACKAGES_QUERY, {
    variables: {
      companyId,
    },
    errorPolicy: 'all',
  });

  const { data: invoiceSuppliersData, loading: invoiceSuppliersLoading } =
    useQuery<
      companyInvoiceSuppliersQuery,
      companyInvoiceSuppliersQueryVariables
    >(COMPANY_INVOICE_SUPPLIERS_QUERY, {
      variables: {
        companyId,
      },
      errorPolicy: 'all',
    });

  const { data: invoiceBenefitTypesData, loading: invoiceBenefitTypesLoading } =
    useQuery<
      companyInvoiceBenefitTypesQuery,
      companyInvoiceBenefitTypesQueryVariables
    >(COMPANY_INVOICE_BENEFIT_TYPES_QUERY, {
      variables: {
        companyId,
      },
      errorPolicy: 'all',
    });

  const userAccountIds = toSafeArray(filters.userAccountIds);

  const fetchMembership = async (input: string[]) => {
    const result = await Promise.allSettled(
      input.map(userAccountId =>
        client.query({
          query: EMPLOYEE_NAME_SEARCH_QUERY,
          variables: {
            companyId,
            userAccountId,
          },
        }),
      ),
    );

    setSelectedEmployees(() =>
      result.filter(isFulfilled).reduce((acc, res) => {
        if (!res.value.data?.membership) {
          return acc;
        }
        return {
          ...acc,
          [res.value.data.membership.userAccountId]:
            `${res.value.data.membership.givenName} ${res.value.data.membership.lastName}`,
        };
      }, {}),
    );
  };

  const accountingCosts = toSafeArray(filters.costCenterIdentifiers);

  useEffect(() => {
    if (userAccountIds.length) {
      fetchMembership(userAccountIds);
    }

    // We only want to call fetchMembership on mount to re-hydrate
    // the selected employees when component mounts, therefore linting is disabled
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const employeeOptions =
    membershipSearchData?.company?.memberships?.edges.map(({ node }) => ({
      label: `${node.givenName} ${node.lastName}`,
      value: node.userAccountId,
    })) ?? [];

  const benefitPackageOptions =
    benefitPackageData?.companyBenefitPackages?.benefitPackages?.edges.map(
      ({ node }) => ({
        label: node.name,
        value: node.id,
      }),
    ) ?? [];

  const benefitTypes = toSafeArray(filters.benefitTypes);

  const benefitTypeOptions =
    invoiceBenefitTypesData?.companyInvoiceBenefitTypes?.invoiceBenefitTypes?.map(
      value => ({
        id: value,
        label: formatMessage({ select: value, messages: benefitTypeMessages }),
        selected: benefitTypes?.includes(value),
      }),
    ) ?? [];

  const suppliers = toSafeArray(filters.suppliers);

  const supplierOptions =
    invoiceSuppliersData?.companyInvoiceSuppliers?.invoiceSuppliers
      ?.map(value => ({
        id: value,
        label: INVOICE_SUPPLIER[value],
        selected: suppliers.includes(value),
      }))
      ?.sort((a, b) => alphabeticallyAscending(a.label, b.label)) ?? [];

  const costCenterOptions =
    costCenterData?.company?.costCenters?.edges.map(({ node }) => ({
      label: node.name,
      value: node.id,
    })) ?? [];

  const taxClassOptions = [
    ...Object.values(TaxClass).map(value => ({
      label: formatMessage({ select: value, messages: taxClassMessages }),
      value,
    })),
    {
      label: formatMessage(invoiceLinesMessages.allOfTheAbove),
      value: '',
    },
  ];

  const pensionActOptions = [
    ...Object.values(PensionLayer)
      .filter(layer => layer !== PensionLayer.GENERAL) // Exclude GENERAL, too confusing for customers
      .map(value => ({
        label: formatMessage({ select: value, messages: pensionActMessages }),
        value,
      })),
    {
      label: formatMessage(invoiceLinesMessages.allOfTheAbove),
      value: '',
    },
  ];

  const selectedFilters = Object.keys(filters);

  const reset = () => {
    clearFilters();
    setSelectedEmployees({});
  };

  const { excelUrl, csvUrl } = invoiceLineSearch;

  const selectedCostCenters = accountingCosts.reduce(
    (acc, cc) => ({
      ...acc,
      [cc]: costCenterOptions.find(({ value }) => value === cc)?.label ?? '',
    }),
    {},
  );

  return (
    <>
      {(membershipSearchLoading ||
        costCenterLoading ||
        benefitPackageLoading ||
        invoiceSuppliersLoading ||
        invoiceBenefitTypesLoading) && <TopLoading />}
      <ChipsWrapper>
        <SelectionFilter
          nSelected={selectedFilters.length}
          clearFilters={reset}
        >
          <FilterSection
            header={<FormattedMessage {...invoiceLinesMessages.employee} />}
          >
            <ChipsFieldWrapper>
              <ChipsField
                dense
                persistCasing
                label={<FormattedMessage {...invoiceLinesMessages.nameOrPin} />}
                options={employeeOptions}
                onChange={value => {
                  setSelectedEmployees(value);
                  setFilterValue(Object.keys(value), 'userAccountIds');
                }}
                value={selectedEmployees}
                onSearchFieldValueChange={debouncedEmployeeSearch}
                filterOptionsBySearchFieldValue={false}
              />
            </ChipsFieldWrapper>
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage {...invoiceLinesMessages.benefitPackage} />
            }
          >
            <SelectField
              dense
              label={<FormattedMessage {...commonMessages.search} />}
              options={benefitPackageOptions}
              onChange={target => {
                setSelectedBenefitPackages(target.detail.value);
                setFilterValue(target.detail.value, 'benefitPackageId');
              }}
              value={selectedBenefitPackages}
            />
          </FilterSection>
          <FilterSection
            header={<FormattedMessage {...invoiceLinesMessages.period} />}
          >
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.from} />}
              persistentLabel
              type="date"
              value={filters.periodFrom ?? ''}
              onChange={event =>
                setFilterValue(event.target.value, 'periodFrom')
              }
            />
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.to} />}
              persistentLabel
              type="date"
              value={filters.periodTo ?? ''}
              onChange={event => setFilterValue(event.target.value, 'periodTo')}
            />
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage
                {...invoiceLinesMessages.insurancesAndServices}
              />
            }
          >
            {benefitTypeOptions.map(({ selected, id, label }) => (
              <div key={id}>
                <CheckboxField
                  checked={selected}
                  label={label}
                  onChange={() => {
                    const value = benefitTypes.includes(id)
                      ? benefitTypes.filter(_id => id !== _id)
                      : [id, ...benefitTypes];

                    setFilterValue(value, 'benefitTypes');
                  }}
                />
              </div>
            ))}
          </FilterSection>
          <FilterSection
            header={<FormattedMessage {...invoiceLinesMessages.supplier} />}
          >
            {supplierOptions.map(({ selected, id, label }) => (
              <div key={id}>
                <CheckboxField
                  checked={selected}
                  label={label}
                  onChange={() => {
                    const value = suppliers.includes(id)
                      ? suppliers.filter(_id => id !== _id)
                      : [id, ...suppliers];

                    setFilterValue(value, 'suppliers');
                  }}
                />
              </div>
            ))}
          </FilterSection>
          <FilterSection
            header={<FormattedMessage {...invoiceLinesMessages.costCenter} />}
          >
            <ChipsFieldWrapper>
              <ChipsField
                dense
                label={<FormattedMessage {...commonMessages.search} />}
                options={costCenterOptions}
                onChange={value => {
                  setFilterValue(Object.keys(value), 'costCenterIdentifiers');
                }}
                value={selectedCostCenters}
              />
            </ChipsFieldWrapper>
          </FilterSection>
          <FilterSection
            header={<FormattedMessage {...invoiceLinesMessages.taxClass} />}
            id={taxClassId}
          >
            <RadioGroup
              checked={filters.taxClass ?? ''}
              onChange={({ value }) => setFilterValue(value, 'taxClass')}
              options={taxClassOptions}
              aria-labelledby={taxClassId}
            />
          </FilterSection>
          <FilterSection
            header={<FormattedMessage {...invoiceLinesMessages.pensionAct} />}
            id={pensionActId}
          >
            <RadioGroup
              checked={filters.pensionAct ?? ''}
              onChange={({ value }) => setFilterValue(value, 'pensionAct')}
              options={pensionActOptions}
              aria-labelledby={pensionActId}
            />
          </FilterSection>
          <FilterSection
            header={
              <FormattedMessage {...invoiceLinesMessages.lastPaymentDate} />
            }
          >
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.from} />}
              persistentLabel
              type="date"
              value={filters.dueFrom ?? ''}
              onChange={event => setFilterValue(event.target.value, 'dueFrom')}
            />
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.to} />}
              persistentLabel
              type="date"
              value={filters.dueTo ?? ''}
              onChange={event => setFilterValue(event.target.value, 'dueTo')}
            />
          </FilterSection>
          <FilterSection
            header={<FormattedMessage {...invoiceLinesMessages.invoiceDate} />}
          >
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.from} />}
              persistentLabel
              type="date"
              value={filters.invoiceFrom ?? ''}
              onChange={event =>
                setFilterValue(event.target.value, 'invoiceFrom')
              }
            />
            <TextField
              dense
              label={<FormattedMessage {...commonMessages.to} />}
              persistentLabel
              type="date"
              value={filters.invoiceTo ?? ''}
              onChange={event =>
                setFilterValue(event.target.value, 'invoiceTo')
              }
            />
          </FilterSection>
        </SelectionFilter>
        {invoiceLineSearch.invoiceLines.totalCount > 0 &&
          (excelUrl || csvUrl) && (
            <>
              {/* XXX: All downloading of invoice lines as excel is temporarily disabled
            due to backend issues. This will be re-enabled in the future. */}

              {excelUrl && (
                <AssistChip
                  text={
                    <FormattedMessage {...invoiceLinesMessages.downloadExcel} />
                  }
                  leadingIcon={download}
                  // eslint-disable-next-line
                onClick={() => (window.location.href = excelUrl)}
                />
              )}
              {csvUrl && (
                <AssistChip
                  text={
                    <FormattedMessage {...invoiceLinesMessages.downloadCsv} />
                  }
                  leadingIcon={download}
                  // eslint-disable-next-line
                onClick={() => (window.location.href = csvUrl)}
                />
              )}
            </>
          )}
      </ChipsWrapper>
    </>
  );
};
