import { CompanyBillingAttachmentFormat } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const billingMessages = defineMessages({
  addBillingEmail: {
    id: 'billingMessages.addBillingEmail',
    defaultMessage: 'Lägg till',
  },
  editBillingEmail: {
    id: 'billingMessages.editBillingEmail',
    defaultMessage: 'Ändra mottagare',
  },
  deleteBillingEmail: {
    id: 'billingMessages.deleteBillingEmail',
    defaultMessage: 'Ta bort',
  },
  recipients: {
    id: 'billingMessages.recipients',
    defaultMessage: 'Mottagare',
  },
  invoiceReference: {
    id: 'billingMessages.invoiceReference',
    defaultMessage: 'Fakturareferens',
  },
  reference: {
    id: 'billingMessages.reference',
    defaultMessage: 'Referens',
  },
  editReference: {
    id: 'billingMessages.editReference',
    defaultMessage: 'Redigera referens',
  },
  updateReferenceSuccess: {
    id: 'billingMessages.updateReferenceSuccess',
    defaultMessage: 'Referens uppdaterad',
  },
  deleteReference: {
    id: 'billingMessages.deleteReference',
    defaultMessage: 'Ta bort referens',
  },
  editRecipient: {
    id: 'billingMessages.editRecipient',
    defaultMessage: 'Redigera mottagare',
  },
  deleteRecipient: {
    id: 'billingMessages.deleteRecipient',
    defaultMessage: 'Ta bort mottagare',
  },
  attachments: {
    id: 'billingMessages.attachments',
    defaultMessage: 'Format',
  },
  noBillingEmails: {
    id: 'billingMessages.noBillingEmails',
    defaultMessage: 'Inga e-postadresser tillagda för fakturering',
  },
  confirmDeleteBillingEmail: {
    id: 'billingMessages.confirmDeleteBillingEmail',
    defaultMessage: 'Ta bort mottagaren?',
  },
  confirmDeleteBillingEmailDescription: {
    id: 'billingMessages.confirmDeleteBillingEmailDescription',
    defaultMessage: 'Mottagaren kommer inte längre att få några fakturor.',
  },
  addBillingEmailSuccess: {
    id: 'billingMessages.createBillingEmailSuccess',
    defaultMessage: 'E-postadressen tillagd',
  },
  updateBillingEmailSuccess: {
    id: 'billingMessages.updateBillingEmailSuccess',
    defaultMessage: 'E-postadressen har uppdaterats',
  },
  deleteBillingEmailSuccess: {
    id: 'billingMessages.deleteBillingEmailSuccess',
    defaultMessage: 'E-postadressen har tagits bort',
  },
  billingEmailNote: {
    id: 'billingMessages.note',
    defaultMessage: `Ange mottagare och vilka format som ska bifogas.
      Fakturorna finns alltid tillgängliga i portalen. PDF-sammanfattning
      innebär att endast en sammanfattande PDF bifogas.`,
  },
  attachmentsRequired: {
    id: 'billingMessages.attachmentsRequired',
    defaultMessage: 'Format (Obligatoriskt)',
  },
});

export const billingAttachmentFormatMessages =
  defineMessages<CompanyBillingAttachmentFormat>({
    CSV: { id: 'CSV', defaultMessage: 'CSV' },
    PDF: { id: 'PDF', defaultMessage: 'PDF' },
    XLSX: { id: 'XLSX', defaultMessage: 'Excel' },
    SIE4: { id: 'SIE4', defaultMessage: 'SIE 4' },
    PDF_SUMMARY: { id: 'PDF_SUMMARY', defaultMessage: 'PDF-sammanfattning' },
  });
