import { ApolloError, useMutation } from '@apollo/client';
import {
  companyBillingConfigurationQuery,
  companyBillingConfigurationQueryVariables,
  updateCompanyContractReferenceMutation,
  updateCompanyContractReferenceMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useRouteMatch } from 'react-router';

import {
  COMPANY_BILLING_CONFIGURATION_QUERY,
  UPDATE_COMPANY_CONTRACT_REFERENCE_MUTATION,
} from '../../../graphql/queries';
import { billingMessages } from '../../../messages';
import { SupplierReference } from '..';

interface Params {
  key: SupplierReference;
  value: string | null;
}

interface Submit {
  _update: (input: Params) => Promise<void>;
  updateLoading: boolean;
  updateError?: ApolloError;
}

export const useUpdate = (): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { data } = useQuery<
    companyBillingConfigurationQuery,
    companyBillingConfigurationQueryVariables
  >(COMPANY_BILLING_CONFIGURATION_QUERY, {
    variables: { companyId },
  });

  const [
    updateCompanyInvoiceReference,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    updateCompanyContractReferenceMutation,
    updateCompanyContractReferenceMutationVariables
  >(UPDATE_COMPANY_CONTRACT_REFERENCE_MUTATION, {
    refetchQueries: [
      {
        query: COMPANY_BILLING_CONFIGURATION_QUERY,
        variables: { companyId },
      },
    ],
    awaitRefetchQueries: true,
  });

  const currentReferences = Object.fromEntries(
    Object.entries(data?.company?.supplierReferenceMapping || {}).filter(
      ([key]) => key !== '__typename',
    ),
  );

  const _update = async (input: Params) => {
    try {
      const res = await updateCompanyInvoiceReference({
        variables: {
          id: companyId,
          contractReference: {
            ...currentReferences,
            [input.key]: input.value,
          },
        },
      });
      const references =
        res.data?.updateCompany?.company.supplierReferenceMapping;
      if (!references) {
        return;
      }
      send({
        message: formatMessage(billingMessages.updateReferenceSuccess),
        type: 'success',
      });
    } catch {
      // Do nothing
    }
  };

  return {
    _update,
    updateLoading,
    updateError,
  };
};
