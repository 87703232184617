import { useMutation } from '@apollo/client';
import { Form, Formik } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { assert, toDecimalFraction, toEffectiveUntil } from '@frontend/utils';
import {
  addEmploymentModalQuery,
  addEmploymentModalQueryVariables,
  addEmploymentMutation,
  addEmploymentMutationVariables,
} from 'app/apollo/graphql/types';
import { employmentMessages } from 'app/messages/employees';
import { formMessages, formSubmitMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { useNotification } from 'features/notifications';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { EmploymentFormFields } from '../EmploymentFormFields';
import { MEMBERSHIP_EMPLOYMENTS_QUERY } from '../graphql/queries';
import { employmentValidationSchema } from '../utils/employment-validation-schema';
import { ADD_EMPLOYMENT_MUTATION } from './graphql/mutations';
import { ADD_EMPLOYMENT_MODAL_QUERY } from './graphql/queries';

interface FormValues {
  rate: number;
  effectiveDate?: string;
  effectiveThrough?: string;
}

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

export const AddEmploymentModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
}) => {
  const { send } = useNotification();
  const intl = useIntl();
  const {
    params: { companyId, userAccountId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const [createEmployment, { error: submissionError }] = useMutation<
    addEmploymentMutation,
    addEmploymentMutationVariables
  >(ADD_EMPLOYMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: MEMBERSHIP_EMPLOYMENTS_QUERY,
        variables: { companyId, userAccountId },
      },
    ],
  });

  const { data } = useQuery<
    addEmploymentModalQuery,
    addEmploymentModalQueryVariables
  >(ADD_EMPLOYMENT_MODAL_QUERY, {
    skip: !companyId,
    variables: { userAccountId, companyId },
  });

  const employeeName = data?.membership
    ? `${data.membership.givenName} ${data.membership.lastName}`
    : '';

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          rate: 100,
        }}
        validationSchema={employmentValidationSchema(intl)}
        validateOnMount
        onSubmit={async ({ effectiveThrough, effectiveDate, rate }) => {
          try {
            assert(effectiveDate != null);

            await createEmployment({
              variables: {
                input: {
                  userAccountId,
                  companyId,
                  effectiveDate,
                  effectiveUntil: effectiveThrough
                    ? toEffectiveUntil(effectiveThrough)
                    : null,
                  rate: rate ? toDecimalFraction(rate) : undefined,
                },
              },
            });
            send({
              message: intl.formatMessage(
                formSubmitMessages.addEmploymentSuccess,
              ),
              type: 'success',
            });
            onRequestClose();
          } catch {
            // do nothing
          }
        }}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <ModalHeader>
              <FormattedMessage
                {...employmentMessages.addEmploymentForName}
                values={{ employeeName }}
              />
            </ModalHeader>
            <ModalBody>
              <EmploymentFormFields />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  filled
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
