import { Table, Td, Th, Tr } from '@frontend/ui';
import {
  bonusSalaryExchangesTableQuery,
  bonusSalaryExchangesTableQueryVariables,
  FlexSalaryExchangeRequestStatus,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { salaryExchangeProviderMessages } from 'app/messages/salary-exchange';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { companySalaryExchangeMessages } from '../messages';
import { BONUS_SALARY_EXCHANGES_TABLE_QUERY } from './graphql/queries';
import { updateQuery } from './utils/update-query';

export const BonusSalaryExchanges: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const [suspend, setSuspend] = useState(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const { data, previousData, loading, fetchMore } = useQuery<
    bonusSalaryExchangesTableQuery,
    bonusSalaryExchangesTableQueryVariables
  >(BONUS_SALARY_EXCHANGES_TABLE_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend,
    variables: {
      companyId,
      first: Number(perPage),
      statuses: [FlexSalaryExchangeRequestStatus.APPROVED],
    },
    onCompleted: () => setSuspend(false),
  });

  const _data = data ?? previousData;

  if (!_data?.bonusSalaryExchangeRequestsHistory) {
    return null;
  }

  const { edges, pageInfo } = _data?.bonusSalaryExchangeRequestsHistory ?? {};

  const bonusSalaryExchanges = edges?.map(edge => edge.node) ?? [];

  return (
    <>
      {loading && <TopLoading />}
      <Table
        navigation={
          <TableNavigation
            perPage={perPage}
            setPerPage={setPerPage}
            pageInfo={pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: pageInfo.endCursor,
                },
                updateQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: pageInfo.startCursor,
                  first: undefined,
                  last: Number(perPage),
                },
                updateQuery,
              });
            }}
          />
        }
      >
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.date} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th type="number">
              <FormattedMessage {...companySalaryExchangeMessages.deduction} />
            </Th>
            <Th type="number">
              <FormattedMessage {...companySalaryExchangeMessages.premium} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.via} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {!bonusSalaryExchanges?.length ? (
            <Tr>
              <Td>
                <FormattedMessage
                  {...companySalaryExchangeMessages.noSalaryExchangeItems}
                />
              </Td>
            </Tr>
          ) : (
            bonusSalaryExchanges.map(item => (
              <Tr key={item.id}>
                <Td>
                  <FormattedDate value={item.effectiveDate} />
                </Td>
                <Td>
                  {item.membership.givenName} {item.membership.lastName}
                </Td>
                <Td type="number">
                  <FormattedCurrency
                    currency="SEK"
                    value={item.deductionValue}
                  />
                </Td>
                <Td type="number">
                  <FormattedCurrency currency="SEK" value={item.premium} />
                </Td>
                <Td>
                  <FormattedMessage
                    select={item.provider}
                    messages={salaryExchangeProviderMessages}
                  />
                </Td>
              </Tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  );
};
