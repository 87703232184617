import { Td, Tr } from '@frontend/ui';
import { toNumber } from '@frontend/utils';
import { invoiceLinesQuery_invoiceLineSearch_InvoiceLineSearchResult_invoiceLines_InvoiceLineConnection2_edges_InvoiceLineEdge2_node_InvoiceLine2 as InvoiceLine } from 'app/apollo/graphql/types';
import { FormattedCurrency, FormattedIdentityNumber } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { FormattedDate } from 'react-intl';

interface Props {
  invoiceLine: InvoiceLine;
}

export const InvoiceLinesTableRow: React.FC<Props> = ({
  invoiceLine: {
    naturalPersonIdentifier,
    firstName,
    lastName,
    name,
    startDate,
    endDate,
    priceAmount,
    totalAmountCurrency,
    quantity,
    supplier,
    vat,
  },
}) => {
  const formattedPin = naturalPersonIdentifier ? (
    <FormattedIdentityNumber value={naturalPersonIdentifier.substring(6)} />
  ) : null;
  const hasEmployeeInfo = !!formattedPin || !!firstName || !!lastName;
  const vatMultiplier = 1 + (toNumber(vat) ?? 0);
  const linePrice =
    (toNumber(priceAmount) ?? 0) * vatMultiplier * (toNumber(quantity) ?? 0);

  return (
    <Tr>
      <Td>
        {hasEmployeeInfo ? (
          <>
            {formattedPin}
            {(firstName || lastName) && (
              <div>
                {firstName}
                {!!firstName && ' '}
                {lastName}
              </div>
            )}
          </>
        ) : (
          <NoValue />
        )}
      </Td>
      <Td multiline>{name ?? <NoValue />}</Td>
      <Td>{supplier ?? <NoValue />}</Td>
      <Td>
        {startDate && endDate ? (
          <>
            <FormattedDate value={startDate} />
            <span> – </span>
            <FormattedDate value={endDate} />
          </>
        ) : (
          <NoValue />
        )}
      </Td>
      <Td>
        {totalAmountCurrency && linePrice ? (
          <FormattedCurrency
            value={linePrice}
            currency={totalAmountCurrency}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
          />
        ) : (
          <NoValue />
        )}
      </Td>
    </Tr>
  );
};
