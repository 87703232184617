import { ApolloError, useMutation } from '@apollo/client';
import { toDecimalFraction, toNumber } from '@frontend/utils';
import {
  editProposalSalaryExchangeBenefitMutation,
  editProposalSalaryExchangeBenefitMutationVariables,
  UpdateProposalSalaryExchangeBenefitInput,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitMatchParams } from 'app/pages/sme/company/proposal/benefits/benefit';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { clearProposalCache } from '../../../../../utils/clear-proposal-cache';
import { FormValues } from '../../../form/salary-exchange';
import { EDIT_PROPOSAL_SALARY_EXCHANGE_BENEFIT_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

interface Params {
  params: BenefitMatchParams;
}

export const useSubmit = ({ params }: Params): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [editBenefit, { error: submissionError }] = useMutation<
    editProposalSalaryExchangeBenefitMutation,
    editProposalSalaryExchangeBenefitMutationVariables
  >(EDIT_PROPOSAL_SALARY_EXCHANGE_BENEFIT_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals'],
    }),
  });

  const submit = async (formValues: FormValues) => {
    try {
      const input: UpdateProposalSalaryExchangeBenefitInput = {
        id: params.benefitId,
        automaticApproval: formValues.automaticApproval,
        benefitPackageIds: formValues.benefitPackageIds,
        incomeMinIBB: toNumber(formValues.incomeMinIbb) ?? 0,
        pensionToSalaryMax:
          toNumber(toDecimalFraction(formValues.pensionToSalaryMax)) ?? 0,
        ongoingMax: formValues.ongoingMax,
        pensionTaxBenefitShare:
          toNumber(toDecimalFraction(formValues.pensionTaxBenefitShare)) ?? 0,
        premiumWaiver: formValues.premiumWaiver,
        retirementAge: toNumber(formValues.retirementAge) ?? 0,
        salaryMultiplier: toNumber(formValues.salaryMultiplier) ?? 0,
      };

      const res = await editBenefit({
        variables: { benefitId: params.benefitId, input },
      });

      if (!res.data?.updateProposalSalaryExchangeBenefit) {
        return;
      }

      send({
        message: formatMessage(smeBenefitFormMessages.benefitSaved),
        type: 'success',
      });
      push(`/sme/${params.companyId}/proposals/${params.proposalId}/benefits`);
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
