import { ApolloError, useMutation } from '@apollo/client';
import { toDecimalFraction, toNumber } from '@frontend/utils';
import {
  CreateProposalSalaryExchangeBenefitInput,
  createProposalSalaryExchangeBenefitMutation,
  createProposalSalaryExchangeBenefitMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { clearProposalCache } from '../../../../../utils/clear-proposal-cache';
import { FormValues } from '../../../form/salary-exchange';
import { CREATE_PROPOSAL_SALARY_EXCHANGE_BENEFIT_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

interface Params {
  params: MatchParams;
}

export const useSubmit = ({ params }: Params): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createBenefit, { error: submissionError }] = useMutation<
    createProposalSalaryExchangeBenefitMutation,
    createProposalSalaryExchangeBenefitMutationVariables
  >(CREATE_PROPOSAL_SALARY_EXCHANGE_BENEFIT_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals', 'validity'],
    }),
  });

  const submit = async (formValues: FormValues) => {
    try {
      const input: CreateProposalSalaryExchangeBenefitInput = {
        automaticApproval: formValues.automaticApproval,
        benefitPackageIds: formValues.benefitPackageIds,
        incomeMinIBB: toNumber(formValues.incomeMinIbb) ?? 0,
        pensionToSalaryMax:
          toNumber(toDecimalFraction(formValues.pensionToSalaryMax)) ?? 0,
        ongoingMax: formValues.ongoingMax,
        pensionTaxBenefitShare:
          toNumber(toDecimalFraction(formValues.pensionTaxBenefitShare)) ?? 0,
        premiumWaiver: formValues.premiumWaiver,
        proposalId: params.proposalId,
        retirementAge: toNumber(formValues.retirementAge) ?? 0,
        salaryMultiplier: toNumber(formValues.salaryMultiplier) ?? 0,
      };

      const res = await createBenefit({
        variables: { input },
      });

      if (!res.data?.createProposalSalaryExchangeBenefit) {
        return;
      }

      send({
        message: formatMessage(smeBenefitFormMessages.benefitSaved),
        type: 'success',
      });
      push(`/sme/${params.companyId}/proposals/${params.proposalId}/benefits`);
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
