import { ApolloError, useMutation } from '@apollo/client';
import {
  deleteCompanyBillingConfigurationMutation,
  deleteCompanyBillingConfigurationMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';
import { useRouteMatch } from 'react-router';

import { DELETE_COMPANY_BILLING_CONFIGURATION_MUTATION } from '../../../graphql/mutations';
import { COMPANY_BILLING_CONFIGURATION_QUERY } from '../../../graphql/queries';
import { billingMessages } from '../../../messages';

interface Submit {
  _delete: (id: string) => Promise<void>;
  deleteError: ApolloError | undefined;
  deleteLoading: boolean;
}

export const useDelete = (): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();
  const { confirm } = useConfirm();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const [deleteCompanyBillingConfiguration, { loading: deleteLoading, error }] =
    useMutation<
      deleteCompanyBillingConfigurationMutation,
      deleteCompanyBillingConfigurationMutationVariables
    >(DELETE_COMPANY_BILLING_CONFIGURATION_MUTATION, {
      refetchQueries: [
        {
          query: COMPANY_BILLING_CONFIGURATION_QUERY,
          variables: { companyId },
        },
      ],
      awaitRefetchQueries: true,
    });

  const _delete = async (id: string) => {
    try {
      const confirmed = await confirm({
        description: formatMessage(billingMessages.confirmDeleteBillingEmail),
      });

      if (!confirmed) {
        return;
      }

      const res = await deleteCompanyBillingConfiguration({
        variables: { input: { id } },
      });
      if (!res?.data?.deleteCompanyBillingConfiguration) {
        throw new Error();
      }
      send({
        type: 'success',
        message: formatMessage(billingMessages.deleteBillingEmailSuccess),
      });
    } catch {
      // Do nothing
    }
  };

  return {
    _delete,
    deleteLoading,
    deleteError: error,
  };
};
