import { ApolloError, useMutation } from '@apollo/client';
import { Wellness, WellnessInput } from '@frontend/benefit-types';
import { toNumber } from '@frontend/utils';
import {
  companyEditBenefitQuery_flexBenefit_FlexBenefit as Benefit,
  FlexUpdateBenefitInput,
  updateBenefitMutation,
  updateBenefitMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/benefits/edit';
import { useIntl } from 'components/formats';
import format from 'date-fns/format';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { benefitFormMessages } from '../../../form/messages';
import { FormValues } from '../../../form/wellness';
import { clearBenefitsCache } from '../../../utils/clear-benefit-cache';
import { UPDATE_BENEFIT_MUTATION } from '../../graphql/mutations';

interface Submit {
  submit: (values: FormValues, benefit: Wellness<Benefit>) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [updateBenefit, { error: submissionError }] = useMutation<
    updateBenefitMutation,
    updateBenefitMutationVariables
  >(UPDATE_BENEFIT_MUTATION, {
    update: clearBenefitsCache({
      companyId: params.companyId,
    }),
  });

  const submit = async (values: FormValues, benefit: Wellness<Benefit>) => {
    try {
      const configEffectiveDate = format(
        new Date(values.effectiveFrom),
        'yyyy-MM-dd',
      );
      const input: WellnessInput<FlexUpdateBenefitInput> = {
        id: params.benefitId,
        configEffectiveDate,
        configuration: {
          allowance: toNumber(values.allowance),
        },
        entitlementRules: {
          accountingCostIds: benefit.entitlementRules?.accountingCostIds ?? [],
          benefitPackageIds: values.benefitPackageIds,
          ouIds: benefit.entitlementRules?.ouIds ?? [],
        },
      };

      await updateBenefit({
        variables: {
          input,
        },
      });

      send({
        message: formatMessage(benefitFormMessages.benefitSaved),
        type: 'success',
      });
      push(
        `/companies/${params.companyId}/benefit-packages/benefits/${params.benefitId}/version-history/${configEffectiveDate}`,
      );
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
