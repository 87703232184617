import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  createNordnetAgreementMutation,
  createNordnetAgreementMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { AGREEMENTS_LIST_QUERY } from '../../../graphql/queries';
import { agreementMessages } from '../../../messages';
import { FormValues } from '..';
import { CREATE_NORDNET_AGREEMENT_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { send } = useNotification();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const [createNordnetAgreement, { error }] = useMutation<
    createNordnetAgreementMutation,
    createNordnetAgreementMutationVariables
  >(CREATE_NORDNET_AGREEMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: AGREEMENTS_LIST_QUERY,
        variables: {
          id: companyId,
        },
      },
    ],
  });

  const submit = async (values: FormValues) => {
    try {
      await createNordnetAgreement({
        variables: {
          input: {
            companyId,
            startDate: values.startDate,
          },
        },
      });

      send({
        message: formatMessage(agreementMessages.createNordnetAgreementSuccess),
        type: 'success',
      });

      push(`/companies/${companyId}/settings/agreements`);
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    error,
  };
};
