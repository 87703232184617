import { DescriptionList, Section, SectionHeader } from '@frontend/ui';
import { toEffectiveUntil } from '@frontend/utils';
import {
  meetingStatisticsQuery,
  meetingStatisticsQuery_meetingStatistics_MeetingStatistics as MeetingStatisticsType,
  meetingStatisticsQueryVariables,
} from 'app/apollo/graphql/types';
import {
  advisorMessages,
  meetingStatisticsMessages,
} from 'app/messages/advisor';
import { useQuery } from 'app/utils/use-query';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import React from 'react';

import { AdviceFilterChips } from '../components/Filter';
import { MEETING_STATISTICS_QUERY } from '../graphql/queries';
import { FilterParams } from '../utils/use-filter-params';

export type MeetingStatisticsKey =
  | 'numberOfInvitationsSent'
  | 'numberOfFirstRemindersSent'
  | 'numberOfSecondRemindersSent'
  | 'numberOfCreatedBookings'
  | 'numberOfBookedMeetings'
  | 'numberOfCancelledMeetings'
  | 'numberOfConfirmedMeetings';

interface Props {
  clearFilter: () => void;
  filterParams: FilterParams;
  toggleFilterSideSheet: () => void;
}

export const MeetingStatistics: React.FC<Props> = ({
  clearFilter,
  filterParams,
  toggleFilterSideSheet,
}) => {
  const { data, loading, error } = useQuery<
    meetingStatisticsQuery,
    meetingStatisticsQueryVariables
  >(MEETING_STATISTICS_QUERY, {
    variables: {
      from: filterParams.from || undefined,
      to: filterParams.to ? toEffectiveUntil(filterParams.to) : undefined,
      advisorId: filterParams.advisor || undefined,
      eventTypeCategories:
        filterParams.eventTypeCategories.length > 0
          ? filterParams.eventTypeCategories
          : undefined,
      companyIds:
        filterParams.companyIds.length > 0
          ? filterParams.companyIds
          : undefined,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _meetingStatistics =
    data?.meetingStatistics ||
    ({ numberOfRemindersSent: {} } as MeetingStatisticsType);

  const meetingStatistics = {
    numberOfInvitationsSent: _meetingStatistics.numberOfInvitationsSent,
    numberOfFirstRemindersSent: _meetingStatistics.numberOfRemindersSent.first,
    numberOfSecondRemindersSent:
      _meetingStatistics.numberOfRemindersSent.second,
    numberOfCreatedBookings: _meetingStatistics.numberOfCreatedBookings,
    numberOfBookedMeetings: _meetingStatistics.numberOfBookedMeetings,
    numberOfCancelledMeetings: _meetingStatistics.numberOfCancelledMeetings,
    numberOfConfirmedMeetings: _meetingStatistics.numberOfConfirmedMeetings,
  };

  const hasPeriodFilter = !!(filterParams.from || filterParams.to);

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <Section>
        <SectionHeader>
          <FormattedMessage {...advisorMessages.meetingStatistics} />
        </SectionHeader>

        <ChipsWrapper belowSearch>
          <AdviceFilterChips
            clearFilter={clearFilter}
            toggleFilterSideSheet={toggleFilterSideSheet}
          />
        </ChipsWrapper>
        <DescriptionList
          alignLeft
          entries={Object.entries(meetingStatistics).map(
            ([key, value]: [MeetingStatisticsKey, number]) => ({
              id: key,
              label: (
                <FormattedMessage
                  select={key}
                  messages={meetingStatisticsMessages}
                  values={{ hasPeriodFilter }}
                />
              ),
              value,
            }),
          )}
        />
      </Section>
    </>
  );
};
