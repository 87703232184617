import { Chip, Table as TableUI, Td, Th, Tr } from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import { internalPostSummary, pageInfoDetails } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { SearchField } from 'components/SearchField';
import { TableNavigation } from 'components/TableNavigation';
import { useCurrentUser } from 'contexts/current-permissions';
import { MatchParams } from 'features/companies/company';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { internalNewsMessages } from '../../messages';
import { hasInternalNewsPermissions } from '../../utils/has-internal-news-permissions';

const StyledChip = styled(Chip)`
  margin-bottom: 0;
  color: ${p => p.theme.onGreenContainer};
  background-color: ${p => p.theme.greenContainer};
`;

interface Props {
  internalNews: internalPostSummary[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
}

export const Table: React.FC<Props> = ({
  onNextPage,
  pageInfo,
  onPreviousPage,
  internalNews,
}) => {
  const {
    params: { companyId },
    url,
  } = useRouteMatch<MatchParams>();
  const history = useHistory();
  const { permissions } = useCurrentUser();
  const { canUpdate } = hasInternalNewsPermissions(permissions);

  return (
    <>
      <SearchField
        label={<FormattedMessage {...internalNewsMessages.searchArticles} />}
      />
      {canUpdate && (
        <ChipsWrapper belowSearch>
          <AssistChip
            text={<FormattedMessage {...internalNewsMessages.createNews} />}
            leadingIcon={addCircle}
            onClick={() =>
              history.push(`/companies/${companyId}/internal-news/create`)
            }
          />
        </ChipsWrapper>
      )}
      <TableUI
        size="xsmall"
        navigation={
          <TableNavigation
            pageInfo={pageInfo}
            onNextPage={onNextPage}
            onPreviousPage={onPreviousPage}
          />
        }
      >
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: '7.5rem' }} />
          <col style={{ width: '7.5rem' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th />
            <Th type="number">
              <FormattedMessage {...commonMessages.date} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {internalNews.length === 0 ? (
            <Tr>
              <Td>
                <FormattedMessage {...internalNewsMessages.noArticlesMatch} />
              </Td>
            </Tr>
          ) : (
            internalNews.map(item => (
              <Tr
                linked
                key={item.id}
                onClick={() => history.push(`${url}/${item.id}`)}
              >
                <Th multiline>{item.title}</Th>
                <Td>
                  {item.important && (
                    <StyledChip
                      text={
                        <FormattedMessage {...internalNewsMessages.important} />
                      }
                      primary
                    />
                  )}
                </Td>
                <Td type="number">
                  <FormattedDate value={item.publishedAt} />
                </Td>
              </Tr>
            ))
          )}
        </tbody>
      </TableUI>
    </>
  );
};
