import { work } from '@frontend/ui/icons';
import { EmptyState as EmptyStateComponent } from 'components/EmptyState';
import { FormattedMessage } from 'components/formats';
import { smeCompanyMessages } from 'features/sme/messages/sme';
import React from 'react';

export const EmptyState: React.FC = () => (
  <EmptyStateComponent
    icon={work}
    title={<FormattedMessage {...smeCompanyMessages.emptyStateHeader} />}
  >
    <FormattedMessage {...smeCompanyMessages.emptyStateDescription} />
  </EmptyStateComponent>
);
