import { BenefitType, PensionLayer, TaxClass } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const invoiceLinesMessages = defineMessages({
  noInvoiceLines: {
    id: 'invoiceLinesMessages.noInvoiceLines',
    defaultMessage: 'Det finns inga fakturarader att visa',
  },
  nResults: {
    id: 'invoiceLinesMessages.nResults',
    defaultMessage: '{count, plural, one {# resultat} other {# resultat}}',
  },
  employee: {
    id: 'invoiceLinesMessages.employee',
    defaultMessage: 'Medarbetare',
  },
  description: {
    id: 'invoiceLinesMessages.description',
    defaultMessage: 'Benämning',
  },
  period: {
    id: 'invoiceLinesMessages.period',
    defaultMessage: 'Period',
  },
  amountIncludingVat: {
    id: 'invoiceLinesMessages.amountIncludingVat',
    defaultMessage: 'Belopp inkl. moms',
  },
  nameOrPin: {
    id: 'invoiceLinesMessages.nameOrPin',
    defaultMessage: 'Namn eller personnummer',
  },
  noInvoiceLineMatch: {
    id: 'invoiceLinesMessages.noInvoiceLineMatch',
    defaultMessage: 'Inga fakturarader matchar sökningen',
  },
  selectPeriod: {
    id: 'invoiceLinesMessages.selectPeriod',
    defaultMessage: 'Välj period',
  },
  selectSupplier: {
    id: 'invoiceLinesMessages.selectSupplier',
    defaultMessage: 'Välj leverantör',
  },
  searchCostCenter: {
    id: 'invoiceLinesMessages.searchCostCenter',
    defaultMessage: 'Sök kostnadsställe',
  },
  selectTaxClass: {
    id: 'invoiceLinesMessages.selectTaxClass',
    defaultMessage: 'Välj skatteklass',
  },
  selectPensionAct: {
    id: 'invoiceLinesMessages.selectPensionAct',
    defaultMessage: 'Välj pensionslager',
  },
  selectLastPaymentDate: {
    id: 'invoiceLinesMessages.selectLastPaymentDate',
    defaultMessage: 'Välj sista betaldatum',
  },
  selectInvoiceDate: {
    id: 'invoiceLinesMessages.selectInvoiceDate',
    defaultMessage: 'Välj fakturadatum',
  },
  allOfTheAbove: {
    id: 'invoiceLinesMessages.allOfTheAbove',
    defaultMessage: 'Alla ovanstående',
  },
  downloadExcel: {
    id: 'invoiceLinesMessages.downloadExcel',
    defaultMessage: 'Ladda ned som Excel',
  },
  downloadCsv: {
    id: 'invoiceLinesMessages.downloadCsv',
    defaultMessage: 'Ladda ned som CSV',
  },
  benefitPackage: {
    id: 'invoiceLinesMessages.benefitPackage',
    defaultMessage: 'Förmånspaket',
  },
  supplier: {
    id: 'invoiceLinesMessages.supplier',
    defaultMessage: 'Leverantör',
  },
  costCenter: {
    id: 'invoiceLinesMessages.costCenter',
    defaultMessage: 'Kostnadsställe',
  },
  taxClass: {
    id: 'invoiceLinesMessages.taxClass',
    defaultMessage: 'Skatteklass',
  },
  lastPaymentDate: {
    id: 'invoiceLinesMessages.lastPaymentDate',
    defaultMessage: 'Sista betaldatum',
  },
  pensionAct: {
    id: 'invoiceLinesMessages.pensionAct',
    defaultMessage: 'Pensionslager',
  },
  invoiceDate: {
    id: 'invoiceLinesMessages.invoiceDate',
    defaultMessage: 'Fakturadatum',
  },
  insurancesAndServices: {
    id: 'invoiceLinesMessages.insurancesAndServices',
    defaultMessage: 'Försäkringar och tjänster',
  },
});

export const benefitTypeMessages = defineMessages<BenefitType>({
  SICKNESS_INSURANCE: {
    id: 'benefitTypeMessages.SICKNESS_INSURANCE',
    defaultMessage: 'Sjukförsäkring',
  },
  PREMIUM_WAIVER: {
    id: 'benefitTypeMessages.PREMIUM_WAIVER',
    defaultMessage: 'Premiebefrielse',
  },
  TGL: { id: 'benefitTypeMessages.TGL', defaultMessage: 'TGL' },
  ACCIDENT_INSURANCE: {
    id: 'benefitTypeMessages.ACCIDENT_INSURANCE',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  HEALTHCARE_INSURANCE: {
    id: 'benefitTypeMessages.HEALTHCARE_INSURANCE',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  LIFE_INSURANCE: {
    id: 'benefitTypeMessages.LIFE_INSURANCE',
    defaultMessage: 'Livförsäkring',
  },
  PENSION: {
    id: 'benefitTypeMessages.PENSION',
    defaultMessage: 'Tjänstepension',
  },
  FAMILY_PENSION: {
    id: 'benefitTypeMessages.FAMILY_PENSION',
    defaultMessage: 'Familjepension',
  },
  SICKNESS_AND_ACCIDENT: {
    id: 'benefitTypeMessages.SICKNESS_AND_ACCIDENT',
    defaultMessage: 'Sjuk- och Olycksfall',
  },
  COMEBACK_WITH_COUNSELING: {
    id: 'benefitTypeMessages.COMEBACK_WITH_COUNSELING',
    defaultMessage: 'Comeback med Samtalsstod',
  },
  K_SICKNESS: {
    id: 'benefitTypeMessages.K_SICKNESS',
    defaultMessage: 'K-Sjuk',
  },
  SALARY_EXCHANGE: {
    id: 'benefitTypeMessages.SALARY_EXCHANGE',
    defaultMessage: 'Löneväxling',
  },
  MISC: {
    id: 'benefitTypeMessages.MISC',
    defaultMessage: 'Övrigt',
  },
});

export const taxClassMessages = defineMessages<TaxClass>({
  PENSION_INSURANCE: {
    id: 'taxClassMessages.PENSION_INSURANCE',
    defaultMessage: 'Pensionsförsäkring',
  },
  ENDOWMENT_INSURANCE: {
    id: 'taxClassMessages.ENDOWMENT_INSURANCE',
    defaultMessage: 'Kapitalförsäkring',
  },
  NONE: {
    id: 'taxClassMessages.NONE',
    defaultMessage: 'Ingendera',
  },
});

export const pensionActMessages = defineMessages<
  Exclude<PensionLayer, 'GENERAL'>
>({
  OCCUPATIONAL: {
    id: 'pensionActMessages.OCCUPATIONAL',
    defaultMessage: 'Tjänstepension',
  },
  PRIVATE: {
    id: 'pensionActMessages.PRIVATE',
    defaultMessage: 'Privat pension',
  },
});
