import gql from 'graphql-tag';

export const SME_COMPANY_FCFW_QUERY = gql`
  query smeCompanyFcfwQuery($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      memberships {
        id
        firstName
        lastName
        isFcfw
        personalIdentityNumber
      }
    }
  }
`;

export const SME_COMPANY_FCFW_COMPANY_SIZE_QUERY = gql`
  query smeCompanyFcfwCompanySizeQuery($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      companySize
    }
  }
`;
