import { GroupAgreementType, ManagingCompany } from 'app/apollo/graphql/types';

const NLP_CODE = '9918';

const isNlpAgreement = (agreementType: GroupAgreementType) =>
  agreementType.includes(NLP_CODE);

/**
 * Retrieves the array of GroupAgreementType for group agreements based on the managing company.
 *
 * Node managed companies should only have node agreements and vice versa for NLP.
 */
export const getGroupAgreementTypesForCompanyManagedBy = (
  managedBy: ManagingCompany,
): GroupAgreementType[] =>
  Object.keys(GroupAgreementType)
    .filter(type =>
      managedBy === ManagingCompany.NLP
        ? isNlpAgreement(GroupAgreementType[type])
        : !isNlpAgreement(GroupAgreementType[type]),
    )
    .map((k: GroupAgreementType) => k);
