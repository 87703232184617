import { useFragment } from '@apollo/client';
import { CheckboxGroupField, Form, Formik } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter, ReadOnlyField } from '@frontend/ui';
import { formatNaturalPersonIdentifier } from '@frontend/utils';
import {
  administratorDetails as AdministratorDetails,
  editAdministratorQuery,
  editAdministratorQueryVariables,
  MembershipRole,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { EMPLOYEE_ROLE } from 'app/utils/constants';
import { usePersistedValue } from 'app/utils/use-persisted-value';
import { useQuery } from 'app/utils/use-query';
import { useStripSearchParams } from 'app/utils/use-strip-search-params';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { afterFadeout, Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useMemo } from 'react';
import { RouteComponentProps, useLocation, useParams } from 'react-router';

import { MatchParams } from '../..';
import {
  administratorDetails,
  EDIT_ADMINISTRATOR_QUERY,
} from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export interface FormValues {
  roles: MembershipRole[];
  userAccountId: string;
  submissionError?: string;
}

export const EditAdministratorModal: React.FC = () => {
  const { formatMessage } = useIntl();

  const { companyId } = useParams<MatchParams>();
  const location = useLocation();

  const stripSearchParams = useStripSearchParams();

  const handleClose = () => {
    stripSearchParams(['edit-administrator']);
  };

  /**
   * Delay resetting membershipId and userAccountId to prevent
   * resetting data when the modal is closing.
   */
  const clearUser = () => {
    stripSearchParams(['membershipId', 'userAccountId']);
  };

  const onCompleted = () => {
    handleClose();
    afterFadeout(() => {
      clearUser();
    });
  };

  const { submit } = useSubmit({
    companyId,
    onCompleted,
  });

  const {
    'edit-administrator': editAdministrator,
    membershipId,
    userAccountId,
  } = qs.parse(location.search);

  const isOpen = editAdministrator === 'true';

  const { data: cachedData } = useFragment<AdministratorDetails>({
    fragment: administratorDetails,
    from: {
      __typename: 'Membership',
      id: membershipId,
    },
  });

  const hasCachedData = cachedData && !!Object.keys(cachedData).length;

  const { data, loading, error } = useQuery<
    editAdministratorQuery,
    editAdministratorQueryVariables
  >(EDIT_ADMINISTRATOR_QUERY, {
    variables: {
      companyId,
      userAccountId,
    },
    skip: hasCachedData || !isOpen,
  });

  const membership = hasCachedData ? cachedData : data?.membership;

  const roles = useMemo(
    () => membership?.roles?.flatMap((role?: MembershipRole) => role ?? []),
    [membership],
  );
  /**
   * Present initial roles as read-only field.
   * If the roles data is updated but the user account remains the same,
   * this is due to a successful role update submission which should not
   * be presented while modal is fading out.
   */
  const initialRoles = usePersistedValue(roles, userAccountId);

  if (!hasCachedData && !data && loading) {
    return <TopLoading />;
  }

  const [nid] = membership?.naturalPersonIdentifiers ?? [];
  const naturalPersonIdentifier = nid ? formatNaturalPersonIdentifier(nid) : '';
  const employee =
    membership?.givenName && membership?.lastName && naturalPersonIdentifier
      ? `${membership?.givenName} ${membership?.lastName} (${naturalPersonIdentifier})`
      : naturalPersonIdentifier ?? '';

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={{
        userAccountId,
        roles:
          initialRoles?.flatMap((role?: MembershipRole) => role ?? []) ?? [],
      }}
      onSubmit={submit}
    >
      {({ dirty, errors, isSubmitting }) => (
        <Modal
          size="medium"
          isOpen={isOpen}
          onRequestClose={() => {
            handleClose();
            afterFadeout(() => {
              clearUser();
            });
          }}
        >
          <Form>
            <ModalHeader>
              <FormattedMessage
                {...companyMessages.selectRoles}
                values={{
                  employee,
                }}
              />
            </ModalHeader>
            <ModalBody>
              {error && <GraphQlError inModal error={error} />}
              <ReadOnlyField
                label={<FormattedMessage {...companyMessages.currentRoles} />}
                value={initialRoles
                  ?.map(role =>
                    formatMessage({
                      select: role,
                      messages: EMPLOYEE_ROLE,
                    }),
                  )
                  .join(', ')}
              />
              <CheckboxGroupField
                name="roles"
                options={Object.values(MembershipRole).map(role => ({
                  label: formatMessage({
                    select: role,
                    messages: EMPLOYEE_ROLE,
                  }),
                  value: role,
                }))}
                label={<FormattedMessage {...companyMessages.membershipRole} />}
              />
              {errors.submissionError && (
                <NotificationCard type="error" inModal>
                  {errors.submissionError}
                </NotificationCard>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button
                  text
                  onClick={() => {
                    handleClose();
                    afterFadeout(() => {
                      clearUser();
                    });
                  }}
                >
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  text
                  type="submit"
                  loading={isSubmitting}
                  disabled={!dirty}
                >
                  <FormattedMessage {...commonMessages.confirm} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

interface getEditAdministratorLinkProps {
  location: RouteComponentProps['location'];
  membershipId: string;
  userAccountId: string;
}

export const getEditAdministratorLink = ({
  location,
  membershipId,
  userAccountId,
}: getEditAdministratorLinkProps): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({
    'edit-administrator': true,
    membershipId,
    userAccountId,
  }),
});
