import { ApolloError } from '@apollo/client';
import { PersonalAdvicePaymentMethod } from '@frontend/benefit-types';
import {
  CheckboxField,
  DatePickerField,
  Form,
  NumberField,
  RadioGroupField,
  SelectField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  Section,
  Subsection,
  SubsectionHeader,
  titleMediumCSS,
} from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell33, TextGrid } from 'components/GridCell';
import isAfter from 'date-fns/isAfter';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { BenefitPackagesSection } from '../components/BenefitPackagesSection';
import { UpcomingChangesNotification } from '../components/UpcomingChangesNotification';
import {
  benefitFormMessages,
  benefitPersonalAdviceMeetingFrequencyMessages,
  benefitPersonalAdvicePaymentMethodMessages,
} from '../messages';
import {
  BenefitPackageOption,
  PersonalAdviceMeetingFrequencyOptionValue,
} from '../types';
import { getEarliestRetroactiveDate } from '../utils';
import {
  PERSONAL_ADVICE_MEETING_FREQUENCY_OPTION_VALUES,
  PERSONAL_ADVICE_PAYMENT_METHODS,
  PERSONAL_ADVICE_PAYMENT_SUFFIX,
} from '../utils/constants';

const Subtitle = styled.div`
  ${titleMediumCSS};
  margin-bottom: 1rem;
`;

const SubField = styled.div`
  margin-left: 2.75rem;
  margin-top: 1rem;
`;

export interface FormValues {
  benefitPackageIds: string[];
  effectiveFrom: string;
  employmentStartFreePeriod: boolean;
  fee: string;
  meetingFrequencyMonths: PersonalAdviceMeetingFrequencyOptionValue | '';
  onboardingFreePeriod: boolean;
  onboardingFreePeriodStartDate: string;
  paymentMethod: PersonalAdvicePaymentMethod | '';
}

interface Props {
  benefitPackageOptions: BenefitPackageOption[];
  isEdit?: boolean;
  latestChangesEffectiveDate?: string | null;
  submissionError?: ApolloError;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    effectiveFrom: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test({
        name: 'valid date',
        message: intl.formatMessage(validationMessages.dateMaxThreeMonthsOld),
        test: (value: string) =>
          isAfter(new Date(value), new Date(getEarliestRetroactiveDate())),
      }),
    meetingFrequencyMonths: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    onboardingFreePeriodStartDate: Yup.date().when('onboardingFreePeriod', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    paymentMethod: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    fee: Yup.string().when('paymentMethod', {
      is: (value: PersonalAdvicePaymentMethod) => value !== 'CAPITAL_FEE',
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
  });

export const PersonalAdviceForm: React.FC<Props> = ({
  benefitPackageOptions,
  isEdit = false,
  latestChangesEffectiveDate,
  submissionError,
}) => {
  const { values, isValid, isSubmitting } = useFormikContext<FormValues>();

  const { formatMessage } = useIntl();

  return (
    <Form>
      <Section>
        <UpcomingChangesNotification
          latestChangesEffectiveDate={latestChangesEffectiveDate}
        />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.paymentMethod} />
          </SubsectionHeader>
          <RadioGroupField
            name="paymentMethod"
            options={PERSONAL_ADVICE_PAYMENT_METHODS.map(method => ({
              label: (
                <FormattedMessage
                  messages={benefitPersonalAdvicePaymentMethodMessages}
                  select={method}
                />
              ),
              value: method,
            }))}
            required
          />

          {values.paymentMethod && values.paymentMethod !== 'CAPITAL_FEE' && (
            <>
              <Subtitle>
                <FormattedMessage {...benefitFormMessages.fee} />
              </Subtitle>
              <DescriptionWrapper>
                <FormattedMessage {...benefitFormMessages.feeDescription} />
              </DescriptionWrapper>
              <Grid>
                <GridCell33>
                  <NumberField
                    dense
                    required
                    name="fee"
                    label={<FormattedMessage {...benefitFormMessages.fee} />}
                    affix={formatMessage(
                      PERSONAL_ADVICE_PAYMENT_SUFFIX[values.paymentMethod],
                    )}
                  />
                </GridCell33>
              </Grid>
            </>
          )}

          <Subtitle>
            <FormattedMessage {...benefitFormMessages.freeAdviceAtLaunch} />
          </Subtitle>
          <CheckboxField
            name="onboardingFreePeriod"
            label={
              <FormattedMessage {...benefitFormMessages.onboadingFreePeriod} />
            }
            withMargin={false}
          />
          {!!values.onboardingFreePeriod && (
            <SubField>
              <Grid>
                <GridCell33>
                  <DatePickerField
                    dense
                    required
                    name="onboardingFreePeriodStartDate"
                    label={
                      <FormattedMessage
                        {...benefitFormMessages.onboardingFreePeriodStartDate}
                      />
                    }
                  />
                </GridCell33>
              </Grid>
            </SubField>
          )}
          <CheckboxField
            name="employmentStartFreePeriod"
            label={
              <FormattedMessage
                {...benefitFormMessages.employmentStartFreePeriod}
              />
            }
          />
        </Subsection>
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage {...benefitFormMessages.meetingFrequencyMonths} />
          </SubsectionHeader>
          <DescriptionWrapper>
            <FormattedMessage
              {...benefitFormMessages.meetingFrequencyMonthsDescription}
            />
          </DescriptionWrapper>
          <Grid>
            <GridCell33>
              <SelectField
                dense
                label={
                  <FormattedMessage
                    {...benefitFormMessages.meetingFrequencyMonthsLabel}
                  />
                }
                name="meetingFrequencyMonths"
                options={PERSONAL_ADVICE_MEETING_FREQUENCY_OPTION_VALUES.map(
                  frequency => ({
                    label: formatMessage({
                      messages: benefitPersonalAdviceMeetingFrequencyMessages,
                      select: frequency,
                    }),

                    value: frequency,
                  }),
                )}
                required
              />
            </GridCell33>
          </Grid>
        </Subsection>
        <BenefitPackagesSection
          benefitPackageOptions={benefitPackageOptions}
          name="benefitPackageIds"
        />
        <Subsection>
          <SubsectionHeader>
            <FormattedMessage
              {...(isEdit
                ? benefitFormMessages.effectiveEditDate
                : benefitFormMessages.effectiveStartDate)}
            />
          </SubsectionHeader>
          <TextGrid>
            <DescriptionWrapper>
              <FormattedMessage
                {...(isEdit
                  ? benefitFormMessages.effectiveEditDateDescription
                  : benefitFormMessages.effectiveStartDateDescription)}
              />
            </DescriptionWrapper>
          </TextGrid>
          <Grid>
            <GridCell33>
              <DatePickerField
                dense
                gridMargin
                label={
                  <FormattedMessage
                    {...(isEdit
                      ? benefitFormMessages.effectiveEditDateLabel
                      : benefitFormMessages.effectiveStartDateLabel)}
                  />
                }
                min={getEarliestRetroactiveDate()}
                name="effectiveFrom"
                required
                type="month"
              />
            </GridCell33>
          </Grid>
        </Subsection>
        {submissionError && <GraphQlError error={submissionError} />}
        <ButtonLayout>
          <Button
            loading={isSubmitting}
            filled
            type="submit"
            disabled={!isValid}
          >
            <FormattedMessage {...benefitFormMessages.save} />
          </Button>
        </ButtonLayout>
      </Section>
    </Form>
  );
};
