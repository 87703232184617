import {
  isAccidentInsuranceBenefit,
  isBenefitType,
  isDisabilityInsuranceBenefit,
  isEmployersPensionBenefit,
  isEpassiBikeBenefit,
  isHealthInsuranceBenefit,
  isLifeInsuranceBenefit,
  isOccupationalPensionBenefit,
  isSalaryExchangeBenefit,
  isSicknessAccidentInsuranceBenefit,
  isWellnessBenefit,
} from '@frontend/benefit-types';
import {
  benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
  ManagingCompany,
} from 'app/apollo/graphql/types';
import {
  commonBenefitMessages,
  sicknessBenefitLevelMessages,
  sicknessBenefitMessages,
  wellnessBenefitMessages,
} from 'app/messages/benefits';
import { translatePBBLevel } from 'app/utils/constants';
import { DetailCell as Cell } from 'components/DetailCell';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { useRemunerationTypes } from 'features/companies/company/utils/use-remuneration-types';
import React from 'react';

import {
  benefitFormMessages,
  benefitHealthcareLevelMessages,
} from '../../../form/messages';
import { OccupationalPensionDetailCell } from './OccupationalPensionDetailCell';
import { PersonalAdviceDetailCell } from './PersonalAdviceDetailCell';
import { SalaryExchangeDetailCell } from './SalaryExchangeDetailCell';

interface Props {
  benefit: Benefit;
  companyId: string;
  managedBy: ManagingCompany;
}

export const DetailCell: React.FC<Props> = ({
  companyId,
  benefit,
  managedBy,
}) => {
  const { remunerationTypes } = useRemunerationTypes({ companyId });

  let cell: React.ReactNode = null;
  if (!isBenefitType(benefit.type)) {
    return <NoValue />;
  }

  switch (benefit.type) {
    case 'advinans_salary_exchange':
      cell = isSalaryExchangeBenefit(benefit) && (
        <SalaryExchangeDetailCell
          companyId={companyId}
          salaryExchange={benefit}
        />
      );
      break;
    case 'advinans_occupational_pension':
      cell = isOccupationalPensionBenefit(benefit) && (
        <OccupationalPensionDetailCell
          managedBy={managedBy}
          occupationalPension={benefit}
        />
      );
      break;
    case 'employers_pension':
      cell = isEmployersPensionBenefit(benefit) && (
        <OccupationalPensionDetailCell
          managedBy={managedBy}
          occupationalPension={benefit}
        />
      );
      break;
    case 'euro_accident_accident':
      cell =
        isAccidentInsuranceBenefit(benefit) &&
        benefit.configuration?.data &&
        translatePBBLevel(benefit.configuration.data.level);
      break;
    case 'euro_accident_sickness_and_accident':
      cell =
        isSicknessAccidentInsuranceBenefit(benefit) &&
        benefit.configuration?.data &&
        translatePBBLevel(benefit.configuration.data.level);
      break;
    case 'euro_accident_plansjuk':
      if (
        isDisabilityInsuranceBenefit(benefit) &&
        benefit.configuration?.data
      ) {
        const configurationData = benefit.configuration.data;
        const sicknessPayrollElement = remunerationTypes.find(
          type => type.id === configurationData.payrollElementCode,
        );
        const { level, payrollElementMultiplier, extendedSickPay } =
          benefit.configuration.data;
        cell = (
          <Cell>
            <p>
              <FormattedMessage
                select={level}
                messages={sicknessBenefitLevelMessages}
              />
              {extendedSickPay && (
                <>
                  {' '}
                  <FormattedMessage
                    {...sicknessBenefitMessages.withExtendedSickPay}
                  />
                </>
              )}
            </p>
            {!!sicknessPayrollElement && !!payrollElementMultiplier && (
              <p>
                <FormattedMessage
                  {...commonBenefitMessages.benefitQualifyingIncomeTableDescription}
                  values={{
                    annualFactor: payrollElementMultiplier,
                    remunerationType: `${sicknessPayrollElement.id} ${
                      sicknessPayrollElement.name ?? ''
                    }`,
                  }}
                />
              </p>
            )}
          </Cell>
        );
      }
      break;
    case 'euro_accident_life':
      cell =
        isLifeInsuranceBenefit(benefit) &&
        benefit.configuration?.data &&
        translatePBBLevel(benefit.configuration.data.level);
      break;
    case 'euro_accident_tgl':
      cell = <FormattedMessage {...commonBenefitMessages.tgl} />;
      break;
    case 'euro_accident_privataccess':
      cell = isHealthInsuranceBenefit(benefit) &&
        benefit.configuration?.data && (
          <FormattedMessage
            messages={benefitHealthcareLevelMessages}
            select={benefit.configuration.data.level}
          />
        );
      break;
    case 'nordea_node_digital_advice':
      cell = <NoValue />;
      break;
    case 'nordea_node_personal_advice':
      cell = <PersonalAdviceDetailCell benefit={benefit} />;
      break;
    case 'epassi':
      cell = <FormattedMessage {...wellnessBenefitMessages.viaEpassi} />;
      break;
    case 'epassi_bike':
      cell = isEpassiBikeBenefit(benefit) ? (
        <FormattedMessage
          {...(benefit.configuration?.data?.useDefaultPolicy
            ? benefitFormMessages.epassiBikeStandardPolicy
            : benefitFormMessages.epassiBikeCustomCompanyPolicy)}
        />
      ) : null;
      break;
    case 'wellness':
      cell = isWellnessBenefit(benefit) &&
        benefit.configuration?.data?.allowance && (
          <FormattedCurrency
            value={benefit.configuration?.data.allowance}
            after="PER_YEAR"
            currency="SEK"
          />
        );
      break;
    default:
      return null;
  }
  return <span>{cell ?? <NoValue />}</span>;
};
