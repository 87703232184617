import { BenefitType, DisabilityLevel } from '@frontend/benefit-types';
import { FlexBenefitPackageAction } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const commonBenefitMessages = defineMessages({
  pension: {
    id: 'commonBenefitMessages.pension',
    description: 'Ålderspension',
    defaultMessage: 'Ålderspension',
  },
  plus: {
    id: 'commonBenefitMessages.plus',
    description: 'Plus benefit',
    defaultMessage: 'Pensionsrådgivning',
  },
  occupationalPension: {
    id: 'commonBenefitMessages.occupationalPension',
    description: 'Tjänstepension',
    defaultMessage: 'Tjänstepension',
  },
  salaryExchange: {
    id: 'commonBenefitMessages.salaryExchange',
    description: 'Löneväxling',
    defaultMessage: 'Löneväxling',
  },
  health: {
    id: 'commonBenefitMessages.health',
    description: 'Sjukvårdsförsäkring (PrivateAccess Silver)',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  healthShort: {
    id: 'commonBenefitMessages.healthShort',
    description: 'Health (short)',
    defaultMessage: 'Sjukvård',
  },
  sickness: {
    id: 'commonBenefitMessages.sickness',
    description: 'Sjukförsäkring (PlanSjuk)',
    defaultMessage: 'Sjukförsäkring',
  },
  accident: {
    id: 'commonBenefitMessages.accidentInsurance',
    description: 'Olycksfallsförsäkring',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  sicknessShort: {
    id: 'commonBenefitMessages.sicknessShort',
    description: 'Sickness (short)',
    defaultMessage: 'Sjuk',
  },
  sicknessTitle: {
    id: 'commonBenefitMessages.sicknessTitle',
    description: 'Sickness (title)',
    defaultMessage: 'Sjukersättning',
  },
  tgl: {
    id: 'commonBenefitMessages.tgl',
    description: 'TGL-försäkring',
    defaultMessage: 'Tjänstegrupplivförsäkring',
  },
  insuranceCoverage: {
    id: 'commonBenefitMessages.insuranceCoverage',
    description: 'Insurance coverage',
    defaultMessage: 'Försäkringsskydd',
  },
  shortfall: {
    id: 'commonBenefitMessages.shortfall',
    description: 'Shortfall',
    defaultMessage: 'Underskott',
  },
  eyewear: {
    id: 'commonBenefitMessages.eyewear',
    description: 'Eyewear',
    defaultMessage: 'Bildskärms- och skyddsglasögon',
  },
  premiumWaiverDescription: {
    id: 'commonBenefitMessages.premiumWaiverDescription',
    description: 'Jag vill teckna premiebefrielseförsäkring',
    defaultMessage: 'Jag vill teckna premiebefrielseförsäkring',
  },
  life: {
    id: 'commonBenefitMessages.lifeInsurance',
    description: 'Extra livförsäkring',
    defaultMessage: 'Livförsäkring',
  },
  lifeShort: {
    id: 'commonBenefitMessages.lifeShort',
    description: 'Life insurance (short)',
    defaultMessage: 'Liv',
  },
  noBenefitsTitle: {
    id: 'commonBenefitMessages.noBenefitsTitle',
    description: 'No benefits in backage title',
    defaultMessage: 'Inga förmåner',
  },
  noBenefits: {
    id: 'commonBenefitMessages.noBenefits',
    description: 'No benefits title',
    defaultMessage: 'Det finns inga förmåner att visa',
  },
  noBenefitsText: {
    id: 'commonBenefitMessages.noBenefitsText',
    description: 'No benefits in backage text',
    defaultMessage: 'Förmåner som läggs till kommer att visas här.',
  },
  noBenefitsInBackage: {
    id: 'commonBenefitMessages.noBenefitsInBackage',
    description: 'No benefits in backage title',
    defaultMessage: 'Det finns inga förmåner i denna förmånsgrupp',
  },
  noBenefitsInBackageText: {
    id: 'commonBenefitMessages.noBenefitsInBackageText',
    description: 'No benefits in backage text',
    defaultMessage:
      'Förmåner som läggs till i förmånsgruppen kommer att visas här.',
  },
  benefitPackageCreated: {
    id: 'commonBenefitMessages.benefitPackageCreated',
    defaultMessage: 'Ny förmånsgrupp {name} har skapats',
  },
  createBenefitPackage: {
    id: 'commonBenefitMessages.createBenefitPackage',
    defaultMessage: 'Skapa förmånsgrupp',
  },
  noBenefitPackages: {
    id: 'commonBenefitMessages.noBenefitPackages',
    description: 'No benefit packages',
    defaultMessage: 'Inga förmånsgrupper',
  },
  noBenefitPackagesTitle: {
    id: 'commonBenefitMessages.noBenefitPackagesTitle',
    description: 'There is no benefit packages',
    defaultMessage: 'Det finns inga förmånsgrupper att visa',
  },
  noBenefitPackagesText: {
    id: 'commonBenefitMessages.noBenefitPackagesText',
    description: 'There is no benefit packages text',
    defaultMessage: 'Förmånsgrupper som skapas kommer att visas här.',
  },
  levelLabel: {
    id: 'commonBenefitMessages.levelLabel',
    description: 'Välj mellan följande nivåer:',
    defaultMessage: 'Välj nivå',
  },
  benefitPackagesFieldLabel: {
    id: 'commonBenefitMessages.benefitPackagesFieldLabel',
    description: 'Vilka grupper skulle du vilja tilldela denna förmån till?',
    defaultMessage: 'Välj förmånsgrupper',
  },
  isActiveOnParentalLeaveLabel: {
    id: 'commonBenefitMessages.isActiveOnParentalLeaveLabel',
    description:
      'Label for checkbox if the insurance should be active when the employee is on parental leave',
    defaultMessage: 'Försäkringen gäller även vid föräldraledighet',
  },
  parentalLeaveDescription: {
    id: 'commonBenefitMessages.parentalLeaveDescription',
    defaultMessage:
      'Det innebär att pensionspremier betalas in till anställdas pensionsförsäkringar under pågående föräldraledighet.',
  },
  editBenefitPresentation: {
    id: 'commonBenefitMessages.editBenefitPresentation',
    defaultMessage: 'Redigera presentation',
  },
  interval: {
    id: 'commonBenefitMessages.interval',
    defaultMessage: 'Intervall',
  },
  remunerationType: {
    id: 'commonBenefitMessages.remunerationType',
    defaultMessage: 'Löneart',
  },
  annualFactor: {
    id: 'commonBenefitMessages.annualFactor',
    defaultMessage: 'Årslönefaktor',
  },
  benefitQualifyingIncome: {
    id: 'commonBenefitMessages.benefitQualifyingIncome',
    defaultMessage: 'Förmånsgrundande löneart',
  },
  benefitQualifyingIncomeDescription: {
    id: 'commonBenefitMessages.benefitQualifyingIncomeDescription',
    defaultMessage:
      'Välj vilken löneart som ska vara förmånsgrundande för denna förmån. Lönearten måste räknas om till en årslön. För att ta hänsyn till semestertillägg på samma sätt som ITP 2, använd 12,2 som årslönefaktor. Om den förmånsgrundande lönearten redan är en årslön, använd 1,00 som årslönefaktor.',
  },
  benefitQualifyingIncomeTableDescription: {
    id: 'commonBenefitMessages.benefitQualifyingIncomeTableDescription',
    defaultMessage: `Förmånsgrundande löneart: {annualFactor, number} ∙ {remunerationType}`,
  },
  numberOfEmployees: {
    id: 'commonBenefitMessages.numberOfEmployees',
    defaultMessage: 'Antal anställda',
  },
  activateBenefitPortal: {
    id: 'commonBenefitMessages.activateBenefitPortal',
    defaultMessage: 'Aktivera förmånsportal',
  },
  showTotalCompensation: {
    id: 'commonBenefitMessages.showTotalCompensation',
    defaultMessage: 'Aktivera total ersättning',
  },
  optionalSuppliers: {
    id: 'commonBenefitMessages.optionalSuppliers',
    defaultMessage: 'Valbara leverantörer',
  },
  optionalSuppliersDesc: {
    id: 'commonBenefitMessages.optionalSuppliersDesc',
    defaultMessage:
      'Välj vilka försäkringslösningar som ska vara tillgängliga för era medarbetare. Medarbetare kan själva välja försäkringslösning i portalen.',
  },
  searchCategory: {
    id: 'commonBenefitMessages.searchCategory',
    defaultMessage: 'Sök kategori',
  },
  confirmLanguageSwitchTitle: {
    id: 'commonBenefitMessages.confirmLanguageSwitchTitle',
    defaultMessage: 'Byta språk?',
  },
  confirmLanguageSwitch: {
    id: 'commonBenefitMessages.confirmLanguageSwitch',
    defaultMessage:
      'Är du säker på att du vill byta språk?\nDu har ändringar som inte är sparade, dessa kommer att försvinna om du inte först sparar formuläret',
  },
  changeCoverImage: {
    id: 'commonBenefitMessages.changeCoverImage',
    defaultMessage: 'Ändra omslagsbild',
  },
  coverImagePlaceholder: {
    id: 'commonBenefitMessages.coverImagePlaceholder',
    defaultMessage: 'Dra och släpp en bild i formatet .jpg eller .png',
  },
  inBenefitPackage: {
    id: 'commonBenefitMessages.inBenefitPackage',
    defaultMessage: 'I förmånsgrupp',
  },
  inBenefitPackageEffectiveDate: {
    id: 'commonBenefitMessages.inBenefitPackageEffectiveDate',
    defaultMessage: 'Välj startdatum',
  },
  inBenefitPackageEffectiveDateLabel: {
    id: 'commonBenefitMessages.inBenefitPackageEffectiveDateLabel',
    defaultMessage: 'Startdatum',
  },
  inBenefitPackageEffectiveDateDescription: {
    id: 'commonBenefitMessages.inBenefitPackageEffectiveDateDescription',
    defaultMessage:
      'Ange den månad från vilken förmånerna ska tillhöra förmånsgruppen. Startdatum är alltid den 1:a i vald månad. Observera att om du anger ett historiskt datum kommer premier och/eller försäkringsskydd kunna att startas retroaktivt. Du kan välja ett startdatum upp till tre månader bakåt i tiden.',
  },
  benefitSubmitSuccess: {
    id: 'formSubmitMessages.benefitSubmitSuccess',
    defaultMessage: 'Förmånen har sparats',
  },
  benefitPortal: {
    id: 'commonBenefitMessages.benefitPortal',
    defaultMessage: 'Förmånsportal',
  },
  digitalAdvice: {
    id: 'commonBenefitMessages.digitalAdvice',
    defaultMessage: 'Digital rådgivning',
  },
  addBenefitToBenefitPackageBreadcrumb: {
    id: 'commonBenefitMessages.addBenefitToBenefitPackageBreadcrumb',
    defaultMessage: 'Lägg till förmåner',
  },
  addBenefitToBenefitPackageLink: {
    id: 'commonBenefitMessages.addBenefitToBenefitPackageLink',
    defaultMessage: 'Lägg till förmåner',
  },
  addBenefitsToBenefitPackage: {
    id: 'commonBenefitMessages.addBenefitsToBenefitPackage',
    defaultMessage: 'Lägg till förmåner i "{name}"',
  },
  confirmAddBenefitsToBenefitPackageTitle: {
    id: 'commonBenefitMessages.confirmAddBenefitsToBenefitPackageTitle',
    defaultMessage: 'Lägg till förmåner i förmånsgruppen?',
  },
  confirmAddBenefitsToBenefitPackageDescription: {
    id: 'commonBenefitMessages.confirmAddBenefitsToBenefitPackageDescription',
    defaultMessage:
      'Valda förmåner kommer att läggas till i förmånsgruppen och börjar gälla direkt.',
  },
  benefitsAddedToBenefitPackage: {
    id: 'commonBenefitMessages.benefitsAddedToBenefitPackage',
    defaultMessage: `{count, plural, =1 {1 förmån} other {# förmåner}} har lagts till i förmånsgruppen`,
  },
  benefitPackageNoEmployeesTitle: {
    id: 'commonBenefitMessages.benefitPackageNoEmployeesTitle',
    defaultMessage: 'Det finns inga anställda att visa',
  },
  benefitPackageNoEmployeesDescription: {
    id: 'commonBenefitMessages.benefitPackageNoEmployeesDescription',
    defaultMessage:
      'Anställda som läggs till i förmånsgruppen kommer att visas här.',
  },
  benefitPackageRenamed: {
    id: 'commonBenefitMessages.benefitPackageRenamed',
    defaultMessage: 'Förmånsgruppens namn ändrades till {name}',
  },
  settings: {
    id: 'commonBenefitMessages.settings',
    defaultMessage: 'Inställningar',
  },
  renameBenefitPackage: {
    id: 'commonBenefitMessages.renameBenefitPackage',
    defaultMessage: 'Ändra namn på förmånsgrupp',
  },
  renameBenefitPackageLink: {
    id: 'commonBenefitMessages.renameBenefitPackageLink',
    defaultMessage: 'Ändra namn',
  },
  noHistory: {
    id: 'commonBenefitMessages.noHistory',
    defaultMessage: 'Det finns ingen historik att visa',
  },
  benefitPackageAuthorInfo: {
    id: 'commonBenefitMessages.benefitPackageAuthorInfo',
    defaultMessage: 'Förmånsgrupp skapad { createdAt } av { author }',
  },
  versionHistory: {
    id: 'commonBenefitMessages.versionHistory',
    defaultMessage: 'Versionshistorik',
  },
  editBenefit: {
    id: 'commonBenefitMessages.editBenefit',
    defaultMessage: 'Redigera förmån',
  },
  insuranceProvider: {
    id: 'commonBenefitMessages.insuranceProvider',
    defaultMessage: 'Valbara försäkringsleverantörer',
  },
  parentalLeave: {
    id: 'commonBenefitMessages.parentalLeave',
    defaultMessage: 'Gäller vid föräldraledighet',
  },
  policy: {
    id: 'commonBenefitMessages.policy',
    defaultMessage: 'Villkor',
  },
  level: {
    id: 'commonBenefitMessages.level',
    defaultMessage: 'Nivå',
  },
});

export const salaryExchangeBenefitMessages = defineMessages({
  premiumPerMonth: {
    id: 'salaryExchangeBenefitMessages.premiumPerMonth',
    description: 'Premie (kr/mån)',
    defaultMessage: 'Premie (kr/mån)',
  },
  salaryExchangeDeductionValue: {
    id: 'salaryExchangeBenefitMessages.salaryExchangeDeductionValue',
    description: 'Max. bruttolönevadrag:',
    defaultMessage:
      'Max. avdrag: <b>{amount, number, ::currency/SEK .}</b>, <b>{share, number, ::percent}</b> ',
  },
  oneTimeSalaryExchangeDeductionValue: {
    id: 'salaryExchangeBenefitMessages.oneTimeSalaryExchangeDeductionValue',
    defaultMessage:
      'Max. avdrag bonuxväxling: <b>{amount, number, ::currency/SEK .}</b>, <b>{share, number, ::percent}</b> ',
  },
  informationAboutSalaryExchange: {
    id: 'salaryExchangeBenefitMessages.informationAboutSalaryExchange',
    defaultMessage: 'Information om löneväxling',
  },
  beneficialSaving: {
    id: 'salaryExchangeBenefitMessages.beneficialSaving',
    defaultMessage: 'Ett förmånligt sparande',
  },
  incomeIbb: {
    id: 'salaryExchangeBenefitMessages.incomeIbb',
    defaultMessage: '{ amount } Inkomstbasbelopp',
  },
});

export const healthBenefitMessages = defineMessages({
  healthLevel: {
    id: 'healthBenefitMessages.healthLevel',
    description: 'Deductible',
    defaultMessage: 'Självrisk',
  },
});

export const pensionBenefitMessages = defineMessages({
  ageIntervalError: {
    id: 'pensionBenefitMessages.ageIntervalError',
    description: 'Åldersintervallerna måste vara i stigande ordning',
    defaultMessage: 'Åldersintervallen måste vara i stigande ordning',
  },
  baseAmountIntervalError: {
    id: 'pensionBenefitMessages.baseAmountIntervalError',
    description: 'Basbeloppsintervallerna måste vara i stigande ordning',
    defaultMessage: 'Basbeloppsintervallen måste vara i stigande ordning',
  },
  deductionsTableMessage: {
    id: 'pensionBenefitMessages.deductionsTableMessage',
    defaultMessage: 'Avdrag inom premieramen: {deductions}',
  },
  pensionProviders: {
    id: 'pensionBenefitMessages.pensionProviders',
    defaultMessage: `{count, plural,
      =1 {Leverantör}
      other {Leverantörer}}`,
  },
  fixedExtraPremium: {
    id: 'pensionBenefitMessages.fixedExtraPremium',
    defaultMessage: 'Fast extra premie',
  },
  premiumWaiver: {
    id: 'pensionBenefitMessages.premiumWaiver',
    defaultMessage: 'Premiebefrielseförsäkring',
  },
  fixedPremium: {
    id: 'pensionBenefitMessages.fixedPremium',
    defaultMessage: 'Fast extra premie utöver premietrappa',
  },
  premiumDeductions: {
    id: 'pensionBenefitMessages.premiumDeductions',
    defaultMessage: 'Avdrag inom premieutrymmet',
  },
});

export const pensionProviderMessages = defineMessages({
  advinans: {
    id: 'pensionProviderMessages.advinans',
    defaultMessage: 'Advinans Autopilot',
  },
  nordnet: {
    id: 'pensionProviderMessages.nordnet',
    defaultMessage: 'Nordnet',
  },
  nordnet_ssek: {
    id: 'pensionProviderMessages.nordnet',
    defaultMessage: 'Nordnet',
  },
  lf: {
    id: 'pensionProviderMessages.lf',
    defaultMessage: 'Länsförsäkringar',
  },
  nordea_fund: {
    id: 'pensionProviderMessages.nordea_fund',
    defaultMessage: 'Nordea Fondförsäkring',
  },
  nordea_trad: {
    id: 'pensionProviderMessages.nordea_trad',
    defaultMessage: 'Nordea Traditionell försäkring',
  },
});

export const wellnessBenefitMessages = defineMessages({
  wellness: {
    id: 'wellnessBenefitMessages.wellness',
    description: 'Wellness',
    defaultMessage: 'Friskvård',
  },
  viaEpassi: {
    id: 'wellnessBenefitMessages.viaEpassi',
    description: 'Via Epassi',
    defaultMessage: 'Via Epassi',
  },
});

export const benefitTypesMessages = defineMessages<BenefitType>({
  advinans_occupational_pension: {
    id: 'benefitTypesMessages.advinans_occupational_pension',
    defaultMessage: 'Tjänstepension',
  },
  advinans_salary_exchange: {
    id: 'benefitTypesMessages.advinans_salary_exchange',
    defaultMessage: 'Löneväxling',
  },
  employers_pension: {
    id: 'benefitTypesMessages.employers_pension',
    defaultMessage: 'Företagarpension',
  },
  epassi: {
    id: 'benefitTypesMessages.epassi',
    defaultMessage: 'Epassi friskvård',
  },
  epassi_bike: {
    id: 'benefitTypesMessages.epassi_bike',
    defaultMessage: 'EpassiBIKE cykelförmån',
  },
  euro_accident_accident: {
    id: 'benefitTypesMessages.euro_accident_accident',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  euro_accident_life: {
    id: 'benefitTypesMessages.euro_accident_life',
    defaultMessage: 'Livförsäkring (obligatorisk)',
  },
  euro_accident_plansjuk: {
    id: 'benefitTypesMessages.euro_accident_plansjuk',
    defaultMessage: 'Sjukförsäkring',
  },
  euro_accident_privataccess: {
    id: 'benefitTypesMessages.euro_accident_privataccess',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  euro_accident_sickness_and_accident: {
    id: 'benefitTypesMessages.euro_accident_sickness_and_accident',
    defaultMessage: 'Sjuk- och olycksfallsförsäkring',
  },
  euro_accident_tgl: {
    id: 'benefitTypesMessages.euro_accident_tgl',
    defaultMessage: 'Tjänstegrupplivförsäkring',
  },
  nordea_node_digital_advice: {
    id: 'benefitTypesMessages.nordea_node_digital_advice',
    defaultMessage: 'Digital rådgivning',
  },
  nordea_node_personal_advice: {
    id: 'benefitTypesMessages.nordea_node_personal_advice',
    defaultMessage: 'Personlig rådgivning',
  },
  synsam: {
    id: 'benefitTypesMessages.synsam',
    defaultMessage: 'Synsam terminalglasögon',
  },
  wellness: {
    id: 'benefitTypesMessages.wellness',
    defaultMessage: 'Friskvård',
  },
});

export const benefitFilterMessages = defineMessages({
  chooseCategory: {
    id: 'benefitFilterMessages.chooseCategory',
    defaultMessage: 'Välj kategori',
  },
  chooseType: {
    id: 'benefitFilterMessages.chooseType',
    defaultMessage: 'Välj förmån',
  },
});

export const pensionBenefitDeductionMessages = defineMessages({
  ADVINANS_MEMBERSHIP_FEE: {
    id: 'pensionBenefitDeductionMessages.ADVINANS_MEMBERSHIP_FEE',
    defaultMessage: 'Nordea Nodes plattformsavgift',
  },
  ADVINANS_ADVICE_FEE: {
    id: 'pensionBenefitDeductionMessages.ADVINANS_ADVICE_FEE',
    defaultMessage: 'Personlig rådgivning',
  },
  PREMIUM_WAIVER: {
    id: 'pensionBenefitDeductionMessages.PREMIUM_WAIVER',
    defaultMessage: 'Premiebefrielseförsäkring',
  },
  SICKNESS_INSURANCE: {
    id: 'pensionBenefitDeductionMessages.SICKNESS_INSURANCE',
    defaultMessage: 'Sjukförsäkring',
  },
});

export const sicknessBenefitLevelMessages = defineMessages<DisabilityLevel>({
  ITP: {
    id: 'sicknessBenefitLevelMessages.ITP',
    description: 'ITP-Sjuk',
    defaultMessage: 'ITP-sjuk',
  },
  MAX: {
    id: 'sicknessBenefitLevelMessages.MAX',
    description: 'Max-Sjuk',
    defaultMessage: 'MAX-sjuk',
  },
  KOMP: {
    id: 'sicknessBenefitLevelMessages.KOMP',
    defaultMessage: 'ITP-komplettering',
  },
  CONSOLIDATION: {
    id: 'sicknessBenefitLevelMessages.CONSOLIDATION',
    defaultMessage: 'Konsolidering',
  },
  HYBRID: {
    id: 'sicknessBenefitLevelMessages.HYBRID',
    defaultMessage: 'Hybrid-sjuk (ITP/MAX)',
  },
});

export const sicknessBenefitMessages = defineMessages({
  withExtendedSickPay: {
    id: 'sicknessBenefitMessages.withExtendedSickPay',
    defaultMessage: 'med utökad sjuklön',
  },
});

export const createBenefitTypesMessages = defineMessages<BenefitType>({
  advinans_occupational_pension: {
    id: 'createBenefitTypesMessages.advinans_occupational_pension',
    defaultMessage: 'Skapa ny tjänstepension',
  },
  advinans_salary_exchange: {
    id: 'createBenefitTypesMessages.advinans_salary_exchange',
    defaultMessage: 'Skapa ny löneväxling',
  },
  employers_pension: {
    id: 'createBenefitTypesMessages.employers_pension',
    defaultMessage: 'Skapa ny företagarpension',
  },
  epassi: {
    id: 'createBenefitTypesMessages.epassi',
    defaultMessage: 'Skapa ny Epassi friskvård',
  },
  epassi_bike: {
    id: 'createBenefitTypesMessages.epassi_bike',
    defaultMessage: 'Skapa ny EpassiBIKE cykelförmån',
  },
  euro_accident_accident: {
    id: 'createBenefitTypesMessages.euro_accident_accident',
    defaultMessage: 'Skapa ny olycksfallsförsäkring',
  },
  euro_accident_life: {
    id: 'createBenefitTypesMessages.euro_accident_life',
    defaultMessage: 'Skapa ny livförsäkring (obligatorisk)',
  },
  euro_accident_plansjuk: {
    id: 'createBenefitTypesMessages.RISK_SICKNESS',
    defaultMessage: 'Skapa ny sjukförsäkring',
  },
  euro_accident_privataccess: {
    id: 'createBenefitTypesMessages.euro_accident_privataccess',
    defaultMessage: 'Skapa ny sjukvårdsförsäkring',
  },
  euro_accident_sickness_and_accident: {
    id: 'createBenefitTypesMessages.euro_accident_sickness_and_accident',
    defaultMessage: 'Skapa ny sjuk- och olycksfallsförsäkring',
  },
  euro_accident_tgl: {
    id: 'createBenefitTypesMessages.euro_accident_tgl',
    defaultMessage: 'Skapa ny tjänstegrupplivförsäkring',
  },
  nordea_node_digital_advice: {
    id: 'createBenefitTypesMessages.nordea_node_digital_advice',
    defaultMessage: 'Skapa ny digital rådgivning',
  },
  nordea_node_personal_advice: {
    id: 'createBenefitTypesMessages.nordea_node_personal_advice',
    defaultMessage: 'Skapa ny personlig rådgivning',
  },
  synsam: {
    id: 'createBenefitTypesMessages.synsam',
    defaultMessage: 'Skapa ny Synsam terminalglasögon',
  },
  wellness: {
    id: 'createBenefitTypesMessages.wellness',
    defaultMessage: 'Skapa ny friskvård',
  },
});

export const benefitPackageActionMessages =
  defineMessages<FlexBenefitPackageAction>({
    ADDED: {
      id: 'benefitPackageActionMessages.ADDED',
      defaultMessage: 'Förmån tillagd',
    },
    REMOVED: {
      id: 'benefitPackageActionMessages.REMOVED',
      defaultMessage: 'Förmån borttagen',
    },
  });
