import { ApolloLink } from '@apollo/client';
import { Button } from '@frontend/ui';
import { ErrorReporter } from '@frontend/utils';
import { client } from 'app/apollo/client';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { BotInfo, BrowserInfo, detect, NodeInfo } from 'detect-browser';
import { Send, useNotification } from 'features/notifications';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import { errorBoundaryMessages } from './messages';
import { getErrorLink } from './utils/get-error-link';

export const Reporter = new ErrorReporter({
  reportUrl: `${window.env.API}/error-report`,
  service: 'frontend/services/backstage',
});

interface Props {
  browser: BrowserInfo | BotInfo | NodeInfo | null;
  history: RouteComponentProps['history'];
  intl: IntlShape;
  send: Send;
  children?: React.ReactNode;
}

interface State {
  hasError: boolean;
  loading: boolean;
}

class Component extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { loading: false, hasError: false };
  }

  // this lifecycle method is absolute and utter
  // rubbish as it doesn't allow us to get proper
  // source maps for the errors, therefore we
  // amend the handleErrorEvent function below
  public componentDidCatch(error) {
    Error.stackTraceLimit = 10;

    this.setState({
      hasError: true,
    });

    this.handleErrorEvent(error);
  }

  private setLoading(loading: boolean) {
    this.setState({ loading });
  }

  private handleErrorEvent = async (error): Promise<void> => {
    if (error) {
      const context = {
        httpRequest: {
          url: location.pathname,
          userAgent: `${this.props.browser?.os}, ${this.props.browser?.name}@${this.props.browser?.version}`,
        },
        reportLocation: {
          filePath: error.fileName,
          lineNumber: error.lineNumber,
        },
        user: 'backstage',
      };

      this.setLoading(true);
      const { intl, send } = this.props;
      await Reporter.report({
        error,
        context,
        onSendError: () =>
          send({
            message: intl.formatMessage(errorBoundaryMessages.errorMessage),
            type: 'error',
          }),
        onSendSuccess: () =>
          send({
            message: intl.formatMessage(errorBoundaryMessages.successMessage),
            type: 'success',
          }),
      });
      this.setLoading(false);
    }
  };

  public render() {
    const { loading, hasError } = this.state;
    const { children, history } = this.props;

    if (hasError) {
      return (
        <EmptyState
          title={<FormattedMessage {...errorBoundaryMessages.title} />}
          actions={
            <Button
              loading={loading}
              onClick={() => {
                this.setState({ hasError: false });
                history.push('/');
                window.location.reload();
              }}
            >
              {loading ? (
                <FormattedMessage
                  {...errorBoundaryMessages.loadingButtonLabel}
                />
              ) : (
                <FormattedMessage {...errorBoundaryMessages.buttonLabel} />
              )}
            </Button>
          }
        >
          <FormattedMessage {...errorBoundaryMessages.description} />
        </EmptyState>
      );
    }

    return children;
  }
}

interface ErrorBoundaryProps {
  children: React.ReactElement;
}

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  // We store the client link in a state so that we can restore the
  // initial state of client when the error boundary component unmounts
  const [clientLink] = useState(client.link);
  const { send } = useNotification();
  const history = useHistory();

  const intl = useIntl();
  const browser = useMemo(() => detect(), []);

  useEffect(() => {
    if (window.env.ERROR_REPORTING_ACTIVE !== 'true') {
      return;
    }

    const errorLink = getErrorLink();

    client.setLink(ApolloLink.from([errorLink, clientLink]));

    // eslint-disable-next-line
    return () => {
      client.setLink(clientLink);
    };
  });

  if (window.env.ERROR_REPORTING_ACTIVE !== 'true') {
    return children;
  }

  return (
    <Component intl={intl} browser={browser} history={history} send={send}>
      {children}
    </Component>
  );
};
