import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const FCFW_EVENT_HISTORY_TABLE_QUERY = gql`
  query fcfwEventHistoryTableQuery(
    $companyId: ID!
    $userAccountId: ID!
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    fcfwAssertions(
      after: $after
      before: $before
      companyId: $companyId
      first: $first
      last: $last
      userAccountId: $userAccountId
    ) {
      edges {
        node {
          userAccountId
          companyId
          effectiveDate
          createdAt
          actor {
            displayName
          }
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;
