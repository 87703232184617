import gql from 'graphql-tag';

export const CREATE_EURO_ACCIDENT_GROUP_AGREEMENT_MUTATION = gql`
  mutation createEuroAccidentGroupAgreementMutation(
    $input: CreateEuroAccidentGroupAgreementMutationInput!
  ) {
    createEuroAccidentGroupAgreement(input: $input) {
      clientMutationId
    }
  }
`;
