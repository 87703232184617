import { CheckboxField, Form, useFormikContext } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  CheckboxField as Checkbox,
  Section,
  Subsection,
  Table,
  Td,
  Th,
  Tr,
} from '@frontend/ui';
import { smeCompanyConvertRiskQuery_proposal_Proposal_memberships_ProposalMembership as Employee } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import React, { useId } from 'react';

export interface FormValues {
  [employeeId: string]: boolean;
}

interface Props {
  employees: readonly Employee[];
}

export const ConvertRiskForm: React.FC<Props> = ({ employees }) => {
  const { isSubmitting, setValues, values } = useFormikContext<FormValues>();
  const { formatMessage } = useIntl();
  const id = useId();

  const toggleSelectAll = (checked: boolean) => {
    const newValues = Object.keys(values).reduce<FormValues>(
      (acc, key) => ({
        ...acc,
        [key]: checked,
      }),
      {},
    );
    setValues(newValues);
  };

  const indeterminate =
    Object.values(values).some(Boolean) &&
    !Object.values(values).every(Boolean);

  return (
    <Section>
      <Form>
        <Subsection>
          <Table
            feedback={{
              children: (
                <FormattedMessage
                  {...smeEmployeesMessages.nEmployeesMarkedAsConvertRisk}
                  values={{
                    count: Object.values(values).filter(Boolean).length,
                    total: Object.values(values).length,
                  }}
                />
              ),
            }}
          >
            <thead>
              <Tr>
                <Th type="input">
                  <Checkbox
                    checked={Object.values(values).every(Boolean)}
                    onChange={toggleSelectAll}
                    indeterminate={indeterminate}
                    aria-label={formatMessage(commonMessages.selectAllRows)}
                  />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.nid} />
                </Th>
                <Th>
                  <FormattedMessage {...commonMessages.givenName} />
                </Th>
                <Th align="left">
                  <FormattedMessage {...commonMessages.lastName} />
                </Th>
              </Tr>
            </thead>
            <tbody>
              {employees.map(employee => (
                <Tr key={employee.id}>
                  <Td type="input">
                    <CheckboxField
                      name={employee.id}
                      withMargin={false}
                      aria-labelledby={id}
                    />
                  </Td>
                  <Td id={id}>
                    <FormattedNaturalPersonIdentifier
                      nid={employee.personalIdentityNumber}
                    />
                  </Td>
                  <Td>{employee.firstName}</Td>
                  <Td align="left">{employee.lastName}</Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </Subsection>
        <Subsection>
          <ButtonLayout>
            <Button filled loading={isSubmitting} text type="submit">
              <FormattedMessage {...smeEmployeesMessages.saveChanges} />
            </Button>
          </ButtonLayout>
        </Subsection>
      </Form>
    </Section>
  );
};
