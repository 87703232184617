import {
  DescriptionList,
  Section,
  SectionHeader,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import { toEffectiveUntil } from '@frontend/utils';
import {
  Advisor,
  ptStatisticsQuery,
  ptStatisticsQueryVariables,
} from 'app/apollo/graphql/types';
import { advisorMessages, ptStatisticsMessages } from 'app/messages/advisor';
import { useQuery } from 'app/utils/use-query';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import React from 'react';

import { AdviceFilterChips } from '../components/Filter';
import { PT_STATISTICS_QUERY } from '../graphql/queries';
import { FilterParams } from '../utils/use-filter-params';

export type PtStatisticsKey =
  | 'totalTransferredCapital'
  | 'transferredCapitalToNordea'
  | 'transferredCapitalToNordnet';

interface Props {
  advisors: readonly Advisor[];
  clearFilter: () => void;
  filterParams: FilterParams;
  toggleFilterSideSheet: () => void;
}

interface TableProps {
  filterParams: FilterParams;
  advisor?: Advisor;
}

const StatisticsTable: React.FC<TableProps> = ({ filterParams, advisor }) => {
  const { data, previousData, loading, error } = useQuery<
    ptStatisticsQuery,
    ptStatisticsQueryVariables
  >(PT_STATISTICS_QUERY, {
    variables: {
      from: filterParams.from || undefined,
      to: filterParams.to ? toEffectiveUntil(filterParams.to) : undefined,
      advisorId: advisor?.id,
      companyId: filterParams.companyId || undefined,
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const _ptStatistics = data?.pensionTransferStatistics ??
    previousData?.pensionTransferStatistics ?? {
      totalTransferredCapital: '0',
      transferredCapitalToInstitute: [],
    };

  const transferredCapitalToNordea = _ptStatistics.transferredCapitalToInstitute
    .filter(
      ({ instituteId }) =>
        instituteId === 'NORDEA' || instituteId === 'UNKNOWN', // Assume UNKNOWN is NORDEA
    )
    .reduce((acc, { capital }) => acc + Number(capital), 0)
    .toString();

  const ptStatistics = {
    totalTransferredCapital: _ptStatistics.totalTransferredCapital,
    transferredCapitalToNordea,
    transferredCapitalToNordnet:
      _ptStatistics.transferredCapitalToInstitute.find(
        ({ instituteId }) => instituteId === 'NORDNET',
      )?.capital ?? '0',
  };

  const advisorName = advisor
    ? `${advisor.firstName} ${advisor.lastName}`
    : undefined;

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      {(!advisor || Number(ptStatistics.totalTransferredCapital) > 0) && ( // Only show tables for advisors that have transferred capital
        <>
          {advisorName && (
            <SubsectionHeader>
              <FormattedMessage
                {...advisorMessages.transferredBy}
                values={{ advisorName }}
              />
            </SubsectionHeader>
          )}
          <Subsection>
            <DescriptionList
              alignLeft
              entries={Object.entries(ptStatistics).map(
                ([key, value]: [PtStatisticsKey, string]) => ({
                  id: key,
                  label: (
                    <FormattedMessage
                      select={key}
                      messages={ptStatisticsMessages}
                    />
                  ),
                  value: <FormattedCurrency currency="SEK" value={value} />,
                }),
              )}
            />
          </Subsection>
        </>
      )}
    </>
  );
};

export const PtStatistics: React.FC<Props> = ({
  clearFilter,
  filterParams,
  toggleFilterSideSheet,
  advisors,
}) => (
  <Section>
    <SectionHeader>
      <FormattedMessage {...advisorMessages.ptStatistics} />
    </SectionHeader>

    <ChipsWrapper belowSearch>
      <AdviceFilterChips
        clearFilter={clearFilter}
        toggleFilterSideSheet={toggleFilterSideSheet}
      />
    </ChipsWrapper>
    <StatisticsTable filterParams={filterParams} />
    {advisors.map(advisor => (
      <StatisticsTable
        key={advisor.id}
        advisor={advisor}
        filterParams={filterParams}
      />
    ))}
  </Section>
);
