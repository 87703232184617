import { defineMessages } from 'react-intl';

export const a11yMessages = defineMessages({
  menu: {
    id: 'a11yMessages.menu',
    description: 'Menu',
    defaultMessage: 'Meny',
  },
  downloadMenu: {
    id: 'a11yMessages.downloadMenu',
    description: 'Download options',
    defaultMessage: 'Nedladdningsalternativ',
  },
  profileButton: {
    id: 'a11yMessages.profileButton',
    description: 'Profile menu',
    defaultMessage: 'Profilalternativ',
  },
  loginEditMenu: {
    id: 'a11yMessages.loginEditMenu',
    description: 'Login edit menu',
    defaultMessage: 'Inloggningsalternativ',
  },
  orgUnitEditMenu: {
    id: 'a11yMessages.orgUnitEditMenu',
    description: 'Organisational unit menu',
    defaultMessage: 'Avdelningsalternativ',
  },
  costReportingEditMenu: {
    id: 'a11yMessages.costReportingEditMenu',
    description: 'Cost reporting edit menu',
    defaultMessage: 'Kostnadsrapporteringsalternativ',
  },
  accountPlanEditMenu: {
    id: 'a11yMessages.accountPlanEditMenu',
    description: 'Account plan edit menu',
    defaultMessage: 'Kontoplansalternativ',
  },
  exit: {
    id: 'a11yMessages.exit',
    defaultMessage: 'Avsluta',
  },
  remunerationEditMenu: {
    id: 'a11yMessages.remunerationEditMenu',
    description: 'Remuneration edit menu',
    defaultMessage: 'Ändra ersättning',
  },
  investmentPlanEditMenu: {
    id: 'a11yMessages.investmentPlanEditMenu',
    description: 'Investmentplan plan edit menu',
    defaultMessage: 'Placeringsalternativ',
  },
  forward: {
    id: 'a11yMessages.forward',
    description: 'Forward table navigation',
    defaultMessage: 'Framåt',
  },
  backward: {
    id: 'a11yMessages.backward',
    description: 'Backward table navigation',
    defaultMessage: 'Tillbaka',
  },
  readArticle: {
    id: 'a11yMessages.readArticle',
    description: 'Read the whole article',
    defaultMessage: 'Läs hela artikeln {title}',
  },
  bankIdLogo: {
    id: 'a11yMessages.bankIdLogo',
    description: 'BankID logotype',
    defaultMessage: 'BankID logotyp',
  },
  close: {
    id: 'a11yMessages.close',
    description: 'Close',
    defaultMessage: 'Stäng',
  },
  openInNewTab: {
    id: 'a11yMessages.openInNewTab',
    description: 'Open link in a new tab',
    defaultMessage: 'Öppna länk i en ny flik',
  },
  expand: {
    id: 'a11yMessages.expand',
    defaultMessage: 'Expandera',
  },
  userMenu: {
    id: 'a11yMessages.userMenu',
    defaultMessage: 'Användarmeny',
  },
  breadcrumb: {
    id: 'a11yMessages.breadcrumb',
    defaultMessage: 'Brödsmula',
  },
  showBreadcrumbs: {
    id: 'a11yMessages.showBreadcrumbs',
    defaultMessage: 'Visa brödsmulor',
  },
  information: {
    id: 'a11yMessages.information',
    defaultMessage: 'Information',
  },
  loading: {
    id: 'a11yMessages.loading',
    defaultMessage: 'Laddar',
  },
  progress: {
    id: 'a11yMessages.progress',
    defaultMessage: 'Framsteg',
  },
  progressStep: {
    id: 'a11yMessages.progressStep',
    defaultMessage: 'Steg {step} av {nSteps}',
  },
});
