import { Billing } from 'features/companies/company/billing';
import { AddBillingEmailModal } from 'features/companies/company/billing/billing-emails/add';
import { EditBillingEmailModal } from 'features/companies/company/billing/billing-emails/edit';
import { ContractReferenceModal } from 'features/companies/company/billing/contract-references/modal';
import React from 'react';

export const BillingRoute: React.FC = () => (
  <>
    <Billing />
    <AddBillingEmailModal />
    <EditBillingEmailModal />
    <ContractReferenceModal />
  </>
);
