import gql from 'graphql-tag';

import { internalPostDetails } from '../../graphql/fragments';

export const UPDATE_INTERNAL_POST_MUTATION = gql`
  mutation updateInternalPostMutation($input: UpdateInternalPostInput!) {
    updateInternalPost(input: $input) {
      internalPost {
        ...internalPostDetails
      }
    }
  }
  ${internalPostDetails}
`;

export const DELETE_INTERNAL_POST_MUTATION = gql`
  mutation deleteInternalPostMutation($input: DeleteInternalPostInput!) {
    deleteInternalPost(input: $input) {
      id
    }
  }
`;
