import gql from 'graphql-tag';

import {
  employersPensionBenefitConfigWithFixedPremium,
  occupationalPensionBenefitConfigWithFixedPremium,
} from '../../graphql/queries';

export const SME_COMPANY_EMPLOYEE_QUERY = gql`
  query smeCompanyEmployeeQuery($id: ID!) {
    proposalMembership(id: $id) {
      id
      benefitPackage {
        id
        benefits {
          id
          type
          configuration {
            ...employersPensionBenefitConfigWithFixedPremium
            ...occupationalPensionBenefitConfigWithFixedPremium
          }
        }
        name
      }
      convertRiskInsurances
      email
      firstName
      isFcfw
      isOwner
      lastName
      monthlyFixedPensionPremium
      monthlySalary
      personalIdentityNumber
      phoneNumber
    }
  }
  ${employersPensionBenefitConfigWithFixedPremium}
  ${occupationalPensionBenefitConfigWithFixedPremium}
`;
