import { BenefitType } from '@frontend/benefit-types';
import { DescriptionList, Dot, Section, SectionHeader } from '@frontend/ui';
import { addCircle, edit } from '@frontend/ui/icons';
import { startOfNextMonth } from '@frontend/utils';
import {
  pensionTableQuery,
  pensionTableQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { employeePensionMessages } from 'app/messages/employees';
import { insuranceMessages } from 'app/messages/insurance';
import { pensionMessages } from 'app/messages/pension';
import { pensionBenefitStatusMessages } from 'app/messages/status';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { MEMBERSHIP_BENEFIT_STATUS_COLOR } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { ScrollableSet } from 'components/scrollable/Set';
import { useCurrentUser } from 'contexts/current-permissions';
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { getUpdateExtraPremiumLink } from '../update-premium/components/UpdateExtraPremiumModal';
import { getUpdatePremiumLink } from '../update-premium/components/UpdatePensionPremiumModal';
import { getUpdateSinglePremiumLink } from '../update-single-premium';
import { PENSION_TABLE_QUERY } from './graphql/queries';
import { canUpdateSinglePremium } from './utils/can-update-single-premium';
import { getPensionTableEntries } from './utils/get-pension-table-entries';
import { showUpdateExtraPremiumButton } from './utils/show-update-extra-premium-button';
import { showUpdatePremiumButton } from './utils/show-update-premium-button';

export const benefitTypeNames: BenefitType[] = [
  'advinans_occupational_pension',
];

const isEmpty = (data?: pensionTableQuery) => !getPensionTableEntries(data);

export const PensionTable: React.FC = () => {
  const location = useLocation();
  const {
    params: { companyId, userAccountId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const { permissions } = useCurrentUser();

  const { data } = useQuery<pensionTableQuery, pensionTableQueryVariables>(
    PENSION_TABLE_QUERY,
    {
      isEmpty,
      suspend: true,
      errorPolicy: 'all',
      variables: {
        userAccountId,
        companyId,
        benefitTypeNames,
        entitlementDate: startOfNextMonth(),
      },
    },
  );

  const _entries = getPensionTableEntries(data);

  if (!_entries) {
    return null;
  }

  const {
    status,
    premium,
    mainInsuranceNumber,
    subInsuranceNumber,
    effectiveDate,
    provider,
    pensionInsurance,
    pensionInsuranceBenefit,
  } = _entries;

  const entries = [
    {
      id: 'employeePension.status',
      label: <FormattedMessage {...commonMessages.status} />,
      value: status ? (
        <Dot
          colorValue={MEMBERSHIP_BENEFIT_STATUS_COLOR[status]}
          size={0.75}
          label={
            <FormattedMessage
              messages={pensionBenefitStatusMessages}
              select={status}
            />
          }
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'employeePension.premium',
      label: <FormattedMessage {...employeePensionMessages.premium} />,
      value: premium,
    },
    {
      id: 'employeePension.mainInsuranceNumber',
      label: <FormattedMessage {...insuranceMessages.mainInsuranceNumber} />,
      value: mainInsuranceNumber,
    },
    {
      id: 'employeePension.subInsuranceNumber',
      label: <FormattedMessage {...insuranceMessages.subInsuranceNumber} />,
      value: subInsuranceNumber,
    },
    {
      id: 'employeePension.effectiveDate',
      label: <FormattedMessage {...commonMessages.effectiveFrom} />,
      value: effectiveDate,
    },
    {
      id: 'employeePension.provider',
      label: <FormattedMessage {...commonMessages.management} />,
      value: provider,
    },
  ];

  const showSinglePremium = canUpdateSinglePremium({
    pensionInsurance,
    permissions,
  });
  const showUpdatePremium = showUpdatePremiumButton({
    benefit: pensionInsuranceBenefit,
    permissions,
  });
  const showUpdateExtraPremium = showUpdateExtraPremiumButton({
    fixedPremium: pensionInsuranceBenefit?.configuration?.data?.fixedPremium,
    premiumMatrix: pensionInsuranceBenefit?.configuration?.data?.premiumMatrix,
    permissions,
  });

  const showActions =
    showSinglePremium || showUpdatePremium || showUpdateExtraPremium;

  return (
    <Section>
      <SectionHeader>
        <FormattedMessage {...commonBenefitMessages.pension} />
      </SectionHeader>
      {showActions && (
        <ChipsWrapper>
          <ScrollableSet>
            {showSinglePremium && (
              <AssistChip
                to={getUpdateSinglePremiumLink(location)}
                text={
                  <FormattedMessage {...pensionMessages.singlePremiumDeposit} />
                }
                leadingIcon={addCircle}
              />
            )}
            {showUpdatePremium && (
              <AssistChip
                to={getUpdatePremiumLink(location)}
                text={
                  <FormattedMessage {...commonMessages.updatePremiumValue} />
                }
                leadingIcon={edit}
              />
            )}
            {showUpdateExtraPremium && (
              <AssistChip
                to={getUpdateExtraPremiumLink(location)}
                text={
                  <FormattedMessage
                    {...pensionMessages.updateFixedExtraPremium}
                  />
                }
                leadingIcon={edit}
              />
            )}
          </ScrollableSet>
        </ChipsWrapper>
      )}
      <DescriptionList alignLeft entries={entries} />
    </Section>
  );
};
