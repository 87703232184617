import { A, DescriptionList, DescriptionListEntry } from '@frontend/ui';
import {
  BenefitPackage,
  EpassiBikeConfiguration,
  FlexEntitlementRules,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { EXTERNAL_URLS } from 'app/utils/external-urls';
import { FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';

import { InBenefitPackage } from '../../../components/BenefitsTable/InBenefitPackage';
import { benefitFormMessages } from '../../../form/messages';

interface Benefit {
  configuration: EpassiBikeConfiguration;
  entitlementRules: Pick<FlexEntitlementRules, 'benefitPackageIds'> | null;
  id: string;
  type: string;
}

interface Props {
  benefit: Benefit;
  benefitPackageOptions: Pick<BenefitPackage, 'id' | 'name'>[];
}

export const EpassiBikeBenefit: React.FC<Props> = ({
  benefit,
  benefitPackageOptions,
}) => {
  const { configuration } = benefit;

  const { customerId, files, useDefaultPolicy } = configuration;

  const entries: DescriptionListEntry[] = [
    {
      id: 'benefit.customerId',
      label: <FormattedMessage {...benefitFormMessages.customerId} />,
      value: customerId ?? <NoValue />,
    },
    {
      id: 'benefit.policy',
      label: <FormattedMessage {...benefitFormMessages.epassiBikePolicy} />,
      value: useDefaultPolicy ? (
        <A href={EXTERNAL_URLS.epassiBikeStandardPolicyHref} target="_blank">
          <FormattedMessage {...benefitFormMessages.epassiBikeStandardPolicy} />
        </A>
      ) : files?.length ? (
        files.map((file, index) => (
          <>
            {!!index && ', '}
            <A key={index} href={file.url} target="_blank">
              {file.name ?? file.url}
            </A>
          </>
        ))
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.inBenefitPackage',
      label: <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />,
      value: (
        <InBenefitPackage
          benefit={benefit}
          benefitPackageOptions={benefitPackageOptions}
        />
      ),
    },
  ];

  return <DescriptionList alignLeft entries={entries} />;
};
