import { OCCUPATIONAL_PENSION_PROVIDER_ID } from 'app/utils/constants';
import { defineMessages } from 'react-intl';

export const insuranceMessages = defineMessages({
  premiumWaiver: {
    id: 'insuranceMessages.premiumWaiver',
    defaultMessage: 'Premiebefrielseförsäkring',
  },
  insurances: {
    id: 'insuranceMessages.insurances',
    defaultMessage: 'Försäkringar',
  },
  insurance: {
    id: 'insuranceMessages.insurance',
    defaultMessage: 'Försäkring',
  },
  insuranceNumber: {
    id: 'insuranceMessages.insuranceNumber',
    defaultMessage: 'Försäkringsnummer',
  },
  institute: {
    id: 'insuranceMessages.institute',
    defaultMessage: 'Försäkringsbolag',
  },
  insured: {
    id: 'insuranceMessages.insured',
    defaultMessage: 'Försäkrad',
  },
  value: {
    id: 'insuranceMessages.value',
    defaultMessage: 'Värde',
  },
  searchInsuranceNumber: {
    id: 'insuranceMessages.searchInsuranceNumber',
    defaultMessage: 'Sök försäkringsnummer',
  },
  searchInsurance: {
    id: 'insuranceMessages.searchInsurance',
    defaultMessage: 'Sök försäkring',
  },
  searchInsuranceDescription: {
    id: 'insuranceMessages.searchInsuranceDescription',
    defaultMessage:
      'För att se information om en försäkring, sök på försäkringsnummer.',
  },
  markAsDeleted: {
    id: 'insuranceMessages.markAsDeleted',
    defaultMessage: 'Markera som borttagen',
  },
  deleted: {
    id: 'insuranceMessages.deleted',
    defaultMessage: 'Borttagen',
  },
  notDeleted: {
    id: 'insuranceMessages.notDeleted',
    defaultMessage: 'Ej borttagen',
  },
  noInsurancesFound: {
    id: 'insuranceMessages.noInsurancesFound',
    defaultMessage: 'Inga försäkringar matchade sökningen "{search}"',
  },
  confirmDeleteInsurancesMessage: {
    id: 'insuranceMessages.confirmDeleteInsurancesMessage',
    defaultMessage:
      'Är du säker på att du vill markera {count, plural, =1 {försäkringen} other {# försäkringar}} som {count, plural, =1 {borttagen} other {borttagna}}?',
  },
  deleteInsurancesSuccess: {
    id: 'insuranceMessages.deletedInsurancesSuccessfully',
    defaultMessage:
      '{count, plural, =1 {# försäkring} other {# försäkringar}} markerades som {count, plural, =1 {borttagen} other {borttagna}}',
  },
  deleteInsurancesError: {
    id: 'insuranceMessages.deleteInsurancesError',
    defaultMessage:
      '{count, plural, =1 {# försäkring} other {# försäkringar}} misslyckades att markeras som {count, plural, =1 {borttagen} other {borttagna}}',
  },
  mainInsuranceNumber: {
    id: 'insuranceMessages.mainInsuranceNumber',
    defaultMessage: 'Huvudförsäkringsnummer',
  },
  subInsuranceNumber: {
    id: 'insuranceMessages.subInsuranceNumber',
    defaultMessage: 'Underförsäkringsnummer',
  },
  terminated: {
    id: 'insuranceMessages.terminated',
    defaultMessage: 'Avslutad',
  },
});

export const pensionInsuranceProviderMessages =
  defineMessages<OCCUPATIONAL_PENSION_PROVIDER_ID>({
    FUTUR: {
      id: 'pensionInsuranceProviderMessages.FUTUR',
      defaultMessage: 'Advinans Autopilot',
    },
    NORDNET: {
      id: 'pensionInsuranceProviderMessages.NORDNET',
      defaultMessage: 'Nordnet',
    },
    LFS: {
      id: 'pensionInsuranceProviderMessages.LFS',
      defaultMessage:
        'Länsförsäkringar {fundManagement, select, true {fondförvaltning} other {traditionell förvaltning}}',
    },
    NORDEA: {
      id: 'pensionInsuranceProviderMessages.NORDEA',
      defaultMessage: 'Nordea',
    },
  });
