import { Form, Formik, TextField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import {
  renameBenefitPackageQuery,
  renameBenefitPackageQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import { RENAME_BENEFIT_PACKAGE_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export interface FormValues {
  name: string;
}

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const RenameBenefitPackageModal = () => {
  const intl = useIntl();

  const history = useHistory();
  const location = useLocation();

  const [latestBenefitPackageName, setLatestBenefitPackageName] =
    useState<string>();

  const { 'rename-benefit-package': benefitPackageId } = qs.parse(
    location.search,
  );

  const onRequestClose = () => {
    stripSearchParams(history, location, ['rename-benefit-package']);
  };

  const { submit, submissionError } = useSubmit({
    benefitPackageId,
    onRequestClose,
  });

  const { data, loading, error } = useQuery<
    renameBenefitPackageQuery,
    renameBenefitPackageQueryVariables
  >(RENAME_BENEFIT_PACKAGE_QUERY, {
    errorPolicy: 'all',
    skip: !benefitPackageId,
    variables: { benefitPackageId },
  });

  const benefitPackage = data?.benefitPackage;

  useEffect(() => {
    if (benefitPackage?.name) {
      setLatestBenefitPackageName(benefitPackage.name);
    }
  }, [benefitPackage?.name]);

  if (benefitPackageId && loading) {
    return <TopLoading />;
  }

  if (!latestBenefitPackageName) {
    return (
      <Modal isOpen={benefitPackageId} onRequestClose={onRequestClose}>
        <ModalHeader>
          <FormattedMessage {...commonBenefitMessages.renameBenefitPackage} />
        </ModalHeader>
        <ModalBody>
          <GraphQlError error={error} inModal />
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={onRequestClose}>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    );
  }

  const initialValues: FormValues = {
    name: latestBenefitPackageName,
  };

  return (
    <Modal
      isOpen={benefitPackageId}
      onRequestClose={onRequestClose}
      size="medium"
    >
      <ModalHeader>
        <FormattedMessage {...commonBenefitMessages.renameBenefitPackage} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <TextField
                dense
                name="name"
                label={<FormattedMessage {...commonMessages.name} />}
                required
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  text
                  type="submit"
                >
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

interface Args {
  benefitPackageId: string;
  location: RouteComponentProps['location'];
}

export const getRenameBenefitPackageLink = ({
  location,
  benefitPackageId,
}: Args): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'rename-benefit-package': benefitPackageId }),
});
