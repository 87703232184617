import { Table, Td, Th, Tr } from '@frontend/ui';
import { fcfwEventHistoryTableQuery_fcfwAssertions_FlexFcfwAssertionConnection as FlexFcfwAssertion } from 'app/apollo/graphql/types';
import { membershipEventHistoryMessages } from 'app/messages/employees';
import { FormattedDate, FormattedMessage } from 'components/formats';
import NoValue from 'components/NoValue';
import { workCapabilityMessages } from 'features/companies/company/employees/assert-work-capability/messages';
import React from 'react';

interface Props {
  fcfwAssertions: FlexFcfwAssertion;
  navigation: React.ReactNode;
}

export const FcfwTable: React.FC<Props> = ({ fcfwAssertions, navigation }) => (
  <Table navigation={navigation}>
    <colgroup>
      <col style={{ width: '30%' }} />
      <col style={{ width: '30%' }} />
      <col style={{ width: '40%' }} />
    </colgroup>
    <thead>
      <Tr>
        <Th>
          <FormattedMessage {...workCapabilityMessages.certificationDate} />
        </Th>
        <Th type="number">
          <FormattedMessage {...membershipEventHistoryMessages.reportingDate} />
        </Th>
        <Th align="left">
          <FormattedMessage {...membershipEventHistoryMessages.author} />
        </Th>
      </Tr>
    </thead>
    <tbody>
      {fcfwAssertions.edges.map(({ node }, i) => (
        <Tr key={i}>
          <Td>
            <FormattedDate value={node.effectiveDate} />
          </Td>
          <Td type="number">
            <FormattedDate
              value={node.createdAt}
              timeStyle="short"
              dateStyle="short"
            />
          </Td>
          <Td align="left">{node.actor?.displayName ?? <NoValue />}</Td>
        </Tr>
      ))}
    </tbody>
  </Table>
);
