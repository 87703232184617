import gql from 'graphql-tag';

export const CREATE_NORDNET_AGREEMENT_MUTATION = gql`
  mutation createNordnetAgreementMutation(
    $input: CreateNordnetAgreementMutationInput!
  ) {
    createNordnetAgreement(input: $input) {
      clientMutationId
    }
  }
`;
