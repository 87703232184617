import { Form, Formik, SelectField, TextField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import {
  createNlpAgreementEditCompanyQuery,
  createNlpAgreementEditCompanyQueryVariables,
  PaymentMethod,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import {
  ClearingFieldType,
  SelectClearingField,
} from 'features/companies/company/select-clearing';
import {
  accountNumberMessages,
  companyMessages,
  paymentMethodMessages,
} from 'features/companies/messages';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { getSelectComponentMonthOptions } from 'utils/get-select-month-options';
import * as Yup from 'yup';

import { agreementMessages } from '../../../messages';
import { CREATE_NLP_AGREEMENT_EDIT_COMPANY_QUERY } from '../graphql/queries';
import { useSubmit } from './use-submit';

export const EDIT_COMPANY_SEARCH_PARAM = 'edit-company';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    clearingSalesOffice: Yup.object().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    accountClosureMonth: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    paymentMethod: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    salaryReviewMonth: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    accountNumber: Yup.string().when('paymentMethod', {
      is: (paymentMethod: PaymentMethod | '') =>
        !!paymentMethod && paymentMethod !== PaymentMethod.INVOICE,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    salesRep: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  accountClosureMonth: number | '';
  accountNumber: string | '';
  clearingSalesOffice: ClearingFieldType | null;
  paymentMethod: PaymentMethod | '';
  salaryReviewMonth: number | '';
  salesRep: string;
}

export const EditCompanyDetailsModal: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const history = useHistory();
  const location = useLocation();
  const { submit, error: submissionError } = useSubmit();
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { data, loading, error } = useQuery<
    createNlpAgreementEditCompanyQuery,
    createNlpAgreementEditCompanyQueryVariables
  >(CREATE_NLP_AGREEMENT_EDIT_COMPANY_QUERY, {
    errorPolicy: 'all',
    variables: { companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const monthOptions = getSelectComponentMonthOptions({ intl });
  const search = qs.parse(location.search);
  const isOpen = EDIT_COMPANY_SEARCH_PARAM in search;

  const onRequestClose = () => {
    stripSearchParams(history, location, [EDIT_COMPANY_SEARCH_PARAM]);
  };

  const salesRepOptions =
    data?.nlpSalesReps.map(({ id, firstName, lastName, externalId }) => ({
      label: `${firstName} ${lastName} (${externalId})`,
      value: id,
    })) ?? [];

  const {
    accountClosureMonth,
    accountNumber,
    clearingCode,
    paymentMethod,
    salaryReviewMonth,
    nlpSalesRep,
  } = data?.company ?? {};

  return (
    <Modal size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...agreementMessages.editCompanyDetails} />
      </ModalHeader>
      <Formik<FormValues>
        onSubmit={submit}
        validationSchema={validationSchema(intl)}
        initialValues={{
          accountClosureMonth: accountClosureMonth ?? '',
          accountNumber: accountNumber ?? '',
          clearingSalesOffice: clearingCode
            ? {
                label: clearingCode.displayName,
                value: clearingCode.code,
              }
            : null,
          salesRep: nlpSalesRep?.id ?? '',
          paymentMethod: paymentMethod ?? '',
          salaryReviewMonth: salaryReviewMonth ?? '',
        }}
      >
        {({ values, isSubmitting }) => (
          <Form noValidate>
            <ModalBody>
              {error && <GraphQlError inModal error={error} />}
              {!nlpSalesRep && (
                <SelectField
                  fixed
                  required
                  dense
                  name="salesRep"
                  options={salesRepOptions}
                  label={formatMessage(companyMessages.nordeaSalesRep)}
                />
              )}
              {!clearingCode && (
                <SelectClearingField
                  required
                  inModal
                  name="clearingSalesOffice"
                />
              )}
              {!accountClosureMonth && (
                <SelectField
                  dense
                  fixed
                  required
                  name="accountClosureMonth"
                  options={monthOptions}
                  label={formatMessage(companyMessages.accountClosureMonth)}
                />
              )}
              {!salaryReviewMonth && (
                <SelectField
                  dense
                  fixed
                  required
                  name="salaryReviewMonth"
                  options={monthOptions}
                  label={formatMessage(companyMessages.salaryReviewMonth)}
                />
              )}
              {!paymentMethod && (
                <SelectField
                  dense
                  fixed
                  required
                  name="paymentMethod"
                  options={Object.values(PaymentMethod).map(value => ({
                    label: formatMessage({
                      messages: paymentMethodMessages,
                      select: value,
                    }),
                    value,
                  }))}
                  label={formatMessage(companyMessages.paymentMethod)}
                />
              )}
              {values.paymentMethod &&
                values.paymentMethod !== PaymentMethod.INVOICE && (
                  <TextField
                    dense
                    label={
                      <FormattedMessage
                        select={values.paymentMethod}
                        messages={accountNumberMessages}
                      />
                    }
                    name="accountNumber"
                    required
                  />
                )}
            </ModalBody>
            {submissionError && (
              <GraphQlError inModal error={submissionError} />
            )}
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button loading={isSubmitting} text type="submit">
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
