import { ContentContainer } from '@frontend/ui';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlErrors } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { Page } from 'features/page';
import { ConvertRisk } from 'features/sme/companies/company/proposals/edit/employees/convert-risk';
import { smeEmployeesMessages } from 'features/sme/messages/sme';
import { Suspense } from 'features/Suspense';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams } from '../..';

export const SmeCompanyProposalEmployeesConvertRiskRoute: React.FC<
  RouteComponentProps<MatchParams>
> = ({ match: { params, url } }) => (
  <NavigationAnchor title={smeEmployeesMessages.convertRiskTitle} url={url}>
    <Page
      title={<FormattedMessage {...smeEmployeesMessages.convertRiskTitle} />}
      parentLink={`/sme/${params.companyId}/proposals/${params.proposalId}/employees`}
    >
      <Suspense fallback={<TopLoading />}>
        {({ errors }) => (
          <>
            {errors && <GraphQlErrors errors={errors} />}
            <ContentContainer>
              <DescriptionWrapper>
                <TextGrid>
                  <FormattedMessage
                    {...smeEmployeesMessages.convertRiskDescription}
                  />
                </TextGrid>
              </DescriptionWrapper>
              <ConvertRisk />
            </ContentContainer>
          </>
        )}
      </Suspense>
    </Page>
  </NavigationAnchor>
);
