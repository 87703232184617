import {
  CheckboxField,
  DatePickerField,
  Form,
  Formik,
  SelectField,
  TextField,
} from '@frontend/formik';
import {
  Button,
  Grid,
  WizardContentCard,
  WizardSection,
  WizardSectionHeader,
} from '@frontend/ui';
import {
  createCompanyQuery,
  Language,
  PaymentMethod,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50 } from 'components/GridCell';
import { SimpleWizard } from 'components/SimpleWizard';
import { TopLoading } from 'components/TopLoading';
import {
  ClearingFieldType,
  SelectClearingField,
} from 'features/companies/company/select-clearing';
import React from 'react';
import { useHistory } from 'react-router';
import { getSelectComponentMonthOptions } from 'utils/get-select-month-options';

import {
  accountNumberMessages,
  companyMessages,
  paymentMethodMessages,
} from '../messages';
import { CREATE_COMPANY_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';
import { validationSchema } from './utils/validations';

const LANGUAGE_OPTIONS: Record<Language, string> = {
  [Language.SV_SE]: 'Svenska',
  [Language.EN_US]: 'English',
};

export interface FormValues {
  accountClosureMonth: number | '';
  accountNumber: string;
  clearingCode: ClearingFieldType;
  email: string;
  firstName: string;
  isOwner: boolean;
  language: Language;
  lastName: string;
  nodeAdminPid: string | null;
  paymentMethod: PaymentMethod | '';
  phone: string;
  pid: string;
  registrationNumber: string;
  salaryReviewMonth: number | '';
  salesRep: string;
  startDate: string;
}

export const initialValues: FormValues = {
  accountClosureMonth: 12,
  accountNumber: '',
  clearingCode: null,
  language: Language.SV_SE,
  isOwner: false,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  pid: '',
  registrationNumber: '',
  startDate: new Date().toISOString().substring(0, 10),
  paymentMethod: '',
  salaryReviewMonth: 4,
  salesRep: '',
  nodeAdminPid: '',
};

export const Create: React.FC = () => {
  const intl = useIntl();
  const { submit, submissionError } = useSubmit();
  const { formatMessage } = intl;
  const history = useHistory();

  const { loading, error, data } = useQuery<createCompanyQuery>(
    CREATE_COMPANY_QUERY,
    { errorPolicy: 'all' },
  );

  const monthOptions = getSelectComponentMonthOptions({ intl });
  const handleClose = () => history.push('/companies');

  const withForm = (content: React.ReactNode) => <Form>{content}</Form>;

  if (loading) {
    return <TopLoading />;
  }

  const salesRepOptions =
    data?.nlpSalesReps.map(({ id, firstName, lastName, externalId }) => ({
      label: `${firstName} ${lastName} (${externalId})`,
      value: id,
    })) ?? [];

  return (
    <Formik<FormValues>
      validateOnMount
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema(intl)}
    >
      {({ values: { startDate, paymentMethod }, isValid, isSubmitting }) => (
        <SimpleWizard
          actions={
            <>
              <Button text onClick={handleClose}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>
              <Button
                filled
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
              >
                <FormattedMessage {...companyMessages.addCompany} />
              </Button>
            </>
          }
          offWhite
          parentLink="/companies"
          title={<FormattedMessage {...companyMessages.addCompany} />}
          withForm={withForm}
        >
          <WizardContentCard
            header={
              <FormattedMessage {...companyMessages.customerInformation} />
            }
          >
            {error && <GraphQlError error={error} />}
            <TextField
              label={formatMessage(companyMessages.registrationNumber)}
              name="registrationNumber"
              dense
              required
            />
            <DatePickerField
              label={formatMessage(companyMessages.startDate)}
              name="startDate"
              dense
              required
              value={startDate}
            />
            <SelectField
              label={formatMessage(companyMessages.companyLanguage)}
              name="language"
              dense
              required
              options={Object.keys(Language).map(k => ({
                value: k,
                label: LANGUAGE_OPTIONS[k],
              }))}
            />
            <SelectField
              name="salesRep"
              dense
              options={salesRepOptions}
              label={formatMessage(companyMessages.nordeaSalesRep)}
              required
            />
            <SelectClearingField name="clearingCode" required />
            <SelectField
              dense
              name="accountClosureMonth"
              options={monthOptions}
              label={formatMessage(companyMessages.accountClosureMonth)}
            />
            <SelectField
              dense
              name="salaryReviewMonth"
              options={monthOptions}
              label={formatMessage(companyMessages.salaryReviewMonth)}
            />
            <SelectField
              dense
              name="paymentMethod"
              options={Object.values(PaymentMethod).map(value => ({
                label: formatMessage({
                  messages: paymentMethodMessages,
                  select: value,
                }),
                value,
              }))}
              label={formatMessage(companyMessages.paymentMethod)}
            />
            {paymentMethod && paymentMethod !== PaymentMethod.INVOICE && (
              <TextField
                dense
                label={
                  <FormattedMessage
                    select={paymentMethod}
                    messages={accountNumberMessages}
                  />
                }
                name="accountNumber"
                required
              />
            )}
            <WizardSection>
              <WizardSectionHeader>
                <FormattedMessage {...companyMessages.administrator} />
              </WizardSectionHeader>
              <CheckboxField
                label={formatMessage(companyMessages.isOwner)}
                name="isOwner"
              />
              <Grid>
                <GridCell50>
                  <TextField
                    label={formatMessage(companyMessages.firstName)}
                    name="firstName"
                    dense
                    required
                  />
                </GridCell50>
                <GridCell50>
                  <TextField
                    label={formatMessage(companyMessages.lastName)}
                    name="lastName"
                    dense
                    required
                  />
                </GridCell50>
              </Grid>
              <TextField
                label={formatMessage(companyMessages.pid)}
                name="pid"
                dense
                required
              />
              <TextField
                label={formatMessage(companyMessages.email)}
                name="email"
                dense
              />
              <TextField
                label={formatMessage(companyMessages.phone)}
                name="phone"
                dense
              />
            </WizardSection>
            <WizardSection>
              <WizardSectionHeader>
                <FormattedMessage {...companyMessages.nodeAdmin} />
              </WizardSectionHeader>
              <TextField
                label={formatMessage(companyMessages.pid)}
                name="nodeAdminPid"
                dense
              />
            </WizardSection>
            {submissionError && <GraphQlError error={submissionError} />}
          </WizardContentCard>
        </SimpleWizard>
      )}
    </Formik>
  );
};
