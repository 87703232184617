import { Section, SectionHeader, Table, Td, Th, Tr } from '@frontend/ui';
import {
  salaryExchangeEventsQuery,
  salaryExchangeEventsQuery_salaryExchangeRequestsHistory_FlexSalaryExchangeRequestsHistoryConnection as SalaryExchangeRequestHistory,
  salaryExchangeEventsQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { salaryExchangeProviderMessages } from 'app/messages/salary-exchange';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import {
  DEFAULT_RESULT_PER_PAGE_SMALL,
  UnitCodeOptions,
  unitCodeSuffixMessages,
} from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
} from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { GraphQlError } from 'components/GraphQlError';
import { NoValue } from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import {
  companySalaryExchangeMessages,
  salaryExchangeRequestStatusMessages,
} from '../messages';
import { SALARY_EXCHANGE_EVENTS_QUERY } from './graphql/queries';
import { updateSalaryExchangeEventsQuery } from './utils/update-query';

interface RequestHistory {
  readonly salaryExchangeRequestsHistory: SalaryExchangeRequestHistory;
}

const isEmpty = (data?: salaryExchangeEventsQuery) =>
  !data?.salaryExchangeRequestsHistory?.edges.length;

const hasOngoingRequests = (
  data?: salaryExchangeEventsQuery,
): data is RequestHistory => !isEmpty(data);

export const SalaryExchangeEventsTable: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const [suspend, setSuspend] = useState(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const resultsPerPage = parseInt(perPage, 10);

  const { loading, data, previousData, error, fetchMore } = useQuery<
    salaryExchangeEventsQuery,
    salaryExchangeEventsQueryVariables
  >(SALARY_EXCHANGE_EVENTS_QUERY, {
    suspend,
    errorPolicy: 'all',
    isEmpty,
    variables: { companyId, first: resultsPerPage },
    onCompleted: () => setSuspend(false),
    notifyOnNetworkStatusChange: true,
  });

  const _data = data ?? previousData;

  if (!hasOngoingRequests(_data)) {
    return null;
  }

  const { edges, pageInfo } = _data.salaryExchangeRequestsHistory;
  const { endCursor, startCursor } = pageInfo;
  const requests = edges.map(({ node }) => node);

  return (
    <>
      {!suspend && loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <Section>
        <SectionHeader>
          <FormattedMessage {...companySalaryExchangeMessages.historyHeader} />
        </SectionHeader>
        <Table
          navigation={
            fetchMore && (
              <TableNavigation
                perPage={perPage}
                setPerPage={setPerPage}
                pageInfo={pageInfo}
                onNextPage={() => {
                  fetchMore({
                    variables: {
                      after: endCursor,
                    },
                    updateQuery: updateSalaryExchangeEventsQuery,
                  });
                }}
                onPreviousPage={() => {
                  fetchMore({
                    variables: {
                      before: startCursor,
                      first: undefined,
                      last: resultsPerPage,
                    },
                    updateQuery: updateSalaryExchangeEventsQuery,
                  });
                }}
              />
            )
          }
        >
          <thead>
            <Tr>
              <Th>
                <FormattedMessage {...commonMessages.effectiveDate} />
              </Th>
              <Th type="number">
                <FormattedMessage {...commonMessages.personalIdentityNumber} />
              </Th>
              <Th>
                <FormattedMessage {...commonMessages.name} />
              </Th>
              <Th type="number">
                <FormattedMessage
                  {...companySalaryExchangeMessages.benefitQualifyingIncome}
                />
              </Th>
              <Th type="number">
                <FormattedMessage
                  {...companySalaryExchangeMessages.deduction}
                />
              </Th>
              <Th type="number">
                <FormattedMessage {...companySalaryExchangeMessages.premium} />
              </Th>
              <Th>
                <FormattedMessage {...commonMessages.via} />
              </Th>
              <Th>
                <FormattedMessage {...commonMessages.status} />
              </Th>
            </Tr>
          </thead>
          <tbody>
            {requests.map(request => (
              <Tr key={request.id}>
                <Td>
                  <FormattedDate value={request.effectiveDate} />
                </Td>
                <Td type="number">
                  <FormattedNaturalPersonIdentifier
                    nids={request.membership.naturalPersonIdentifiers}
                  />
                </Td>
                <Td>
                  {request.membership.givenName} {request.membership.lastName}
                </Td>
                <Td type="number">
                  {request.remuneration ? (
                    <FormattedCurrency
                      currency={request.remuneration.currency ?? 'SEK'}
                      value={request.remuneration.value}
                      after={
                        request.remuneration.unitCode &&
                        request.remuneration.unitCode !== UnitCodeOptions.LS ? (
                          <>
                            /
                            <FormattedMessage
                              select={request.remuneration.unitCode}
                              messages={unitCodeSuffixMessages}
                            />
                          </>
                        ) : null
                      }
                    />
                  ) : (
                    <NoValue />
                  )}
                </Td>
                <Td type="number">
                  <FormattedCurrency
                    after="PER_MONTH"
                    currency="SEK"
                    value={request.deductionValue}
                  />
                </Td>
                <Td type="number">
                  <FormattedCurrency
                    after="PER_MONTH"
                    currency="SEK"
                    value={request.premium}
                  />
                </Td>
                <Td>
                  <FormattedMessage
                    select={request.provider}
                    messages={salaryExchangeProviderMessages}
                  />
                </Td>
                <Td>
                  <FormattedMessage
                    select={request.status}
                    messages={salaryExchangeRequestStatusMessages}
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </>
  );
};
