import { Form, Formik, SelectField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter, SelectOption } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import { commonMessages, validationMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import { agreementMessages } from '../../messages';

enum AgreementType {
  EuroAccidentGroup = 'EuroAccidentGroup',
  EuroAccidentTjp = 'EuroAccidentTjp',
  Nordea = 'Nordea',
  Nordnet = 'Nordnet',
}

const AGREEMENT_OPTIONS: SelectOption[] = [
  { label: 'Euro Accident Grupp', value: AgreementType.EuroAccidentGroup },
  { label: 'Euro Accident TJP', value: AgreementType.EuroAccidentTjp },
  { label: 'Nordea', value: AgreementType.Nordea },
  { label: 'Nordnet', value: AgreementType.Nordnet },
];

/**
 * URL paths for each agreement type
 */
export const AGREEMENT_TYPE_URL: Record<AgreementType, string> = {
  EuroAccidentGroup: 'euro-accident-group',
  EuroAccidentTjp: 'euro-accident-tjp',
  Nordea: 'nlp',
  Nordnet: 'nordnet',
};

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    agreementType: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

const ADD_AGREEMENT_SEARCH_PARAM = 'add-agreement';

interface Args {
  location: RouteComponentProps['location'];
}

export const getAddAgreementLink = ({
  location,
}: Args): RouteComponentProps['location'] => ({
  ...location,
  search: ADD_AGREEMENT_SEARCH_PARAM,
});

interface FormValues {
  agreementType: AgreementType | '';
}

export const AddAgreementModal: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { push } = history;
  const location = useLocation();

  const search = qs.parse(location.search);
  const isOpen = ADD_AGREEMENT_SEARCH_PARAM in search;

  const onRequestClose = () => {
    stripSearchParams(history, location, [ADD_AGREEMENT_SEARCH_PARAM]);
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        onSubmit={({ agreementType }) => {
          push(`${location.pathname}/${AGREEMENT_TYPE_URL[agreementType]}`);
        }}
        initialValues={{ agreementType: '' }}
        validationSchema={validationSchema(intl)}
      >
        <Form noValidate>
          <ModalHeader>
            <FormattedMessage {...agreementMessages.add} />
          </ModalHeader>
          <ModalBody>
            <SelectField
              fixed
              dense
              required
              name="agreementType"
              label={
                <FormattedMessage {...agreementMessages.selectAgreementType} />
              }
              options={AGREEMENT_OPTIONS}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonLayout align="right">
              <Button text onClick={onRequestClose}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>
              <Button text type="submit">
                <FormattedMessage {...commonMessages.continue} />
              </Button>
            </ButtonLayout>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  );
};
