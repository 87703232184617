import {
  // eslint-disable-next-line
  Modal as ModalComponent,
  // eslint-disable-next-line
  ModalBody as ModalBodyComponent,
  // eslint-disable-next-line
  ModalHeader as ModalHeaderComponent,
  ModalHeaderProps,
  ModalProps,
} from '@frontend/ui';
import { APP_ID } from 'app/utils/constants';
import { IntlShape } from 'components/formats';
import React, {
  createContext,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import * as Yup from 'yup';

interface ModalAriaContext {
  isMissingHeader: boolean;
  labelId: string;
  setIsMissingHeader: (isMissingHeader: boolean) => void;
}

export const ModalAriaContext = createContext<ModalAriaContext>({
  isMissingHeader: false,
  labelId: '',
  setIsMissingHeader: (isMissingHeader: boolean) => isMissingHeader,
});

export const useModalAriaContext = (): ModalAriaContext =>
  useContext(ModalAriaContext);

type Props = Omit<ModalProps, 'labelId'>;

export const Modal: React.FC<Props> = ({
  children,
  contentLabel,
  onRequestClose,
  withAnimation,
  ...props
}) => {
  const labelId = useId();
  const [isMissingHeader, setIsMissingHeader] = useState(true);
  const appElement = document.getElementById(APP_ID);

  const value = useMemo(
    () => ({
      isMissingHeader,
      setIsMissingHeader,
      labelId,
    }),
    [labelId, isMissingHeader],
  );

  const _props = contentLabel
    ? { ...props, contentLabel }
    : { ...props, labelId };

  if (props.size === 'full-window') {
    return (
      <ModalComponent
        {..._props}
        appElement={appElement}
        withAnimation={withAnimation}
        size="full-window"
      >
        <ModalAriaContext.Provider value={value}>
          {children}
        </ModalAriaContext.Provider>
      </ModalComponent>
    );
  }

  return (
    <ModalComponent
      {..._props}
      appElement={appElement}
      onRequestClose={onRequestClose}
    >
      <ModalAriaContext.Provider value={value}>
        {children}
      </ModalAriaContext.Provider>
    </ModalComponent>
  );
};

export const ModalHeader: React.FC<ModalHeaderProps> = props => {
  const { labelId, setIsMissingHeader } = useModalAriaContext();

  useEffect(() => {
    setIsMissingHeader(false);
  }, [setIsMissingHeader]);

  return <ModalHeaderComponent id={labelId} {...props} />;
};

interface ModalBodyProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const ModalBody: React.FC<ModalBodyProps> = props => {
  const { labelId, isMissingHeader } = useModalAriaContext();

  return (
    <ModalBodyComponent id={isMissingHeader ? labelId : undefined} {...props} />
  );
};

/**
 * Use this function to run an action after the modal has faded out.
 * Primiarily intended to clear modal forms after the modal has closed
 * to prevent flickering.
 */
export const afterFadeout = (callback: () => void) => {
  setTimeout(() => {
    callback();
  }, 300);
};
// Stepper modal
export interface Step {
  body: React.ReactNode;
  header: React.ReactNode;
  validationSchema?: (intlShape: IntlShape) => Yup.ObjectSchema<object>;
}

interface StepWithIndex extends Step {
  index: number;
}

interface ModalStepper {
  activeStep: StepWithIndex;
  goToNext: () => void;
  goToPrevious: () => void;
  isFirstStep: boolean;
  isLastStep: boolean;
  resetStepper: () => void;
  stepsWithIndex: StepWithIndex[];
}

interface Params {
  steps: Step[];
}

/**
 * Manages the state of a modal stepper.
 */
export const useModalStepper = ({ steps }: Params): ModalStepper => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const activeStep = { ...steps[activeStepIndex], index: activeStepIndex };

  const goToNext = () => {
    setActiveStepIndex(prevIndex => Math.min(prevIndex + 1, steps.length - 1));
  };

  const goToPrevious = () => {
    setActiveStepIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === steps.length - 1;

  const resetStepper = () => {
    setActiveStepIndex(0);
  };

  const stepsWithIndex = steps.map((step, index) => ({
    ...step,
    index,
  }));

  return {
    activeStep,
    goToNext,
    goToPrevious,
    isFirstStep,
    isLastStep,
    resetStepper,
    stepsWithIndex,
  };
};
