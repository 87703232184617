import { ApolloError } from '@apollo/client';
import { ChipGroupField, Form, Formik, TextField } from '@frontend/formik';
import { Button, ButtonLayout, Icon, ModalFooter } from '@frontend/ui';
import { check } from '@frontend/ui/icons';
import {
  CompanyBillingAttachmentFormat,
  companyBillingConfigurationDetails,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import React from 'react';
import { isEmail } from 'validations';
import * as Yup from 'yup';

import { billingMessages } from '../../messages';

const BILLING_FORMATS = Object.values(CompanyBillingAttachmentFormat);

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid email',
        intl.formatMessage(validationMessages.isValidEmail),
        value => isEmail(value),
      ),
    attachmentFormats: Yup.array().min(
      1,
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  attachmentFormats: readonly CompanyBillingAttachmentFormat[];
  email: string;
}

interface Props {
  isOpen: boolean;
  label: string;
  onRequestClose: () => void;
  onSubmit: (formValues: FormValues) => Promise<void>;
  billingEmail?: companyBillingConfigurationDetails;
  error?: ApolloError;
  onRequestDelete?: () => Promise<void>;
}

export const BillingEmailModal: React.FC<Props> = ({
  billingEmail,
  onRequestClose,
  isOpen,
  onSubmit,
  error,
  label,
}) => {
  const intl = useIntl();

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {error && <GraphQlError inModal error={error} />}
      <Formik<FormValues>
        initialValues={{
          email: billingEmail?.email ?? '',
          attachmentFormats: billingEmail?.attachmentFormats ?? [],
        }}
        enableReinitialize
        validationSchema={validationSchema(intl)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalHeader>{label}</ModalHeader>
            <ModalBody>
              <p>
                <FormattedMessage {...billingMessages.billingEmailNote} />
              </p>
              <TextField
                required
                type="email"
                name="email"
                label={<FormattedMessage {...commonMessages.email} />}
              />
              <ChipGroupField
                required
                name="attachmentFormats"
                selectedIcon={<Icon icon={check} decorative />}
                legend={
                  <FormattedMessage {...billingMessages.attachmentsRequired} />
                }
                options={BILLING_FORMATS.map(format => ({
                  label: format,
                  value: format,
                }))}
              />
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  text
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
