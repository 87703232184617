import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  ChipsField,
  DatePickerField,
  Form as FormikForm,
  RadioGroupField,
  TextField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  ContentContainer,
  Section,
  Subsection,
  SubsectionHeader,
} from '@frontend/ui';
import {
  internalNewsFormConfigQuery,
  internalNewsFormConfigQueryVariables,
  InternalPostStatus,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { formMessages } from 'app/messages/form';
import { menuMessages } from 'app/messages/menu';
import { useQuery } from 'app/utils/use-query';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FileInfo } from 'components/FileUploadTable';
import { FormattedMessage, useIntl } from 'components/formats';
import { FieldTags } from 'components/formik/FieldTags';
import { FileUploadField } from 'components/formik/FileUploadField';
import { Image, ImageUploadField } from 'components/formik/ImageUploadField';
import { RichTextEditor } from 'components/formik/RichTextEditor';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { MatchParams } from 'features/companies/company';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { internalNewsFormMessages } from '../../messages';
import { INTERNAL_NEWS_FORM_CONFIG_QUERY } from './graphql/queries';

export interface FormValues {
  body: string;
  important: boolean;
  preamble: string;
  publicationDate: string;
  status: InternalPostStatus;
  title: string;
  attachmentFiles?: FileInfo[] | null;
  benefitPackages?: Record<string, string>;
  hero?: Image | null;
  organizationalUnits?: Record<string, string>;
  tags?: readonly string[] | null;
}

interface Props {
  secondaryAction?: React.ReactNode;
  submissionError?: ApolloError;
}

export const Form: React.FC<Props> = ({ secondaryAction, submissionError }) => {
  const { formatMessage } = useIntl();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const { isSubmitting, isValid } = useFormikContext<FormValues>();

  const { data, loading, error } = useQuery<
    internalNewsFormConfigQuery,
    internalNewsFormConfigQueryVariables
  >(INTERNAL_NEWS_FORM_CONFIG_QUERY, {
    variables: { companyId },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  const benefitPackageOptions =
    data?.company?.benefitPackages?.edges.map(p => ({
      value: p.node.id,
      label: p.node.name,
    })) ?? [];

  const organizationalUnitOptions =
    data?.company?.organizationalUnits?.edges.map(u => ({
      value: u.node.id,
      label: u.node.name,
    })) ?? [];

  return (
    <ContentContainer>
      <Section>
        <FormikForm>
          {error && <GraphQlError error={error} />}
          <TextGrid>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...internalNewsFormMessages.detail} />
              </SubsectionHeader>
              <TextField
                name="title"
                label={<FormattedMessage {...internalNewsFormMessages.title} />}
                required
                dense
              />
              <TextField
                name="preamble"
                label={
                  <FormattedMessage {...internalNewsFormMessages.description} />
                }
                required
                dense
              />
              <div className="with-presentation-text-styles">
                <RichTextEditor
                  name="body"
                  placeholder={formatMessage(internalNewsFormMessages.body)}
                  height="21rem"
                  markup="html"
                />
              </div>
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...internalNewsFormMessages.image} />
              </SubsectionHeader>
              <ImageUploadField
                companyId={companyId}
                name="hero"
                imageType="hero"
                // eslint-disable-next-line
                label={({ isEdit }) => (
                  <FormattedMessage
                    {...(!isEdit
                      ? internalNewsFormMessages.addImage
                      : internalNewsFormMessages.editImage)}
                  />
                )}
              />
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...internalNewsFormMessages.categorization}
                />
              </SubsectionHeader>
              <CheckboxField
                name="important"
                label={formatMessage(internalNewsFormMessages.markAsImportant)}
              />
              <FieldTags
                name="tags"
                label={formatMessage(internalNewsFormMessages.tags)}
              />
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...internalNewsFormMessages.attachDocuments}
                />
              </SubsectionHeader>
              <FileUploadField
                companyId={companyId}
                name="attachmentFiles"
                label={
                  <FormattedMessage {...internalNewsFormMessages.selectFiles} />
                }
              />
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...commonMessages.benefitPackages} />
              </SubsectionHeader>
              <DescriptionWrapper>
                <FormattedMessage
                  {...internalNewsFormMessages.benefitPackagesDescription}
                />
              </DescriptionWrapper>

              <ChipsField
                dense
                name="benefitPackages"
                label={
                  <FormattedMessage
                    {...formMessages.benefitPackagesPlaceholder}
                  />
                }
                options={benefitPackageOptions}
              />
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage {...menuMessages.organizationalUnits} />
              </SubsectionHeader>
              <DescriptionWrapper>
                <FormattedMessage
                  {...internalNewsFormMessages.organizationalUnitsDescription}
                />
              </DescriptionWrapper>
              <ChipsField
                dense
                name="organizationalUnits"
                label={<FormattedMessage {...companyMessages.selectOrgUnit} />}
                options={organizationalUnitOptions}
              />
            </Subsection>
            <Subsection>
              <SubsectionHeader>
                <FormattedMessage
                  {...internalNewsFormMessages.publicationStatus}
                />
              </SubsectionHeader>
              <DescriptionWrapper>
                <RadioGroupField
                  name="status"
                  options={[
                    {
                      value: 'PRIVATE',
                      label: formatMessage(internalNewsFormMessages.private),
                    },
                    {
                      value: 'PUBLIC',
                      label: formatMessage(internalNewsFormMessages.published),
                    },
                  ]}
                  required
                />
              </DescriptionWrapper>
              <DatePickerField
                dense
                name="publicationDate"
                label={
                  <FormattedMessage
                    {...internalNewsFormMessages.publicationDate}
                  />
                }
                helperText={
                  <FormattedMessage
                    {...internalNewsFormMessages.publicationDateHelperText}
                  />
                }
              />
            </Subsection>
          </TextGrid>
          <Subsection>
            {submissionError && <GraphQlError error={submissionError} />}
            <ButtonLayout align="left">
              <Button
                filled
                type="submit"
                loading={isSubmitting}
                disabled={!isValid}
              >
                <FormattedMessage {...formMessages.save} />
              </Button>
              {secondaryAction}
            </ButtonLayout>
          </Subsection>
        </FormikForm>
      </Section>
    </ContentContainer>
  );
};
