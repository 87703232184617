import { addCircle } from '@frontend/ui/icons';
import {
  companyImportsQuery,
  companyImportsQueryVariables,
} from 'app/apollo/graphql/types';
import { employeesImportMessages } from 'app/messages/employees';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React, { useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { MatchParams } from '..';
import { COMPANY_IMPORTS_QUERY } from './graphql/queries';
import { ImportsTable } from './Table';
import updateCompanyImportsQuery from './utils/update-company-imports-query';

const isEmpty = (data?: companyImportsQuery) =>
  !data?.company?.imports.edges.length;

export const Imports: React.FC = () => {
  const location = useLocation();
  const { params } = useRouteMatch<MatchParams>();
  const { per_page } = qs.parse(location.search);

  const [suspend, setSuspend] = useState(true);

  const { loading, error, previousData, data, fetchMore } = useQuery<
    companyImportsQuery,
    companyImportsQueryVariables
  >(COMPANY_IMPORTS_QUERY, {
    errorPolicy: 'all',
    // XXX: Not ideal but this is easier than trying to
    // refetch the query within all the different mutations
    // whilst persisting current per_page amount.
    fetchPolicy: 'cache-and-network',
    isEmpty,
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setSuspend(false),
    suspend,
    variables: {
      companyId: params.companyId,
      first: per_page ? parseInt(per_page, 10) : DEFAULT_RESULT_PER_PAGE,
    },
  });

  const _data = data ?? previousData;

  if (!_data?.company?.imports || isEmpty(_data)) {
    return null;
  }

  const { imports } = _data.company;

  return (
    <>
      {loading && !suspend && <TopLoading />}
      {error && !suspend && <GraphQlError error={error} />}
      <ChipsWrapper>
        <AssistChip
          to={`/companies/${params.companyId}/employees/import`}
          text={
            <FormattedMessage {...employeesImportMessages.importEmployees} />
          }
          leadingIcon={addCircle}
        />
      </ChipsWrapper>
      <ImportsTable
        imports={imports.edges.map(e => e.node)}
        navigation={
          <TableNavigation
            pageInfo={imports.pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: imports.pageInfo.endCursor,
                },
                updateQuery: updateCompanyImportsQuery,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: imports.pageInfo.startCursor,
                  first: undefined,
                  last: per_page
                    ? parseInt(per_page, 10)
                    : DEFAULT_RESULT_PER_PAGE,
                },
                updateQuery: updateCompanyImportsQuery,
              });
            }}
          />
        }
      />
    </>
  );
};
