import { useMutation } from '@apollo/client';
import {
  deleteInternalPostMutation,
  deleteInternalPostMutationVariables,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'app/messages/form';
import { EditRouteMatchParams } from 'app/pages/companies/company/internal-news/edit';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { DELETE_INTERNAL_POST_MUTATION } from '../graphql/mutations';

export const useDelete = () => {
  const { push } = useHistory();
  const {
    params: { id, companyId },
  } = useRouteMatch<EditRouteMatchParams>();
  const intl = useIntl();
  const { send } = useNotification();

  const [deleteInternalPost, { loading, error }] = useMutation<
    deleteInternalPostMutation,
    deleteInternalPostMutationVariables
  >(DELETE_INTERNAL_POST_MUTATION, {
    update: cache => {
      cache.evict({ id: `InternalPost:${id}` });
      cache.gc();
    },
  });

  const _delete = async () => {
    try {
      const res = await deleteInternalPost({
        variables: {
          input: {
            id,
          },
        },
      });

      if (!res.data?.deleteInternalPost?.id) {
        throw new Error();
      }

      send({
        message: intl.formatMessage(formSubmitMessages.deleteNewsSuccessText),
        type: 'success',
      });
      push(`/companies/${companyId}/internal-news`);
    } catch {
      // Do nothing
    }
  };
  return { _delete, loading, error };
};
