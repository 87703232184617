import { stripSearchParams } from '@frontend/utils';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

/**
 * A custom hook that returns the `stripSearchParams` function
 * from the frontend utils library. This function is used to
 * remove specified search parameters from the URL.
 *
 * @param {string[]} [strip] - An optional array of parameters to be stripped from the URL.
 * If omitted, all search parameters will be stripped.
 *
 * This hook ensures that the latest `history` and `location` objects are used,
 * making it safe to use with callbacks that utilize `setTimeout`, such as `afterFadeout`.
 */

export const useStripSearchParams = () => {
  const history = useHistory();
  const location = useLocation();
  const historyRef = useRef(history);
  const locationRef = useRef(location);

  useEffect(() => {
    historyRef.current = history;
    locationRef.current = location;
  }, [history, location]);

  return (strip?: string[]) =>
    stripSearchParams(historyRef.current, locationRef.current, strip);
};
