import { Agreements } from 'features/companies/company/agreements';
import { AddAgreementModal } from 'features/companies/company/agreements/components/AddAgreementModal';
import React from 'react';

export const AgreementsRoute: React.FC = () => (
  <>
    <Agreements />
    <AddAgreementModal />
  </>
);
