import { TextField, useFormikContext } from '@frontend/formik';
import { Body, Grid, Subsection, SubsectionHeader } from '@frontend/ui';
import { membershipDetailsQuery_membership_Membership as Membership } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import {
  employeeFormMessages,
  workInformationMessages,
} from 'app/messages/employees';
import { DescriptionWrapper } from 'components/DescriptionWrapper';
import { FormattedMessage } from 'components/formats';
import { GridCell50 } from 'components/GridCell';
import React from 'react';

import { NidTableField, NidValue } from '../NidTableField';

export interface PersonalDataFormValues {
  addressCity: string;
  addressLine1: string;
  addressPostalCode: string;
  email: string | null;
  employeeId: string | null;
  givenName: string | null;
  lastName: string | null;
  nids: NidValue[];
  phone: string | null;
}

export const personalDataInitialValues = (
  membership: Membership,
): PersonalDataFormValues => ({
  addressCity: membership.address?.city ?? '',
  addressLine1: membership.address?.line1 ?? '',
  addressPostalCode: membership.address?.postalCode ?? '',
  givenName: membership.givenName ?? '',
  lastName: membership.lastName ?? '',
  employeeId: membership.employeeNumber ?? '',
  email: membership.email ?? '',
  phone: membership.phone ?? '',
  nids: membership.naturalPersonIdentifiers.map(nid => ({
    value: nid,
    editable: false,
  })),
});

export const PersonalDataFields: React.FC = () => {
  const { values } = useFormikContext<PersonalDataFormValues>();
  const hasNid = values.nids.length > 0;
  const hasAddressSet =
    !!values.addressCity || !!values.addressLine1 || !!values.addressPostalCode;

  return (
    <Subsection>
      <SubsectionHeader>
        <FormattedMessage {...workInformationMessages.personalDetails} />
      </SubsectionHeader>
      <Grid>
        <GridCell50>
          <TextField
            dense
            name="givenName"
            label={<FormattedMessage {...commonMessages.givenName} />}
            required
          />
        </GridCell50>
        <GridCell50>
          <TextField
            dense
            name="lastName"
            label={<FormattedMessage {...commonMessages.lastName} />}
            required
          />
        </GridCell50>
      </Grid>
      <Body>
        <FormattedMessage {...employeeFormMessages.nidDescription} />
      </Body>
      <DescriptionWrapper>
        <NidTableField name="nids" />
      </DescriptionWrapper>
      <TextField
        dense
        name="addressLine1"
        label={<FormattedMessage {...employeeFormMessages.address} />}
        required={!hasNid || hasAddressSet}
      />
      <Grid>
        <GridCell50>
          <TextField
            dense
            name="addressPostalCode"
            label={<FormattedMessage {...commonMessages.postalCode} />}
            required={!hasNid || hasAddressSet}
          />
        </GridCell50>
        <GridCell50>
          <TextField
            dense
            name="addressCity"
            label={<FormattedMessage {...employeeFormMessages.city} />}
            required={!hasNid || hasAddressSet}
          />
        </GridCell50>
      </Grid>
      <TextField
        dense
        name="employeeId"
        label={
          <FormattedMessage {...workInformationMessages.employeeNumberLabel} />
        }
      />
      <TextField
        dense
        name="email"
        type="email"
        label={<FormattedMessage {...commonMessages.email} />}
        required
      />
      <TextField
        dense
        name="phone"
        type="tel"
        label={<FormattedMessage {...commonMessages.phone} />}
      />
    </Subsection>
  );
};
