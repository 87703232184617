import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  updateMembershipRolesMutation,
  updateMembershipRolesMutationVariables,
} from 'app/apollo/graphql/types';
import { companyMessages } from 'app/messages/company';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';

import { UPDATE_MEMBERSHIP_ROLES_MUTATION } from '../../graphql/mutations';
import { FormValues } from '..';

interface Submit {
  loading: boolean;
  submit: (
    values?: FormValues,
    helpers?: FormikHelpers<FormValues>,
  ) => Promise<void>;
  error?: ApolloError;
}

interface Props {
  companyId: string;
  onCompleted: () => void;
}

export const useSubmit = ({ companyId, onCompleted }: Props): Submit => {
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const [updateMembershipRoles, { error, loading }] = useMutation<
    updateMembershipRolesMutation,
    updateMembershipRolesMutationVariables
  >(UPDATE_MEMBERSHIP_ROLES_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${companyId}`,
        fieldName: 'administrators',
      });
      cache.evict({
        id: `Company: ${companyId}`,
        fieldName: 'memberships',
      });
      cache.gc();
    },
  });

  const submit = async (values, helpers) => {
    try {
      await updateMembershipRoles({
        variables: {
          companyId,
          userAccountId: values.userAccountId,
          roles: values.roles,
        },
      });

      send({
        message: formatMessage(companyMessages.administratorEditSuccess),
        type: 'success',
      });
      onCompleted();
    } catch (e) {
      helpers.setErrors({
        submissionError: e?.message,
      });
    }
  };

  return {
    submit,
    loading,
    error,
  };
};
