import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const MEMBERSHIP_EVENT_HISTORY_TABLE_QUERY = gql`
  query membershipEventHistoryTableQuery(
    $companyId: ID!
    $userAccountId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      eventHistory(first: $first, after: $after, last: $last, before: $before) {
        edges {
          node {
            effectiveDate
            createdAt
            actor {
              displayName
            }
            source
            events {
              event
              value
            }
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${pageInfoDetails}
`;
