import gql from 'graphql-tag';

export const SME_COMPANY_CONVERT_RISK_QUERY = gql`
  query smeCompanyConvertRiskQuery($proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      memberships {
        id
        convertRiskInsurances
        firstName
        lastName
        personalIdentityNumber
      }
    }
  }
`;
