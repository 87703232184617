import { Details } from '../components/Details';
import { FormValues } from '../components/Form';

export const getPreviewDetails = (values: FormValues): Details => ({
  title: values.title,
  description: values.preamble,
  body: values.body,
  publishedAt: values.publicationDate,
  attachments: values.attachmentFiles ?? [],
  tags: values.tags ?? [],
  hero: values.hero?.previewUrl,
});
