import { RadioGroupOption } from '@frontend/ui';
import { bpsToDecimalFraction, toNumber } from '@frontend/utils';
import {
  CommissionWaiverFactor,
  RiskBucket,
  updateProposalQuery_Query as Data,
} from 'app/apollo/graphql/types';
import { IntlShape } from 'components/formats';
import { smeFeesMessages } from 'features/sme/messages/sme';

import { OnChange } from '../../../edit/contexts/autosave';

interface FormValues {
  capitalFeeFund: string;
  capitalFeePortfolio: string;
  capitalFeeTraditional: string;
  commissionWaiverAllowed: boolean;
  commissionWaiverHealthcare: CommissionWaiverFactor;
  commissionWaiverPremiumWaiver: CommissionWaiverFactor;
  commissionWaiverSickness: CommissionWaiverFactor;
  fundDiscountPermitted: boolean;
  riskBucket: string;
  annualFeeFund?: string;
  annualFeePortfolio?: string;
  annualFeeTraditional?: string;
  fundDiscountValue?: string;
}

export const formatRiskBucketInput = (value: string): RiskBucket | null => {
  if (value === '') {
    return null;
  }
  const riskBucketValue = Object.values(RiskBucket).find(v => v === value);
  return riskBucketValue ?? null;
};

export const getFormValues = (data?: Data): FormValues => ({
  annualFeeFund:
    data?.proposal?.fees?.annualFeeFund != null
      ? data.proposal.fees?.annualFeeFund.toString()
      : undefined,
  annualFeePortfolio:
    data?.proposal?.fees?.annualFeePortfolio != null
      ? data.proposal.fees?.annualFeePortfolio.toString()
      : undefined,
  annualFeeTraditional:
    data?.proposal?.fees?.annualFeeTraditional != null
      ? data.proposal.fees?.annualFeeTraditional.toString()
      : undefined,
  capitalFeeFund:
    data?.proposal?.fees?.capitalFeeFund != null
      ? data.proposal.fees?.capitalFeeFund.toString()
      : '',
  capitalFeePortfolio:
    data?.proposal?.fees?.capitalFeePortfolio != null
      ? data.proposal.fees?.capitalFeePortfolio.toString()
      : '',
  capitalFeeTraditional:
    data?.proposal?.fees?.capitalFeeTraditional != null
      ? data.proposal.fees?.capitalFeeTraditional.toString()
      : '',
  fundDiscountPermitted: data?.proposal?.fees?.fundDiscountPermitted ?? false,
  fundDiscountValue:
    data?.proposal?.fees?.fundDiscountValue != null
      ? data.proposal.fees?.fundDiscountValue.toString()
      : undefined,
  commissionWaiverAllowed:
    data?.proposal?.fees?.commissionWaiverAllowed ?? false,
  commissionWaiverHealthcare:
    data?.proposal?.fees.commissionWaiverHealthcare != null
      ? data.proposal.fees.commissionWaiverHealthcare
      : CommissionWaiverFactor.ZERO,
  commissionWaiverPremiumWaiver:
    data?.proposal?.fees?.commissionWaiverPremiumWaiver != null
      ? data.proposal.fees?.commissionWaiverPremiumWaiver
      : CommissionWaiverFactor.ZERO,
  commissionWaiverSickness:
    data?.proposal?.fees?.commissionWaiverSickness != null
      ? data.proposal.fees?.commissionWaiverSickness
      : CommissionWaiverFactor.ZERO,
  riskBucket: data?.proposal?.fees?.riskBucket ?? '',
});

export const getOnChangeHandlers = (onChange: OnChange) => ({
  annualFeeFund: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          annualFeeFund: option.value === 'true',
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            annualFeeFund: option.value === 'true',
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  annualFeePortfolio: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          annualFeePortfolio: option.value === 'true',
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            annualFeePortfolio: option.value === 'true',
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  annualFeeTraditional: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          annualFeeTraditional: option.value === 'true',
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            annualFeeTraditional: option.value === 'true',
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  capitalFeeFund: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          capitalFeeFund: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            capitalFeeFund: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  capitalFeePortfolio: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          capitalFeePortfolio: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            capitalFeePortfolio: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  capitalFeeTraditional: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          capitalFeeTraditional: toNumber(value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            capitalFeeTraditional: toNumber(value) ?? null,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  fundDiscountPermitted: (value: boolean) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          fundDiscountPermitted: value,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            fundDiscountPermitted: value,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  fundDiscountValue: (option: RadioGroupOption) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          fundDiscountValue: toNumber(option.value) ?? null,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            fundDiscountValue: toNumber(option.value),
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  commissionWaiverAllowed: (value: boolean) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          commissionWaiverAllowed: value,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            commissionWaiverAllowed: value,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  commissionWaiverHealthcare: (value: CommissionWaiverFactor) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          commissionWaiverHealthcare: value,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            commissionWaiverHealthcare: value,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  commissionWaiverPremiumWaiver: (value: CommissionWaiverFactor) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          commissionWaiverPremiumWaiver: value,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            commissionWaiverPremiumWaiver: value,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  commissionWaiverSickness: (value: CommissionWaiverFactor) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          commissionWaiverSickness: value,
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            commissionWaiverSickness: value,
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
  riskBucket: (value: string) =>
    onChange({
      cacheValue: {
        fees: existingFees => ({
          ...existingFees,
          riskBucket: formatRiskBucketInput(value),
        }),
      },
      mutationInput: {
        proposal: {
          fees: {
            riskBucket: formatRiskBucketInput(value),
          },
        },
      },
      evictCacheFields: ['cost'],
    }),
});

const DEFAULT_FEE_OPTIONS = [
  0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75,
];

export const getFeeOptions = (
  intl: IntlShape,
  options: number[] = DEFAULT_FEE_OPTIONS,
) =>
  options.map(value => ({
    label: intl.formatNumber(Number(bpsToDecimalFraction(value)), {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    value: value.toString(),
  }));

export const getTraditionalFeeOptions = (intl: IntlShape) =>
  getFeeOptions(intl, [40, 45, 50, 55, 60, 65, 70, 75]);

export const getDiscountOptions = (intl: IntlShape) =>
  [0.2, 0.3, 0.5].map(value => ({
    label: intl.formatMessage(smeFeesMessages.fundDiscountValue, {
      percentage: intl.formatNumber(value, {
        style: 'percent',
      }),
    }),
    value: value.toString(),
  }));

export const getCommissionWaiverOptions = (intl: IntlShape) => [
  {
    label: intl.formatMessage(smeFeesMessages.noCommissionWaiver),
    value: CommissionWaiverFactor.ZERO,
  },
  {
    label: intl.formatMessage(smeFeesMessages.halfCommissionWaiver),
    value: CommissionWaiverFactor.HALF,
  },
  {
    label: intl.formatMessage(smeFeesMessages.fullCommissionWaiver),
    value: CommissionWaiverFactor.FULL,
  },
];
