import { ApolloCache, NormalizedCacheObject } from '@apollo/client';

interface Params {
  companyId: string;
}

/**
 *  Clears the cache for benefit related values.
 *
 *  @param companyId - ID of the current company.
 */
export const clearBenefitsCache =
  ({ companyId }: Params) =>
  (cache: ApolloCache<NormalizedCacheObject>) => {
    cache.evict({ id: `Company:${companyId}`, fieldName: 'flexBenefits' });
    cache.evict({ id: `Company:${companyId}`, fieldName: 'benefitPackages' });

    // This is used in order to ensure benefit package history is
    // updated correctly when adding and removing benefits.
    const cacheKeys = cache.extract();
    Object.keys(cacheKeys).forEach(key => {
      if (key.startsWith('BenefitPackage')) {
        cache.evict({ id: key });
      }
    });

    cache.gc();
  };
