import gql from 'graphql-tag';

import { internalPostDetails } from '../../graphql/fragments';

export const INTERNAL_POST_DETAILS_QUERY = gql`
  query internalPostQuery($id: ID!) {
    internalPost(id: $id) {
      ...internalPostDetails
    }
  }
  ${internalPostDetails}
`;
