import { Icon, Td, Tr } from '@frontend/ui';
import { _delete, edit } from '@frontend/ui/icons';
import { companyBillingConfigurationDetails } from 'app/apollo/graphql/types';
import { FormattedMessage, useIntl } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import React from 'react';
import styled from 'styled-components';

import {
  billingAttachmentFormatMessages,
  billingMessages,
} from '../../../messages';

const StyledTd = styled(Td)`
  overflow: visible;
  vertical-align: middle;
`;

interface Props {
  billingEmail: companyBillingConfigurationDetails;
  onDelete: () => void;
  onEdit: () => void;
}

export const BillingMailRow: React.FC<Props> = ({
  billingEmail,
  onEdit,
  onDelete,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Tr
      inlineMenu={
        <KebabMenu fixed>
          <MenuItem
            title={<FormattedMessage {...billingMessages.editRecipient} />}
            icon={<Icon icon={edit} decorative />}
            onClick={onEdit}
          />
          <MenuItem
            title={<FormattedMessage {...billingMessages.deleteRecipient} />}
            icon={<Icon icon={_delete} decorative />}
            onClick={onDelete}
          />
        </KebabMenu>
      }
    >
      <StyledTd>{billingEmail.email}</StyledTd>
      <StyledTd align="left">
        {billingEmail.attachmentFormats
          .map(attachmentFormat =>
            formatMessage({
              messages: billingAttachmentFormatMessages,
              select: attachmentFormat,
            }),
          )
          .join(', ')}
      </StyledTd>
    </Tr>
  );
};
