import { TextField } from '@frontend/ui';
import type { TextFieldProps } from '@frontend/ui/components/Fields/Inputs/TextField';
import qs from 'query-string';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce/lib';

const DEBOUNCE_TIMER = 300;

interface Props extends TextFieldProps {
  label?: React.ReactNode;
  placeholder?: string;
  transparent?: boolean;
}

export const SearchField: React.FC<Props> = ({
  label,
  placeholder,
  ..._props
}) => {
  const props = { ..._props, rounded: true };
  const { replace } = useHistory();
  const location = useLocation();

  const { search: qsSearch, ...rest } = qs.parse(location.search);

  const [search, setSearch] = useState(qsSearch);
  const [renderKey, setRenderKey] = useState(0);
  /**
   * Controls whether the state of the search field should be updated when the URL changes.
   */
  const skipUrlSync = useRef(true);

  const updateSearch = useCallback(() => {
    replace({
      search: qs.stringify({
        search,
        ...rest,
      }),
    });
  }, [replace, search, rest]);

  const [updateSearchDebounced] = useDebouncedCallback(
    updateSearch,
    DEBOUNCE_TIMER,
  );

  useEffect(() => {
    updateSearchDebounced();
    skipUrlSync.current = true;
  }, [search, updateSearchDebounced]);

  useEffect(() => {
    if (skipUrlSync.current) {
      skipUrlSync.current = false;
      return;
    }

    const { search: _search } = qs.parse(location.search);
    setSearch(() => _search);

    // Update key value to trigger a re-render of the text field component which
    // will enforce that the value of the field is up to date with the state of this component.
    setRenderKey(prev => prev + 1);
  }, [location.search]);

  return (
    <TextField
      {...props}
      key={renderKey}
      label={label}
      onChange={event => setSearch(event.target.value)}
      value={search}
    />
  );
};
