import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const FIXED_PREMIUM_HISTORY_TABLE_QUERY = gql`
  query fixedPremiumHistoryTableQuery(
    $userAccountId: ID!
    $companyId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    # This query could potentially be moved to Membership.fixedpremiums
    # but for caching/code structure purposes this has not been done.

    # If this query is moved to Membership.fixedPremiums, we MUST ensure that
    # the cached entries are propely evicted when new fixed premiums are added to ensure
    # that the cache is not stale when the mutation completes. This is tricky since
    # memberships are cached on Membership.id and not on the userAccountId/companyId pair which
    # we can easily access from user context.
    fixedPremium(
      companyId: $companyId
      userAccountId: $userAccountId
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          premium
          actor {
            displayName
          }
          effectiveDate
          createdAt
        }
      }
      pageInfo {
        ...pageInfoDetails
      }
    }
  }
  ${pageInfoDetails}
`;
