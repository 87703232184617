import { FormattedMessage } from 'components/formats';
import { Create } from 'features/companies/company/internal-news/create';
import { internalNewsMessages } from 'features/companies/company/internal-news/messages';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { MatchParams } from '../..';

export const CreateRoute: React.FC<RouteComponentProps<MatchParams>> = ({
  match: {
    params: { companyId },
    url,
  },
}) => (
  <Page
    parentLink={`/companies/${companyId}/internal-news`}
    title={<FormattedMessage {...internalNewsMessages.createNews} />}
    tabs={[
      {
        title: internalNewsMessages.edit,
        url,
      },
      {
        title: internalNewsMessages.preview,
        url: `${url}/preview`,
      },
    ]}
  >
    <Create />
  </Page>
);
