import { Formik } from '@frontend/formik';
import {
  companyCreateBenefitQuery,
  companyCreateBenefitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { MONTHLY_SALARY_REMUNERATION_TYPE_ID } from 'features/companies/company/utils/constants';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { customPremiumMatrixInitialValues } from '../../form/custom-premium-matrix/utils/constants';
import {
  FormValues,
  OccupationalPensionForm,
  validationSchema,
} from '../../form/occupational-pension';
import {
  itpLikePremiumMatrixInitialValues,
  itpPremiumMatrixInitialValues,
} from '../../form/static-premium-matrix/utils/constants';
import {
  getBenefitPackageOptions,
  getHasEaTjpAgreement,
} from '../../form/utils';
import {
  PENSION_PREMIUM_CALCULATION_METHODS_NLP,
  PENSION_PREMIUM_CALCULATION_METHODS_NODE,
} from '../../form/utils/constants';
import { COMPANY_CREATE_BENEFIT_QUERY } from '../graphql/queries';
import { useSubmit } from './utils/use-submit';

export const CreateOccupationalPension: React.FC = () => {
  const intl = useIntl();
  const { params } = useRouteMatch<MatchParams>();
  const { submit, submissionError } = useSubmit();

  const { data, loading, error } = useQuery<
    companyCreateBenefitQuery,
    companyCreateBenefitQueryVariables
  >(COMPANY_CREATE_BENEFIT_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (
    !data?.company?.agreements ||
    !data.company.benefitPackages ||
    !data.company.remunerationTypes
  ) {
    return <EmptyState error={error} />;
  }

  const { agreements, benefitPackages, managedBy, remunerationTypes } =
    data.company;

  const benefitPackageOptions = getBenefitPackageOptions(
    ['advinans_occupational_pension', 'employers_pension'],
    benefitPackages,
  );
  const isManagedByNode = managedBy === 'NODE';

  const PREMIUM_CALCULATION_METHOD_OPTIONS = isManagedByNode
    ? PENSION_PREMIUM_CALCULATION_METHODS_NODE
    : PENSION_PREMIUM_CALCULATION_METHODS_NLP;

  const initialValues: FormValues = {
    benefitPackageIds: [],
    premiumWaiverConverting: false,
    premiumWaiverConvertingFrom: '',
    customPremiumMatrix: customPremiumMatrixInitialValues,
    defaultProvider: '',
    effectiveFrom: '',
    extraFixedPremium: false,
    isActiveOnParentalLeave: false,
    itpLikePremiumMatrix: itpLikePremiumMatrixInitialValues,
    itpPremiumMatrix: itpPremiumMatrixInitialValues,
    payrollElementCode: MONTHLY_SALARY_REMUNERATION_TYPE_ID,
    payrollElementMultiplier: '12',
    premiumCalculationMethod: '',
    premiumDeductions: [],
    premiumWaiver: getHasEaTjpAgreement(agreements) ? '' : 'no',
    providers: managedBy === 'NLP' ? ['NORDEA'] : [],
    retirementAge: '67',
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={submit}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      <OccupationalPensionForm
        agreements={agreements}
        benefitPackageOptions={benefitPackageOptions}
        managedBy={managedBy}
        premiumCalculationMethodOptions={PREMIUM_CALCULATION_METHOD_OPTIONS}
        remunerationTypes={remunerationTypes}
        submissionError={submissionError}
      />
    </Formik>
  );
};
