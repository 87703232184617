import { ApolloError, useMutation } from '@apollo/client';
import { EpassiBikeInput } from '@frontend/benefit-types';
import {
  createBenefitsMutation,
  createBenefitsMutationVariables,
  FlexCreateBenefitInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company';
import { useIntl } from 'components/formats';
import format from 'date-fns/format';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { FormValues, PolicyType } from '../../../form/epassi-bike';
import { benefitFormMessages } from '../../../form/messages';
import { clearBenefitsCache } from '../../../utils/clear-benefit-cache';
import { CREATE_BENEFITS_MUTATION } from '../../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  submissionError?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createBenefit, { error: submissionError }] = useMutation<
    createBenefitsMutation,
    createBenefitsMutationVariables
  >(CREATE_BENEFITS_MUTATION, {
    update: clearBenefitsCache({
      companyId: params.companyId,
    }),
  });

  const submit = async (values: FormValues) => {
    try {
      const input: EpassiBikeInput<FlexCreateBenefitInput> = {
        companyId: params.companyId,
        benefitTypeName: 'epassi_bike',
        configuration: {
          onboardingDate: format(new Date(values.effectiveFrom), 'yyyy-MM-dd'),
          useDefaultPolicy:
            values.policyType === PolicyType.EPASSI_DEFAULT_POLICY,
          customerId: values.customerId,
          files:
            values.policyType !== PolicyType.EPASSI_DEFAULT_POLICY
              ? values.files.map(file => file.id)
              : [],
        },
        entitlementRules: {
          benefitPackageIds: values.benefitPackageIds,
          ouIds: [],
          accountingCostIds: [],
        },
        benefitStartDate: format(new Date(values.effectiveFrom), 'yyyy-MM-dd'),
      };

      await createBenefit({
        variables: {
          input: {
            benefits: [input],
          },
        },
      });

      send({
        message: formatMessage(benefitFormMessages.benefitSaved),
        type: 'success',
      });

      push(`/companies/${params.companyId}/benefit-packages/benefits`);
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
