import { AdvinansOccupationalPension } from '@frontend/benefit-types';
import {
  benefitsQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
  ManagingCompany,
} from 'app/apollo/graphql/types';
import {
  commonBenefitMessages,
  pensionBenefitDeductionMessages,
  pensionBenefitMessages,
} from 'app/messages/benefits';
import { PENSION_PROVIDER } from 'app/utils/constants';
import { DetailCell } from 'components/DetailCell';
import { FormattedMessage, useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { MatchParams } from 'features/companies/company';
import { benefitPensionPremiumCalculationMethodMessages } from 'features/companies/company/benefits/form/messages';
import { getPremiumCalculationMethod } from 'features/companies/company/benefits/form/utils';
import {
  PENSION_PREMIUM_CALCULATION_METHODS_NLP,
  PENSION_PREMIUM_CALCULATION_METHODS_NODE,
} from 'features/companies/company/benefits/form/utils/constants';
import { useRemunerationTypes } from 'features/companies/company/utils/use-remuneration-types';
import React from 'react';
import { useParams } from 'react-router';

interface OccupationalPensionDetailCellProps {
  managedBy: ManagingCompany;
  occupationalPension: AdvinansOccupationalPension<Benefit>;
}

export const OccupationalPensionDetailCell: React.FC<
  OccupationalPensionDetailCellProps
> = ({ managedBy, occupationalPension }) => {
  const { formatMessage } = useIntl();

  const { companyId } = useParams<MatchParams>();

  const { remunerationTypes } = useRemunerationTypes({ companyId });

  if (!occupationalPension.configuration?.data) {
    return <NoValue />;
  }
  const {
    payrollElementCode,
    payrollElementMultiplier,
    premiumMatrix,
    providers: _providers,
    premiumDeductions,
    fixedPremium,
    premiumWaiver,
  } = occupationalPension.configuration.data;

  const pensionPayrollElement = remunerationTypes.find(
    type => type.id === payrollElementCode,
  );
  const providers = _providers?.includes('nordea_trad')
    ? _providers.filter(p => p !== 'nordea_trad')
    : _providers;

  const deductions = premiumDeductions?.length
    ? premiumDeductions
        .map(deduction =>
          formatMessage({
            messages: pensionBenefitDeductionMessages,
            select: deduction,
          }),
        )
        .join(', ')
    : null;

  const isManagedByNode = managedBy === 'NODE';

  const PREMIUM_CALCULATION_METHOD_OPTIONS = isManagedByNode
    ? PENSION_PREMIUM_CALCULATION_METHODS_NODE
    : PENSION_PREMIUM_CALCULATION_METHODS_NLP;

  const premiumCalculationMethod = getPremiumCalculationMethod({
    availableOptions: PREMIUM_CALCULATION_METHOD_OPTIONS,
    premiumMatrix,
  });

  return (
    <DetailCell>
      <p>
        <FormattedMessage
          select={premiumCalculationMethod}
          messages={benefitPensionPremiumCalculationMethodMessages}
        />
      </p>
      {premiumWaiver && (
        <p>
          <FormattedMessage {...pensionBenefitMessages.premiumWaiver} />
        </p>
      )}
      {!!fixedPremium && (
        <p>
          <FormattedMessage {...pensionBenefitMessages.fixedExtraPremium} />
        </p>
      )}
      {!!providers?.length && (
        <p>
          <FormattedMessage
            {...pensionBenefitMessages.pensionProviders}
            values={{
              count: providers.length,
            }}
          />
          <span>: </span>
          {providers.map((p, i) => (
            <span key={i}>
              {PENSION_PROVIDER[p]}
              {i !== providers.length - 1 && ', '}
            </span>
          ))}
        </p>
      )}
      {pensionPayrollElement && payrollElementMultiplier && (
        <p>
          <FormattedMessage
            {...commonBenefitMessages.benefitQualifyingIncomeTableDescription}
            values={{
              annualFactor: payrollElementMultiplier,
              remunerationType: `${pensionPayrollElement.id} ${
                pensionPayrollElement.name ?? ''
              }`,
            }}
          />
        </p>
      )}
      {!!deductions?.length && (
        <p>
          <FormattedMessage
            {...pensionBenefitMessages.deductionsTableMessage}
            values={{ deductions }}
          />
        </p>
      )}
    </DetailCell>
  );
};
