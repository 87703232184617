import { bpsToDecimalFraction } from '@frontend/utils';
import { IntlShape } from 'components/formats';

export const getFundCapitalFeeOptions = (intl: IntlShape) =>
  [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75].map(value => ({
    label: intl.formatNumber(Number(bpsToDecimalFraction(value)), {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    value: value.toString(),
  }));
