import { ApolloError, useMutation } from '@apollo/client';
import {
  CreateProposalAccidentBenefitInput,
  createProposalAccidentBenefitMutation,
  createProposalAccidentBenefitMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useIntl } from 'components/formats';
import { useNotification } from 'features/notifications';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import { useHistory } from 'react-router';

import { clearProposalCache } from '../../../../../utils/clear-proposal-cache';
import { FormValues } from '../../../form/accident';
import { CREATE_PROPOSAL_ACCIDENT_BENEFIT_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => void;
  submissionError?: ApolloError;
}

interface Params {
  params: MatchParams;
}

export const useSubmit = ({ params }: Params): Submit => {
  const { push } = useHistory();
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [createBenefit, { error: submissionError }] = useMutation<
    createProposalAccidentBenefitMutation,
    createProposalAccidentBenefitMutationVariables
  >(CREATE_PROPOSAL_ACCIDENT_BENEFIT_MUTATION, {
    update: clearProposalCache({
      proposalId: params.proposalId,
      fieldNames: ['cost', 'proposals', 'validity'],
    }),
  });

  const submit = async (formValues: FormValues) => {
    // This value is guaranteed through the form validation
    if (!formValues.level) {
      return;
    }
    try {
      const input: CreateProposalAccidentBenefitInput = {
        benefitPackageIds: formValues.benefitPackageIds,
        level: formValues.level,
        proposalId: params.proposalId,
      };

      const res = await createBenefit({
        variables: { input },
      });

      if (!res.data?.createProposalAccidentBenefit) {
        return;
      }

      send({
        message: formatMessage(smeBenefitFormMessages.benefitSaved),
        type: 'success',
      });
      push(`/sme/${params.companyId}/proposals/${params.proposalId}/benefits`);
    } catch {
      // do nothing
    }
  };

  return { submit, submissionError };
};
