import gql from 'graphql-tag';

export const CREATE_EURO_ACCIDENT_TJP_AGREEMENT_MUTATION = gql`
  mutation createEuroAccidentTjpAgreementMutation(
    $input: CreateEuroAccidentTJPAgreementMutationInput!
  ) {
    createEuroAccidentTJPAgreement(input: $input) {
      clientMutationId
    }
  }
`;
