import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  emptyStringToNull,
  objectMap,
  toMoney,
  toNumber,
} from '@frontend/utils';
import {
  UpdateMembershipInput,
  updateMembershipMutation,
  updateMembershipMutationVariables,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useIntl } from 'components/formats';
import { UPDATE_MEMBERSHIP_MUTATION } from 'features/companies/company/employees/graphql/mutations';
import { useNotification } from 'features/notifications';
import { useRouteMatch } from 'react-router';

import { MEMBERSHIP_DETAILS_QUERY } from '../../graphql/queries';
import { FormValues } from '..';

interface Props {
  onRequestClose: () => void;
}

interface Submit {
  submit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;
  submissionError?: ApolloError;
}

export const useSubmit = ({ onRequestClose }: Props): Submit => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { send } = useNotification();
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();

  const [updateMembership, { error: submissionError }] = useMutation<
    updateMembershipMutation,
    updateMembershipMutationVariables
  >(UPDATE_MEMBERSHIP_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: MEMBERSHIP_DETAILS_QUERY,
        variables: {
          companyId,
          userAccountId,
        },
      },
    ],
  });

  const submit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);
    const _values = objectMap(values, emptyStringToNull);

    const {
      addressCity,
      addressLine1,
      addressPostalCode,
      givenName,
      lastName,
      email,
      phone,
      collectiveAgreement,
      companyCarMonthlyValue,
      employeeId,
      fitnessContributionBalance,
      incomeTaxTable,
      vacationDaysAdvance,
      vacationDaysPaid,
      vacationDaysPerYear,
      vacationDaysSaved,
      vacationDaysUnpaid,
      nids,
    } = _values;
    const [nid] = nids.filter(n => n.editable);

    try {
      const updateMembershipInput: UpdateMembershipInput = {
        address: {
          city: addressCity,
          line1: addressLine1,
          postalCode: addressPostalCode,
        },
        companyId,
        userAccountId,
        givenName,
        lastName,
        naturalPersonIdentifier: nid?.value,
        email,
        phone,
        collectiveAgreement,
        companyCarMonthlyValue: toMoney(companyCarMonthlyValue) ?? null,
        employeeNumber: employeeId,
        fitnessContributionBalance: toMoney(fitnessContributionBalance) ?? null,
        incomeTaxTable: toNumber(incomeTaxTable) ?? null,
        vacationDaysAdvance: toNumber(vacationDaysAdvance) ?? null,
        vacationDaysPaid: toNumber(vacationDaysPaid) ?? null,
        vacationDaysPerYear: toNumber(vacationDaysPerYear) ?? null,
        vacationDaysSaved: toNumber(vacationDaysSaved) ?? null,
        vacationDaysUnpaid: toNumber(vacationDaysUnpaid) ?? null,
      };

      const res = await updateMembership({
        variables: { input: updateMembershipInput },
      });

      if (!res.data) {
        throw new Error();
      }
      send({
        message: formatMessage(
          formSubmitMessages.editContactInformationSubmitSuccess,
        ),
        type: 'success',
      });
      onRequestClose();
    } catch {
      // Do nothing
    }
  };

  return { submit, submissionError };
};
