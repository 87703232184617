import gql from 'graphql-tag';

export const REMOVE_BENEFIT_FROM_BENEFIT_PACKAGE_MUTATION = gql`
  mutation removeBenefitFromBenefitPackageMutation(
    $input: RemoveProposalBenefitFromBenefitPackageInput!
  ) {
    removeProposalBenefitFromBenefitPackage(input: $input) {
      id
      benefits {
        benefitPackages {
          id
        }
      }
      benefitPackages {
        id
        benefits {
          id
        }
      }
    }
  }
`;
