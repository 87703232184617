import {
  Button,
  ButtonLayout,
  Grid as _Grid,
  ModalFooter,
  ModalSubheader,
  NumberField,
} from '@frontend/ui';
import { add, remove } from '@frontend/ui/icons';
import { commonMessages, suffixMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, useIntl } from 'components/formats';
import { GridCell50 } from 'components/GridCell';
import { afterFadeout, Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { smeBenefitFormMessages } from 'features/sme/messages/sme';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  sanitizeAges,
  sanitizeBaseAmounts,
  validateAges,
  validateBaseAmounts,
} from '../utils';
import { PremiumIntervalsValidationError } from '../utils/constants';
import { premiumIntervalValidationMessages } from '../utils/messages';

const Grid = styled(_Grid)`
  width: 100%;
`;

const IntervalWrapper = styled.div`
  margin-bottom: 1em;
`;

interface SubmitParams {
  ages: number[];
  baseAmounts: string[];
}

interface Props {
  ages: readonly number[];
  baseAmountType: 'IBB' | 'PBB';
  baseAmounts: readonly string[];
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (params: SubmitParams) => void;
}

export const IntervalsModal: React.FC<Props> = ({
  ages,
  baseAmountType,
  baseAmounts,
  isOpen,
  onRequestClose,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const [agesInternal, setAgesInternal] = useState<string[]>(ages.map(String));
  const [baseAmountsInternal, setBaseAmountsInternal] = useState<string[]>([
    ...baseAmounts,
  ]);

  const [error, setError] = useState<PremiumIntervalsValidationError>();

  const handleResetAndClose = () => {
    onRequestClose();
    afterFadeout(() => {
      setAgesInternal(ages.map(String));
      setBaseAmountsInternal([...baseAmounts]);
      setError(undefined);
    });
  };

  const handleSubmit = () => {
    const _ages = sanitizeAges(agesInternal);
    const _baseAmounts = sanitizeBaseAmounts(baseAmountsInternal);

    const validationError =
      validateAges(_ages) ?? validateBaseAmounts(_baseAmounts);

    if (validationError) {
      setError(validationError);
      return;
    }
    onSubmit({ ages: _ages, baseAmounts: _baseAmounts });
    onRequestClose();
  };

  // Upon invalid submission, remove the error message when the input is changed
  useEffect(() => {
    setError(undefined);
  }, [agesInternal, baseAmountsInternal]);

  return (
    <Modal isOpen={isOpen} onRequestClose={handleResetAndClose}>
      <ModalHeader>
        <FormattedMessage
          {...smeBenefitFormMessages.configurePremiumIntervals}
        />
      </ModalHeader>
      <ModalBody>
        <p>
          <FormattedMessage
            {...smeBenefitFormMessages.configurePremiumIntervalsDescription}
          />
        </p>
        <Grid>
          <GridCell50>
            <ModalSubheader>
              <FormattedMessage {...smeBenefitFormMessages.ageIntervals} />
            </ModalSubheader>
            <IntervalWrapper>
              {agesInternal.map((age, i) => (
                <NumberField
                  affix={formatMessage(suffixMessages.age)}
                  allowNegative={false}
                  dense
                  decimalScale={0}
                  key={i}
                  locale={locale}
                  onValueChange={values =>
                    setAgesInternal(_ages => {
                      const updatedAges = [..._ages];
                      updatedAges[i] = values.value;
                      return updatedAges;
                    })
                  }
                  value={age}
                />
              ))}
            </IntervalWrapper>
            <Button
              icon={add}
              onClick={() =>
                setAgesInternal(_agesInterval => [..._agesInterval, '0'])
              }
              text
            >
              <FormattedMessage {...commonMessages.add} />
            </Button>
            <Button
              icon={remove}
              onClick={() =>
                setAgesInternal(_agesInterval => _agesInterval.slice(0, -1))
              }
              text
            >
              <FormattedMessage {...commonMessages.remove} />
            </Button>
          </GridCell50>
          <GridCell50>
            <ModalSubheader>
              <FormattedMessage
                {...smeBenefitFormMessages.baseAmountIntervals}
              />
            </ModalSubheader>
            <IntervalWrapper>
              {baseAmountsInternal.map((baseAmount, i) => (
                <NumberField
                  affix={baseAmountType}
                  allowNegative={false}
                  decimalScale={2}
                  dense
                  key={i}
                  locale={locale}
                  onValueChange={values =>
                    setBaseAmountsInternal(_baseAmounts => {
                      const updatedBaseAmounts = [..._baseAmounts];
                      updatedBaseAmounts[i] = values.value;
                      return updatedBaseAmounts;
                    })
                  }
                  value={baseAmount}
                />
              ))}
            </IntervalWrapper>
            <Button
              icon={add}
              onClick={() =>
                setBaseAmountsInternal(_baseAmountsInternal => [
                  ..._baseAmountsInternal,
                  '0',
                ])
              }
              text
            >
              <FormattedMessage {...commonMessages.add} />
            </Button>
            <Button
              icon={remove}
              onClick={() =>
                setBaseAmountsInternal(_baseAmountsInternal =>
                  _baseAmountsInternal.slice(0, -1),
                )
              }
              text
            >
              <FormattedMessage {...commonMessages.remove} />
            </Button>
          </GridCell50>
        </Grid>
        {error && (
          <NotificationCard inModal type="error">
            <FormattedMessage
              select={error}
              messages={premiumIntervalValidationMessages}
            />
          </NotificationCard>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button onClick={handleResetAndClose} text>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button onClick={handleSubmit} text>
            <FormattedMessage {...formMessages.save} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};
