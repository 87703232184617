import { useMutation } from '@apollo/client';
import {
  companyBillingConfigurationQuery,
  companyBillingConfigurationQueryVariables,
  deleteCompanyBillingConfigurationMutation,
  deleteCompanyBillingConfigurationMutationVariables,
  updateCompanyBillingConfigurationMutation,
  updateCompanyBillingConfigurationMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { useConfirm } from 'contexts/confirmation';
import { useNotification } from 'features/notifications';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import {
  DELETE_COMPANY_BILLING_CONFIGURATION_MUTATION,
  UPDATE_COMPANY_BILLING_CONFIGURATION_MUTATION,
} from '../../graphql/mutations';
import { COMPANY_BILLING_CONFIGURATION_QUERY } from '../../graphql/queries';
import { billingMessages } from '../../messages';
import { BillingEmailModal } from '../modal';

export const openEditBillingEmailModal = id => ({ edit: id });

export const EditBillingEmailModal: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const { send } = useNotification();
  const { formatMessage } = useIntl();
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { confirm } = useConfirm();

  const { edit: id } = qs.parse(search);

  const { data, loading, error } = useQuery<
    companyBillingConfigurationQuery,
    companyBillingConfigurationQueryVariables
  >(COMPANY_BILLING_CONFIGURATION_QUERY, {
    skip: !id,
    variables: { companyId },
  });

  const [updateCompanyBillingConfiguration, { error: updateError }] =
    useMutation<
      updateCompanyBillingConfigurationMutation,
      updateCompanyBillingConfigurationMutationVariables
    >(UPDATE_COMPANY_BILLING_CONFIGURATION_MUTATION, {
      refetchQueries: [
        {
          query: COMPANY_BILLING_CONFIGURATION_QUERY,
          variables: { companyId },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [deleteCompanyBillingConfiguration, { error: deletError }] =
    useMutation<
      deleteCompanyBillingConfigurationMutation,
      deleteCompanyBillingConfigurationMutationVariables
    >(DELETE_COMPANY_BILLING_CONFIGURATION_MUTATION, {
      variables: { input: { id } },
      refetchQueries: [
        {
          query: COMPANY_BILLING_CONFIGURATION_QUERY,
          variables: { companyId },
        },
      ],
      awaitRefetchQueries: true,
    });

  if (loading) {
    return <TopLoading />;
  }

  const billingEmail = data?.company?.billingConfigurations?.edges?.find(
    e => e.node.id === id,
  )?.node;

  if (!billingEmail) {
    return null;
  }

  const onRequestClose = () => push(pathname);

  return (
    <BillingEmailModal
      isOpen
      error={error || updateError || deletError}
      onRequestClose={onRequestClose}
      billingEmail={billingEmail}
      label={formatMessage(billingMessages.editBillingEmail)}
      onSubmit={async ({ ...formValues }) => {
        const res = await updateCompanyBillingConfiguration({
          variables: {
            input: {
              id: billingEmail.id,
              ...formValues,
            },
          },
        });

        if (!res?.data?.updateCompanyBillingConfiguration) {
          throw new Error();
        }

        send({
          type: 'success',
          message: formatMessage(billingMessages.updateBillingEmailSuccess),
        });
        onRequestClose();
      }}
      onRequestDelete={async () => {
        const confirmed = await confirm({
          title: formatMessage(billingMessages.confirmDeleteBillingEmail),
          description: formatMessage(
            billingMessages.confirmDeleteBillingEmailDescription,
          ),
        });

        if (!confirmed) {
          return;
        }

        const res = await deleteCompanyBillingConfiguration();

        if (!res?.data?.deleteCompanyBillingConfiguration) {
          throw new Error();
        }
        send({
          type: 'success',
          message: formatMessage(billingMessages.deleteBillingEmailSuccess),
        });
        onRequestClose();
      }}
    />
  );
};
