import { AdvinansSalaryExchange } from '@frontend/benefit-types';
import { DescriptionList, DescriptionListEntry } from '@frontend/ui';
import {
  BenefitPackage,
  FlexEntitlementRules,
  ManagingCompany,
  MiscConfiguration,
  RemunerationType,
} from 'app/apollo/graphql/types';
import {
  commonBenefitMessages,
  salaryExchangeBenefitMessages,
} from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { salaryExchangeProviderMessages } from 'app/messages/salary-exchange';
import {
  FormattedCurrency,
  FormattedList,
  FormattedMessage,
  FormattedPercent,
} from 'components/formats';
import NoValue from 'components/NoValue';
import React from 'react';

import { InBenefitPackage } from '../../../components/BenefitsTable/InBenefitPackage';
import { benefitFormMessages } from '../../../form/messages';

interface Benefit {
  configuration: MiscConfiguration;
  entitlementRules: Pick<FlexEntitlementRules, 'benefitPackageIds'> | null;
  id: string;
  type: string;
}

interface Props {
  benefit: AdvinansSalaryExchange<Benefit>;
  benefitPackageOptions: Pick<BenefitPackage, 'id' | 'name'>[];
  managedBy: ManagingCompany;
  remunerationTypes: readonly RemunerationType[];
}

export const SalaryExchangeBenefit: React.FC<Props> = ({
  benefit,
  benefitPackageOptions,
  managedBy,
  remunerationTypes,
}) => {
  const { configuration } = benefit;

  const {
    retirementAge,
    providers,
    automaticApproval,
    pensionTaxBenefitShare,
    ongoingMax,
    basePensionableIncomePayrollElementCode,
    basePensionableIncomePayrollElementMultiplier,
    incomeMinIbb,
    ongoingDeductionShareMax,
  } = configuration?.data ?? {};

  const basePensionableIncomePayrollElement = remunerationTypes.find(
    type => type.id === basePensionableIncomePayrollElementCode,
  );

  const isManagedByNode = managedBy === 'NODE';

  const entries: DescriptionListEntry[] = [
    {
      id: 'benefit.retirementAge',
      label: <FormattedMessage {...benefitFormMessages.retirementAge} />,
      value: retirementAge ? (
        <FormattedMessage
          {...commonMessages.nYears}
          values={{ count: retirementAge }}
        />
      ) : (
        <NoValue />
      ),
    },
    ...(isManagedByNode
      ? [
          {
            id: 'benefit.insuranceProviders',
            label: (
              <FormattedMessage {...commonBenefitMessages.insuranceProvider} />
            ),
            value: providers?.length ? (
              <FormattedList
                type="unit"
                value={providers.map(provider => (
                  <FormattedMessage
                    messages={salaryExchangeProviderMessages}
                    select={provider}
                  />
                ))}
              />
            ) : (
              <NoValue />
            ),
          },
        ]
      : []),
    {
      id: 'benefit.payrollElement',
      label: (
        <FormattedMessage {...commonBenefitMessages.benefitQualifyingIncome} />
      ),
      value: basePensionableIncomePayrollElement ? (
        <>
          {basePensionableIncomePayrollElement.id}{' '}
          {basePensionableIncomePayrollElement.name ?? ''}
        </>
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.annualSalaryFactor',
      label: <FormattedMessage {...commonBenefitMessages.annualFactor} />,
      value: basePensionableIncomePayrollElementMultiplier ?? <NoValue />,
    },
    {
      id: 'benefit.ongoingMax',
      label: <FormattedMessage {...benefitFormMessages.ongoingMaxSek} />,
      value: ongoingMax ? (
        <FormattedCurrency
          after="PER_MONTH"
          currency="SEK"
          value={ongoingMax}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.ongoingDeductionShareMax',
      label: <FormattedMessage {...benefitFormMessages.ongoingMaxShare} />,
      value: ongoingDeductionShareMax ? (
        <FormattedPercent integer value={ongoingDeductionShareMax} />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.incomeMinIbb',
      label: <FormattedMessage {...benefitFormMessages.incomeMinIbb} />,
      value: incomeMinIbb ? (
        <FormattedMessage
          {...salaryExchangeBenefitMessages.incomeIbb}
          values={{ amount: incomeMinIbb }}
        />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.pensionTaxBenefitShare',
      label: (
        <FormattedMessage {...benefitFormMessages.pensionTaxBenefitShare} />
      ),
      value: pensionTaxBenefitShare ? (
        <FormattedPercent integer value={pensionTaxBenefitShare} />
      ) : (
        <NoValue />
      ),
    },
    {
      id: 'benefit.automaticApproval',
      label: <FormattedMessage {...benefitFormMessages.automaticApproval} />,
      value:
        automaticApproval != null ? (
          automaticApproval ? (
            <FormattedMessage {...commonMessages.yes} />
          ) : (
            <FormattedMessage {...commonMessages.no} />
          )
        ) : (
          <NoValue />
        ),
    },
    {
      id: 'benefit.inBenefitPackage',
      label: <FormattedMessage {...commonBenefitMessages.inBenefitPackage} />,
      value: (
        <InBenefitPackage
          benefit={benefit}
          benefitPackageOptions={benefitPackageOptions}
        />
      ),
    },
  ];

  return <DescriptionList alignLeft entries={entries} />;
};
