import { Form, Formik } from '@frontend/formik';
import { Button, ButtonLayout, Section } from '@frontend/ui';
import {
  editEmployeeDetailsFormQuery,
  editEmployeeDetailsFormQueryVariables,
} from 'app/apollo/graphql/types';
import { formMessages } from 'app/messages/form';
import { EmployeeRouteMatchParams } from 'app/pages/companies/company/employees/employee';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import {
  CompensationDataFormValues,
  CompensationFields,
  compensationInitialValues,
} from './components/CompensationFields';
import {
  EmploymentDataFields,
  EmploymentDataFormValues,
  employmentDataInitialValues,
} from './components/EmploymentDataFields';
import {
  PersonalDataFields,
  PersonalDataFormValues,
  personalDataInitialValues,
} from './components/PersonalDataFields';
import {
  VacationFields,
  VacationFormValues,
  vacationInitialValues,
} from './components/VacationFields';
import { EDIT_EMPLOYEE_DETAILS_FORM_QUERY } from './graphql/queries';
import { useSubmit } from './utils/submit';
import { personalDataValidationSchema } from './utils/validation-schema';

export type FormValues = PersonalDataFormValues &
  EmploymentDataFormValues &
  CompensationDataFormValues &
  VacationFormValues;

export const EditEmployeeDetailsForm: React.FC = () => {
  const {
    params: { userAccountId, companyId },
  } = useRouteMatch<EmployeeRouteMatchParams>();
  const history = useHistory();
  const intl = useIntl();

  const { data, loading, error } = useQuery<
    editEmployeeDetailsFormQuery,
    editEmployeeDetailsFormQueryVariables
  >(EDIT_EMPLOYEE_DETAILS_FORM_QUERY, {
    variables: {
      companyId,
      userAccountId,
      benefitTypeNames: ['epassi'],
    },
    suspend: true,
    errorPolicy: 'all',
  });

  const onRequestClose = () =>
    history.push(`/companies/${companyId}/employees/${userAccountId}`);

  const { submit, submissionError } = useSubmit({
    onRequestClose,
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.membership) {
    return <GraphQlError error={error} />;
  }

  const { membership } = data;

  const wellnessEntitlement = data?.entitlements?.edges.map(
    ({ node }) => node.benefit,
  )[0];

  const wellnessDisabled = wellnessEntitlement?.type === 'epassi';

  return (
    <Formik<FormValues>
      initialValues={{
        ...personalDataInitialValues(membership),
        ...employmentDataInitialValues(membership),
        ...compensationInitialValues(membership),
        ...vacationInitialValues(membership),
      }}
      onSubmit={submit}
      validationSchema={personalDataValidationSchema(intl)}
      validateOnMount
    >
      {({ isValid, isSubmitting, dirty }) => (
        <Section>
          <Form>
            <TextGrid>
              <PersonalDataFields />
              <EmploymentDataFields />
              <CompensationFields wellnessDisabled={wellnessDisabled} />
              <VacationFields />
            </TextGrid>
            {submissionError && <GraphQlError error={submissionError} />}
            <ButtonLayout>
              <Button
                filled
                type="submit"
                disabled={!isValid || !dirty}
                loading={isSubmitting}
              >
                <FormattedMessage {...formMessages.save} />
              </Button>
            </ButtonLayout>
          </Form>
        </Section>
      )}
    </Formik>
  );
};
