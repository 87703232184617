import { DatePickerField, Form, Formik, SelectField } from '@frontend/formik';
import { Button, Section, Subsection } from '@frontend/ui';
import {
  CompanySize,
  createEuroAccidentGroupAgreementQuery,
  createEuroAccidentGroupAgreementQueryVariables,
  GroupAgreementType,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TextGrid } from 'components/GridCell';
import { TopLoading } from 'components/TopLoading';
import { byCompanySize } from 'features/companies/utils/by-company-size';
import React from 'react';
import { useRouteMatch } from 'react-router';
import * as Yup from 'yup';

import {
  agreementMessages,
  companySizeMessages,
  groupAgreementTypeMessages,
} from '../../messages';
import { CREATE_EURO_ACCIDENT_GROUP_AGREEMENT_QUERY } from './graphql/queries';
import { getGroupAgreementTypesForCompanyManagedBy } from './utils/get-group-agreement-types-for-company-managed-by';
import { useSubmit } from './utils/use-submit';

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    startDate: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    groupAgreementType: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    companySize: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export interface FormValues {
  companySize: CompanySize | '';
  groupAgreementType: GroupAgreementType | '';
  startDate: string;
}

export const AddEuroAccidentGroupAgreementForm: React.FC = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();

  const { submit, error: submissionError } = useSubmit();

  const { loading, data, error } = useQuery<
    createEuroAccidentGroupAgreementQuery,
    createEuroAccidentGroupAgreementQueryVariables
  >(CREATE_EURO_ACCIDENT_GROUP_AGREEMENT_QUERY, {
    errorPolicy: 'all',
    variables: { id: companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <EmptyState error={error} />;
  }

  const { managedBy } = data.company;

  const groupAgreementOptions = getGroupAgreementTypesForCompanyManagedBy(
    managedBy,
  ).map(k => ({
    value: k,
    label: formatMessage({
      messages: groupAgreementTypeMessages,
      select: k,
    }),
  }));

  return (
    <Section>
      {error && <GraphQlError error={error} />}
      <Formik<FormValues>
        validationSchema={validationSchema(intl)}
        initialValues={{
          companySize: '',
          groupAgreementType: '',
          startDate: '',
        }}
        onSubmit={submit}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Subsection>
              <TextGrid>
                <SelectField
                  helperText={formatMessage(
                    agreementMessages.companySizeHelperText,
                  )}
                  label={formatMessage(agreementMessages.companySize)}
                  name="companySize"
                  options={Object.keys(CompanySize)
                    .sort(byCompanySize)
                    .map(k => ({
                      value: k,
                      label: formatMessage({
                        messages: companySizeMessages,
                        select: k,
                      }),
                    }))}
                  required
                  dense
                />
                <SelectField
                  label={formatMessage(agreementMessages.eaGroupAgreementType)}
                  name="groupAgreementType"
                  options={groupAgreementOptions}
                  required
                  dense
                />
                <DatePickerField
                  dense
                  required
                  name="startDate"
                  label={formatMessage(agreementMessages.startDate)}
                />
              </TextGrid>
            </Subsection>
            {submissionError && <GraphQlError error={submissionError} />}
            <Button type="submit" loading={isSubmitting} filled>
              <FormattedMessage {...agreementMessages.add} />
            </Button>
          </Form>
        )}
      </Formik>
    </Section>
  );
};
