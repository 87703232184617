import { internalNewsOverviewQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: internalNewsOverviewQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: internalNewsOverviewQuery;
  },
) => {
  if (
    !previousResult?.company?.internalPosts ||
    !fetchMoreResult?.company?.internalPosts
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.internalPosts;

  return {
    company: {
      ...previousResult.company,
      internalPosts: {
        __typename: previousResult.company.internalPosts.__typename,
        edges: [...edges],
        pageInfo,
      },
    },
  };
};
