import {
  bonusSalaryExchangeRequestsQuery,
  bonusSalaryExchangeRequestsQueryVariables,
  FlexSalaryExchangeRequestStatus,
} from 'app/apollo/graphql/types';
import { SalaryExchangeRouteMatchParams } from 'app/pages/companies/company/salary-exchange';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { RequestsTable } from '../components/Table';
import { RequestSalaryExchangeType } from '../types';
import { BONUS_SALARY_EXCHANGE_REQUESTS_QUERY } from './graphql/queries';
import { updateQuery } from './utils/update-query';

const isEmpty = (data?: bonusSalaryExchangeRequestsQuery) =>
  !data?.bonusSalaryExchangeRequests?.edges.length;

const hasPendingRequests = (
  data?: bonusSalaryExchangeRequestsQuery,
): data is bonusSalaryExchangeRequestsQuery => !isEmpty(data);

export const BonusSalaryExchangeRequests: React.FC = () => {
  const [suspend, setSuspend] = useState(true);
  const [perPage, setPerPage] = useState(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );
  const {
    params: { companyId },
  } = useRouteMatch<SalaryExchangeRouteMatchParams>();

  const { data, previousData, loading, fetchMore } = useQuery<
    bonusSalaryExchangeRequestsQuery,
    bonusSalaryExchangeRequestsQueryVariables
  >(BONUS_SALARY_EXCHANGE_REQUESTS_QUERY, {
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    suspend,
    isEmpty,
    variables: {
      companyId,
      statuses: [FlexSalaryExchangeRequestStatus.REQUESTED],
      first: Number(perPage),
    },
    onCompleted: () => setSuspend(false),
  });

  const _data = data ?? previousData;

  if (!_data?.bonusSalaryExchangeRequests || !hasPendingRequests(_data)) {
    return null;
  }

  const { edges, pageInfo } = _data.bonusSalaryExchangeRequests;

  const salaryExchangeRequests = edges.map(e => e.node);

  const navigation = (
    <TableNavigation
      perPage={perPage}
      setPerPage={setPerPage}
      pageInfo={pageInfo}
      onNextPage={() => {
        fetchMore({
          variables: {
            after: pageInfo.endCursor,
          },
          updateQuery,
        });
      }}
      onPreviousPage={() => {
        fetchMore({
          variables: {
            before: pageInfo.startCursor,
            first: undefined,
            last: Number(perPage),
          },
          updateQuery,
        });
      }}
    />
  );

  return (
    <>
      {!suspend && loading && <TopLoading />}
      <RequestsTable
        type={RequestSalaryExchangeType.ONETIME}
        requests={salaryExchangeRequests}
        navigation={navigation}
      />
    </>
  );
};
