import {
  Table,
  Td,
  Th,
  Tr,
  WizardSection,
  WizardSectionHeader,
} from '@frontend/ui';
import {
  importErrorsTableQuery,
  importErrorsTableQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employeesImportMessages } from 'app/messages/employees';
import { DEFAULT_RESULT_PER_PAGE_SMALL } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { TableNavigation } from 'components/TableNavigation';
import { TopLoading } from 'components/TopLoading';
import React, { useState } from 'react';

import { IMPORT_ERRORS_TABLE_QUERY } from './graphql/queries';
import { updateRecords } from './graphql/update-records';

interface Props {
  importId: string;
}

export const ImportErrorsTable: React.FC<Props> = ({ importId }) => {
  const { formatMessage } = useIntl();

  const [suspend, setSuspend] = useState(true);
  const [perPage, setPerPage] = useState<string>(
    DEFAULT_RESULT_PER_PAGE_SMALL.toString(),
  );

  const { data, previousData, loading, fetchMore } = useQuery<
    importErrorsTableQuery,
    importErrorsTableQueryVariables
  >(IMPORT_ERRORS_TABLE_QUERY, {
    suspend,
    variables: {
      id: importId,
      first: Number(perPage),
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setSuspend(false),
  });

  const records = data?.import?.records ?? previousData?.import?.records;

  if (!records?.edges.length) {
    return null;
  }

  const errorData = records.edges.map(edge => edge.node);

  return (
    <WizardSection>
      {loading && <TopLoading />}
      <WizardSectionHeader>
        <FormattedMessage
          {...employeesImportMessages.statusFailureWithCount}
          values={{ count: records.totalCount }}
        />
      </WizardSectionHeader>
      <Table
        navigation={
          <TableNavigation
            perPage={perPage}
            setPerPage={setPerPage}
            pageInfo={records.pageInfo}
            onNextPage={() => {
              fetchMore({
                variables: {
                  after: records.pageInfo.endCursor,
                },
                updateQuery: updateRecords,
              });
            }}
            onPreviousPage={() => {
              fetchMore({
                variables: {
                  before: records.pageInfo.startCursor,
                  first: undefined,
                  last: Number(perPage),
                },
                updateQuery: updateRecords,
              });
            }}
          />
        }
      >
        {errorData.length && (
          <>
            <thead>
              <Tr>
                <Th key={employeesImportMessages.errorTableRowColumn.id}>
                  {formatMessage(employeesImportMessages.errorTableRowColumn)}
                </Th>
                <Th key={commonMessages.personalIdentityNumber.id}>
                  {formatMessage(commonMessages.personalIdentityNumber)}
                </Th>
                <Th
                  align="left"
                  key={employeesImportMessages.errorTableErrorColumn.id}
                >
                  {formatMessage(employeesImportMessages.errorTableErrorColumn)}
                </Th>
              </Tr>
            </thead>
            <colgroup>
              <col style={{ width: '7%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '78%' }} />
            </colgroup>
            <tbody>
              {errorData.map(error =>
                error?.causes?.map(cause => (
                  <Tr key={`${error.index}-${cause}`}>
                    <Th>{error.index}</Th>
                    <Td>{error.id?.personalIdentityNumber ?? <NoValue />}</Td>
                    <Td align="left">{cause}</Td>
                  </Tr>
                )),
              )}
            </tbody>
          </>
        )}
      </Table>
    </WizardSection>
  );
};
