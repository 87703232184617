import gql from 'graphql-tag';

import { createNlpAgreementFragment } from './fragments';

export const CREATE_NLP_AGREEMENT_QUERY = gql`
  query createNlpAgreementQuery($companyId: ID!) {
    company(id: $companyId) {
      ...createNlpAgreementFragment
    }
  }
  ${createNlpAgreementFragment}
`;

export const CREATE_NLP_AGREEMENT_EDIT_COMPANY_QUERY = gql`
  query createNlpAgreementEditCompanyQuery($companyId: ID!) {
    company(id: $companyId) {
      ...createNlpAgreementFragment
    }
    nlpSalesReps {
      id
      firstName
      lastName
      externalId
    }
  }
  ${createNlpAgreementFragment}
`;
