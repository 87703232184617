import { Section } from '@frontend/ui';
import {
  versionHistoryTableQuery,
  versionHistoryTableQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as BenefitEditMatchParams } from 'app/pages/companies/company/benefits/edit';
import { useQuery } from 'app/utils/use-query';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { VersionHistoryTable } from './components/VersionHistoryTable';
import { VERSION_HISTORY_TABLE_QUERY } from './graphql/queries';

export interface MatchParams extends BenefitEditMatchParams {
  asOf: string;
}

export const VersionHistory: React.FC = () => {
  const {
    params: { benefitId, companyId },
  } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    versionHistoryTableQuery,
    versionHistoryTableQueryVariables
  >(VERSION_HISTORY_TABLE_QUERY, {
    errorPolicy: 'all',
    variables: {
      benefitId,
      companyId,
    },
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.flexBenefit) {
    return <GraphQlError error={error} />;
  }

  const changes = data?.flexBenefit?.changes;
  const companyStartDate = data?.company?.startDate;
  return (
    <Section>
      <VersionHistoryTable
        changes={changes}
        companyStartDate={companyStartDate}
      />
    </Section>
  );
};
