import { ContentContainer } from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import {
  benefitPackageLayoutQuery,
  benefitPackageLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { employeeMessages } from 'app/messages/employees';
import { menuMessages } from 'app/messages/menu';
import { BENEFIT_PACKAGE_LAYOUT_QUERY } from 'app/queries';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage } from 'components/formats';
import { RoutedTab } from 'components/RoutedTabBar';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { getRenameBenefitPackageLink } from 'features/companies/company/benefit-packages/rename';
import { Page } from 'features/page';
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../..';
import { BenefitPackageOverviewRoute } from './_index';
import { BenefitPackageAddBenefitsRoute } from './add-benefit';
import { BenefitPackageEmployeesRoute } from './employees';
import { BenefitPackageHistoryRoute } from './history';

interface Tab extends RoutedTab {
  path: string;
}

export interface MatchParams extends CompanyMatchParams {
  benefitPackageId: string;
}

export const BenefitPackageRoutes: React.FC<
  RouteComponentProps<MatchParams>
> = props => {
  const { location, match } = props;

  const { data, loading, error } = useQuery<
    benefitPackageLayoutQuery,
    benefitPackageLayoutQueryVariables
  >(BENEFIT_PACKAGE_LAYOUT_QUERY, {
    errorPolicy: 'all',
    variables: { id: match.params.benefitPackageId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const tabs: Tab[] = [
    {
      title: menuMessages.overview,
      path: match.path,
      url: match.url,
    },
    {
      title: employeeMessages.employees,
      path: `${match.path}/employees`,
      url: `${match.url}/employees`,
    },
    {
      title: commonMessages.history,
      path: `${match.path}/history`,
      url: `${match.url}/history`,
    },
  ];

  const parentLink = `/companies/${match.params.companyId}/benefit-packages`;
  const benefitPackage = data?.benefitPackage;

  if (!benefitPackage) {
    return <EmptyStatePage error={error} parentLink={parentLink} />;
  }
  return (
    <NavigationAnchor title={benefitPackage.name} url={match.url}>
      <Switch>
        <Route path={`${match.path}/add`}>
          <BenefitPackageAddBenefitsRoute benefitPackage={benefitPackage} />
        </Route>
        <Route exact path={tabs.map(({ path }) => path)}>
          <Page
            actions={[
              {
                text: (
                  <FormattedMessage
                    {...commonBenefitMessages.renameBenefitPackageLink}
                  />
                ),
                leadingIcon: edit,
                to: getRenameBenefitPackageLink({
                  benefitPackageId: match.params.benefitPackageId,
                  location,
                }),
              },
            ]}
            parentLink={parentLink}
            title={benefitPackage.name}
            tabs={tabs}
          >
            <ContentContainer>
              <Switch>
                <Route
                  component={BenefitPackageOverviewRoute}
                  exact
                  path={match.path}
                />
                <Route
                  component={BenefitPackageEmployeesRoute}
                  path={`${match.path}/employees`}
                />
                <Route
                  component={BenefitPackageHistoryRoute}
                  path={`${match.path}/history`}
                />
              </Switch>
            </ContentContainer>
          </Page>
        </Route>
      </Switch>
    </NavigationAnchor>
  );
};
