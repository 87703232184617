import { CompanySize } from 'app/apollo/graphql/types';

const COMPANY_SIZE_SORT_ORDER: Record<CompanySize, number> = {
  [CompanySize.LT_10]: 1,
  [CompanySize.LT_50]: 2,
  [CompanySize.GTE_50]: 3,
  [CompanySize.GTE_250]: 4,
  [CompanySize.GTE_1000]: 5,
};

export const byCompanySize = (a: CompanySize, b: CompanySize) =>
  COMPANY_SIZE_SORT_ORDER[a] - COMPANY_SIZE_SORT_ORDER[b];
