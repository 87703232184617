import { ApolloError, useMutation } from '@apollo/client';
import {
  CreateInternalPostInput,
  createInternalPostMutation,
  createInternalPostMutationVariables,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'app/messages/form';
import { useIntl } from 'components/formats';
import { MatchParams } from 'features/companies/company';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';

import { FormValues } from '../../components/Form';
import { CREATE_INTERNAL_POST_MUTATION } from '../graphql/mutations';

interface Submit {
  submit: (values: FormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (): Submit => {
  const { push } = useHistory();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  const [createInternalPost, { error }] = useMutation<
    createInternalPostMutation,
    createInternalPostMutationVariables
  >(CREATE_INTERNAL_POST_MUTATION, {
    update: cache => {
      cache.evict({
        id: `Company:${companyId}`,
        fieldName: 'internalPosts',
      });
      cache.gc();
    },
  });

  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const submit = async (values: FormValues) => {
    try {
      const {
        publicationDate,
        attachmentFiles,
        benefitPackages,
        organizationalUnits,
        hero,
        preamble,
        ...rest
      } = values;

      const input: CreateInternalPostInput = {
        ...rest,
        companyId,
        description: preamble,
        publicationDate:
          publicationDate && new Date(publicationDate).toISOString(),
        attachmentIds: attachmentFiles?.map(file => file.id),
        benefitPackageIds: benefitPackages
          ? Object.keys(benefitPackages)
          : null,
        orgUnitIds: organizationalUnits
          ? Object.keys(organizationalUnits)
          : null,
        heroImageId: hero?.uploadId,
      };

      const res = await createInternalPost({
        variables: {
          input,
        },
      });

      if (!res?.data?.createInternalPost) {
        throw new Error();
      }

      const {
        internalPost: { title, id },
      } = res.data.createInternalPost;

      send({
        message: `${formatMessage(formSubmitMessages.createNewsSuccessText, {
          title,
        })}`,
        type: 'success',
      });
      push(`/companies/${companyId}/internal-news/${id}/preview`);
    } catch {
      // Do nothing
    }
  };

  return { submit, error };
};
