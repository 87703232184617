import { CheckboxField } from '@frontend/ui';
import { toSafeArray } from '@frontend/utils';
import { benefitsQuery_company_Company_benefitCategories_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit_categories_FlexBenefitCategory as Category } from 'app/apollo/graphql/types';
import {
  benefitFilterMessages,
  benefitTypesMessages,
} from 'app/messages/benefits';
import { FormattedMessage } from 'components/formats';
import { FilterSection } from 'features/filter-sheet/components/FilterSection';
import { SelectionFilter } from 'features/filter-sheet/components/SelectionFilter';
import { useDebouncedFilters } from 'features/filter-sheet/utils/use-debounced-filters';
import React from 'react';

interface Tag {
  id: string;
  label: React.ReactNode;
  selected?: boolean;
}

interface FilterSectionProps {
  header: React.ReactNode;
  onTagSelect: (id: string) => void;
  tags: Tag[];
}

const BenefitFilterSection: React.FC<FilterSectionProps> = ({
  tags,
  header,
  onTagSelect,
}) => (
  <FilterSection header={header}>
    {tags.map(({ selected, id, label }) => (
      <div key={id}>
        <CheckboxField
          checked={selected}
          label={label}
          onChange={() => onTagSelect(id)}
        />
      </div>
    ))}
  </FilterSection>
);

interface Props {
  availableCategoryOptions: Category[];
  availableTypeOptions: string[];
}

interface Filters {
  benefitTypeNames: string[];
  categoryIds: string[];
}

export const Filters: React.FC<Props> = ({
  availableCategoryOptions,
  availableTypeOptions,
}) => {
  const { clearFilters, filters, setFilterValue } =
    useDebouncedFilters<Filters>();

  const categoryOptions = availableCategoryOptions.map(({ id, name }) => ({
    id,
    label: name,
    selected: toSafeArray(filters.categoryIds).includes(id),
  }));

  const typeOptions = availableTypeOptions.map(type => ({
    id: type,
    label: <FormattedMessage messages={benefitTypesMessages} select={type} />,
    selected: toSafeArray(filters.benefitTypeNames).includes(type),
  }));

  const selectedCategories = categoryOptions.filter(
    ({ selected }) => !!selected,
  );
  const selectedCategoryIds = selectedCategories.map(({ id }) => id);
  const selectedTypes = typeOptions.filter(({ selected }) => !!selected);
  const selectedBenefitTypeNames = selectedTypes.map(({ id }) => id);
  const selectedFilters = [...selectedCategories, ...selectedTypes];

  return (
    <SelectionFilter
      clearFilters={clearFilters}
      nSelected={selectedFilters.length}
      singleFilterChipLabel={selectedFilters[0]?.label}
    >
      <BenefitFilterSection
        tags={typeOptions}
        header={<FormattedMessage {...benefitFilterMessages.chooseType} />}
        onTagSelect={id => {
          const value = selectedBenefitTypeNames.includes(id)
            ? selectedBenefitTypeNames.filter(_id => id !== _id)
            : [id, ...selectedBenefitTypeNames];

          setFilterValue(value, 'benefitTypeNames');
        }}
      />
      <BenefitFilterSection
        tags={categoryOptions}
        header={<FormattedMessage {...benefitFilterMessages.chooseCategory} />}
        onTagSelect={id => {
          const value = selectedCategoryIds.includes(id)
            ? selectedCategoryIds.filter(_id => id !== _id)
            : [id, ...selectedCategoryIds];

          setFilterValue(value, 'categoryIds');
        }}
      />
    </SelectionFilter>
  );
};
