import {
  FlexChangeStatus,
  FlexChangeType,
  FlexInsuranceAction,
  FlexNordeaManagementType,
} from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const insuranceEventHistoryMessages = defineMessages({
  change: {
    id: 'insuranceEventHistoryMessages.change',
    defaultMessage: 'Ändring',
  },
  configuration: {
    id: 'insuranceEventHistoryMessages.configuration',
    defaultMessage: 'Konfiguration',
  },
  explanationStatus: {
    id: 'insuranceEventHistoryMessages.explanationStatus',
    defaultMessage: 'Förklaring status',
  },
  sentAt: {
    id: 'insuranceEventHistoryMessages.sentAt',
    defaultMessage: 'Skickat',
  },
  transactionId: {
    id: 'insuranceEventHistoryMessages.transactionId',
    defaultMessage: 'Transaktions-ID',
  },
  premium: {
    id: 'insuranceEventHistoryMessages.premium',
    defaultMessage: 'Premie: {amount, number, ::currency/SEK .}',
  },
  annualSalary: {
    id: 'insuranceEventHistoryMessages.annualSalary',
    defaultMessage: 'Årslön: {amount, number, ::currency/SEK .}',
  },
  level: {
    id: 'insuranceEventHistoryMessages.level',
    defaultMessage: 'Nivå: {level}',
  },
  retirementAge: {
    id: 'insuranceEventHistoryMessages.retirementAge',
    defaultMessage: 'Pensionsålder: {age}',
  },
  insuranceNumber: {
    id: 'insuranceEventHistoryMessages.insuranceNumber',
    defaultMessage: 'Försäkringsnummer: {insuranceNumber}',
  },
  noChangesMatch: {
    id: 'insuranceEventHistoryMessages.noChangesMatch',
    defaultMessage: 'Inga händelser matchar sökningen',
  },
  noChanges: {
    id: 'insuranceEventHistoryMessages.noChanges',
    defaultMessage: 'Inga händelser',
  },
});

export const nordeaPensionManagementTypeMessages =
  defineMessages<FlexNordeaManagementType>({
    FUND: {
      id: 'nordeaPensionManagementTypeMessages.FUND',
      defaultMessage: 'Förvaltning: Fond',
    },
    TRAD: {
      id: 'nordeaPensionManagementTypeMessages.TRAD',
      defaultMessage: 'Förvaltning: Traditionell',
    },
  });

export const changeTypeMessages = defineMessages<FlexChangeType>({
  PENSION_FUTUR: {
    id: 'changeTypeMessages.PENSION_FUTUR',
    defaultMessage: 'Tjänstepension Futur',
  },
  SINGLE_PREMIUM_PENSION_FUTUR: {
    id: 'changeTypeMessages.SINGLE_PREMIUM_PENSION_FUTUR',
    defaultMessage: 'Engångspremie Futur',
  },
  PENSION_NORDNET: {
    id: 'changeTypeMessages.PENSION_NORDNET',
    defaultMessage: 'Tjänstepension Nordnet',
  },
  PENSION_NORDNET_SSEK: {
    id: 'changeTypeMessages.PENSION_NORDNET_SSEK',
    defaultMessage: 'Tjänstepension Nordnet',
  },
  SINGLE_PREMIUM_PENSION_NORDNET: {
    id: 'changeTypeMessages.SINGLE_PREMIUM_PENSION_NORDNET',
    defaultMessage: 'Engångspremie Nordnet',
  },
  SINGLE_PREMIUM_PENSION_NORDNET_SSEK: {
    id: 'changeTypeMessages.SINGLE_PREMIUM_PENSION_NORDNET_SSEK',
    defaultMessage: 'Engångspremie Nordnet',
  },
  PENSION_NORDEA: {
    id: 'changeTypeMessages.PENSION_NORDEA',
    defaultMessage: 'Tjänstepension Nordea',
  },
  SINGLE_PREMIUM_PENSION_NORDEA: {
    id: 'changeTypeMessages.SINGLE_PREMIUM_PENSION_NORDEA',
    defaultMessage: 'Engångspremie Nordea',
  },
  EMPLOYERS_PENSION_NORDEA: {
    id: 'changeTypeMessages.EMPLOYERS_PENSION_NORDEA',
    defaultMessage: 'Företagarpension Nordea',
  },
  SINGLE_PREMIUM_EMPLOYERS_PENSION_NORDEA: {
    id: 'changeTypeMessages.SINGLE_PREMIUM_EMPLOYERS_PENSION_NORDEA',
    defaultMessage: 'Engångspremie Nordea',
  },
  PENSION_LF: {
    id: 'changeTypeMessages.PENSION_LF',
    defaultMessage: 'Tjänstepension Länsförsäkringar',
  },
  SINGLE_PREMIUM_PENSION_LF: {
    id: 'changeTypeMessages.SINGLE_PREMIUM_PENSION_LF',
    defaultMessage: 'Engångspremie Länsförsäkringar',
  },
  RISK_SICKNESS: {
    id: 'changeTypeMessages.RISK_SICKNESS',
    defaultMessage: 'Sjukförsäkring',
  },
  RISK_ACCIDENT: {
    id: 'changeTypeMessages.RISK_ACCIDENT',
    defaultMessage: 'Olycksfallsförsäkring',
  },
  RISK_TGL: {
    id: 'changeTypeMessages.RISK_TGL',
    defaultMessage: 'TGL',
  },
  RISK_LIFE: {
    id: 'changeTypeMessages.RISK_LIFE',
    defaultMessage: 'Livförsäkring',
  },
  RISK_HEALTHCARE: {
    id: 'changeTypeMessages.RISK_HEALTHCARE',
    defaultMessage: 'Sjukvårdsförsäkring',
  },
  RISK_PREMIUM_WAIVER: {
    id: 'changeTypeMessages.RISK_PREMIUM_WAIVER',
    defaultMessage: 'Premiebefrielse',
  },
  RISK_SICKNESS_AND_ACCIDENT: {
    id: 'changeTypeMessages.RISK_SICKNESS_AND_ACCIDENT',
    defaultMessage: 'Sjuk- och olycksfallsförsäkring',
  },
  ONGOING_SALARY_EXCHANGE_INSURANCE_NORDEA: {
    id: 'changeTypeMessages.ONGOING_SALARY_EXCHANGE_INSURANCE_NORDEA',
    defaultMessage: 'Löneväxling Nordea',
  },
  ONETIME_SALARY_EXCHANGE_INSURANCE_NORDEA: {
    id: 'changeTypeMessages.ONETIME_SALARY_EXCHANGE_INSURANCE_NORDEA',
    defaultMessage: 'Bonusväxling Nordea',
  },
  ONGOING_SALARY_EXCHANGE_INSURANCE_FUTUR: {
    id: 'changeTypeMessages.ONGOING_SALARY_EXCHANGE_INSURANCE_FUTUR',
    defaultMessage: 'Löneväxling Futur',
  },
  ONETIME_SALARY_EXCHANGE_INSURANCE_FUTUR: {
    id: 'changeTypeMessages.ONETIME_SALARY_EXCHANGE_INSURANCE_FUTUR',
    defaultMessage: 'Bonusväxling Futur',
  },
  ONGOING_SALARY_EXCHANGE_INSURANCE_NORDNET: {
    id: 'changeTypeMessages.ONGOING_SALARY_EXCHANGE_INSURANCE_NORDNET',
    defaultMessage: 'Löneväxling Nordnet',
  },
  ONETIME_SALARY_EXCHANGE_INSURANCE_NORDNET: {
    id: 'changeTypeMessages.ONETIME_SALARY_EXCHANGE_INSURANCE_NORDNET',
    defaultMessage: 'Bonusväxling Nordnet',
  },
  ONGOING_SALARY_EXCHANGE_INSURANCE_LF: {
    id: 'changeTypeMessages.ONGOING_SALARY_EXCHANGE_INSURANCE_LF',
    defaultMessage: 'Löneväxling Länsförsäkringar',
  },
  ONETIME_SALARY_EXCHANGE_INSURANCE_LF: {
    id: 'changeTypeMessages.ONETIME_SALARY_EXCHANGE_INSURANCE_LF',
    defaultMessage: 'Bonusväxling Länsförsäkringar',
  },
  RETIREMENT_AGE_NORDEA: {
    id: 'changeTypeMessages.RETIREMENT_AGE_NORDEA',
    defaultMessage: 'Pensionsålder Tjänstepension Nordea',
  },
  RETIREMENT_AGE_NORDEA_SALARY_EXCHANGE: {
    id: 'changeTypeMessages.RETIREMENT_AGE_NORDEA_SALARY_EXCHANGE',
    defaultMessage: 'Pensionsålder Löneväxling Nordea',
  },
});

export const changeActionMessages = defineMessages<FlexInsuranceAction>({
  CREATE: {
    id: 'changeActionMessages.CREATE',
    defaultMessage: 'Nyteckning',
  },
  TERMINATE: {
    id: 'changeActionMessages.TERMINATE',
    defaultMessage: 'Avslutad',
  },
  PAUSE: {
    id: 'changeActionMessages.PAUSE',
    defaultMessage: 'Pausad',
  },
  RESUME: {
    id: 'changeActionMessages.RESUME',
    defaultMessage: 'Återaktiverad',
  },
  ADJUST: {
    id: 'changeActionMessages.ADJUST',
    defaultMessage: 'Ändrad',
  },
  SINGLE_PREMIUM: {
    id: 'changeActionMessages.SINGLE_PREMIUM',
    defaultMessage: 'Engångspremie',
  },
  DELETE: {
    id: 'changeActionMessages.DELETE',
    defaultMessage: 'Borttagen',
  },
});

export const changeStatusMessages = defineMessages<FlexChangeStatus>({
  PENDING_APPROVAL: {
    id: 'changeStatusMessages.PENDING_APPROVAL',
    defaultMessage: 'Inväntar godkännande',
  },
  NEW: {
    id: 'changeStatusMessages.NEW',
    defaultMessage: 'Ny',
  },
  PRECONDITION: {
    id: 'changeStatusMessages.PRECONDITION',
    defaultMessage: 'Inväntar förhandsvillkor',
  },
  IN_PROGRESS: {
    id: 'changeStatusMessages.IN_PROGRESS',
    defaultMessage: 'Under genomförande',
  },
  ERROR: {
    id: 'changeStatusMessages.ERROR',
    defaultMessage: 'Fel',
  },
  FINISHED: {
    id: 'changeStatusMessages.FINISHED',
    defaultMessage: 'Genomförd',
  },
  GRACE_PERIOD: {
    id: 'changeStatusMessages.GRACE_PERIOD',
    defaultMessage: 'I valfönster',
  },
  RISK_ASSESSMENT: {
    id: 'changeStatusMessages.RISK_ASSESSMENT',
    defaultMessage: 'Under riskbedömning',
  },
  RISK_ASSESSMENT_FAILED: {
    id: 'changeStatusMessages.RISK_ASSESSMENT_FAILED',
    defaultMessage: 'Avslag',
  },
  RISK_ASSESSMENT_FINISHED: {
    id: 'changeStatusMessages.RISK_ASSESSMENT_FINISHED',
    defaultMessage: 'Genomförd',
  },
  RISK_ASSESSMENT_FINISHED_CLAUSE: {
    id: 'changeStatusMessages.RISK_ASSESSMENT_FINISHED_CLAUSE',
    defaultMessage: 'Genomförd med klausul',
  },
  RISK_ASSESSMENT_FINISHED_INCREASE: {
    id: 'changeStatusMessages.RISK_ASSESSMENT_FINISHED_INCREASE',
    defaultMessage: 'Genomförd med premieförhöjning',
  },
  RISK_ASSESSMENT_FINISHED_OPTION: {
    id: 'changeStatusMessages.RISK_ASSESSMENT_FINISHED_OPTION',
    defaultMessage: 'Genomförd med option',
  },
});
